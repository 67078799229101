import './DetalleCpe.css'

import { CheckCircleTwoTone, CloseCircleTwoTone, TrademarkCircleOutlined, CloudDownloadOutlined, CheckCircleOutlined, DollarOutlined } from '@ant-design/icons'
import { Collapse, Spin, Table, Tabs, Tooltip, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { cpeRecuperarUrl, ocpGetNumUrl } from '../../../api/apiUrl'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { notifyType } from '../../../types/notifyType'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { formatAmount, formatCamelCase, formatDate, formatDigits, formatTime } from '../../../utils/formatUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { NotifyRed } from '../../Msg/NotifyRed'

var jszip = require('jszip')

export const DetalleCpe = (props) => {
    const { rcpt: rcptProp, cpe: cpeProp } = props

    const { axiosTnaGet } = useAxiosTna()
    const notifyRedRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [loadCargarOcp, setLoadCargarOcp] = useState(false)
    const [rcptDB, setRcptDB] = useState(null)
    const [cpeDB, setCpeDB] = useState(null)
    const [attachedFiles, setAttachedFiles] = useState([])
    const [programs, setPrograms] = useState([])
    const [receipts, setReceipts] = useState([])
    const [collActiveKeyRUC, setCollActiveKeyRUC] = useState([])
    const [collActiveKeyDET, setCollActiveKeyDET] = useState([])
    const [currentLineFI, setCurrentLineFI] = useState(null)

    useEffect(() => {
        let isMounted = true

        isMounted && setLoadPage(loadCargarOcp)

        return () => {
            isMounted = false
        }
    }, [loadCargarOcp])

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            if (rcptProp)
                setRcptDB({ ...rcptProp })
            if (cpeProp) {
                setCpeDB({ ...cpeProp })
                if (cpeProp.attach)
                    setAttachedFiles(() => {
                        const files = cpeProp.attach.map(file => ({ ...file, key: file.dataOrder }))
                        files.sort(sortAttach)
                        return files
                    })
                if (cpeProp.formaPago.programacion)
                    setPrograms(() => {
                        const progs = cpeProp.formaPago.programacion.map(prog => ({ ...prog, key: prog.line }))
                        progs.sort(sortProgram)
                        return progs
                    })
                if (cpeProp.origen?.recepcion)
                    setReceipts(() => {
                        const rcps = cpeProp.origen.recepcion.map(rcp => ({ ...rcp, key: rcp.numRE }))
                        rcps.sort(sortReceipt)
                        return rcps
                    })
            }
        }

        return () => {
            isMounted = false
        }
    }, [rcptProp, cpeProp])

    useEffect(() => {
        let isMounted = true

        const getOcp = async () => {
            let err = null
            let data = null

            setLoadCargarOcp(true)

            const url = ocpGetNumUrl(rcptDB.ruc, cpeDB.origen.numOC)
            const [data_api, err_api] = await axiosTnaGet(url)
            data = data_api
            err = err_api

            if (data && isMounted) {
                const { success, data: ocp } = data
                if (success) {
                    if (ocp.cpeAttach && cpeDB.attach)
                        setAttachedFiles(() => {
                            const files = cpeDB.attach.map(file => ({ ...file, key: file.dataOrder }))
                            ocp.cpeAttach.filter(oc => oc.required && !files.find(fi => fi.dataOrder === oc.line)).map(oc => {
                                files.push({ fileName: oc.name, contentType: null, dataOrder: oc.line, required: oc.required, key: oc.line })
                                return oc
                            })
                            files.sort(sortAttach)
                            return files
                        })
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)
            setLoadCargarOcp(false)
        }

        if (rcptDB && cpeDB && cpeDB.origen?.numOC)
            getOcp()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [rcptDB, cpeDB])

    const sortAttach = (a, b) => {
        if (a.dataOrder > b.dataOrder)
            return 1
        else if (a.dataOrder < b.dataOrder)
            return -1
        else
            return -1
    }

    const sortProgram = (a, b) => {
        if (a.line > b.line)
            return 1
        else if (a.line < b.line)
            return -1
        else
            return -1
    }

    const sortReceipt = (a, b) => {
        if (a.numRE > b.numRE)
            return 1
        else if (a.numRE < b.numRE)
            return -1
        else
            return -1
    }

    const getSTributoName = (nombreTributo) => {
        if (nombreTributo === configType.tributoDET.codigo)
            return configType.tributoDET.nombre
        else if (nombreTributo === configType.tributoRET.codigo)
            return configType.tributoRET.nombre
        else
            return ''
    }

    const getSTributoMoneda = (nombreTributo) => {
        if (nombreTributo === configType.tributoDET.codigo)
            return ` ${configType.monedaISOPEN}`
        else
            return ''
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = cpeRecuperarUrl(cpeDB.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex items-center'>
                    {
                        record.required &&
                        <Tooltip placement="right" title="Archivo requerido">
                            <TrademarkCircleOutlined size='small' className='text-red-600 mr-1' />
                        </Tooltip>
                    }
                    {
                        record.contentType ?
                            <button
                                className='text-blue-600 w-full text-left'
                                disabled={record.dataOrder === currentLineFI}
                                onClick={() => handleDownFile(record, false)}
                            >
                                {`${record.fileName}`}
                            </button> :
                            <span>{`${record.fileName}`}</span>
                    }
                    <div className='flex justify-end'>
                        {
                            record.dataOrder !== currentLineFI && record.contentType &&
                            <button className='mr-1' onClick={() => handleDownFile(record, true)}>
                                <CloudDownloadOutlined />
                            </button>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='mr-1' />
                        }
                    </div>
                </div>
        },
    ]

    const prColumns = [
        {
            title: 'Fecha',
            dataIndex: 'fechaProg',
            width: 120,
            render: (_, record) => <span>{formatDate(record.fechaProg)}</span>
        },
        {
            title: 'Monto',
            dataIndex: 'montoProg',
            align: 'right',
            width: 120,
            render: (_, record) => <span>{formatAmount(record.montoProg)}</span>
        },
        {
            title: '-@-',
            dataIndex: 'interno',
            width: 55,
            render: (text, record) =>
                <div className='flex items-center space-x-1.5'>
                    {
                        record.aprobada &&
                        <Tooltip placement="left" title="Programación aprobada">
                            <CheckCircleOutlined className='text-blue-800' />
                        </Tooltip>
                    }
                    {
                        record.pagada &&
                        <Tooltip placement="left" title="Programación pagada">
                            <DollarOutlined className='text-green-800' />
                        </Tooltip>
                    }
                </div>
        },
    ]

    const rcColumns = [
        {
            title: 'Nº Ingreso',
            dataIndex: 'numRE',
            width: 160,
        },
        {
            title: 'Monto',
            dataIndex: 'totRcp',
            align: 'right',
            width: 120,
            render: (_, record) => <span>{formatAmount(record.totRcp)}</span>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            {
                loadPage &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' />
                </div>
            }
            {
                cpeDB && !loadPage &&
                <>
                    <div className='detallecpe-data-cpe text-xs font-normal text-gray-900'>
                        {
                            cpeDB.emisor &&
                            <>
                                <p><span>{`RUC: `}</span>{`${cpeDB.emisor.ruc}`}</p>
                                <p><span>{`Emisor: `}</span>{`${cpeDB.emisor.name}`}</p>
                            </>
                        }
                        <div className="flex items-center">
                            <p><span>{`Nº de comprobante: `}</span>{`${cpeDB.tipoComprobanteID}-${cpeDB.serie}-${cpeDB.numero}`}</p>
                            <div className='flex items-center mx-1'>
                                {
                                    cpeDB.validado ?
                                        <Tooltip title="Comprobante validado">
                                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                                        </Tooltip> :
                                        <Tooltip title="Comprobante sin validar">
                                            <CloseCircleTwoTone twoToneColor="#eb2f96" />
                                        </Tooltip>
                                }
                            </div>
                            <p className="p-0.5 text-xs text-indigo-600">
                                {`${cpeDB.estado}`}
                            </p>
                        </div>
                        <p><span>{`Fecha de emisión: `}</span>{`${formatDate(cpeDB.fechaEmision)}`}</p>
                        {
                            rcptDB &&
                            <>
                                <p><span>{`RUC: `}</span>{`${rcptDB.ruc}`}</p>
                                <p><span>{`Receptor: `}</span>{`${rcptDB.name}`}</p>
                            </>
                        }
                        <p><span>{`Moneda: `}</span>{`${cpeDB.monedaISO}`}</p>
                        {
                            cpeDB.tipoCambio &&
                            <p><span>{`Tipo de cambio: `}</span>{`${formatDigits(cpeDB.tipoCambio)}`}</p>
                        }
                        <p><span>{`Total valor venta: `}</span>{`${formatAmount(cpeDB.base)}`}</p>
                        {
                            cpeDB.tributo &&
                            <ul>
                                {
                                    cpeDB.tributo.map((tributo, index) => (
                                        <li key={index}><span>{`${tributo.nombreTributo}: `}</span>{`${formatAmount(tributo.montoTributo)}`}</li>
                                    ))
                                }
                            </ul>
                        }
                        <p><span>{`Importe total: `}</span>{`${formatAmount(cpeDB.total)}`}</p>
                        {
                            cpeDB.stributo &&
                            <p><span>{`${getSTributoName(cpeDB.stributo.nombreTributo)}: `}</span>{`${formatAmount(cpeDB.stributo.montoTributo)}${getSTributoMoneda(cpeDB.stributo.nombreTributo)} [${cpeDB.stributo.categoriaID} ${cpeDB.stributo.factor * 100}%]`}</p>
                        }
                        <p><span>{`Forma de pago: `}</span>{`${cpeDB.formaPago?.formaPagoID ?? ''}`}</p>
                        {
                            cpeDB.formaPago.montoPendPago &&
                            <p><span>{`Monto neto pendiente: `}</span>{`${formatAmount(cpeDB.formaPago.montoPendPago)}`}</p>
                        }
                        {
                            cpeDB.formaPago.cuota &&
                            <ul>
                                {
                                    cpeDB.formaPago.cuota.filter(p => p.cuotaID !== '').map((cuota, index) => (
                                        <li key={index}><span>{`${cuota.cuotaID} `}</span>{`| ${formatDate(cuota.fechaPago)} | ${formatAmount(cuota.montoPago)}`}</li>
                                    ))
                                }
                            </ul>
                        }
                        {
                            cpeDB.ordenCompra &&
                            <p><span>{`Orden de compra: `}</span>{`${cpeDB.ordenCompra}`}</p>
                        }
                    </div>
                    {
                        cpeDB.factoring &&
                        <div className='detallecpe-collapse-fact mt-1' >
                            <Collapse className='text-xs' ghost defaultActiveKey={[]} activeKey={collActiveKeyRUC} onChange={setCollActiveKeyRUC}>
                                <Collapse.Panel header="Factoring" key="1">
                                    <div className="flex">
                                        <p><span>{`RUC: `}</span>{`${cpeDB.factoring.ruc}`}</p>
                                        <div className='flex items-center ml-1'>
                                            {
                                                cpeDB.factoring.validado ?
                                                    <Tooltip title="RUC validado">
                                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                    </Tooltip> :
                                                    <Tooltip title="RUC sin validar">
                                                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                                                    </Tooltip>
                                            }
                                        </div>
                                    </div>
                                    <p><span>{`Razón Social: `}</span>{`${cpeDB.factoring.nomRazSoc}`}</p>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    }
                    {
                        cpeDB.stributo?.autodetraccion &&
                        <div className='detallecpe-collapse-fact mt-1' >
                            <Collapse className='text-xs' ghost defaultActiveKey={[]} activeKey={collActiveKeyDET} onChange={setCollActiveKeyDET}>
                                <Collapse.Panel header="Autodetracción" key="1">
                                    <p><span>{`Nº de constancia: `}</span>{`${cpeDB.stributo.autodetraccion.numConstancia}`}</p>
                                    <p><span>{`Fecha de pago: `}</span>{`${formatDate(cpeDB.stributo.autodetraccion.fechaPago)}`}</p>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    }
                    <div className='detallecpe-data-adi-cpe mt-1.5 text-xs font-normal text-gray-900'>
                        <p><span>{`${formatCamelCase(rcptDB?.grpName ?? '')}: `}</span>{`${cpeDB.grupo}`}</p>
                        <p><span>{`Fecha de registro: `}</span>{`${formatTime(cpeDB.fechaRegistro)}`}</p>
                        <p><span>{`Usuario: `}</span>{`${cpeDB.userID}`}</p>
                        <p><span>{`Fecha contable: `}</span>{`${formatDate(cpeDB.fechaContable)}`}</p>
                        <p><span>{`Fecha de vencimiento: `}</span>{`${formatDate(cpeDB.fechaVcto)}`}</p>
                        <p><span>{`Situación: `}</span>{`${cpeDB.interno}`}</p>
                        {
                            ((cpeDB.formaPago?.montoProg ?? 0.0) !== (cpeDB.formaPago?.montoPendPago ?? 0.0)) &&
                            <p><span>{`Monto Programable: `}</span>{`${formatAmount(cpeDB.formaPago?.montoProg ?? 0.0)}`}</p>
                        }
                        <p><span>{`Saldo Total: `}</span>{`${formatAmount(cpeDB.saldo)}`}</p>
                        {
                            cpeDB.observacion &&
                            <p><span>{`Mensaje: `}</span>{`${cpeDB.observacion}`}</p>
                        }
                    </div>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Adjunto`,
                                key: '1',
                                children:
                                    attachedFiles.length > 0 ?
                                        <div className='detallecpe-tabla'>
                                            <Table
                                                columns={fiColumns}
                                                dataSource={attachedFiles}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                        : <span></span>
                            },
                            {
                                label: `Recepción`,
                                key: '2',
                                children:
                                    receipts.length > 0 ?
                                        <div className='max-w-xs flex'>
                                            <div className='detallecpe-tabla'>
                                                <Table
                                                    columns={rcColumns}
                                                    dataSource={receipts}
                                                    pagination={false}
                                                    bordered
                                                    size='small'
                                                    summary={(pageData) => {
                                                        let montoRcp = 0.0
                                                        pageData.forEach(({ totRcp }) => {
                                                            montoRcp += totRcp
                                                        })
                                                        return (
                                                            <>
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={1} align='right'>
                                                                        <Typography.Text type='danger'>{formatAmount(montoRcp)}</Typography.Text>
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            </>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <span></span>
                                        </div>
                                        : <span></span>
                            },
                            {
                                label: `Programación`,
                                key: '3',
                                children:
                                    programs.length > 0 ?
                                        <div className='max-w-xs flex'>
                                            <div className='detallecpe-tabla'>
                                                <Table
                                                    columns={prColumns}
                                                    dataSource={programs}
                                                    pagination={false}
                                                    bordered
                                                    size='small'
                                                    summary={(pageData) => {
                                                        let totalProg = 0.0
                                                        pageData.forEach(({ montoProg }) => {
                                                            totalProg += montoProg
                                                        })
                                                        return (
                                                            <>
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={1} align='right'>
                                                                        <Typography.Text type='danger'>{formatAmount(totalProg)}</Typography.Text>
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            </>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <span></span>
                                        </div>
                                        : <span></span>
                            },
                        ]}
                    />
                </>
            }
        </>
    )
}
