import { CloudDownloadOutlined } from '@ant-design/icons'
import { Spin, Table, Tabs, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ocpGetNumUrl, ocpRecuperarUrl, rcpGetNocUrl, segConnUrl } from '../../../api/apiUrl'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { notifyType } from '../../../types/notifyType'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { formatAmount, formatCamelCase, formatDate, formatTime } from '../../../utils/formatUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { NotifyRed } from '../../Msg/NotifyRed'

import './DetalleOcp.css'

var jszip = require('jszip')

export const DetalleOcp = (props) => {
    const { rcpt: rcptDB, numOC } = props

    const { axiosTnaGet } = useAxiosTna()
    const notifyRedRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadCargarOcp, setLoadCargarOcp] = useState(true)
    const [loadCargarRcp, setLoadCargarRcp] = useState(true)
    const [attachedFiles, setAttachedFiles] = useState([])
    const [currentLineFI, setCurrentLineFI] = useState(null)
    const [ocpDB, setOcpDB] = useState(null)
    const [rcpsDB, setRcpsDB] = useState([])

    useEffect(() => {
        setLoadPage(loadTokenConn || loadCargarOcp || loadCargarRcp)
    }, [loadTokenConn, loadCargarOcp, loadCargarRcp])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const getOcp = async () => {
            const url = ocpGetNumUrl(rcptDB.ruc, numOC)
            const [data, err] = await axiosTnaGet(url)

            if (data && isMounted) {
                const { success, data: ocp } = data
                if (success) {
                    setOcpDB(ocp)
                    if (ocp.attach)
                        setAttachedFiles(() => {
                            const files = ocp.attach.map(file => ({ ...file, key: file.dataOrder }))
                            files.sort(sortAttach)
                            return files
                        })
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadCargarOcp(false)
        }

        const getRcp = async () => {
            const url = rcpGetNocUrl(rcptDB.ruc, numOC)
            const [data, err] = await axiosTnaGet(url)

            if (data && isMounted) {
                const { success, data: rcs } = data
                if (success) {
                    rcs.sort(sortRcp)
                    setRcpsDB(rcs.map(rc => ({ ...rc, key: rc.numRE })))
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadCargarRcp(false)
        }

        if (!loadTokenConn && numOC) {
            getOcp()
            getRcp()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [rcptDB, numOC, loadTokenConn])

    const sortAttach = (a, b) => {
        if (a.dataOrder > b.dataOrder)
            return 1
        else if (a.dataOrder < b.dataOrder)
            return -1
        else
            return -1
    }

    const sortRcp = (a, b) => {
        if (a.fechaRecibo > b.fechaRecibo)
            return 1
        else if (a.fechaRecibo < b.fechaRecibo)
            return -1
        else if (a.numRE > b.numRE)
            return 1
        else
            return -1
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = ocpRecuperarUrl(ocpDB.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex items-center'>
                    <button
                        className='text-blue-600 w-full text-left'
                        disabled={record.dataOrder === currentLineFI}
                        onClick={() => handleDownFile(record, false)}
                    >
                        {`${record.fileName}`}
                    </button>
                    <div className='flex justify-end'>
                        {
                            record.dataOrder !== currentLineFI &&
                            <button className='mr-1' onClick={() => handleDownFile(record, true)}>
                                <CloudDownloadOutlined />
                            </button>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='mr-1' />
                        }
                    </div>
                </div>
        },
    ]

    const rcColumns = [
        {
            title: 'Nº Ingreso',
            dataIndex: 'numRE',
            width: 150,
        },
        {
            title: 'Fecha',
            dataIndex: 'fechaRecibo',
            width: 100,
            render: (_, record) => <span>{formatDate(record.fechaRecibo)}</span>
        },
        {
            title: 'Monto',
            dataIndex: 'total',
            width: 120,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.total)}</span>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            {
                loadPage &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' />
                </div>
            }
            {
                ocpDB &&
                <>
                    <div className='detalleocp-data text-xs font-normal text-gray-900'>
                        {
                            ocpDB.emisor &&
                            <>
                                <p><span>{`RUC: `}</span>{`${ocpDB.emisor.ruc}`}</p>
                                <p><span>{`Emisor: `}</span>{`${ocpDB.emisor.name}`}</p>
                            </>
                        }
                        <div className="flex items-center">
                            <p><span>{`Nº de documento: `}</span>{`${ocpDB.numOC}`}</p>
                            <p className="p-0.5 text-xs ml-1 text-indigo-600">
                                {`${ocpDB.estado}`}
                            </p>
                        </div>
                        <p><span>{`Fecha de emisión: `}</span>{`${formatDate(ocpDB.fechaEmision)}`}</p>
                        {
                            rcptDB &&
                            <>
                                <p><span>{`RUC: `}</span>{`${rcptDB.ruc}`}</p>
                                <p><span>{`Receptor: `}</span>{`${rcptDB.name}`}</p>
                            </>
                        }
                        <p><span>{`Moneda: `}</span>{`${ocpDB.monedaISO}`}</p>
                        <p><span>{`Total: `}</span>{`${formatAmount(ocpDB.total)}`}</p>
                        <p><span>{`Total recibido: `}</span>{`${formatAmount(ocpDB.totRcp)}`}</p>
                        <p><span>{`Total facturado: `}</span>{`${formatAmount(ocpDB.totCpe)}`}</p>
                        <p><span>{`Tipo de orden: `}</span>{`${ocpDB.tipoOrden}`}</p>
                        <p><span>{`Condición de pago: `}</span>{`${ocpDB.condPago} ${ocpDB.condPago === 1 ? 'día' : 'días'}`}</p>
                        <p><span>{`Comprador: `}</span>{`${ocpDB.comprador ?? ''}`}</p>
                    </div>
                    <div className='detalleocp-data-adi mt-1.5 text-xs font-normal text-gray-900'>
                        <p><span>{`${formatCamelCase(rcptDB?.grpName ?? '')}: `}</span>{`${ocpDB.grupo}`}</p>
                        <p><span>{`Fecha de registro: `}</span>{`${formatTime(ocpDB.fechaRegistro)}`}</p>
                    </div>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Adjunto`,
                                key: '1',
                                children:
                                    attachedFiles.length > 0 ?
                                        <div className='detalleocp-tabla'>
                                            <Table
                                                columns={fiColumns}
                                                dataSource={attachedFiles}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                        : <span></span>
                            },
                            {
                                label: `Recepción`,
                                key: '2',
                                children:
                                    rcpsDB.length > 0 ?
                                        <div className='detalleocp-tabla'>
                                            <Table
                                                columns={rcColumns}
                                                dataSource={rcpsDB}
                                                pagination={false}
                                                bordered
                                                size='small'
                                                summary={(pageData) => {
                                                    let montoRcp = 0.0
                                                    pageData.forEach(({ total }) => {
                                                        montoRcp += total
                                                    })
                                                    return (
                                                        <>
                                                            <Table.Summary.Row>
                                                                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                                <Table.Summary.Cell index={2} align='right'>
                                                                    <Typography.Text type='danger'>{formatAmount(montoRcp)}</Typography.Text>
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                        </>
                                                    )
                                                }}
                                            />
                                        </div>
                                        : <span></span>
                            },
                        ]}
                    />
                </>
            }
        </>
    )
}
