import './RegistrarOPE.css'

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { FileAddOutlined, SearchOutlined, ZoomOutOutlined, QuestionCircleOutlined, CloseCircleOutlined, FileTextOutlined, ExclamationCircleOutlined, EyeOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Modal, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip, message } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { catGetCodUrl, opeEliminarUrl, opeGetNumUrl, opeGetPendUrl, opeGetRegUrl, rctGrpRecurUrl, segConnUrl, tsk10Url, tskRecuperarUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { NewOpe } from '../../../components/Panel/enr/RegOpe/NewOpe'
import { DetalleOpe } from '../../../components/Panel/Detail/DetalleOpe'
import { DetalleSol } from '../../../components/Panel/Detail/DetalleSol'
import { DetalleRen } from '../../../components/Panel/Detail/DetalleRen'

var jszip = require('jszip')

export const RegistrarOPE = ({ page: { code: resource } }) => {
    const dvFechaIni = moment().startOf('month')
    const dvFechaFin = moment().endOf('month')

    const { confirm } = Modal
    const { axiosTnaGet, axiosTnaPost, axiosTnaDelete } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const detSolRef = useRef()
    const detRenRef = useRef()
    const newOpeRef = useRef()
    const detOpeRef = useRef()
    const [disablePage, setDisablePage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
    const [loadTipoOpe, setLoadTipoOpe] = useState(true)
    const [loadTipoPago, setLoadTipoPago] = useState(true)
    const [loadConfigRT, setLoadConfigRT] = useState(false)
    const [loadBusqOpe, setLoadBusqOpe] = useState(false)
    const [loadEliOpe, setLoadEliOpe] = useState(false)
    const [loadTask10Add, setLoadTask10Add] = useState(false)
    const [loadTask10Down, setLoadTask10Down] = useState(false)
    const [allGroup, setAllGroup] = useState(false)
    const [receptores, setReceptores] = useState([])
    const [grupos, setGrupos] = useState([])
    const [tiposOpe, setTiposOpe] = useState([])
    const [areas, setAreas] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [tiposPago, setTiposPago] = useState([])
    const [selReceptor, setSelReceptor] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [dbSolicitud, setDbSolicitud] = useState(null)
    const [dbRendicion, setDbRendicion] = useState(null)
    const [pendiente, setPendiente] = useState(false)
    const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
    const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
    const [foTipoOpe, setFoTipoOpe] = useState(null)
    const [foArea, setFoArea] = useState(null)
    const [foCuenta, setFoCuenta] = useState(null)
    const [foNumOP, setFoNumOP] = useState(null)
    const [foSave, setFoSave] = useState(null)
    const [operaciones, setOperaciones] = useState([])
    const [selOperacion, setSelOperacion] = useState(null)
    const [opTotalDocuments, setOpTotalDocuments] = useState(0)
    const [opPageSize, setOpPageSize] = useState(10)
    const [opCurrentPage, setOpCurrentPage] = useState(1)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)
    const [activeTabOpe, setActiveTabOpe] = useState('1')
    const [taskInterval, setTaskInterval] = useState(false)
    const [task10, setTask10] = useState({ task: null, id: null, first: true })

    useEffect(() => {
        setLoadPage(loadTokenConn || loadRcptEmsr || loadTipoOpe || loadTipoPago)
    }, [loadTokenConn, loadRcptEmsr, loadTipoOpe, loadTipoPago])

    useEffect(() => {
        setDisablePage(loadConfigRT || loadBusqOpe || loadEliOpe || loadTask10Add || loadTask10Down)
    }, [loadConfigRT, loadBusqOpe, loadEliOpe, loadTask10Add, loadTask10Down])

    useEffect(() => {
        setDbSolicitud(selOperacion && selOperacion.solRen ? { numSO: selOperacion.numOP } : null)
        setDbRendicion(selOperacion && !selOperacion.solRen ? { numRD: selOperacion.numOP } : null)
    }, [selOperacion])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const rcptEmsr = async () => {
            const url = usuRcptEmsrUrl(resource)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setReceptores(data.rcpt)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRcptEmsr(false)
        }

        const catTipoOP = async () => {
            const url = catGetCodUrl(configType.catTipoOpeER)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposOpe(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoOpe(false)
        }

        const catTipoPag = async () => {
            const url = catGetCodUrl(configType.catTipoPago)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposPago(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoPago(false)
        }

        if (!loadTokenConn) {
            rcptEmsr()
            catTipoOP()
            catTipoPag()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const t10Get = async () => {
            const url = tsk10Url()
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTask10(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
            if (err)
                console.log(`Task: ${err.message}`)
        }

        if (!loadTokenConn && (task10.first || (!task10.task && task10.id) || (task10.task && !task10.task.complete)))
            t10Get()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [taskInterval, loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const interval = setInterval(() => {
            isMounted && setTaskInterval(prev => !prev)
        }, 5000)

        return () => {
            clearInterval(interval)
            isMounted = false
        }
    }, [])

    const setConfigRT = useCallback(async (person) => {
        setFoArea(null)
        setFoCuenta(null)

        if (!person) {
            setAreas([])
            setCuentas([])
            return
        }

        setLoadConfigRT(true)

        const url = rctGrpRecurUrl(person.rcptRUC, allGroup ? '' : person.ruc, resource)
        const [data, err] = await axiosTnaGet(url)

        if (data) {
            const { success, data: config } = data
            if (success) {
                setAreas(config.areas || [])
                setCuentas(config.cuentas || [])
            }
        }
        if (err) {
            setAreas([])
            setCuentas([])
            notifyRedRef.current.handleOpen(err, notifyType.error)
        }

        setLoadConfigRT(false)

        // eslint-disable-next-line
    }, [setAreas, setCuentas, setFoArea, setFoCuenta])

    const sortOpe = (a, b) => {
        if (a.index > b.index)
            return 1
        else
            return -1
    }

    const getUsu = (usu) => {
        if (!usu)
            return ''
        else if (usu.docIdentidad)
            return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
        else
            return `${usu.userID} - ${usu.userName}`
    }

    const getOpe = (ope, index = null) => {
        var nope = { ...ope, key: ope.id, index, registrado: (ope.linOP !== undefined && ope.linOP !== null) }
        if (index === null)
            delete nope.index
        return nope
    }

    const getTitleDrawer = (ope) => {
        if (!ope || ope.isOpe === null || ope.isOpe === undefined || ope.isDet === null || ope.isDet === undefined)
            return ''
        else if (!ope.isOpe)
            return `${ope.solRen ? 'Solicitud' : 'Rendición'} ( ${ope.numOP} )`
        else if (ope.isDet === 1)
            return `Operación ( ${ope.numOP} )`
        else if (ope.isDet === 0 && !ope.registrado)
            return `Nueva Operación ( ${ope.numOP} ) ( ${ope.grpCode} )`
        else if (ope.isDet === 0 && ope.registrado)
            return `Editar Operación ( ${ope.numOP} ) ( ${ope.grpCode} )`
        else
            return ''
    }

    const clearFiltroOP = (all = true) => {
        if (all) {
            setFoSave(null)
            setOperaciones([])
            setOpTotalDocuments(0)
        }
        setSelOperacion(null)
        setSelectedRows([])
        setSelectedRowKeys([])
    }

    const handleActiveTabOpe = useCallback((tab) => { setActiveTabOpe(tab) }, [setActiveTabOpe])

    const handleSetOperacion = useCallback((ope, sel = true) => {
        const uope = getOpe(ope)
        setOperaciones(prev => {
            const oope = pendiente ? prev.find(p => p.id === uope.origen.origenID) : prev.find(p => p.id === uope.id)
            const aope = pendiente ? prev.filter(p => p.id !== uope.origen.origenID) : prev.filter(p => p.id !== uope.id)
            const nope = pendiente ?
                (oope && uope.origen.mtoTsr > 0.0 ? [...aope, { ...oope, monto: uope.origen.mtoTsr }] : [...aope]) :
                (oope ? [...aope, { ...oope, ...uope }] : [...aope])
            nope.sort(sortOpe)
            return nope
        })
        if (sel)
            setSelOperacion(prev => ({ ...prev, ...uope }))
    }, [setSelOperacion, pendiente])

    const handlePendiente = (value) => {
        setPendiente(value)
        setFoFechaIni(value ? null : dvFechaIni)
        setFoFechaFin(value ? null : dvFechaFin)
        setFoArea(null)
        setFoCuenta(null)
        clearFiltroOP()
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        setSelOperacion(null)
        setActiveTabOpe('1')
    }

    const handleSelReceptor = useCallback((person) => {
        setSelReceptor(person)
        let a_grupos = []
        if (person && person.grupo.length > 0)
            a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
        setGrupos(a_grupos)
        clearFiltroOP()
    }, [setSelReceptor])

    const handleSelGrupo = useCallback((person) => {
        setSelGrupo(person)
        setConfigRT(person)
        clearFiltroOP()
    }, [setSelGrupo, setConfigRT])

    const handleBuscarOP = async (isReg) => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (isReg && !pendiente && (!foFechaIni || !foFechaFin))
            err = {
                message: msgType.selectFecha,
                oops: false
            }
        else if (pendiente && !foTipoOpe)
            err = {
                message: msgType.selectTipoOP,
                oops: false
            }
        else if (!isReg && !foNumOP)
            err = {
                message: msgType.inputNumOP,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqOpe(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
        const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
        const tipo = foTipoOpe || ''
        const area = foArea || ''
        const numeroCta = foCuenta || ''
        const numOP = foNumOP || ''

        const url = pendiente ?
            opeGetPendUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, tipo, area, resource, 1, opPageSize) : (isReg ?
                opeGetRegUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, tipo, area, numeroCta, resource, 1, opPageSize) :
                opeGetNumUrl(selReceptor.ruc, numOP, 1, opPageSize))
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: opes } = data
            setOperaciones(opes.map((ope, index) => getOpe(ope, index)))
            setOpTotalDocuments(totalDocuments)
            setOpCurrentPage(1)
            clearFiltroOP(false)
            setFoSave({
                svSelReceptor: selReceptor,
                svGrupo: grupo,
                svFechaIni: fechaIni,
                svFechaFin: fechaFin,
                svTipo: tipo,
                svArea: area,
                svNumeroCta: numeroCta,
                svNumOP: numOP,
                svPendiente: pendiente,
                svIsReg: isReg,
            })
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqOpe(false)
        setLastAccess()
    }

    const handleChangePagOP = async (page, pageSize) => {
        if (!foSave)
            return

        const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, svNumeroCta, svNumOP, svPendiente, svIsReg } = foSave

        let err = null
        let data = null

        setLoadBusqOpe(true)

        const url = svPendiente ?
            opeGetPendUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, resource, page, pageSize) : (svIsReg ?
                opeGetRegUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, svNumeroCta, resource, page, pageSize) :
                opeGetNumUrl(svSelReceptor.ruc, svNumOP, page, pageSize))
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: opes } = data
            setOperaciones(opes.map((ope, index) => getOpe(ope, index)))
            setOpTotalDocuments(totalDocuments)
            setOpCurrentPage(page)
            clearFiltroOP(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqOpe(false)
        setLastAccess()
    }

    const handleNuevaOpe = () => {
        const ope = (selectedRows && selectedRows.length > 0) ? (operaciones.find(p => p.id === selectedRows[0].id) || {}) : {}
        let err = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!ope.id)
            err = {
                message: msgType.selectOperacion,
                oops: false
            }
        else if (selectedRows && selectedRows.length > 1)
            err = {
                message: msgType.registroNoOne,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setSelOperacion({ ...ope, isOpe: true, isDet: 0 })
        setOpenDrawer(true)
    }

    const handleDetalleOpe = (record, isOpe, isDet) => {
        const ope = { ...record, isOpe, isDet }
        setSelOperacion(ope)
        setOpenDrawer(true)
    }

    const handleRegistrarOpe = () => {
        if (newOpeRef.current.exactAmount())
            newOpeRef.current.handleSave()
        else
            confirm({
                title: msgType.neDataMontoAteT,
                icon: <ExclamationCircleOutlined />,
                content: '¿Confirma la operación?',
                onOk() {
                    newOpeRef.current.handleSave()
                },
                onCancel() { },
            })
    }

    const handleEliminarOpe = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selOperacion)
            err = {
                message: msgType.selectOperacion,
                oops: false
            }

        if (err) {
            selOperacion.isDet === 1 ? detOpeRef.current.showNotify(err, notifyType.warning) : newOpeRef.current.showNotify(err, notifyType.warning)
            return
        }

        setLoadEliOpe(true)

        const url = opeEliminarUrl(selOperacion.id)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data) {
            setOperaciones(prev => prev.filter(ope => ope.id !== selOperacion.id))
            handleCloseDrawer()
            message.success(msgType.operacionEliminar);
        }
        if (err)
            selOperacion.isDet === 1 ? detOpeRef.current.showNotify(err, notifyType.error) : newOpeRef.current.showNotify(err, notifyType.error)

        setLoadEliOpe(false)
        setLastAccess()
    }

    const handleTask10_Add = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foFechaIni || !foFechaFin)
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadTask10Add(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni.format().split('T')[0] + 'T00:00:00'
        const fechaFin = foFechaFin.format().split('T')[0] + 'T00:00:00'
        const tpoOP = foTipoOpe || ''
        const area = foArea || ''
        const numeroCta = foCuenta || ''

        const url = tsk10Url()
        const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, grupo, fechaIni, fechaFin, tpoOP, area, numeroCta, resource })
        data = data_api
        err = err_api

        if (data) {
            setTask10(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask10Add(false)
        setLastAccess()
    }

    const handleTask10_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask10Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask10(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask10Down(false)
        setLastAccess()
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: false,
            name: record.id,
        }),
        type: 'radio',
    }

    const opColumns = [
        {
            title: 'Nº Operación',
            dataIndex: 'numOP',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <button
                            className='text-blue-600 w-full text-left'
                            onClick={() => handleDetalleOpe(record, false, 1)}
                        >
                            <span>{`${record.numOP}`}</span>
                            <span className=' text-black' style={{ fontSize: '0.6rem' }}>{pendiente ? '' : (record.isSubLin ? ` (${record.linOP + 1}.${record.subLinOP + 1})` : ` (${record.linOP + 1})`)}</span>
                        </button>
                    </div>
                    {
                        !pendiente &&
                        <div className='flex justify-end space-x-1'>
                            <Tooltip title={'Detalle de operación'} placement="right">
                                <button onClick={() => handleDetalleOpe(record, true, 1)}>
                                    <EyeOutlined />
                                </button>
                            </Tooltip>
                        </div>
                    }
                </div>,
        },
        {
            title: 'Tipo',
            dataIndex: 'tpoOP',
            width: 120,
        },
        {
            title: 'Fecha Ope.',
            dataIndex: 'fechaOpe',
            width: 92,
            render: (_, record) => <span>{formatDate(record.fechaOpe)}</span>
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 50,
            align: 'center',
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            })
        },
        {
            title: 'Monto',
            dataIndex: 'monto',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.monto)}</span>
        },
        {
            title: 'Area',
            dataIndex: 'area',
            width: 250,
            responsive: ['lg'],
            render: (_, record) => allGroup ? <span>{`${record.grpCode} ${record.area}`}</span> : <span>{record.area}</span>
        },
        {
            title: pendiente ? 'Estado' : 'Nª Cuenta',
            dataIndex: pendiente ? 'estado' : 'numeroCta',
            width: pendiente ? 110 : 150,
        },
        {
            title: 'Rindente',
            dataIndex: 'rindente',
            width: 320,
            responsive: ['xl'],
            render: (_, record) => <span>{getUsu(record.rindente)}</span>
        },
    ]

    return (
        <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
            <Drawer
                title={getTitleDrawer(selOperacion)}
                placement="right"
                onClose={handleCloseDrawer}
                open={openDrawer}
                getContainer={false}
                style={{ position: 'absolute' }}
                bodyStyle={{ padding: 20, }}
                headerStyle={{ padding: 15, }}
                destroyOnClose={true}
                maskClosable={false}
                zIndex={5}
                width={720}
                closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
                extra={
                    <Space>
                        {
                            (selOperacion && selOperacion.isOpe) ?
                                <>
                                    {
                                        selOperacion.isDet === 0 && activeTabOpe === '1' &&
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`¿Confirma la operación?`}
                                            onConfirm={handleRegistrarOpe}
                                            placement="left"
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button shape="round" type="primary">
                                                Guardar
                                            </Button>
                                        </Popconfirm>
                                    }
                                    {
                                        (selOperacion.isDet === 1 || (selOperacion.isDet === 0 && activeTabOpe === '1' && selOperacion.registrado)) &&
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`¿Confirma la operación?`}
                                            onConfirm={handleEliminarOpe}
                                            placement="left"
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button shape="round" type="primary">
                                                Eliminar
                                            </Button>
                                        </Popconfirm>
                                    }
                                </> :
                                <></>
                        }
                    </Space>
                }
            >
                {
                    selOperacion ?
                        <>
                            {
                                !selOperacion.isOpe ?
                                    <>
                                        {
                                            selOperacion.solRen ?
                                                <DetalleSol ref={detSolRef} selReceptor={selReceptor} dbSolicitud={dbSolicitud} /> :
                                                <DetalleRen ref={detRenRef} selReceptor={selReceptor} dbRendicion={dbRendicion} />
                                        }
                                    </> :
                                    <>
                                        {
                                            selOperacion.isDet === 0 ?
                                                <NewOpe ref={newOpeRef} selReceptor={selReceptor} selOperacion={selOperacion}
                                                    tiposOpe={tiposOpe} cuentas={cuentas} tiposPago={tiposPago} updateOpe={handleSetOperacion} tabKey={handleActiveTabOpe} /> :
                                                <>
                                                    {
                                                        selOperacion.isDet === 1 ?
                                                            <DetalleOpe ref={detOpeRef} selReceptor={selReceptor} selOperacion={selOperacion} /> :
                                                            <></>
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </> :
                        <></>
                }
            </Drawer>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex-grow'>
                        <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
                            <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
                            {
                                !allGroup &&
                                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
                            }
                            <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
                        </div>

                        <div className='p-3 flex flex-col ant-common-titania'>
                            <div className="flex flex-row flex-wrap space-x-3 mb-3">
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        !disablePage ?
                                            <div className='flex items-center cursor-pointer' onClick={handleNuevaOpe}>
                                                <Tooltip title={pendiente ? "Nueva operación" : "Editar operación"} placement='right'>
                                                    {
                                                        pendiente ?
                                                            <FileAddOutlined className="mr-1.5 text-gray-600" aria-hidden="true" /> :
                                                            <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                                    }
                                                </Tooltip>
                                                {pendiente ? 'Nueva' : 'Editar'}
                                            </div>
                                            :
                                            <div className='flex items-center cursor-not-allowed'>
                                                {
                                                    pendiente ?
                                                        <FileAddOutlined className="mr-1.5 text-gray-600" aria-hidden="true" /> :
                                                        <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                                }
                                                {pendiente ? 'Nueva' : 'Editar'}
                                            </div>
                                    }
                                </div>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task10.id || task10.task?.message) ?
                                            <>
                                                {
                                                    (!disablePage && !pendiente) ?
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            title={`¿Confirma la operación?`}
                                                            onConfirm={handleTask10_Add}
                                                            placement="right"
                                                            okText="Sí"
                                                            cancelText="No"
                                                        >
                                                            <div className='flex items-center cursor-pointer'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                                {`Descargar`}
                                                            </div>
                                                        </Popconfirm> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask10Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Descargar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task10.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask10Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask10_Down(task10.task)}
                                                            >
                                                                {task10.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    task10.task?.message &&
                                    <div className='flex items-center'>
                                        <p className="text-xs text-red-700">
                                            {task10.task?.message}
                                        </p>
                                    </div>
                                }
                            </div>

                            <p className="text-xs text-gray-700 mb-2">
                                Busca una operación usando los filtros de fecha y tipo de operación.
                            </p>
                            <div className='flex space-x-10'>
                                <div className='flex items-center space-x-2'>
                                    <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                                    <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                                    <Switch checkedChildren="PEND" unCheckedChildren="PEND" checked={pendiente} onChange={handlePendiente} />
                                    <Select placeholder='Tipo' allowClear style={{ width: 150 }} onChange={(v) => setFoTipoOpe(v || null)}>
                                        {
                                            tiposOpe.map(({ codigo, nombre }) => (
                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                    <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                                        {
                                            loadConfigRT ?
                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg> :
                                                <>
                                                    <Select placeholder='Area' value={foArea} allowClear style={{ width: 210 }} onChange={(v) => setFoArea(v || null)}>
                                                        {
                                                            areas.map(({ areaCode, areaName }) => (
                                                                <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                    {
                                                        !pendiente &&
                                                        <Select placeholder='Cuenta' value={foCuenta} allowClear style={{ width: 240 }} onChange={(v) => setFoCuenta(v || null)}>
                                                            {
                                                                cuentas.map(({ bancoID, monedaISO, numeroCta }) => (
                                                                    <Select.Option key={numeroCta} value={numeroCta}>{`${bancoID}_${monedaISO} ${numeroCta}`}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    }
                                                </>
                                        }
                                    </div>
                                    {
                                        !disablePage ?
                                            <Tooltip title="Buscar operación">
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => handleBuscarOP(true)} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                                {
                                    !pendiente &&
                                    <div className='flex items-center space-x-2'>
                                        <Input placeholder="Nº de operación" style={{ width: 145 }} onChange={(e) => setFoNumOP(e.target.value)} />
                                        {
                                            !disablePage ?
                                                <Tooltip title="Buscar operación">
                                                    <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => handleBuscarOP(false)} />
                                                </Tooltip> :
                                                <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                        }
                                    </div>
                                }
                            </div>

                            <div className='lg:max-w-7xl'>
                                {
                                    loadBusqOpe ?
                                        <div className='my-5 flex justify-center'>
                                            <Spin tip='Loading....' size='large' />
                                        </div> :
                                        <>
                                            <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-fnregope'>
                                                <Table
                                                    rowSelection={rowSelection}
                                                    columns={opColumns}
                                                    dataSource={operaciones}
                                                    pagination={false}
                                                    scroll={{ y: 400 }}
                                                    bordered
                                                    size='small'
                                                />
                                            </div>
                                            <div className='mt-2 flex justify-end ant-pagination-titania'>
                                                <Pagination
                                                    defaultCurrent={opCurrentPage}
                                                    total={opTotalDocuments}
                                                    showSizeChanger
                                                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} operaciones`}
                                                    defaultPageSize={opPageSize}
                                                    onChange={handleChangePagOP}
                                                    onShowSizeChange={(_, size) => setOpPageSize(size)}
                                                    disabled={disablePage} />
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
