import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useTokenTna } from "./useTokenTna";
import { axiosTna } from "../api/axiosTna";

const useInterceptTna = () => {
    const refresh = useTokenTna()
    const { auth } = useAuth()

    useEffect(() => {
        const requestIntercept = axiosTna.interceptors.request.use(
            config => {
                if (!config.headers['Authorization'])
                    config.headers['Authorization'] = `Bearer ${auth?.tokenTna ?? ''}`
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosTna.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true
                    const newTokenTna = await refresh()
                    prevRequest.headers['Authorization'] = `Bearer ${newTokenTna}`
                    if (newTokenTna)
                        return axiosTna(prevRequest)
                    else
                        return Promise.reject(error)
                }
                return Promise.reject(error)
            }
        )

        return () => {
            axiosTna.interceptors.request.eject(requestIntercept)
            axiosTna.interceptors.response.eject(responseIntercept)
        }
        // eslint-disable-next-line
    }, [auth])

    return axiosTna
}

export default useInterceptTna