import './ConsultarSOL.css'

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { CloseCircleOutlined, SearchOutlined, ZoomOutOutlined, QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { rctGrpRecurUrl, segConnUrl, solGetConUrl, solGetNumUrl, stdGetTipoUrl, tsk9Url, tskRecuperarUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { DetalleSol } from '../../../components/Panel/Detail/DetalleSol'

var jszip = require('jszip')

export const ConsultarSOL = ({ page: { code: resource } }) => {
    const dvFechaIni = moment().startOf('month')
    const dvFechaFin = moment().endOf('month')

    const { axiosTnaGet, axiosTnaPost } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const detSolRef = useRef()
    const [disablePage, setDisablePage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
    const [loadEstado, setLoadEstado] = useState(true)
    const [loadConfigRT, setLoadConfigRT] = useState(false)
    const [loadBusqSol, setLoadBusqSol] = useState(false)
    const [loadTask9Add, setLoadTask9Add] = useState(false)
    const [loadTask9Down, setLoadTask9Down] = useState(false)
    const [allGroup, setAllGroup] = useState(false)
    const [receptores, setReceptores] = useState([])
    const [grupos, setGrupos] = useState([])
    const [tiposSol, setTiposSol] = useState([])
    const [areas, setAreas] = useState([])
    const [estados, setEstados] = useState([])
    const [selReceptor, setSelReceptor] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
    const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
    const [foTipoSol, setFoTipoSol] = useState(null)
    const [foArea, setFoArea] = useState(null)
    const [foEstado, setFoEstado] = useState(null)
    const [foNumSO, setFoNumSO] = useState(null)
    const [foSave, setFoSave] = useState(null)
    const [solicitudes, setSolicitudes] = useState([])
    const [selSolicitud, setSelSolicitud] = useState(null)
    const [soTotalDocuments, setSoTotalDocuments] = useState(0)
    const [soPageSize, setSoPageSize] = useState(10)
    const [soCurrentPage, setSoCurrentPage] = useState(1)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [, setSelectedRows] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)
    const [taskInterval, setTaskInterval] = useState(false)
    const [task9, setTask9] = useState({ task: null, id: null, first: true })

    useEffect(() => {
        setLoadPage(loadTokenConn || loadRcptEmsr || loadEstado)
    }, [loadTokenConn, loadRcptEmsr, loadEstado])

    useEffect(() => {
        setDisablePage(loadConfigRT || loadBusqSol || loadTask9Add || loadTask9Down)
    }, [loadConfigRT, loadBusqSol, loadTask9Add, loadTask9Down])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const rcptEmsr = async () => {
            const url = usuRcptEmsrUrl(resource)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setReceptores(data.rcpt)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRcptEmsr(false)
        }

        const stdTipo = async () => {
            const url = stdGetTipoUrl(configType.stdSolicitud)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setEstados(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadEstado(false)
        }

        if (!loadTokenConn) {
            rcptEmsr()
            stdTipo()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const t9Get = async () => {
            const url = tsk9Url()
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTask9(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
            if (err)
                console.log(`Task: ${err.message}`)
        }

        if (!loadTokenConn && (task9.first || (!task9.task && task9.id) || (task9.task && !task9.task.complete)))
            t9Get()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [taskInterval, loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const interval = setInterval(() => {
            isMounted && setTaskInterval(prev => !prev)
        }, 5000)

        return () => {
            clearInterval(interval)
            isMounted = false
        }
    }, [])

    const setConfigRT = useCallback(async (person) => {
        setFoTipoSol(null)
        setFoArea(null)

        if (!person) {
            setTiposSol([])
            setAreas([])
            return
        }

        setLoadConfigRT(true)

        const url = rctGrpRecurUrl(person.rcptRUC, allGroup ? '' : person.ruc, resource)
        const [data, err] = await axiosTnaGet(url)

        if (data) {
            const { success, data: config } = data
            if (success) {
                setTiposSol(config.tiposSol || [])
                setAreas(config.areas || [])
            }
        }
        if (err) {
            setTiposSol([])
            setAreas([])
            notifyRedRef.current.handleOpen(err, notifyType.error)
        }

        setLoadConfigRT(false)
        // eslint-disable-next-line
    }, [setTiposSol, setAreas, setFoTipoSol, setFoArea])

    const getUsu = (usu) => {
        if (!usu)
            return ''
        else if (usu.docIdentidad)
            return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
        else
            return `${usu.userID} - ${usu.userName}`
    }

    const getSol = (sol, index = null) => {
        var nsol = { ...sol, key: sol.id, index }
        if (index === null)
            delete nsol.index
        return nsol
    }

    const getTitleDrawer = (sol) => {
        if (!sol || sol.isDet === null || sol.isDet === undefined)
            return ''
        else if (sol.isDet === 1)
            return `Solicitud ( ${sol.numSO} )`
        else
            return ''
    }

    const clearFiltroSO = (all = true) => {
        if (all) {
            setFoSave(null)
            setSolicitudes([])
            setSoTotalDocuments(0)
        }
        setSelSolicitud(null)
        setSelectedRows([])
        setSelectedRowKeys([])
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        setSelSolicitud(null)
    }

    const handleSelReceptor = useCallback((person) => {
        setSelReceptor(person)
        let a_grupos = []
        if (person && person.grupo.length > 0)
            a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
        setGrupos(a_grupos)
        clearFiltroSO()
    }, [setSelReceptor])

    const handleSelGrupo = useCallback((person) => {
        setSelGrupo(person)
        setConfigRT(person)
        clearFiltroSO()
    }, [setSelGrupo, setConfigRT])

    const handleBuscarSOFecha = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foFechaIni || !foFechaFin)
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqSol(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni.format().split('T')[0] + 'T00:00:00'
        const fechaFin = foFechaFin.format().split('T')[0] + 'T00:00:00'
        const tipo = foTipoSol || ''
        const area = foArea || ''
        const estado = foEstado || ''

        const url = solGetConUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, tipo, area, estado, resource, 1, soPageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: sols } = data
            setSolicitudes(sols.map((sol, index) => getSol(sol, index)))
            setSoTotalDocuments(totalDocuments)
            setSoCurrentPage(1)
            clearFiltroSO(false)
            setFoSave({
                svSelReceptor: selReceptor,
                svGrupo: grupo,
                svFechaIni: fechaIni,
                svFechaFin: fechaFin,
                svTipo: tipo,
                svArea: area,
                svEstado: estado,
            })
        }
        if (err && notifyRedRef.current)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqSol(false)
        setLastAccess()
    }

    const handleChangePagSO = async (page, pageSize) => {
        if (!foSave)
            return

        const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, svEstado } = foSave

        let err = null
        let data = null

        setLoadBusqSol(true)

        const url = solGetConUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, svEstado, resource, page, pageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: sols } = data
            setSolicitudes(sols.map((sol, index) => getSol(sol, index)))
            setSoTotalDocuments(totalDocuments)
            setSoCurrentPage(page)
            clearFiltroSO(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqSol(false)
        setLastAccess()
    }

    const handleBuscarSONum = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        if (!foNumSO || foNumSO.trim().length === 0)
            err = {
                message: msgType.inputNumSO,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqSol(true)

        const url = solGetNumUrl(selReceptor.ruc, foNumSO)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { success, data: sol } = data
            if (success && (allGroup || selGrupo.ruc === sol.grpCode)) {
                setSolicitudes([getSol(sol, 0)])
                setSoTotalDocuments(1)
            }
            else {
                setSolicitudes([])
                setSoTotalDocuments(0)
            }
            clearFiltroSO(false)
            setFoSave(null)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqSol(false)
        setLastAccess()
    }

    const handleDetalleSol = (record, isDet) => {
        const sol = { ...record, isDet }
        setSelSolicitud(sol)
        setOpenDrawer(true)
    }

    const handleTask9_Add = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foFechaIni || !foFechaFin)
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadTask9Add(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni.format().split('T')[0] + 'T00:00:00'
        const fechaFin = foFechaFin.format().split('T')[0] + 'T00:00:00'
        const tipo = foTipoSol || ''
        const area = foArea || ''
        const estado = foEstado || ''

        const url = tsk9Url()
        const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, grupo, fechaIni, fechaFin, tipo, area, estado, resource })
        data = data_api
        err = err_api

        if (data) {
            setTask9(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask9Add(false)
        setLastAccess()
    }

    const handleTask9_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask9Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask9(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask9Down(false)
        setLastAccess()
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys)
            setSelectedRows(newSelectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: false,
        }),
        type: 'radio',
    }

    const soColumns = [
        {
            title: 'Nº Solicitud',
            dataIndex: 'numSO',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <button
                            className='text-blue-600 w-full text-left'
                            onClick={() => handleDetalleSol(record, 1)}
                        >
                            <span>{`${record.numSO}`}</span>
                        </button>
                    </div>
                </div>,
        },
        {
            title: 'Fecha Sol.',
            dataIndex: 'fechaSol',
            width: 92,
            render: (_, record) => <span>{formatDate(record.fechaSol)}</span>
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 50,
            align: 'center',
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            })
        },
        {
            title: 'Solicitado',
            dataIndex: 'totSol',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.totSol)}</span>
        },
        {
            title: 'Rendido',
            dataIndex: 'totRen',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.totRen)}</span>
        },
        {
            title: 'Saldo',
            dataIndex: 'saldo',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.saldo)}</span>
        },
        {
            title: 'Area',
            dataIndex: 'area',
            width: 245,
            responsive: ['lg'],
            render: (_, record) => allGroup ? <span>{`${record.grpCode} ${record.area}`}</span> : <span>{record.area}</span>
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            width: 100,
        },
        {
            title: 'Rindente',
            dataIndex: 'rindente',
            width: 315,
            responsive: ['xl'],
            render: (_, record) => <span>{getUsu(record.rindente ?? record.solicitante)}</span>
        },
    ]

    return (
        <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
            <Drawer
                title={getTitleDrawer(selSolicitud)}
                placement="right"
                onClose={handleCloseDrawer}
                open={openDrawer}
                getContainer={false}
                style={{ position: 'absolute' }}
                bodyStyle={{ padding: 20, }}
                headerStyle={{ padding: 15, }}
                destroyOnClose={true}
                maskClosable={false}
                zIndex={5}
                width={720}
                closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
                extra={
                    <Space>
                        <></>
                    </Space>
                }
            >
                {
                    selSolicitud ?
                        <>
                            {
                                selSolicitud.isDet === 1 ?
                                    <DetalleSol ref={detSolRef} selReceptor={selReceptor} selSolicitud={selSolicitud} /> :
                                    <></>
                            }
                        </> :
                        <></>
                }
            </Drawer >
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex-grow'>
                        <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
                            <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
                            {
                                !allGroup &&
                                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
                            }
                            <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
                        </div>

                        <div className='p-3 flex flex-col ant-common-titania'>
                            <div className='flex flex-row flex-wrap space-x-3 mb-3'>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task9.id || task9.task?.message) ?
                                            <>
                                                {
                                                    !disablePage ?
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            title={`¿Confirma la operación?`}
                                                            onConfirm={handleTask9_Add}
                                                            placement="right"
                                                            okText="Sí"
                                                            cancelText="No"
                                                        >
                                                            <div className='flex items-center cursor-pointer'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                                {`Descargar`}
                                                            </div>
                                                        </Popconfirm> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask9Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Descargar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task9.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask9Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask9_Down(task9.task)}
                                                            >
                                                                {task9.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    task9.task?.message &&
                                    <div className='flex items-center'>
                                        <p className="text-xs text-red-700">
                                            {task9.task?.message}
                                        </p>
                                    </div>
                                }
                            </div>

                            <p className='text-xs text-gray-700 mb-2'>
                                Busca una solicitud de fondo usando los filtros de fecha de solicitud o número de solicitud.
                            </p>
                            <div className='flex space-x-10'>
                                <div className='flex items-center space-x-2'>
                                    <DatePicker placeholder='Fecha inicio' defaultValue={dvFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                                    <DatePicker placeholder='Fecha fin' defaultValue={dvFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                                    {
                                        loadConfigRT &&
                                        <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                    <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                                        {
                                            !loadConfigRT &&
                                            <Select placeholder='Tipo' value={foTipoSol} allowClear style={{ width: 150 }} onChange={(v) => setFoTipoSol(v || null)}>
                                                {
                                                    tiposSol.map(({ reqCode, reqName }) => (
                                                        <Select.Option key={reqCode} value={reqCode}>{reqName}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        {
                                            !loadConfigRT &&
                                            <Select placeholder='Area' value={foArea} allowClear style={{ width: 200 }} onChange={(v) => setFoArea(v || null)}>
                                                {
                                                    areas.map(({ areaCode, areaName }) => (
                                                        <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        <Select placeholder='Estado' allowClear style={{ width: 135 }} onChange={(v) => setFoEstado(v || null)}>
                                            {
                                                estados.map(({ estadoID, nombre }) => (
                                                    <Select.Option key={estadoID} value={nombre}>{nombre}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    {
                                        !disablePage ?
                                            <Tooltip title="Buscar solicitud">
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarSOFecha} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <Input placeholder="Nº de solicitud" onChange={(e) => setFoNumSO(e.target.value)} />
                                    {
                                        !disablePage ?
                                            <Tooltip title="Buscar solicitud">
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarSONum} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                            </div>

                            <div className='lg:max-w-7xl'>
                                {
                                    loadBusqSol ?
                                        <div className='my-5 flex justify-center'>
                                            <Spin tip='Loading....' size='large' />
                                        </div> :
                                        <>
                                            <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-fnconsol'>
                                                <Table
                                                    rowSelection={rowSelection}
                                                    columns={soColumns}
                                                    dataSource={solicitudes}
                                                    pagination={false}
                                                    scroll={{ y: 400 }}
                                                    bordered
                                                    size='small'
                                                />
                                            </div>
                                            <div className='mt-2 flex justify-end ant-pagination-titania'>
                                                <Pagination
                                                    defaultCurrent={soCurrentPage}
                                                    total={soTotalDocuments}
                                                    showSizeChanger
                                                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} solicitudes`}
                                                    defaultPageSize={soPageSize}
                                                    onChange={handleChangePagSO}
                                                    onShowSizeChange={(_, size) => setSoPageSize(size)}
                                                    disabled={disablePage} />
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
