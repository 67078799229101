import './DetalleSol.css'

import { CloudDownloadOutlined, EyeFilled } from '@ant-design/icons'
import { Input, Modal, Select, Spin, Table, Tabs, Tooltip } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { solGetNumUrl, solRecuperarUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate, formatDigits, formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyYellow } from '../../Msg/NotifyYellow'
import { NotifyRed } from '../../Msg/NotifyRed'
import { NotifyGreen } from '../../Msg/NotifyGreen'
import { DetalleOpe } from './DetalleOpe'
import { DetalleRen } from './DetalleRen'

var jszip = require('jszip')

export const DetalleSol = forwardRef((props, ref) => {
  const { usuario, selReceptor, selSolicitud: vwSolicitud, dbSolicitud, aprRevertir, setObservacion } = props

  const { axiosTnaGet } = useAxiosTna()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const detOpeRef = useRef()
  const detRenRef = useRef()
  const [loadPage, setLoadPage] = useState(true)
  const [loadGetSol, setLoadGetSol] = useState(false)
  const [camposSol, setCamposSol] = useState([])
  const [camposFon, setCamposFon] = useState([])
  const [camposApr, setCamposApr] = useState([])
  const [camposAteFon, setCamposAteFon] = useState([])
  const [camposRen, setCamposRen] = useState([])
  const [camposDev, setCamposDev] = useState([])
  const [camposRep, setCamposRep] = useState([])
  const [camposAteRep, setCamposAteRep] = useState([])
  const [ccosto, setCcosto] = useState([])
  const [fondos, setFondos] = useState([])
  const [aprobadores, setAprobadores] = useState([])
  const [atencionesFon, setAtencionesFon] = useState([])
  const [rendiciones, setRendiciones] = useState([])
  const [devoluciones, setDevoluciones] = useState([])
  const [reposiciones, setReposiciones] = useState([])
  const [atencionesRep, setAtencionesRep] = useState([])
  const [adjuntos, setAdjuntos] = useState([])
  const [estados, setEstados] = useState([])
  const [selSolicitud, setSelSolicitud] = useState(null)
  const [selFondo, setSelFondo] = useState(null)
  const [selAprobador, setSelAprobador] = useState(null)
  const [selAtencionFon, setSelAtencionFon] = useState(null)
  const [selRendicion, setSelRendicion] = useState(null)
  const [selDevolucion, setSelDevolucion] = useState(null)
  const [selReposicion, setSelReposicion] = useState(null)
  const [selAtencionRep, setSelAtencionRep] = useState(null)
  const [dbOperacion, setDbOperacion] = useState(null)
  const [currentAprob, setCurrentAprob] = useState(false)
  const [currentLineFI, setCurrentLineFI] = useState(null)
  const [activeTabKey, setActiveTabKey] = useState("1")
  const [openModalOpe, setOpenModalOpe] = useState(false)
  const [openModalRen, setOpenModalRen] = useState(false)

  useEffect(() => {
    setLoadPage(loadGetSol)
  }, [loadGetSol])

  useEffect(() => {
    setSelSolicitud(vwSolicitud)
  }, [vwSolicitud])

  useEffect(() => {
    setDbOperacion(selAtencionFon ? { id: selAtencionFon.operacionID } : null)
  }, [selAtencionFon])

  useEffect(() => {
    setDbOperacion(selDevolucion && selDevolucion.atencion ? { id: selDevolucion.atencion.operacionID } : null)
  }, [selDevolucion])

  useEffect(() => {
    setDbOperacion(selAtencionRep ? { id: selAtencionRep.operacionID } : null)
  }, [selAtencionRep])

  useEffect(() => {
    setObservacion && setObservacion(null)
  }, [currentAprob, setObservacion])

  useEffect(() => {
    const getCampo = (rec, sol) => {
      const campos = [
        { label: 'Receptor', value: `${rec.ruc} - ${rec.name}` },
        { label: 'Grupo', value: sol.grupo },
        { label: 'Nº de solicitud', value: sol.numSO },
        { label: 'Fecha de solicitud', value: formatDate(sol.fechaSol) },
        { label: 'Tipo de solicitud', value: sol.tipo },
        { label: 'Área', value: sol.area },
        { label: 'Estado', value: sol.estado },
        { label: 'Fecha de registro', value: formatTime(sol.fechaReg) },
        { label: 'Solicitante', value: getUsuario(sol.solicitante) },
        { label: 'Rindente', value: getUsuario(sol.rindente) },
        { label: 'Total solicitado', value: `${formatAmount(sol.totSol)} ${sol.monedaISO}` },
        { label: 'Total aprobado', value: sol.totApr !== 0.0 && sol.totApr !== sol.totSol ? `${formatAmount(sol.totApr)}` : null },
        { label: 'Total atendido', value: sol.totFon !== 0.0 && sol.totFon !== sol.totSol ? `${formatAmount(sol.totFon)}` : null },
        { label: 'Total rendido', value: sol.totRen !== 0.0 ? `${formatAmount(sol.totRen)}` : null },
        { label: 'Total devuelto', value: sol.totDev !== 0.0 ? `${formatAmount(sol.totDev)}` : null },
        { label: 'Total reposición', value: sol.totRep !== 0.0 ? `${formatAmount(sol.totRep)}` : null },
        { label: 'Ajuste redondeo', value: sol.mtoRdo !== 0.0 ? `${formatAmount(sol.mtoRdo)}` : null },
        { label: 'Saldo', value: sol.totFon > 0.0 ? `${formatAmount(sol.saldo)}` : null },
      ]
      return campos.filter(p => p.value)
    }

    setCamposSol(selReceptor && selSolicitud ? getCampo(selReceptor, selSolicitud) : [])
  }, [selReceptor, selSolicitud])

  useEffect(() => {
    const getCampo = (fon) => {
      const campos = [
        { label: 'Monto solicitado', value: `${formatAmount(fon.monto)} ${fon.monedaISO}` + (fon.tipoCambio ? ` (TC: ${formatDigits(fon.tipoCambio)})` : '') },
        { label: 'Motivo de solicitud', value: fon.motivo },
        { label: 'Fecha requerida', value: formatDate(fon.fechaReq) },
        { label: 'Fecha de registro', value: formatTime(fon.fechaReg) },
        { label: 'Estado', value: fon.atendido ? ('ATENDIDO' + (fon.atenDif > 0.0 ? ' ( exceso )' : (fon.atenDif < 0.0 ? ' ( parcial )' : ''))) : (fon.aprobado ? 'APROBADO' : (fon.aprobadores.find(p => p.observ) ? 'RECHAZADO' : 'PENDIENTE')) },
        { label: 'Monto atendido', value: fon.atenciones && fon.atenciones.length > 1 && fon.atendido && fon.atenDif !== 0.0 ? `${formatAmount(fon.monto + fon.atenDif)} ${fon.monedaISO}` : null },
      ]
      return campos.filter(p => p.value)
    }

    setCamposFon(selFondo ? getCampo(selFondo) : [])
  }, [selFondo])

  useEffect(() => {
    const getCampo = (apr) => {
      const campos = [
        { label: 'Aprobador', value: getUsuario(apr.aprobador) },
        { label: 'Decisión', value: apr.aprobado ? 'APROBADO' : (apr.fechaAprob ? 'RECHAZADO' : 'POR EVALUAR') },
        { label: 'Observación', value: apr.observ },
        { label: 'Fecha de aprobación', value: apr.aprobado ? formatTime(apr.fechaAprob) : null },
        { label: 'Fecha de rechazo', value: !apr.aprobado && apr.fechaAprob ? formatTime(apr.fechaAprob) : null },
        { label: 'Reemplazo por', value: apr.reemplazo ? getUsuario(apr.reemplazo) : null },
      ]
      return campos.filter(p => p.value)
    }

    setCamposApr(selAprobador ? getCampo(selAprobador) : [])
  }, [selAprobador])

  useEffect(() => {
    const getCampo = (ate) => {
      const campos = [
        { label: 'Monto atendido', value: `${formatAmount(ate.monto)} ${ate.monedaISO}` },
        { label: 'Fecha de atención', value: formatTime(ate.fechaAten) },
        { label: 'Tesorero', value: getUsuario(ate.tesorero) },
      ]
      return campos.filter(p => p.value)
    }

    setCamposAteFon(selAtencionFon ? getCampo(selAtencionFon) : [])
  }, [selAtencionFon])

  useEffect(() => {
    const getCampo = (ren) => {
      const campos = [
        { label: 'Monto rendido', value: `${formatAmount(ren.monto)} ${ren.monedaISO}` },
        { label: 'Nº de rendición', value: ren.numRD },
        { label: 'Fecha de registro', value: formatTime(ren.fechaReg) },
      ]
      return campos.filter(p => p.value)
    }

    setCamposRen(selRendicion ? getCampo(selRendicion) : [])
  }, [selRendicion])

  useEffect(() => {
    const getCampo = (dev) => {
      const campos = [
        { label: 'Nº de cuenta', value: `${dev.banco} ; ${dev.numeroCta}` },
        { label: 'Tipo de pago', value: dev.tipoPago },
        { label: 'Nº y fecha de transacción', value: `${dev.numTran} ; ${formatDate(dev.fechaTran)}` },
        { label: 'Monto devuelto', value: `${formatAmount(dev.monto)} ${dev.monedaISO}` },
        { label: 'Fecha de registro', value: formatTime(dev.fechaReg) },
      ]
      return campos.filter(p => p.value)
    }

    setCamposDev(selDevolucion ? getCampo(selDevolucion) : [])
  }, [selDevolucion])

  useEffect(() => {
    const getCampo = (rep) => {
      const campos = [
        { label: 'Monto reposición', value: `${formatAmount(rep.monto)} ${rep.monedaISO}` },
        { label: 'Origen', value: rep.renDev ? `Rendición ${rep.lineRenDev + 1}` : `Devolución ${rep.lineRenDev + 1}` },
        { label: 'Fecha de registro', value: formatTime(rep.fechaReg) },
        { label: 'Estado', value: rep.atendido ? ('ATENDIDO' + (rep.atenDif > 0.0 ? ' ( exceso )' : (rep.atenDif < 0.0 ? ' ( parcial )' : ''))) : 'PENDIENTE' },
        { label: 'Monto atendido', value: rep.atenciones && rep.atenciones.length > 1 && rep.atendido && rep.atenDif !== 0.0 ? `${formatAmount(rep.monto + rep.atenDif)} ${rep.monedaISO}` : null },
      ]
      return campos.filter(p => p.value)
    }

    setCamposRep(selReposicion ? getCampo(selReposicion) : [])
  }, [selReposicion])

  useEffect(() => {
    const getCampo = (ate) => {
      const campos = [
        { label: 'Monto atendido', value: `${formatAmount(ate.monto)} ${ate.monedaISO}` },
        { label: 'Fecha de atención', value: formatTime(ate.fechaAten) },
        { label: 'Tesorero', value: getUsuario(ate.tesorero) },
      ]
      return campos.filter(p => p.value)
    }

    setCamposAteRep(selAtencionRep ? getCampo(selAtencionRep) : [])
  }, [selAtencionRep])

  useEffect(() => {
    if (selSolicitud) {
      if (selSolicitud.ccosto)
        setCcosto(selSolicitud.ccosto.map((cco, index) => ({
          ...cco,
          key: index
        })))
      if (selSolicitud.fondos) {
        const afon = selSolicitud.fondos.map((fon, index) => ({
          ...fon,
          key: index,
          label: `Fondo ${fon.line + 1}`,
          monedaISO: selSolicitud.monedaISO,
        }))
        const sfon = afon.length > 0 ? afon[afon.length - 1] : null
        setFondos(afon)
        setSelFondo(sfon)
        if (sfon) {
          const [aapr, sapr] = getAprobador(sfon)
          setAprobadores(aapr)
          setSelAprobador(sapr)
          setCurrentAprob(getCurrentAprob(sfon, sapr, usuario))
          const [aate, sate] = getAtencion(sfon)
          setAtencionesFon(aate)
          setSelAtencionFon(sate)
        }
      }
      if (selSolicitud.rendiciones) {
        const aren = selSolicitud.rendiciones.map((ren, index) => ({
          ...ren,
          key: index,
          label: `Rendición ${ren.line + 1}`,
          monedaISO: selSolicitud.monedaISO,
        }))
        const sren = aren.length > 0 ? aren[aren.length - 1] : null
        setRendiciones(aren)
        setSelRendicion(sren)
      }
      if (selSolicitud.devoluciones) {
        const adev = selSolicitud.devoluciones.map((dev, index) => ({
          ...dev,
          key: index,
          label: `Devolución ${dev.line + 1}`,
          monedaISO: selSolicitud.monedaISO,
          adjuntos: selSolicitud.attach && dev.dataOrder != null ? selSolicitud.attach.filter(p => p.dataOrder === dev.dataOrder).map(p => ({ ...p, key: p.dataOrder })) : [],
        }))
        const sdev = adev.length > 0 ? adev[adev.length - 1] : null
        setDevoluciones(adev)
        setSelDevolucion(sdev)
      }
      if (selSolicitud.reposiciones) {
        const arep = selSolicitud.reposiciones.map((rep, index) => ({
          ...rep,
          key: index,
          label: `Reposición ${rep.line + 1}`,
          monedaISO: selSolicitud.monedaISO,
        }))
        const srep = arep.length > 0 ? arep[arep.length - 1] : null
        setReposiciones(arep)
        setSelReposicion(srep)
        if (srep) {
          const [aate, sate] = getAtencion(srep)
          setAtencionesRep(aate)
          setSelAtencionRep(sate)
        }
      }
      if (selSolicitud.attach)
        setAdjuntos(selSolicitud.attach.filter(p => !selSolicitud.devoluciones || !selSolicitud.devoluciones.find(q => q.dataOrder === p.dataOrder)).map((att, index) => ({
          ...att,
          key: index
        })))
      if (selSolicitud.audit)
        setEstados(selSolicitud.audit.map((sto, index) => ({
          ...sto,
          key: index
        })))
    }
  }, [selSolicitud, usuario])

  useEffect(() => {
    let isMounted = true

    const getSol = async () => {
      isMounted && setLoadGetSol(true)

      const url = solGetNumUrl(selReceptor.ruc, dbSolicitud.numSO)
      const [data, err] = await axiosTnaGet(url)

      if (data) {
        const { success, data: sol } = data
        if (success)
          isMounted && setSelSolicitud({ ...sol, key: sol.id })
      }
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadGetSol(false)
    }

    if (dbSolicitud)
      getSol()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [selReceptor, dbSolicitud])

  useImperativeHandle(
    ref,
    () => ({
      showNotify(err, type) {
        if (type === notifyType.warning)
          notifyYellowRef.current.handleOpen(err, type)
        else if (type === notifyType.error)
          notifyRedRef.current.handleOpen(err, type)
        else if (type === notifyType.success)
          notifyGreenRef.current.handleOpen(err, type)
      }
    })
  )

  const getAprobador = (fon) => {
    if (!fon)
      return [[], null]

    const aapr = fon.aprobadores.map((apr, index) => ({
      ...apr,
      key: index,
      label: `Aprobador ${apr.subLine + 1}.${apr.aprLine + 1}`
    }))
    const sapr = aapr.length > 0 ? aapr.find(apr => apr.subLine === fon.subLine) : null
    return [aapr, sapr]
  }

  const getAtencion = (fon) => {
    if (!fon)
      return [[], null]

    const aate = fon.atenciones.map((ate, index) => ({
      ...ate,
      key: index,
      label: `Atención ${ate.subLine + 1}`,
      monedaISO: fon.monedaISO,
    }))
    const sate = aate.length > 0 ? aate.findLast(() => true) : null
    return [aate, sate]
  }

  const getCurrentAprob = (fon, apr, usu) =>
    fon && apr && usu && fon.subLine === apr.subLine && !apr.fechaAprob && apr.aprobador && apr.aprobador.userID === usu.userID

  const getUsuario = (usu) => {
    if (!usu)
      return ''
    else if (usu.docIdentidad)
      return `${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`
    else
      return `${usu.userID} - ${usu.userName}`
  }

  const getClassName = (index, lista) => {
    if (index === 0)
      return 'sm:flex'
    else if (index === lista.length - 1)
      return 'py-0.5 sm:flex'
    else
      return 'pt-0.5 sm:flex'
  }

  const handleChangeFondo = (value, afon, usu) => {
    const sfon = afon.find(p => p.line === value)
    setSelFondo(sfon)
    const [aapr, sapr] = getAprobador(sfon)
    setAprobadores(aapr)
    setSelAprobador(sapr)
    setCurrentAprob(getCurrentAprob(sfon, sapr, usu))
    const [aate, sate] = getAtencion(sfon)
    setAtencionesFon(aate)
    setSelAtencionFon(sate)
  }

  const handleChangeAprobador = (value, aapr, fon, usu) => {
    const sapr = aapr.find(p => p.key === value)
    setSelAprobador(sapr)
    setCurrentAprob(getCurrentAprob(fon, sapr, usu))
  }

  const handleChangeAtencionFon = (value, aate) => {
    const sate = aate.find(p => p.key === value)
    setSelAtencionFon(sate)
  }

  const handleChangeRendicion = (value, aren) => {
    const sren = aren.find(p => p.line === value)
    setSelRendicion(sren)
  }

  const handleChangeDevolucion = (value, adev) => {
    const sdev = adev.find(p => p.line === value)
    setSelDevolucion(sdev)
  }

  const handleChangeReposicion = (value, arep) => {
    const srep = arep.find(p => p.line === value)
    setSelReposicion(srep)
    const [aate, sate] = getAtencion(srep)
    setAtencionesRep(aate)
    setSelAtencionRep(sate)
  }

  const handleChangeAtencionRep = (value, aate) => {
    const sate = aate.find(p => p.key === value)
    setSelAtencionRep(sate)
  }

  const handleDownFile = async (record, download) => {
    let err = null
    let data = null

    setCurrentLineFI(record.dataOrder)

    const url = solRecuperarUrl(selSolicitud.id, record.dataOrder)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (!download && record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setCurrentLineFI(null)
    setLastAccess()
  }

  const ccColumns = [
    {
      title: 'Dimensión',
      dataIndex: 'dimName',
      width: 180,
    },
    {
      title: 'Código',
      dataIndex: 'codeName',
    },
  ]

  const fiColumns = [
    {
      title: 'Archivo',
      dataIndex: 'dataOrder',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex space-x-1 items-center'>
            <button
              className='text-blue-600 text-left'
              disabled={record.dataOrder === currentLineFI}
              onClick={() => handleDownFile(record, false)}
            >
              {`${record.fileName}`}
            </button>
          </div>
          <div className='flex justify-end items-center'>
            {
              record.dataOrder !== currentLineFI &&
              <>
                <button onClick={() => handleDownFile(record, true)}>
                  <CloudDownloadOutlined />
                </button>
              </>
            }
            {
              record.dataOrder === currentLineFI &&
              <Spin size='small' className='ml-2' />
            }
          </div>
        </div>
    },
  ]

  const stColumns = [
    {
      title: 'Estado',
      dataIndex: 'estadoName',
      width: 120,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaIni',
      width: 160,
      render: (_, record) => <span>{formatTime(record.fechaIni)}</span>
    },
    {
      title: 'Usuario',
      dataIndex: 'userID',
    },
  ]

  return (
    <>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        openModalOpe &&
        <Modal
          open={openModalOpe}
          title={`Operación ( ${selSolicitud?.numSO} )`}
          onOk={() => setOpenModalOpe(false)}
          onCancel={() => setOpenModalOpe(false)}
          footer={[]}
          width={720}>
          <DetalleOpe ref={detOpeRef} selReceptor={selReceptor} dbOperacion={dbOperacion} />
        </Modal >
      }
      {
        openModalRen &&
        <Modal
          open={openModalRen}
          title={`Rendición ( ${selRendicion?.numRD} )`}
          onOk={() => setOpenModalRen(false)}
          onCancel={() => setOpenModalRen(false)}
          footer={[]}
          width={720}>
          <DetalleRen ref={detRenRef} selReceptor={selReceptor} dbRendicion={selRendicion} />
        </Modal >
      }
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            < Spin tip='Loading....' size='large' />
          </div > :
          <div className='detallesol-tabs'>
            <Tabs
              defaultActiveKey="1"
              activeKey={activeTabKey}
              onChange={setActiveTabKey}
              items={[
                {
                  label: `Data`,
                  key: '1',
                  children:
                    <>
                      <div>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposSol.map(({ label, value }, index) => (
                              <div className={getClassName(index, camposSol)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="text-gray-900">{value}</div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        ccosto.length > 0 &&
                        <div className='mt-4'>
                          <p className="text-xs text-gray-800">
                            Centros de costo:
                          </p>
                          <div className='mt-2 detallesol-table'>
                            <Table
                              columns={ccColumns}
                              dataSource={ccosto}
                              pagination={false}
                              bordered
                              size='small'
                            />
                          </div>
                        </div>
                      }
                    </>
                },
                {
                  label: `Fondo`,
                  key: '2',
                  disabled: fondos.length === 0,
                  children: selFondo ?
                    <>
                      <div>
                        <Select placeholder='Fondo' style={{ width: 150 }} value={selFondo.line} onChange={(v) => handleChangeFondo(v, fondos, usuario)}>
                          {
                            fondos.map(({ key, line, label }) => (
                              <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                          }
                        </Select>
                      </div>
                      <div className='mt-3 ml-1'>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposFon.map(({ label, value }, index) => (
                              <div className={getClassName(index, camposFon)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="text-gray-900">{value}</div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        selAprobador &&
                        <>
                          <div className='mt-3'>
                            <Select placeholder='Aprobador' style={{ width: 150 }} value={selAprobador.key} onChange={(v) => handleChangeAprobador(v, aprobadores, selFondo, usuario)}>
                              {
                                aprobadores.map(({ key, label }) => (
                                  <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                              }
                            </Select>
                          </div>
                          <div className='mt-3 ml-1'>
                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                              {
                                camposApr.map(({ label, value }, index) => (
                                  <div className={getClassName(index, camposApr)} key={index}>
                                    <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                      <div className="text-gray-900">{value}</div>
                                    </dd>
                                  </div>
                                ))
                              }
                            </dl>
                          </div>
                        </>
                      }
                      {
                        currentAprob && !aprRevertir && setObservacion &&
                        < div className='mt-3'>
                          <Input.TextArea
                            maxLength={500}
                            showCount
                            placeholder="Ingrese motivo de rechazo"
                            onChange={(e) => setObservacion(e.target.value)}
                          />
                        </div>
                      }
                      {
                        selAtencionFon &&
                        <>
                          <div className='flex items-center mt-3'>
                            <Select placeholder='Atención' style={{ width: 150 }} value={selAtencionFon?.key} onChange={(v) => handleChangeAtencionFon(v, atencionesFon)}>
                              {
                                atencionesFon.map(({ key, label }) => (
                                  <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                              }
                            </Select>
                            <Tooltip title="Detalle de operación" placement='right'>
                              <button className='ml-2' onClick={() => { setDbOperacion({ id: selAtencionFon.operacionID }); setOpenModalOpe(true) }}>
                                <EyeFilled className='text-blue-700' style={{ fontSize: '1.1rem' }} />
                              </button>
                            </Tooltip>
                          </div>
                          <div className='mt-3 ml-1'>
                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                              {
                                camposAteFon.map(({ label, value }, index) => (
                                  <div className={getClassName(index, camposAteFon)} key={index}>
                                    <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                      <div className="text-gray-900">{value}</div>
                                    </dd>
                                  </div>
                                ))
                              }
                            </dl>
                          </div>
                        </>
                      }
                    </> :
                    <span></span>
                },
                {
                  label: `Consumo`,
                  key: '3',
                  disabled: rendiciones.length === 0 && devoluciones.length === 0,
                  children:
                    <div className='flex flex-col space-y-3'>
                      {
                        selRendicion &&
                        <>
                          <div className='flex items-center'>
                            <Select placeholder='Rendición' style={{ width: 150 }} value={selRendicion.line} onChange={(v) => handleChangeRendicion(v, rendiciones)} >
                              {
                                rendiciones.map(({ key, line, label }) => (
                                  <Select.Option key={key} value={line}>{label}</Select.Option>
                                ))
                              }
                            </Select>
                            <Tooltip title="Detalle de rendición" placement='right'>
                              <button className='ml-2' onClick={() => setOpenModalRen(true)}>
                                <EyeFilled className='text-blue-700' style={{ fontSize: '1.1rem' }} />
                              </button>
                            </Tooltip>
                          </div>
                          <div className='mt-3 ml-1'>
                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                              {
                                camposRen.map(({ label, value }, index) => (
                                  <div className={getClassName(index, camposRen)} key={index}>
                                    <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                      <div className="text-gray-900">{value}</div>
                                    </dd>
                                  </div>
                                ))
                              }
                            </dl>
                          </div>
                        </>
                      }
                      {
                        selDevolucion &&
                        <>
                          <div className='flex items-center'>
                            <Select placeholder='Devolución' style={{ width: 150 }} value={selDevolucion.line} onChange={(v) => handleChangeDevolucion(v, devoluciones)} >
                              {
                                devoluciones.map(({ key, line, label }) => (
                                  <Select.Option key={key} value={line}>{label}</Select.Option>
                                ))
                              }
                            </Select>
                            {
                              selDevolucion.atencion &&
                              <Tooltip title="Detalle de operación" placement='right'>
                                <button className='ml-2' onClick={() => { setDbOperacion({ id: selDevolucion.atencion.operacionID }); setOpenModalOpe(true) }}>
                                  <EyeFilled className='text-blue-700' style={{ fontSize: '1.1rem' }} />
                                </button>
                              </Tooltip>
                            }
                          </div>
                          <div className='mt-3 ml-1'>
                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                              {
                                camposDev.map(({ label, value }, index) => (
                                  <div className={getClassName(index, camposDev)} key={index}>
                                    <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                      <div className="text-gray-900">{value}</div>
                                    </dd>
                                  </div>
                                ))
                              }
                            </dl>
                          </div>
                          {
                            selDevolucion.adjuntos.length > 0 &&
                            <div className='mt-3 detallesol-table'>
                              <Table
                                columns={fiColumns}
                                dataSource={selDevolucion.adjuntos}
                                pagination={false}
                                bordered
                                size='small'
                              />
                            </div>
                          }
                        </>
                      }
                    </div>
                },
                {
                  label: `Reposición`,
                  key: '4',
                  disabled: reposiciones.length === 0,
                  children: selReposicion ?
                    <>
                      <div>
                        <Select placeholder='Reposición' style={{ width: 150 }} value={selReposicion.line} onChange={(v) => handleChangeReposicion(v, reposiciones)}>
                          {
                            reposiciones.map(({ key, line, label }) => (
                              <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                          }
                        </Select>
                      </div>
                      <div className='mt-3 ml-1'>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposRep.map(({ label, value }, index) => (
                              <div className={getClassName(index, camposRep)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="text-gray-900">{value}</div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        selAtencionRep &&
                        <>
                          <div className='flex items-center mt-3'>
                            <Select placeholder='Atención' style={{ width: 150 }} value={selAtencionRep?.key} onChange={(v) => handleChangeAtencionRep(v, atencionesRep)}>
                              {
                                atencionesRep.map(({ key, label }) => (
                                  <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                              }
                            </Select>
                            <Tooltip title="Detalle de operación" placement='right'>
                              <button className='ml-2' onClick={() => { setDbOperacion({ id: selAtencionRep.operacionID }); setOpenModalOpe(true) }}>
                                <EyeFilled className='text-blue-700' style={{ fontSize: '1.1rem' }} />
                              </button>
                            </Tooltip>
                          </div>
                          <div className='mt-3 ml-1'>
                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                              {
                                camposAteRep.map(({ label, value }, index) => (
                                  <div className={getClassName(index, camposAteRep)} key={index}>
                                    <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                      <div className="text-gray-900">{value}</div>
                                    </dd>
                                  </div>
                                ))
                              }
                            </dl>
                          </div>
                        </>
                      }
                    </> :
                    <span></span>
                },
                {
                  label: `Adjunto`,
                  key: '5',
                  disabled: adjuntos.length === 0,
                  children:
                    <>
                      {
                        adjuntos.length > 0 &&
                        <div className='mb-3 detallesol-table'>
                          <Table
                            columns={fiColumns}
                            dataSource={adjuntos}
                            pagination={false}
                            bordered
                            size='small'
                          />
                        </div>
                      }
                    </>
                },
                {
                  label: `Estado`,
                  key: '6',
                  disabled: estados.length === 0,
                  children:
                    <div className='mb-3 detallesol-table'>
                      <Table
                        columns={stColumns}
                        dataSource={estados}
                        pagination={false}
                        bordered
                        size='small'
                      />
                    </div>
                },
              ]}
            />
          </div >
      }
    </>
  )
})
