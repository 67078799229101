import { getError } from "../api/apiUtil";
import useInterceptTna from "./useInterceptTna";

export const useAxiosTna = () => {
    const axiosTna = useInterceptTna();

    const axiosTnaGet = async (url) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosTna.get(url, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosTnaPost = async (url, data) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosTna.post(url, JSON.stringify(data), {
                signal: controller.signal,
                headers: { 'Content-Type': 'application/json' },
            })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosTnaDelete = async (url) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosTna.delete(url, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosTnaPut = async (url, data) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosTna.put(url, data, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    return { axiosTnaGet, axiosTnaPost, axiosTnaDelete, axiosTnaPut }
}
