import moment from "moment"

export const formatAmount = (amount) => {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    const amountFormat = numberFormat.format(amount)
    return amount >= 0 ? amountFormat.substring(1, amountFormat.length) : (amountFormat.substring(0, 1) + amountFormat.substring(2, amountFormat.length))
}

export const formatDigits = (amount, digits) => {
    const options = { maximumSignificantDigits: digits }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    const amountFormat = numberFormat.format(amount)
    return amountFormat
}

export const formatDate = (date) => {
    const dateFormat = moment(date).format('DD/MM/yyyy')
    return dateFormat
}

export const formatTime = (date) => {
    const dateFormat = moment(date).format('DD/MM/yyyy HH:mm:ss')
    return dateFormat
}

export const formatCamelCase = (str) => {
    if (!str || str.length === 0)
        return ''
    const camel = str.substring(0, 1).toUpperCase() + str.substring(1, str.length).toLowerCase()
    return camel
}

export const formatRound = (amount, digits) => {
    const round = Math.round(amount * 10 ** digits) / 10 ** digits
    return round
}