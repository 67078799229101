import './NewAer.css'

import { SyncOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, Switch, Tabs, Tooltip } from 'antd'
import moment from 'moment'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { aerGetIdUrl, aerModificarUrl, aerRegistrarUrl, rctGrpRecurUrl, segConnUrl, usuGetRlerUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { NotifyRed } from '../../Msg/NotifyRed'
import { NotifyGreen } from '../../Msg/NotifyGreen'
import { NotifyYellow } from '../../Msg/NotifyYellow'

export const NewAer = forwardRef((props, ref) => {
  const { selReceptor, selGrupo, selAcceso, roles: roles_all, updateAer, tabKey } = props

  const { axiosTnaGet, axiosTnaPost, axiosTnaPut } = useAxiosTna()
  const [formDta] = Form.useForm()
  const [formCfg] = Form.useForm()
  const [formRee] = Form.useForm()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [loadPage, setLoadPage] = useState(false)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadConfigRT, setLoadConfigRT] = useState(false)
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [loadUsuario, setLoadUsuario] = useState(false)
  const [loadRegAer, setLoadRegAer] = useState(false)
  const [usuarios, setUsuarios] = useState([])
  const [roles, setRoles] = useState([])
  const [areas, setAreas] = useState([])
  const [fmRol, setFmRol] = useState(null)
  const [fmArea, setFmArea] = useState(null)
  const [fmUsuario, setFmUsuario] = useState(null)
  const [fmMontoIni, setFmMontoIni] = useState(null)
  const [fmMontoFin, setFmMontoFin] = useState(null)
  const [fmPorOtro, setFmPorOtro] = useState(false)
  const [fmReemplazo, setFmReemplazo] = useState(null)
  const [fmFechaIni, setFmFechaIni] = useState()
  const [fmFechaFin, setFmFechaFin] = useState()
  const [opcionesUsu, setOpcionesUsu] = useState([])
  const [opcionesRee, setOpcionesRee] = useState([])
  const [dvSearchUsu, setDvSearchUsu] = useState(null)
  const [dvSearchRee, setDvSearchRee] = useState(null)
  const [activeTabKey, setActiveTabKey] = useState("1")

  useEffect(() => {
    setLoadPage(loadTokenConn || loadConfigRT)
  }, [loadTokenConn, loadConfigRT])

  useEffect(() => {
    setComponentDisabled(loadUsuario || loadRegAer)
  }, [loadUsuario, loadRegAer])

  useEffect(() => {
    setRoles(roles_all?.map(p => ({ ...p, rindAprob: !(p.bool1 !== null && p.bool1 === false) })) || [])
  }, [roles_all])

  useEffect(() => {
    const usu = usuarios.find(p => p.userID === selAcceso?.usuario?.userID)
    const ree = usuarios.find(p => p.userID === selAcceso?.reemplazo?.usuario?.userID)
    const ops_usu = searchResultUsu(usu?.value, usuarios)
    const ops_ree = searchResultUsu(ree?.value, usuarios)

    setFmUsuario(usu)
    setDvSearchUsu(usu?.value)
    setOpcionesUsu(ops_usu)
    setFmReemplazo(ree)
    setDvSearchRee(ree?.value)
    setOpcionesRee(ops_ree)
    // eslint-disable-next-line
  }, [selAcceso, usuarios])

  useEffect(() => {
    const area = selAcceso?.areaCode ? areas.find(p => p.areaCode === selAcceso.areaCode) : areas.find(() => true)
    const rol = selAcceso?.rolCode ? roles.find(p => p.codigo === selAcceso.rolCode) : roles.find(() => true)
    const montoIni = selAcceso?.config?.montoIni ?? null
    const montoFin = selAcceso?.config?.montoFin ?? null
    const porOtro = selAcceso?.config?.porOtro || false
    const fechaIni = selAcceso?.reemplazo?.fechaIni ? moment(selAcceso.reemplazo.fechaIni) : null
    const fechaFin = selAcceso?.reemplazo?.fechaFin ? moment(selAcceso.reemplazo.fechaFin) : null

    setFmArea(area)
    setFmRol(rol)
    setFmMontoIni(montoIni)
    setFmMontoFin(montoFin)
    setFmPorOtro(porOtro)
    setFmFechaIni(fechaIni)
    setFmFechaFin(fechaFin)

    formDta.setFieldsValue({
      rol: rol?.codigo,
      area: area?.areaCode,
    })
    formCfg.setFieldsValue({
      montoIni: montoIni,
      montoFin: montoFin,
    })
    formRee.setFieldsValue({
      fechaIni: fechaIni,
      fechaFin: fechaFin,
    })
    // eslint-disable-next-line
  }, [selAcceso, areas, roles, formDta, formCfg, formRee])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const configRT = async () => {
      const url = rctGrpRecurUrl(selReceptor?.ruc, selAcceso?.grpCode ?? selGrupo?.ruc, 'resource')
      const [data, err] = await axiosTnaGet(url)

      if (data && data.success)
        isMounted && setAreas(data.data?.areas ?? [])
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadConfigRT(false)
    }

    if (!loadTokenConn)
      configRT()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const rlerUsuario = async () => {
      isMounted && setLoadUsuario(true)

      const url = usuGetRlerUrl(selReceptor.ruc, selAcceso?.grpCode ?? selGrupo?.ruc ?? '', fmRol.rindAprob)
      const [data, err] = await axiosTnaGet(url)

      if (data && data.totalDocuments > 0)
        isMounted && setUsuarios(data.data?.map(p => ({ ...p, value: `${p.userID}`, label: p.docIdentidad ? `${p.docIdentidad.documento} - ${p.docIdentidad.denominacion}` : `${p.userName}` })) || [])
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadUsuario(false)
    }

    if (fmRol)
      rlerUsuario()
    else if (isMounted)
      setUsuarios([])

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [fmRol])

  useImperativeHandle(
    ref,
    () => ({
      handleSave() {
        handleRegAer()
      },
      showNotify(err, type) {
        showNotifyRen(err, type)
      }
    })
  )

  const refreshAer = async (rcpt, aer) => {
    const url = aerGetIdUrl(aer.id)
    const [data, err] = await axiosTnaGet(url)

    if (err)
      console.log(`Acceso: ${err.message}`)
    const nren = data?.success ? data.data : null
    return nren
  }

  const onValuesChangeDta = (changedValues, allValues) => {
    const area = areas.find(p => p.areaCode === allValues.area) || null
    const rol = roles.find(p => p.codigo === allValues.rol) || null
    setFmArea(area)
    setFmRol(rol)
  }

  const onValuesChangeCfg = (changedValues, allValues) => {
    const montoIni = allValues.montoIni ?? null
    const montoFin = allValues.montoFin ?? null
    setFmMontoIni(montoIni)
    setFmMontoFin(montoFin)
  }

  const onValuesChangeRee = (changedValues, allValues) => {
    const fechaIni = allValues.fechaIni ?? null
    const fechaFin = allValues.fechaFin ?? null
    setFmFechaIni(fechaIni)
    setFmFechaFin(fechaFin)
  }

  const showNotifyRen = (err, type) => {
    if (type === notifyType.warning)
      notifyYellowRef.current.handleOpen(err, type)
    else if (type === notifyType.error)
      notifyRedRef.current.handleOpen(err, type)
    else if (type === notifyType.success)
      notifyGreenRef.current.handleOpen(err, type)
  }

  const compareText = (field, value) => {
    if (value === undefined)
      return false
    else {
      const nvalue = value.toUpperCase()
      const nfield = field.toUpperCase()
      return nfield.indexOf(nvalue) !== -1
    }
  }

  const searchResultUsu = (value, ausu) => {
    const nusu = ausu.filter(p => value === configType.searchAllCode || compareText(p.userID, value) || compareText(p.userName, value) || (p.docIdentidad && (compareText(p.docIdentidad.documento, value) || compareText(p.docIdentidad.denominacion, value))))
    const aops = nusu.map(p => ({
      value: p.value,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{p.docIdentidad ? `${p.docIdentidad.documento} - ${p.docIdentidad.denominacion}` : `${p.userName}`}</span>
          <span>{`${p.userID}`}</span>
        </div>
      ),
    }))
    return aops
  }

  const handleActiveTabKey = (activeKey) => { setActiveTabKey(activeKey); if (tabKey) tabKey(activeKey) }

  const handleSearchUsu = (value, ausu) => setOpcionesUsu(value ? searchResultUsu(value, ausu) : [])
  const handleChangeUsu = (value) => { setFmUsuario(null); setDvSearchUsu(value) }
  const handleSelectUsu = (value, ausu) => { setFmUsuario(usuarios.find(p => p.value === value) || null); setOpcionesUsu(value ? searchResultUsu(value, ausu) : []) }

  const handleSearchRee = (value, ausu) => setOpcionesRee(value ? searchResultUsu(value, ausu) : [])
  const handleChangeRee = (value) => { setFmReemplazo(null); setDvSearchRee(value) }
  const handleSelectRee = (value, ausu) => { setFmReemplazo(usuarios.find(p => p.value === value) || null); setOpcionesRee(value ? searchResultUsu(value, ausu) : []) }

  const handleRegAer = async () => {
    let err = null
    let data = null

    if (componentDisabled)
      return;

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !selAcceso)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (!fmArea)
      err = {
        message: `${msgType.selectArea}`,
        oops: false
      }
    else if (!fmRol)
      err = {
        message: `${msgType.selectRol}`,
        oops: false
      }
    else if (!fmUsuario)
      err = {
        message: `${msgType.selectUsuario}`,
        oops: false
      }
    else if (fmReemplazo && (!fmFechaIni || !fmFechaFin))
      err = {
        message: `${msgType.inputFechaRee}`,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadRegAer(true)

    const montoIni = fmRol.rindAprob ? null : fmMontoIni
    const montoFin = fmRol.rindAprob ? null : fmMontoFin
    const porOtro = !fmRol.rindAprob ? null : fmPorOtro
    const fechaIni = !fmRol.rindAprob && fmReemplazo && fmFechaIni ? (fmFechaIni.format().split('T')[0] + 'T00:00:00') : null
    const fechaFin = !fmRol.rindAprob && fmReemplazo && fmFechaFin ? (fmFechaFin.format().split('T')[0] + 'T00:00:00') : null
    const isNew = !selAcceso?.id

    const url = isNew ? aerRegistrarUrl() : aerModificarUrl(selAcceso?.id)
    const body = isNew ?
      {
        rcptNumDoc: selReceptor.ruc, grupo: selAcceso?.grpCode ?? selGrupo?.ruc,
        area: fmArea.areaCode, rol: fmRol.codigo, userID: fmUsuario.userID,
        config: montoIni || montoFin || porOtro ? { montoIni, montoFin, porOtro } : null,
        reemplazo: fmReemplazo ? { userID: fmReemplazo.userID, fechaIni, fechaFin } : null
      } :
      {
        config: montoIni || montoFin || porOtro ? { montoIni, montoFin, porOtro } : null,
        reemplazo: fmReemplazo ? { userID: fmReemplazo.userID, fechaIni, fechaFin } : null
      }
    const [data_api, err_api] = isNew ? await axiosTnaPost(url, body) : await axiosTnaPut(url, body)
    data = data_api
    err = err_api

    if (data) {
      const raer = isNew ? { ...data } : { ...data, id: selAcceso?.id }
      notifyGreenRef.current.handleOpen({
        message: `${isNew ? msgType.accesoRegistrar : msgType.accesoModificar} ${raer.id}`,
        oops: false
      }, notifyType.success)
      const naer = await refreshAer(selReceptor, raer)
      if (naer && updateAer)
        updateAer(naer)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadRegAer(false)
    setLastAccess()
  }

  return (
    <>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage &&
        <div className='my-5 flex justify-center'>
          <Spin tip='Loading....' size='large' />
        </div>
      }
      <div className='ant-tabs-titania ant-common-titania'>
        <Tabs
          defaultActiveKey="1"
          hidden={loadPage}
          activeKey={activeTabKey}
          onChange={handleActiveTabKey}
          items={[
            {
              label: `Data`,
              key: '1',
              children:
                <>
                  <Form
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeDta}
                    disabled={componentDisabled}
                    autoComplete="off"
                    hidden={loadPage}
                    form={formDta}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="rol"
                          label="Rol"
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Select placeholder="Seleccione rol" allowClear disabled={selAcceso?.id}>
                            {
                              roles.map(({ codigo, nombre }) => (
                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="area"
                          label="Area"
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Select placeholder="Seleccione área" allowClear disabled={selAcceso?.id}>
                            {
                              areas.map(({ areaCode, areaName }) => (
                                <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  {
                    !loadPage &&
                    <div className='mb-2'>
                      <div className=' mb-1'>
                        <span className='span-requerido-cfg-newaer'>*</span>
                        <span>Usuario</span>
                      </div>
                      <div className='flex space-x-0.5'>
                        <AutoComplete
                          dropdownMatchSelectWidth={500}
                          style={{ width: 400, }}
                          options={opcionesUsu}
                          onSearch={(v) => handleSearchUsu(v, usuarios)}
                          onChange={handleChangeUsu}
                          onSelect={(v) => handleSelectUsu(v, usuarios)}
                          value={dvSearchUsu}
                          disabled={componentDisabled || selAcceso?.id}
                        >
                          <Input.Search size="middle" placeholder="Ingrese filtro" />
                        </AutoComplete>
                        <Input readOnly placeholder='Resultado de búsqueda' value={fmUsuario?.label} />
                      </div>
                    </div>
                  }
                </>
            },
            {
              label: `Config`,
              key: '2',
              forceRender: true,
              children:
                <>
                  <Form
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeCfg}
                    disabled={componentDisabled}
                    autoComplete="off"
                    hidden={loadPage}
                    form={formCfg}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="montoIni"
                          label="Monto inicio"
                          rules={[
                            {
                              required: false,
                              message: '',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder='Ingrese rango inicial'
                            className='text-xs w-36'
                            size='middle'
                            maxLength={15} min={0.0}
                            bordered={true} precision={2}
                            disabled={!fmRol || fmRol.rindAprob}
                            style={{
                              width: '100%',
                            }} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="montoFin"
                          label="Monto fin"
                          rules={[
                            {
                              required: false,
                              message: '',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder='Ingrese rango final'
                            className='text-xs w-36'
                            size='middle'
                            maxLength={15} min={0.0}
                            bordered={true} precision={2}
                            disabled={!fmRol || fmRol.rindAprob}
                            style={{
                              width: '100%',
                            }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <div className='mt-1'>
                    <Switch checkedChildren="rendir por otro" unCheckedChildren="rendir por otro" disabled={!fmRol || !fmRol.rindAprob} checked={fmPorOtro} onChange={setFmPorOtro} />
                  </div>
                </>
            },
            {
              label: `Reemplazo`,
              key: '3',
              forceRender: true,
              children:
                <>
                  {
                    !loadPage &&
                    <div className='mb-2'>
                      <div className=' mb-1'>
                        <span className='span-requerido-cfg-newaer'>*</span>
                        <span>Usuario</span>
                      </div>
                      <div className='flex space-x-0.5'>
                        <AutoComplete
                          dropdownMatchSelectWidth={500}
                          style={{ width: 400, }}
                          options={opcionesRee}
                          onSearch={(v) => handleSearchRee(v, usuarios)}
                          onChange={handleChangeRee}
                          onSelect={(v) => handleSelectRee(v, usuarios)}
                          value={dvSearchRee}
                          disabled={componentDisabled || !fmRol || fmRol.rindAprob}
                        >
                          <Input.Search size="middle" placeholder="Ingrese filtro" />
                        </AutoComplete>
                        <Input readOnly placeholder='Resultado de búsqueda' value={fmReemplazo?.label} />
                      </div>
                    </div>
                  }
                  <Form
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeRee}
                    disabled={componentDisabled}
                    autoComplete="off"
                    hidden={loadPage}
                    form={formRee}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="fechaIni"
                          label="Fecha inicio"
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <DatePicker placeholder='Ingrese rango inicial'
                            getPopupContainer={(trigger) => trigger.parentElement}
                            disabled={!fmReemplazo}
                            style={{
                              width: '100%',
                            }} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="fechaFin"
                          label="Fecha fin"
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <DatePicker placeholder='Ingrese rango final'
                            getPopupContainer={(trigger) => trigger.parentElement}
                            disabled={!fmReemplazo}
                            style={{
                              width: '100%',
                            }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </>
            },
          ]}
        />
        <div className='flex justify-center mt-4'>
          {
            loadRegAer &&
            <Tooltip title="Procesando" placement="top">
              <Button
                type="primary"
                shape="circle"
                icon={<SyncOutlined spin />}
              />
            </Tooltip>
          }
        </div>
      </div>
    </>
  )
})
