export const segLoginUrl = () => {
    const endpoint = 'login'
    const url = `/${endpoint}`
    return url
}

export const segRenewUrl = () => {
    const endpoint = 'renew'
    const url = `/${endpoint}`
    return url
}

export const segConnUrl = () => {
    const endpoint = 'conn'
    const url = `/${endpoint}`
    return url
}

export const segCtoTokenUrl = () => {
    const endpoint = 'cto/token'
    const url = `/${endpoint}`
    return url
}

export const segDneTokenUrl = () => {
    const endpoint = 'dne/token'
    const url = `/${endpoint}`
    return url
}

export const usuSignUpUrl = () => {
    const endpoint = 'usu/signUp'
    const url = `/${endpoint}`
    return url
}

export const usuChangePwdUrl = () => {
    const endpoint = 'usu/changePwd'
    const url = `/${endpoint}`
    return url
}

export const usuSendCodeUrl = () => {
    const endpoint = 'usu/sendCode'
    const url = `/${endpoint}`
    return url
}

export const usuModificarUrl = (id) => {
    const endpoint = 'usu'
    const url = `/${endpoint}/${id}`
    return url
}

export const usuRcptEmsrUrl = (resource) => {
    const endpoint = 'usu/rcptEmsr'
    const url = `/${endpoint}/${resource}`
    return url
}

export const usuGetNumUrl = (userID) => {
    const endpoint = 'usu'
    const url = `/${endpoint}?userID=${userID}`
    return url
}

export const usuGetCurrUrl = () => {
    const endpoint = 'usu/current'
    const url = `/${endpoint}`
    return url
}

export const usuGetAcerUrl = (rcptNumDoc, grupo, solRen, rindAprob, area) => {
    const endpoint = 'usu/accessER'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&solRen=${solRen.toString()}&rindAprob=${rindAprob.toString()}&area=${area}`
    return url
}

export const usuGetRlerUrl = (rcptNumDoc, grupo, rindAprob) => {
    const endpoint = 'usu/rolER'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&rindAprob=${rindAprob.toString()}`
    return url
}

export const accRegistrarUrl = () => {
    const endpoint = 'acc'
    const url = `/${endpoint}`
    return url
}

export const accEliminarUrl = (id) => {
    const endpoint = 'acc'
    const url = `/${endpoint}/${id}`
    return url
}

export const accAsignarUrl = (code) => {
    const endpoint = 'acc'
    const url = `/${endpoint}/${code}`
    return url
}

export const accGetBPagUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, access, rol, emsrNumDoc, page, pageSize) => {
    const endpoint = 'acc/busq'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}T00:00:00&fechaFin=${fechaFin}T00:00:00&access=${access}&rol=${rol}&emsrNumDoc=${emsrNumDoc}&page=${page}&pageSize=${pageSize}`
    return url
}

export const aerRegistrarUrl = () => {
    const endpoint = 'aer'
    const url = `/${endpoint}`
    return url
}

export const aerEliminarUrl = (id) => {
    const endpoint = 'aer'
    const url = `/${endpoint}/${id}`
    return url
}

export const aerModificarUrl = (id) => {
    const endpoint = 'aer'
    const url = `/${endpoint}/${id}`
    return url
}

export const aerGetIdUrl = (id) => {
    const endpoint = 'aer'
    const url = `/${endpoint}/${id}`
    return url
}

export const aerGetCfgUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, access, rol, area, resource, page, pageSize) => {
    const endpoint = 'aer/config'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&access=${access}&rol=${rol}&area=${area}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const stdGetTipoUrl = (tipo) => {
    const endpoint = 'std'
    const url = `/${endpoint}?tipo=${tipo}`
    return url
}

export const catGetCodUrl = (codigo) => {
    const endpoint = 'cat'
    const url = `/${endpoint}?codigo=${codigo}`
    return url
}

export const cfgGetDocCpeUrl = () => {
    const endpoint = 'cfg/docCpe'
    const url = `/${endpoint}`
    return url
}

export const cfgGetDocEnrUrl = () => {
    const endpoint = 'cfg/docEnr'
    const url = `/${endpoint}`
    return url
}

export const cfgGetFileBillUrl = () => {
    const endpoint = 'cfg/fileBill'
    const url = `/${endpoint}`
    return url
}

export const rolGetUserAllUrl = () => {
    const endpoint = 'rol/userAll'
    const url = `/${endpoint}`
    return url
}

export const rolGetUserPagUrl = () => {
    const endpoint = 'rol/userPag'
    const url = `/${endpoint}`
    return url
}

export const rctEntrRendUrl = (rcptNumDoc, grupo, solRen, rindAprob, rindAprobID = '') => {
    const endpoint = 'rct/entrRend'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&solRen=${solRen.toString()}&rindAprob=${rindAprob.toString()}&rindAprobID=${rindAprobID}`
    return url
}

export const rctGrpRecurUrl = (rcptNumDoc, grupo, resource) => {
    const endpoint = 'rct/grpRecur'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&resource=${resource}`
    return url
}

export const ocpRecuperarUrl = (ordenCompraID, line) => {
    const endpoint = 'ocp'
    const url = `/${endpoint}/${ordenCompraID}/recuperar/${line}`
    return url
}

export const ocpGetRegcUrl = (rcptNumDoc, grupo, emsrNumDoc, fechaIni, fechaFin, tipoOrden, pendiente, page, pageSize) => {
    const endpoint = 'ocp/regCpe'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&emsrNumDoc=${emsrNumDoc}&fechaIni=${fechaIni}T00:00:00&fechaFin=${fechaFin}T00:00:00&tipoOrden=${tipoOrden}&pendiente=${pendiente.toString()}&page=${page}&pageSize=${pageSize}`
    return url
}

export const ocpGetNumUrl = (rcptNumDoc, numOC) => {
    const endpoint = 'ocp'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numOC=${numOC}`
    return url
}

export const rcpRecuperarUrl = (recepcionID, line) => {
    const endpoint = 'rcp'
    const url = `/${endpoint}/${recepcionID}/recuperar/${line}`
    return url
}

export const rcpGetNumUrl = (rcptNumDoc, numRE) => {
    const endpoint = 'rcp'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numRE=${numRE}`
    return url
}

export const rcpGetNocUrl = (rcptNumDoc, numOC) => {
    const endpoint = 'rcp'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numOC=${numOC}`
    return url
}

export const cpeRegistrarUrl = () => {
    const endpoint = 'cpe'
    const url = `/${endpoint}`
    return url
}

export const cpeEliminarUrl = (comprobanteID) => {
    const endpoint = 'cpe'
    const url = `/${endpoint}/${comprobanteID}`
    return url
}

export const cpeAdjuntarUrl = (comprobanteID, line, data = false) => {
    const endpoint = 'cpe'
    const url = `/${endpoint}/${comprobanteID}/adjuntar/${line}` + (data ? '/data' : '')
    return url
}

export const cpeAprobarUrl = () => {
    const endpoint = 'cpe/aprobar'
    const url = `/${endpoint}`
    return url
}

export const cpeRechazarUrl = () => {
    const endpoint = 'cpe/rechazar'
    const url = `/${endpoint}`
    return url
}

export const cpeProgramarUrl = (comprobanteID, line, aprob = 0) => {
    const endpoint = 'cpe'
    const url = `/${endpoint}/${comprobanteID}/programar` + (line ? `/${line}` : '') + (aprob === 1 ? '/aprobar' : (aprob === 2 ? '/rechazar' : ''))
    return url
}

export const cpeRecuperarUrl = (comprobanteID, line) => {
    const endpoint = 'cpe'
    const url = `/${endpoint}/${comprobanteID}/recuperar/${line}`
    return url
}

export const cpeGetNumUrl = (rcptNumDoc, emsrNumDoc, tipoComprobanteID, serie, numero) => {
    const endpoint = 'cpe'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&emsrNumDoc=${emsrNumDoc}&tipoComprobanteID=${tipoComprobanteID}&serie=${serie}&numero=${numero}`
    return url
}

export const cpeGetNpgUrl = (rcptNumDoc, numPago) => {
    const endpoint = 'cpe'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numPago=${numPago}`
    return url
}

export const cpeGetValUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, pendiente, tipoComprobanteID, monedaISO, emsrNumDoc, page, pageSize) => {
    const endpoint = 'cpe/validar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}T00:00:00&fechaFin=${fechaFin}T00:00:00&pendiente=${pendiente.toString()}&tipoComprobanteID=${tipoComprobanteID}&monedaISO=${monedaISO}&emsrNumDoc=${emsrNumDoc}&page=${page}&pageSize=${pageSize}`
    return url
}

export const cpeGetProgUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, condicionID, tipoComprobanteID, monedaISO, emsrNumDoc, page, pageSize) => {
    const endpoint = 'cpe/programar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}T00:00:00&fechaFin=${fechaFin}T00:00:00&condicionID=${condicionID}&tipoComprobanteID=${tipoComprobanteID}&monedaISO=${monedaISO}&emsrNumDoc=${emsrNumDoc}&page=${page}&pageSize=${pageSize}`
    return url
}

export const cpeGetStdoUrl = (rcptNumDoc, grupo, emsrNumDoc, fechaIni, fechaFin, estado, condicionID, tipoComprobanteID, monedaISO, page, pageSize) => {
    const endpoint = 'cpe/estadoCta'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&emsrNumDoc=${emsrNumDoc}&fechaIni=${fechaIni}T00:00:00&fechaFin=${fechaFin}T00:00:00&estado=${estado}&condicionID=${condicionID}&tipoComprobanteID=${tipoComprobanteID}&monedaISO=${monedaISO}&page=${page}&pageSize=${pageSize}`
    return url
}

export const cpeGetCntaUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, estado, condicionID, tipoComprobanteID, monedaISO, emsrNumDoc, page, pageSize) => {
    const endpoint = 'cpe/consultar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}T00:00:00&fechaFin=${fechaFin}T00:00:00&estado=${estado}&condicionID=${condicionID}&tipoComprobanteID=${tipoComprobanteID}&monedaISO=${monedaISO}&emsrNumDoc=${emsrNumDoc}&page=${page}&pageSize=${pageSize}`
    return url
}

export const pagRecuperarUrl = (pagoID, line) => {
    const endpoint = 'pag'
    const url = `/${endpoint}/${pagoID}/recuperar/${line}`
    return url
}

export const pagGetNumUrl = (rcptNumDoc, numPago) => {
    const endpoint = 'pag'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numPago=${numPago}`
    return url
}

export const pagGetNcpUrl = (comprobanteID) => {
    const endpoint = 'pag'
    const url = `/${endpoint}?comprobanteID=${comprobanteID}`
    return url
}

export const solRegistrarUrl = () => {
    const endpoint = 'sol'
    const url = `/${endpoint}`
    return url
}

export const solEliminarUrl = (id) => {
    const endpoint = 'sol'
    const url = `/${endpoint}/${id}`
    return url
}

export const solAdjuntarUrl = (id, line = null) => {
    const endpoint = 'sol'
    const url = `/${endpoint}/${id}/adjuntar` + (line != null ? `/${line}` : '')
    return url
}

export const solAprobarUrl = (id) => {
    const endpoint = 'sol'
    const url = `/${endpoint}/${id}/aprobar`
    return url
}

export const solRechazarUrl = (id) => {
    const endpoint = 'sol'
    const url = `/${endpoint}/${id}/rechazar`
    return url
}

export const solAtenderUrl = (id) => {
    const endpoint = 'sol'
    const url = `/${endpoint}/${id}/atender`
    return url
}

export const solDevolverUrl = (id) => {
    const endpoint = 'sol'
    const url = `/${endpoint}/${id}/devolver`
    return url
}

export const solRecuperarUrl = (id, line) => {
    const endpoint = 'sol'
    const url = `/${endpoint}/${id}/recuperar/${line}`
    return url
}

export const solGetNumUrl = (rcptNumDoc, numSO) => {
    const endpoint = 'sol'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numSO=${numSO}`
    return url
}

export const solGetNregUrl = (rcptNumDoc, numSO) => {
    const endpoint = 'sol/registrar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numSO=${numSO}&solicitante=`
    return url
}

export const solGetNaprUrl = (rcptNumDoc, numSO, pendiente, aprobador) => {
    const endpoint = 'sol/aprobar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numSO=${numSO}&pendiente=${pendiente.toString()}&aprobador=${aprobador}`
    return url
}

export const solGetRegUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipo, area, estado, resource, page, pageSize) => {
    const endpoint = 'sol/registrar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipo=${tipo}&area=${area}&estado=${estado}&solicitante=&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const solGetAprUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, pendiente, tipo, area, estado, aprobador, resource, page, pageSize) => {
    const endpoint = 'sol/aprobar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&pendiente=${pendiente.toString()}&tipo=${tipo}&area=${area}&estado=${estado}&aprobador=${aprobador}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const solGetRenUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipo, area, resource, page, pageSize) => {
    const endpoint = 'sol/rendir'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipo=${tipo}&area=${area}&solicitante=&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const solGetConUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipo, area, estado, resource, page, pageSize) => {
    const endpoint = 'sol/consultar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipo=${tipo}&area=${area}&estado=${estado}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const opeRegistrarUrl = () => {
    const endpoint = 'ope'
    const url = `/${endpoint}`
    return url
}

export const opeEliminarUrl = (id) => {
    const endpoint = 'ope'
    const url = `/${endpoint}/${id}`
    return url
}

export const opeModificarUrl = (id) => {
    const endpoint = 'ope'
    const url = `/${endpoint}/${id}`
    return url
}

export const opeAdjuntarUrl = (id, line = null) => {
    const endpoint = 'ope'
    const url = `/${endpoint}/${id}/adjuntar` + (line ? `/${line}` : '')
    return url
}

export const opeRecuperarUrl = (id, line) => {
    const endpoint = 'ope'
    const url = `/${endpoint}/${id}/recuperar/${line}`
    return url
}

export const opeGetIdUrl = (id) => {
    const endpoint = 'ope'
    const url = `/${endpoint}/${id}`
    return url
}

export const opeGetNumUrl = (rcptNumDoc, numOP, page, pageSize) => {
    const endpoint = 'ope'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numOP=${numOP}&page=${page}&pageSize=${pageSize}`
    return url
}

export const opeGetRegUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tpoOP, area, numeroCta, resource, page, pageSize) => {
    const endpoint = 'ope/registrar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tpoOP=${tpoOP}&area=${area}&numeroCta=${numeroCta}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const opeGetPendUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tpoOP, area, resource, page, pageSize) => {
    const endpoint = 'ope/pendiente'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tpoOP=${tpoOP}&area=${area}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const renRegistrarUrl = () => {
    const endpoint = 'ren'
    const url = `/${endpoint}`
    return url
}

export const renEliminarUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}`
    return url
}

export const renModificarUrl = (id, val = null) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}` + (val ? `/val` : '')
    return url
}

export const renAdjuntarUrl = (id, line = null) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/adjuntar` + (line !== null && line !== undefined ? `/${line}` : '')
    return url
}

export const renRevisarUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/revisar`
    return url
}

export const renEnviarUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/enviar`
    return url
}

export const renAprobarUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/aprobar`
    return url
}

export const renRechazarUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/rechazar`
    return url
}

export const renValidarUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/validar`
    return url
}

export const renObservarUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/observar`
    return url
}

export const renDevolverUrl = (id) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/devolver`
    return url
}

export const renRecuperarUrl = (id, line) => {
    const endpoint = 'ren'
    const url = `/${endpoint}/${id}/recuperar/${line}`
    return url
}

export const renGetNumUrl = (rcptNumDoc, numRD) => {
    const endpoint = 'ren'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numRD=${numRD}`
    return url
}

export const renGetNregUrl = (rcptNumDoc, numRD) => {
    const endpoint = 'ren/registrar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numRD=${numRD}&solicitante=`
    return url
}

export const renGetNrevUrl = (rcptNumDoc, numRD, pendiente) => {
    const endpoint = 'ren/revisar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numRD=${numRD}&pendiente=${pendiente.toString()}`
    return url
}

export const renGetNaprUrl = (rcptNumDoc, numRD, pendiente, aprobador) => {
    const endpoint = 'ren/aprobar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numRD=${numRD}&pendiente=${pendiente.toString()}&aprobador=${aprobador}`
    return url
}

export const renGetNvalUrl = (rcptNumDoc, numRD, pendiente) => {
    const endpoint = 'ren/validar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numRD=${numRD}&pendiente=${pendiente.toString()}`
    return url
}

export const renGetRegUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipo, area, estado, numSO, resource, page, pageSize) => {
    const endpoint = 'ren/registrar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipo=${tipo}&area=${area}&estado=${estado}&numSO=${numSO}&solicitante=&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const renGetRevUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, pendiente, tipo, area, numSO, resource, page, pageSize) => {
    const endpoint = 'ren/revisar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&pendiente=${pendiente.toString()}&tipo=${tipo}&area=${area}&numSO=${numSO}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const renGetAprUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, pendiente, tipo, area, estado, numSO, aprobador, resource, page, pageSize) => {
    const endpoint = 'ren/aprobar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&pendiente=${pendiente.toString()}&tipo=${tipo}&area=${area}&estado=${estado}&numSO=${numSO}&aprobador=${aprobador}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const renGetValUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, pendiente, tipo, area, numSO, resource, page, pageSize) => {
    const endpoint = 'ren/validar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&pendiente=${pendiente.toString()}&tipo=${tipo}&area=${area}&numSO=${numSO}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const renGetConUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipo, area, estado, numSO, resource, page, pageSize) => {
    const endpoint = 'ren/consultar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipo=${tipo}&area=${area}&estado=${estado}&numSO=${numSO}&resource=${resource}&page=${page}&pageSize=${pageSize}`
    return url
}

export const docRegistrarUrl = () => {
    const endpoint = 'doc'
    const url = `/${endpoint}`
    return url
}

export const docEliminarUrl = (id, val = null) => {
    const endpoint = 'doc'
    const url = `/${endpoint}/${id}` + (val ? `/val` : '')
    return url
}

export const docModificarUrl = (id, val = null) => {
    const endpoint = 'doc'
    const url = `/${endpoint}/${id}` + (val ? `/val` : '')
    return url
}

export const docAdjuntarUrl = (id, fileType = null, line = null, val = null) => {
    const endpoint = 'doc'
    const url = `/${endpoint}/${id}/adjuntar` + (fileType ? `/${fileType}/data` : '') + (line !== null && line !== undefined ? `/${line}` : '') + (val ? `/val` : '')
    return url
}

export const docRecuperarUrl = (id, fileType = null, line = null) => {
    const endpoint = 'doc'
    const url = `/${endpoint}/${id}/recuperar` + (fileType ? `/${fileType}/data` : '') + (line !== null && line !== undefined ? `/${line}` : '')
    return url
}

export const docGetIdUrl = (id) => {
    const endpoint = 'doc'
    const url = `/${endpoint}/${id}`
    return url
}

export const docGetRenUrl = (rendicionID) => {
    const endpoint = 'doc'
    const url = `/${endpoint}?rendicionID=${rendicionID}`
    return url
}

export const movGetNumUrl = (rcptNumDoc, numMV, page, pageSize) => {
    const endpoint = 'mov'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numMV=${numMV}&page=${page}&pageSize=${pageSize}`
    return url
}

export const movGetConUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipoDoc, tipoMov, rindente, resource, sort, page, pageSize) => {
    const endpoint = 'mov/consultar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipoDoc=${tipoDoc}&tipoMov=${tipoMov}&rindente=${rindente}&resource=${resource}&sort=${sort.toString()}&page=${page}&pageSize=${pageSize}`
    return url
}

export const movGetSdocUrl = (rcptNumDoc, grupo, fecha, tipoDoc, rindente, resource) => {
    const endpoint = 'mov/saldodoc'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&tipoDoc=${tipoDoc}&rindente=${rindente}&resource=${resource}`
    return url
}

export const movGetSrinUrl = (rcptNumDoc, grupo, fecha, rindente, resource) => {
    const endpoint = 'mov/saldorin'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&rindente=${rindente}&resource=${resource}`
    return url
}

export const movGetStpoUrl = (rcptNumDoc, grupo, fecha, resource) => {
    const endpoint = 'mov/saldotpo'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&resource=${resource}`
    return url
}

export const atoGetNumUrl = (rcptNumDoc, numMV, page, pageSize) => {
    const endpoint = 'ato'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numMV=${numMV}&page=${page}&pageSize=${pageSize}`
    return url
}

export const atoGetConUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipoCta, tipoMov, rindente, resource, sort, page, pageSize) => {
    const endpoint = 'ato/consultar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipoCta=${tipoCta}&tipoMov=${tipoMov}&rindente=${rindente}&resource=${resource}&sort=${sort.toString()}&page=${page}&pageSize=${pageSize}`
    return url
}

export const atoGetSdocUrl = (rcptNumDoc, grupo, fecha, tipoCta, rindente, resource) => {
    const endpoint = 'ato/saldodoc'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&tipoCta=${tipoCta}&rindente=${rindente}&resource=${resource}`
    return url
}

export const atoGetSrinUrl = (rcptNumDoc, grupo, fecha, rindente, resource) => {
    const endpoint = 'ato/saldorin'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&rindente=${rindente}&resource=${resource}`
    return url
}

export const atoGetStpoUrl = (rcptNumDoc, grupo, fecha, resource) => {
    const endpoint = 'ato/saldotpo'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&resource=${resource}`
    return url
}

export const ctbGetNumUrl = (rcptNumDoc, numMV, page, pageSize) => {
    const endpoint = 'ctb'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&numMV=${numMV}&page=${page}&pageSize=${pageSize}`
    return url
}

export const ctbGetConUrl = (rcptNumDoc, grupo, fechaIni, fechaFin, tipoCta, tipoMov, rindente, resource, sort, page, pageSize) => {
    const endpoint = 'ctb/consultar'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&tipoCta=${tipoCta}&tipoMov=${tipoMov}&rindente=${rindente}&resource=${resource}&sort=${sort.toString()}&page=${page}&pageSize=${pageSize}`
    return url
}

export const ctbGetSdocUrl = (rcptNumDoc, grupo, fecha, tipoCta, rindente, resource) => {
    const endpoint = 'ctb/saldodoc'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&tipoCta=${tipoCta}&rindente=${rindente}&resource=${resource}`
    return url
}

export const ctbGetSrinUrl = (rcptNumDoc, grupo, fecha, rindente, resource) => {
    const endpoint = 'ctb/saldorin'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&rindente=${rindente}&resource=${resource}`
    return url
}

export const ctbGetStpoUrl = (rcptNumDoc, grupo, fecha, resource) => {
    const endpoint = 'ctb/saldotpo'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&fecha=${fecha}&resource=${resource}`
    return url
}

export const tsk1Url = () => {
    const endpoint = 'tsk/cpeProgDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk2Url = (rcptNumDoc = null, grupo = null) => {
    const endpoint = 'tsk/cpeProgUpFile'
    const parameter = rcptNumDoc && (grupo || grupo === '') ? `?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}` : ''
    const url = `/${endpoint}${parameter}`
    return url
}

export const tsk3Url = () => {
    const endpoint = 'tsk/cpeStdoDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk4Url = () => {
    const endpoint = 'tsk/cpeCntaDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk5Url = () => {
    const endpoint = 'tsk/docConDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk6Url = () => {
    const endpoint = 'tsk/renConDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk7Url = () => {
    const endpoint = 'tsk/movConDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk8Url = (rcptNumDoc = null, numRD = null) => {
    const endpoint = 'tsk/docRegUpFile'
    const parameter = rcptNumDoc && numRD ? `?rcptNumDoc=${rcptNumDoc}&numRD=${numRD}` : ''
    const url = `/${endpoint}${parameter}`
    return url
}

export const tsk9Url = () => {
    const endpoint = 'tsk/solConDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk10Url = () => {
    const endpoint = 'tsk/opeRegDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk11Url = (rcptNumDoc = null) => {
    const endpoint = 'tsk/ctoCpeValUpFile'
    const parameter = rcptNumDoc ? `?rcptNumDoc=${rcptNumDoc}` : ''
    const url = `/${endpoint}${parameter}`
    return url
}

export const tsk12Url = () => {
    const endpoint = 'tsk/atoConDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk13Url = () => {
    const endpoint = 'tsk/ctbConBuildFile'
    const url = `/${endpoint}`
    return url
}

export const tsk14Url = () => {
    const endpoint = 'tsk/ctbConDownFile'
    const url = `/${endpoint}`
    return url
}

export const tsk15Url = (rcptNumDoc = null) => {
    const endpoint = 'tsk/aerCfgUpFile'
    const parameter = rcptNumDoc ? `?rcptNumDoc=${rcptNumDoc}` : ''
    const url = `/${endpoint}${parameter}`
    return url
}

export const tsk16Url = () => {
    const endpoint = 'tsk/aerCfgDownFile'
    const url = `/${endpoint}`
    return url
}

export const tskRecuperarUrl = (taskID) => {
    const endpoint = 'tsk'
    const url = `/${endpoint}/${taskID}/recuperar`
    return url
}

export const chtGetTotDocUrl = (rcptNumDoc, grupo, emsrNumDoc, fechaIni, fechaFin) => {
    const endpoint = 'cht/totDoc'
    const url = `/${endpoint}?rcptNumDoc=${rcptNumDoc}&grupo=${grupo}&emsrNumDoc=${emsrNumDoc}&fechaIni=${fechaIni}&fechaFin=${fechaFin}`
    return url
}

/** CALISTO ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** **/

export const cpeDataFVUrl = () => {
    const endpoint = 'cpe/dataFV'
    const url = `/${endpoint}`
    return url
}

export const cpeValidateUrl = () => {
    const endpoint = 'cpe/validate'
    const url = `/${endpoint}`
    return url
}

export const storageRUCUrl = (numDoc) => {
    const endpoint = 'storage/ruc'
    const url = `/${endpoint}?numDoc=${numDoc}`
    return url
}

export const storageTCambioUrl = (monedaID, fecha) => {
    const endpoint = 'storage/tipoCambio'
    const url = `/${endpoint}?monedaID=${monedaID}&fecha=${fecha}T00:00:00`
    return url
}

export const storagePageDNIUrl = (numDoc) => {
    const endpoint = 'storage/dni'
    const url = `/${endpoint}?numDoc=${numDoc}`
    return url
}

export const webPageDNIUrl = (numDoc) => {
    const endpoint = 'webPage/dni'
    const url = `/${endpoint}?numDoc=${numDoc}`
    return url
}

/** Dione ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** **/

export const billDataUrl = (tipoComprobanteID) => {
    const endpoint = 'bill/data'
    const url = `/${endpoint}/${tipoComprobanteID}`
    return url
}