import { CloudDownloadOutlined } from '@ant-design/icons'
import { Spin, Table } from 'antd'
import React, { memo, useEffect, useRef, useState } from 'react'
import { pagGetNcpUrl, pagRecuperarUrl } from '../../../api/apiUrl'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { notifyType } from '../../../types/notifyType'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { NotifyRed } from '../../Msg/NotifyRed'

import './ListaPag.css'

var jszip = require('jszip')

export const ListaPag = memo((props) => {
    const { record: cpeDB, selectedRcpt, openDrawer } = props

    const { axiosTnaGet } = useAxiosTna()
    const notifyRedRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [payments, setPayments] = useState([])
    const [currentNumPago, setCurrentNumPago] = useState('')

    useEffect(() => {
        let isMounted = true

        const busqNumCpe = async () => {
            let err = null
            let data = null

            const url = pagGetNcpUrl(cpeDB.id)
            const [data_api, err_api] = await axiosTnaGet(url)
            data = data_api
            err = err_api

            if (data && isMounted) {
                const { success, data: pags } = data
                if (success) {
                    pags.sort(sortPag)
                    setPayments(pags.map(pag => {
                        if (pag.comprobante)
                            pag.cpe = pag.comprobante.find(p => p.comprobanteID === cpeDB.id) || null
                        if (pag.attach)
                            pag.file = pag.attach[0] || null
                        pag.moneda_Pag = false
                        pag.monedaISO_Apl = cpeDB.monedaISO
                        pag.monto_Apl = pag.cpe?.montoCpe || 0.0
                        return { ...pag, key: pag.numPago }
                    }))
                }
                else
                    setPayments([])
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)
            setLoadPage(false)
        }

        busqNumCpe()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [cpeDB, selectedRcpt])

    const sortPag = (a, b) => {
        if (a.fechaPago > b.fechaPago)
            return 1
        if (a.fechaPago < b.fechaPago)
            return -1
        if (a.numPago > b.numPago)
            return 1
        else
            return -1
    }

    const handleClickMonto = (record) => setPayments(prev => {
        const pags = prev.filter(p => p.id !== record.id)
        const pag = { ...record }
        if (pag.moneda_Pag) {
            pag.moneda_Pag = false
            pag.monedaISO_Apl = cpeDB.monedaISO
            pag.monto_Apl = pag.cpe?.montoCpe || 0.0
        }
        else {
            pag.moneda_Pag = true
            pag.monedaISO_Apl = pag.monedaISO
            pag.monto_Apl = pag.cpe?.monto || 0.0
        }
        const data = [...pags, pag]
        data.sort(sortPag)
        return data
    })

    const handleClickNumPago = async (record) => {
        const attach = record.file

        let err = null
        let data = null

        setCurrentNumPago(record.numPago)

        const url = pagRecuperarUrl(record.id, attach.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(attach.fileName).async('base64')
                if (attach.contentType === configType.contentTypePDF)
                    viewerFile(unzip, attach.contentType, attach.fileName)
                else
                    downloadFile(unzip, attach.contentType, attach.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentNumPago('')
        setLastAccess()
    }

    const pgColumns = [
        {
            title: 'Nº Pago',
            dataIndex: 'numPago',
            width: 160,
            render: (text, record) =>
                <div className='flex items-center'>
                    <button
                        className='text-blue-600 w-full text-left'
                        onClick={() => openDrawer(cpeDB, record)}
                        disabled={record.numPago === currentNumPago}
                    >
                        {text}
                    </button>
                    {
                        record.file &&
                        <div className='flex justify-end'>
                            {
                                record.numPago !== currentNumPago &&
                                <button onClick={() => handleClickNumPago(record)}>
                                    <CloudDownloadOutlined />
                                </button>
                            }
                            {
                                record.numPago === currentNumPago &&
                                <Spin size='small' />
                            }
                        </div>
                    }
                </div>
        },
        {
            title: 'Fecha',
            dataIndex: 'fechaPago',
            width: 100,
            render: (_, record) => <span>{formatDate(record.fechaPago)}</span>
        },
        {
            title: 'Tipo',
            dataIndex: 'tipoPago',
        },
        {
            title: 'Banco',
            dataIndex: 'banco',
            width: 130,
            render: (_, record) => <span>{record.banco || ''}</span>
        },
        {
            title: 'Nº Cuenta',
            dataIndex: 'numCuenta',
            width: 130,
            render: (_, record) => <span>{record.numCuenta || ''}</span>
        },
        {
            title: 'Nº Operación',
            dataIndex: 'numOperacion',
            width: 130,
            render: (_, record) => <span>{record.numOperacion || ''}</span>
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO_Apl',
            width: 50,
            align: 'center',
        },
        {
            title: 'Monto',
            dataIndex: 'monto_Apl',
            width: 120,
            align: 'right',
            render: (_, record) => record.monedaISO === cpeDB.monedaISO ?
                <span>{formatAmount(record.monto_Apl || 0.0)}</span> :
                <button
                    className='text-blue-600'
                    onClick={() => handleClickMonto(record)}
                >
                    {formatAmount(record.monto_Apl || 0.0)}
                </button>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' />
                    </div> :
                    <div className='m-1 ant-table-titania'>
                        <p className='mb-2 text-xs text-gray-700'>
                            Detalle de pagos aplicados al comprobante.
                        </p>
                        <Table className='lg:max-w-5xl' columns={pgColumns} dataSource={payments} pagination={false} bordered />
                    </div>
            }
        </>
    )
})
