import { getError } from "./apiUtil"
import { axiosTna } from "./axiosTna"

export const axiosTnaGet = async (url) => {
    let err = null
    let dat = null
    try {
        const resp = await axiosTna.get(url)
        dat = resp?.data
    } catch (error) {
        err = getError(error)
        console.log(error)
    }
    return [dat, err]
}

export const axiosTnaPost = async (url, data) => {
    let err = null
    let dat = null
    try {
        const resp = await axiosTna.post(url, JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
        })
        dat = resp?.data
    } catch (error) {
        err = getError(error)
        console.log(error)
    }
    return [dat, err]
}