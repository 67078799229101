import './NewDoc.css'

import { QuestionCircleOutlined, DeleteOutlined, SaveOutlined, CloudUploadOutlined, SyncOutlined, ScheduleOutlined, DeleteFilled, FileAddOutlined, ClearOutlined, ReloadOutlined, CloudDownloadOutlined, CheckCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Spin, Table, Tabs, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../../hooks/useAxiosCto'
import { useAxiosDne } from '../../../../hooks/useAxiosDne'
import { billDataUrl, catGetCodUrl, cfgGetDocEnrUrl, cfgGetFileBillUrl, cpeValidateUrl, docAdjuntarUrl, docEliminarUrl, docGetIdUrl, docModificarUrl, docRecuperarUrl, docRegistrarUrl, renGetNumUrl, segConnUrl, storagePageDNIUrl, storageRUCUrl, storageTCambioUrl, webPageDNIUrl } from '../../../../api/apiUrl'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { msgType } from '../../../../types/msgType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { downloadFile, jsonBase64, toBase64, viewerFile } from '../../../../utils/fileUtil'
import { formatAmount, formatDigits, formatRound } from '../../../../utils/formatUtil'
import { ListaDoc } from '../../List/ListaDoc'

var jszip = require('jszip')

export const NewDoc = (props) => {
  const { selReceptor, selRendicion, monedas, egasto, area, tolTCambio, showNotify, updateRen, updateDoc, validar } = props

  const dvFecha = moment()
  const dvMoneda = monedas.find(() => true)
  const dvMonto = 0.0

  const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
  const { axiosCtoGet, axiosCtoPost } = useAxiosCto()
  const { axiosDnePut } = useAxiosDne()
  const [formDta] = Form.useForm()
  const [formCtb] = Form.useForm()
  const [formAsg] = Form.useForm()
  const [loadPage, setLoadPage] = useState(false)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadTipoDoc, setLoadTipoDoc] = useState(false)
  const [loadTipoSTrib, setLoadTipoSTrib] = useState(false)
  const [loadTipoAdj, setLoadTipoAdj] = useState(false)
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [loadDocumento, setLoadDocumento] = useState(false)
  const [loadValEmisor, setLoadValEmisor] = useState(false)
  const [loadValComprobante, setLoadValComprobante] = useState(false)
  const [loadDataDocumento, setLoadDataDocumento] = useState(false)
  const [loadTipoCambio, setLoadTipoCambio] = useState(false)
  const [loadFile, setLoadFile] = useState(false)
  const [loadRegDoc, setLoadRegDoc] = useState(false)
  const [loadEliDoc, setLoadEliDoc] = useState(false)
  const [documentos, setDocumentos] = useState([])
  const [tiposDoc, setTiposDoc] = useState([])
  const [tiposTrib, setTiposTrib] = useState([])
  const [tiposSTrib, setTiposSTrib] = useState([])
  const [tiposAdj, setTiposAdj] = useState([])
  const [asignaciones, setAsignaciones] = useState([])
  const [ccosto, setCcosto] = useState([])
  const [adjuntos, setAdjuntos] = useState([])
  const [opcionesEgas, setOpcionesEgas] = useState([])
  const [dbDocumento, setDbDocumento] = useState(null)
  const [selDocumento, setSelDocumento] = useState(null)
  const [selAsignacion, setSelAsignacion] = useState(null)
  const [selTipoAdj, setSelTipoAdj] = useState(null)
  const [fmTipoDoc, setFmTipoDoc] = useState(null)
  const [fmSerie, setFmSerie] = useState(null)
  const [fmNumero, setFmNumero] = useState(null)
  const [fmEmsrNumDoc, setFmEmsrNumDoc] = useState(null)
  const [fmEmisor, setFmEmisor] = useState(null)
  const [fmValidatedCPE, setFmValidatedCPE] = useState(false)
  const [fmFechaDoc, setFmFechaDoc] = useState(dvFecha)
  const [fmMoneda, setFmMoneda] = useState(dvMoneda)
  const [fmTipoCambio, setFmTipoCambio] = useState(null)
  const [fmTCambioRen, setFmTCambioRen] = useState(null)
  const [fmTipoTrib, setFmTipoTrib] = useState(null)
  const [fmMontoBase, setFmMontoBase] = useState(dvMonto)
  const [fmMontoTrib, setFmMontoTrib] = useState(dvMonto)
  const [fmCargo, setFmCargo] = useState(dvMonto)
  const [fmDescuento, setFmDescuento] = useState(dvMonto)
  const [fmTotal, setFmTotal] = useState(dvMonto)
  const [fmMontoRen, setFmMontoRen] = useState(dvMonto)
  const [fmDescrip, setFmDescrip] = useState(null)
  const [fmUploadedXML, setFmUploadedXML] = useState(false)
  const [fmTipoSTrib, setFmTipoSTrib] = useState(null)
  const [fmMontoSTrib, setFmMontoSTrib] = useState(dvMonto)
  const [fmCatSTrib, setFmCatSTrib] = useState(null)
  const [fmNumConst, setFmNumConst] = useState(null)
  const [fmFechaPago, setFmFechaPago] = useState(dvFecha)
  const [fmEGasto, setFmEGasto] = useState(null)
  const [dvSearchEgas, setDvSearchEgas] = useState(null)
  const [documentXML, setDocumentXML] = useState(null)
  const [documentJSON, setDocumentJSON] = useState(null)
  const [currentLineFI, setCurrentLineFI] = useState(null)
  const [activeTabKey, setActiveTabKey] = useState("1")
  const [clearDoc, setClearDoc] = useState(false)
  const [totalDoc, setTotalDoc] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    setLoadPage(loadTokenConn || loadTipoDoc || loadTipoSTrib || loadTipoAdj)
  }, [loadTokenConn, loadTipoDoc, loadTipoSTrib, loadTipoAdj])

  useEffect(() => {
    setComponentDisabled(loadDocumento || loadValEmisor || loadValComprobante || loadTipoCambio || loadFile || loadRegDoc || loadEliDoc)
  }, [loadDocumento, loadValEmisor, loadValComprobante, loadDataDocumento, loadTipoCambio, loadFile, loadRegDoc, loadEliDoc])

  useEffect(() => {
    if (documentos && documentos.length > 0 && selRendicion) {
      let monto = 0.0
      documentos.forEach(doc => {
        monto += doc.monto;
      })
      setTotalDoc(`${documentos.length} doc. y ${formatAmount(monto)} ${selRendicion.monedaISO}`)
    }
    else
      setTotalDoc(null)
  }, [documentos, selRendicion])

  useEffect(() => {
    if (selRendicion) {
      if (selRendicion.documentos) {
        const adoc = selRendicion.documentos.map((doc, index) => ({ ...doc, key: index }))
        const sdoc = adoc.length > 0 ? adoc[adoc.length - 1] : null
        setDocumentos(adoc)
        setSelDocumento(sdoc)
      }
    }
  }, [selRendicion])

  useEffect(() => {
    if (dbDocumento) {
      const tdoc = tiposDoc.find(p => p.tipoDocumentoID === dbDocumento.tipoDocumentoID)
      const mon = monedas.find(p => p.codigo === dbDocumento.monedaISO)
      const [atrib, strib] = getTrib(tdoc, dbDocumento)
      const mbase = strib?.montoBase ?? dvMonto
      const mtrib = strib?.montoTributo ?? dvMonto
      const tipoSTrib = dbDocumento.stributo ? tiposSTrib.find(p => p.codigo === dbDocumento.stributo.nombreTributo) : null
      const montoSTrib = tipoSTrib ? dbDocumento.stributo.montoTributo : dvMonto
      const catSTrib = tipoSTrib ? dbDocumento.stributo.categoriaID : null
      const numConst = tipoSTrib && tipoSTrib.bool1 && dbDocumento.stributo.autodetraccion ? dbDocumento.stributo.autodetraccion.numConstancia : null
      const fechaPago = tipoSTrib && tipoSTrib.bool1 && dbDocumento.stributo.autodetraccion ? dbDocumento.stributo.autodetraccion.fechaPago : dvFecha
      const egas = egasto.find(p => p.code === dbDocumento.egastoCode)
      const aasg = dbDocumento.asignaciones.map((asg, index) => getAsig({ ...asg, porcentaje: asg.porcentaje * 100.0 }, index))
      const sasg = aasg.length > 0 ? aasg[aasg.length - 1] : null
      const montoAsg = sasg?.monto ?? dvMonto
      const porcAsg = sasg?.porcentaje ?? dvMonto
      const acos = getCCosArea(area, sasg)
      const aadj = dbDocumento.attach.map(p => ({ ...p, key: p.dataOrder }))

      let tbase = 0
      let ttrib = 0
      atrib.forEach(trib => {
        tbase += (trib.montoBase)
        ttrib += formatRound(trib.montoTributo * (trib.negativo ? -1.0 : 1.0), 2)
      })

      setFmTipoDoc(tdoc)
      setFmSerie(dbDocumento.serie)
      setFmNumero(dbDocumento.numero)
      setFmEmsrNumDoc(dbDocumento.emisor?.code)
      setFmEmisor(dbDocumento.emisor)
      setFmValidatedCPE(dbDocumento.validatedCPE)
      setFmFechaDoc(moment(dbDocumento.fechaDoc))
      setFmMoneda(mon)
      setFmTipoCambio(dbDocumento.tipoCambio)
      setFmTCambioRen(dbDocumento.tCambioRen)
      setTiposTrib(atrib)
      setFmTipoTrib(strib)
      setFmMontoBase(mbase)
      setFmMontoTrib(mtrib)
      setFmCargo(dbDocumento.cargo)
      setFmDescuento(dbDocumento.descuento)
      setFmTotal(dbDocumento.total)
      setFmMontoRen(dbDocumento.origen?.montoRen)
      setFmDescrip(dbDocumento.descrip)
      setFmUploadedXML(dbDocumento.uploadedXML)
      formDta.setFieldsValue({
        fechaDoc: moment(dbDocumento.fechaDoc),
        monedaISO: mon?.codigo,
        tipoCambio: dbDocumento.tipoCambio,
        tipoTrib: strib?.nombreTributo,
        montoBase: mbase,
        montoTrib: mtrib,
        totalBase: tbase,
        totalTrib: ttrib,
        cargo: dbDocumento.cargo,
        descuento: dbDocumento.descuento,
        total: dbDocumento.total,
        montoRen: dbDocumento.origen?.montoRen,
        descrip: dbDocumento.descrip,
      })

      setFmTipoSTrib(tipoSTrib)
      setFmMontoSTrib(montoSTrib)
      setFmCatSTrib(catSTrib)
      setFmNumConst(numConst)
      setFmFechaPago(moment(fechaPago))
      formCtb.setFieldsValue({
        tipoSTrib: tipoSTrib?.codigo,
        montoSTrib: montoSTrib,
        catSTrib: catSTrib,
        numConst: numConst,
        fechaPago: moment(fechaPago),
      })

      setOpcionesEgas(searchResultEgas(egas?.code, egasto))
      setFmEGasto(egas)
      setDvSearchEgas(egas?.code)
      setAsignaciones(aasg)
      setSelAsignacion(sasg)
      setCcosto(acos)
      formAsg.setFieldsValue({
        monto: montoAsg,
        porcentaje: porcAsg,
      })

      setAdjuntos(aadj)
    }
    else {
      const egas = egasto.find(p => p.code === selRendicion?.egastoCode)
      const ocos = selRendicion?.ccosto?.map(p => ({ dimID: p.dimID, codeID: p.codeID })) || []
      const asgn = [{ line: 0, monto: dvMonto, porcentaje: dvMonto, ccosto: ocos }]
      const aasg = asgn.map((asg, index) => getAsig(asg, index))
      const sasg = aasg.length > 0 ? aasg[aasg.length - 1] : null
      const montoAsg = sasg?.monto ?? dvMonto
      const porcAsg = sasg?.porcentaje ?? dvMonto
      const acos = getCCosArea(area, sasg)

      setFmTipoDoc(null)
      setFmSerie(null)
      setFmNumero(null)
      setFmEmsrNumDoc(null)
      setFmEmisor(null)
      setFmValidatedCPE(false)
      setFmFechaDoc(dvFecha)
      setFmMoneda(dvMoneda)
      setFmTipoCambio(null)
      setFmTCambioRen(null)
      setTiposTrib([])
      setFmTipoTrib(null)
      setFmMontoBase(dvMonto)
      setFmMontoTrib(dvMonto)
      setFmCargo(dvMonto)
      setFmDescuento(dvMonto)
      setFmTotal(dvMonto)
      setFmMontoRen(dvMonto)
      setFmDescrip(null)
      setFmUploadedXML(false)
      formDta.resetFields()

      setFmTipoSTrib(null)
      setFmMontoSTrib(dvMonto)
      setFmCatSTrib(null)
      setFmNumConst(null)
      setFmFechaPago(dvFecha)
      formCtb.resetFields()

      setOpcionesEgas(searchResultEgas(egas?.code, egasto))
      setFmEGasto(egas)
      setDvSearchEgas(egas?.code)
      setAsignaciones(aasg)
      setSelAsignacion(sasg)
      setCcosto(acos)
      formAsg.setFieldsValue({
        monto: montoAsg,
        porcentaje: porcAsg,
      })

      setAdjuntos([])
    }

    setSelTipoAdj(null)
    setDocumentXML(null)
    setDocumentJSON(null)
    setActiveTabKey('1')
    // eslint-disable-next-line
  }, [dbDocumento, clearDoc, selReceptor, selRendicion, monedas, egasto, area, tiposDoc, tiposSTrib])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const cfgDocEnr = async () => {
      isMounted && setLoadTipoDoc(true)

      const url = cfgGetDocEnrUrl()
      const [data, err] = await axiosTnaGet(url)

      if (data && data.detalle)
        isMounted && setTiposDoc(data.detalle.map((p, index) => ({ ...p, key: index })))
      if (err)
        showNotify(err, notifyType.error)

      isMounted && setLoadTipoDoc(false)
    }

    const catTipoSTrib = async () => {
      isMounted && setLoadTipoSTrib(true)

      const url = catGetCodUrl(configType.catTipoSTrib)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposSTrib(data.detalle.filter(dt => dt.bool1 !== null))
      if (err)
        showNotify(err, notifyType.error)

      isMounted && setLoadTipoSTrib(false)
    }

    const cfgFileBill = async () => {
      isMounted && setLoadTipoAdj(true)

      const url = cfgGetFileBillUrl()
      const [data, err] = await axiosTnaGet(url)

      if (data && data.detalle)
        isMounted && setTiposAdj(data.detalle.map((p, index) => ({ ...p, key: index })))
      if (err)
        showNotify(err, notifyType.error)

      isMounted && setLoadTipoAdj(false)
    }

    if (!loadTokenConn) {
      cfgDocEnr()
      catTipoSTrib()
      cfgFileBill()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const getDoc = async () => {
      isMounted && setLoadDocumento(true)

      const url = docGetIdUrl(selDocumento.documentoID)
      const [data, err] = await axiosTnaGet(url)

      if (data) {
        const { success, data: doc } = data
        if (success)
          isMounted && setDbDocumento({ ...doc, key: doc.id })
      }
      if (err)
        showNotify(err, notifyType.error)

      isMounted && setLoadDocumento(false)
    }

    if (selDocumento)
      getDoc()
    else
      isMounted && setDbDocumento(null)

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [selDocumento])

  const refreshTCambio = async (fechaDoc, monedaISO) => {
    const fecha = fechaDoc.format().split('T')[0]
    const url = storageTCambioUrl(monedaISO, fecha)
    const [data, err] = await axiosCtoGet(url)

    if (err)
      console.log(`Tipo de cambio: ${err.message}`)
    const tipoCambio = data?.tcVenta || null
    return tipoCambio
  }

  const refreshRen = async (rcpt, ren) => {
    const url = renGetNumUrl(rcpt.ruc, ren.numRD)
    const [data, err] = await axiosTnaGet(url)

    if (err)
      console.log(`Rendición: ${err.message}`)
    const nren = data?.success ? data.data : null
    return nren
  }

  const attachFile = async (id, fileType, base64, mime, name) => {
    const url = docAdjuntarUrl(id, fileType)
    const [data, err] = await axiosTnaPut(url, { fileName: name, document: base64, contentType: mime })

    if (err)
      console.log(`Adjunto: ${err.message}`)
    const nadj = data ? true : false
    return nadj
  }

  const onValuesChangeDta = (changedValues, allValues) => {
    const fechaDoc = allValues.fechaDoc ?? null
    const moneda = monedas.find(p => p.codigo === allValues.monedaISO) || null
    const tipoCambio = allValues.tipoCambio ?? null
    const tipoTrib = tiposTrib.find(p => p.nombreTributo === allValues.tipoTrib) || null
    const montoBase = allValues.montoBase ?? null
    const montoTrib = allValues.montoTrib ?? null
    const cargo = allValues.cargo ?? null
    const descuento = allValues.descuento ?? null
    const total = allValues.total ?? null
    const montoRen = allValues.montoRen ?? null
    const descrip = allValues.descrip ?? null

    setFmFechaDoc(fechaDoc)
    setFmMoneda(moneda)
    setFmTipoCambio(tipoCambio)
    setFmTipoTrib(tipoTrib)
    setFmMontoBase(montoBase)
    setFmMontoTrib(montoTrib)
    setFmCargo(cargo)
    setFmDescuento(descuento)
    setFmTotal(total)
    setFmDescrip(descrip)
    setFmMontoRen(montoRen)

    if (changedValues.hasOwnProperty('fechaDoc') || changedValues.hasOwnProperty('total'))
      setFmValidatedCPE(false)

    if (changedValues.hasOwnProperty('tipoTrib')) {
      const mbase = tipoTrib?.montoBase ?? 0.0
      const mtrib = tipoTrib?.montoTributo ?? 0.0
      setFmMontoBase(mbase)
      setFmMontoTrib(mtrib)
      formDta.setFieldsValue({
        montoBase: mbase,
        montoTrib: mtrib,
      })
    }

    if (changedValues.hasOwnProperty('montoBase') || changedValues.hasOwnProperty('montoTrib')) {
      if (tipoTrib) {
        let tbase = 0.0
        let ttrib = 0
        tiposTrib.filter(p => p.nombreTributo !== tipoTrib.nombreTributo).forEach(trib => {
          tbase += (trib.montoBase)
          ttrib += formatRound(trib.montoTributo * (trib.negativo ? -1.0 : 1.0), 2)
        })
        const mbase = montoBase
        const mtrib = formatRound(montoTrib * (tipoTrib.negativo ? -1.0 : 1.0), 2)
        const mtot = formatRound(tbase + ttrib + cargo - descuento + mbase + mtrib, 2)
        const monRen = disMontoRen(selRendicion, moneda) ? mtot : getMontoRen(moneda, tipoCambio, mtot, selRendicion, fmTCambioRen)
        setFmTotal(mtot)
        setFmMontoRen(monRen)
        formDta.setFieldsValue({
          totalBase: tbase + mbase,
          totalTrib: ttrib + mtrib,
          total: mtot,
          montoRen: monRen,
        })
        setFmValidatedCPE(false)
        setTiposTrib(prev => {
          const otrib = prev.filter(p => p.nombreTributo !== tipoTrib.nombreTributo)
          const ntrib = { ...tipoTrib, montoBase: montoBase, montoTributo: montoTrib }
          const atrib = [...otrib, ntrib]
          atrib.sort(sortData)
          return atrib
        })
      }
    }

    if (changedValues.hasOwnProperty('cargo') || changedValues.hasOwnProperty('descuento')) {
      let tbase = 0.0
      let ttrib = 0
      tiposTrib.forEach(trib => {
        tbase += (trib.montoBase)
        ttrib += formatRound(trib.montoTributo * (trib.negativo ? -1.0 : 1.0), 2)
      })
      const mtot = formatRound(tbase + ttrib + cargo - descuento, 2)
      const monRen = disMontoRen(selRendicion, moneda) ? mtot : getMontoRen(moneda, tipoCambio, mtot, selRendicion, fmTCambioRen)
      setFmTotal(mtot)
      setFmMontoRen(monRen)
      formDta.setFieldsValue({
        total: mtot,
        montoRen: monRen,
      })
      setFmValidatedCPE(false)
    }

    if (changedValues.hasOwnProperty('montoBase') && asignaciones && asignaciones.length > 0) {
      let tbase = 0.0
      if (tipoTrib)
        tiposTrib.filter(p => p.nombreTributo !== tipoTrib.nombreTributo).forEach(trib => {
          tbase += (trib.montoBase)
        })
      const pasg = asignaciones[0]
      const sasg = { ...pasg, monto: tbase + montoBase, porcentaje: 100.0 }
      const aasg = [sasg]
      setAsignaciones(aasg)
      setSelAsignacion(sasg)
      formAsg.setFieldsValue({
        monto: sasg.monto,
        porcentaje: sasg.porcentaje,
      })
      setCcosto(getCCosArea(area, sasg))
    }
  }

  const onValuesChangeCtb = (changedValues, allValues) => {
    const tipoSTrib = tiposSTrib.find(p => p.codigo === allValues.tipoSTrib) || null
    const montoSTrib = allValues.montoSTrib ?? null
    const catSTrib = allValues.catSTrib ?? null
    const numConst = allValues.numConst ?? null
    const fechaPago = allValues.fechaPago ?? null

    setFmTipoSTrib(tipoSTrib)
    setFmMontoSTrib(montoSTrib)
    setFmCatSTrib(catSTrib)
    setFmNumConst(numConst)
    setFmFechaPago(fechaPago)

    if (changedValues.hasOwnProperty('tipoSTrib')) {
      if (!tipoSTrib) {
        setFmMontoSTrib(dvMonto)
        setFmCatSTrib(null)
        setFmNumConst(null)
        setFmFechaPago(dvFecha)
        formCtb.setFieldsValue({
          montoSTrib: dvMonto,
          catSTrib: null,
          numConst: null,
          fechaPago: dvFecha,
        })
      }
      else if (!tipoSTrib.bool1) {
        setFmNumConst(null)
        setFmFechaPago(dvFecha)
        formCtb.setFieldsValue({
          numConst: null,
          fechaPago: dvFecha,
        })
      }
    }

    if (changedValues.hasOwnProperty('catSTrib')) {
      const nCatSTrib = catSTrib?.trim()?.toUpperCase()
      setFmCatSTrib(nCatSTrib)
      formCtb.setFieldsValue({ catSTrib: nCatSTrib })
    }

    if (changedValues.hasOwnProperty('numConst')) {
      const nNumConst = numConst?.trim()?.toUpperCase()
      setFmNumConst(nNumConst)
      formCtb.setFieldsValue({ numConst: nNumConst })
    }
  }

  const onValuesChangeAsg = (changedValues, allValues) => {
    const monto = allValues.monto ?? null
    const porcentaje = allValues.porcentaje ?? null

    if (selAsignacion) {
      if (changedValues.hasOwnProperty('monto')) {
        const tbase = getBase()
        const oporc = monto / (tbase ?? dvMonto) * 100.0
        const nporc = formatRound(oporc, 2)
        const aasg = asignaciones.filter(p => p.line !== selAsignacion.line)
        const sasg = { ...selAsignacion, monto, porcentaje: nporc }
        const nasg = [...aasg, sasg]
        nasg.sort(sortData)
        setAsignaciones(nasg)
        setSelAsignacion(sasg)
        formAsg.setFieldsValue({ porcentaje: nporc })
      }
      if (changedValues.hasOwnProperty('porcentaje')) {
        const tbase = getBase()
        const omon = (tbase ?? dvMonto) * porcentaje / 100.0
        const nmon = formatRound(omon, 2)
        const aasg = asignaciones.filter(p => p.line !== selAsignacion.line)
        const sasg = { ...selAsignacion, porcentaje, monto: nmon }
        const nasg = [...aasg, sasg]
        nasg.sort(sortData)
        setAsignaciones(nasg)
        setSelAsignacion(sasg)
        formAsg.setFieldsValue({ monto: nmon })
      }
    }
  }

  const sortData = (a, b) => {
    if (a.key > b.key)
      return 1
    else
      return -1
  }

  const clearFileCPE = () => {
    setFmUploadedXML(false)
    setDocumentXML(null)
    setDocumentJSON(null)
    const input = document.getElementById("file-upload-cpe")
    if (input)
      input.value = null
  }

  const clearFile = () => {
    const input = document.getElementById("file-upload-doc")
    if (input)
      input.value = null
  }

  const updAsignacion = (asg, ccos) => {
    if (asg) {
      const acos = ccos.filter(p => p.code).map(p => ({ dimID: p.dimID, codeID: p.code.codeID })) || []
      const sasg = { ...asg, ccosto: acos }
      setAsignaciones(prev => {
        const oasg = prev.filter(p => p.line !== sasg.line)
        const aasg = [...oasg, sasg]
        aasg.sort(sortData)
        return aasg
      })
      setSelAsignacion(sasg)
    }
  }

  const valTCambio = (tolerancia, tipoCambio, monto_D, monto_d) => {
    if (tipoCambio) {
      const tcab = monto_D / monto_d
      const ntcab = formatRound(tcab, 3)
      return Math.abs(ntcab - tipoCambio) > Math.abs(tolerancia) ? {
        message: `${msgType.rgDataTCambio} ${formatDigits(ntcab)} <> ${formatDigits(tipoCambio)}`,
        oops: false
      } : null
    }
    else
      return {
        message: `${msgType.noDataTCambioC}`,
        oops: false
      }
  }

  const valAdjunto = (doc, uploadedXML) => {
    if (!doc || !uploadedXML)
      return true
    const { attach: files, totalRequired: totReq } = doc
    if (!files || totReq === null || totReq === undefined)
      return false

    let treq = 0
    files.forEach(file => {
      treq += (file.required ? 1 : 0);
    })
    return treq >= totReq
  }

  const valAsignacion = (aasig, tmon) => {
    if (aasig === null || tmon === null || tmon === undefined)
      return false

    let tot = 0
    aasig.forEach(asg => {
      tot += asg.monto;
    })
    return tot < tmon
  }

  const disMontoRen = (rendicion, moneda) => !rendicion || !moneda || rendicion.monedaISO === moneda.codigo
  const enaMontoRen = (rendicion, moneda) => rendicion && moneda && rendicion.monedaISO !== moneda.codigo && rendicion.monedaISO !== configType.monedaISOPEN

  const getMontoRen = (moneda, tipoCambio, total, rendicion, tCambioRen) => {
    let monRen = null
    if (moneda && total && rendicion && moneda.codigo !== rendicion.monedaISO) {
      if (rendicion.monedaISO === configType.monedaISOPEN) {
        if (tipoCambio)
          monRen = total * tipoCambio
      }
      else if (tCambioRen) {
        if (moneda.codigo === configType.monedaISOPEN)
          monRen = total / tCambioRen
        else
          monRen = total * tCambioRen
      }
    }
    if (monRen)
      return formatRound(monRen, 2)
    else
      return total
  }

  const getNameTipoDocIden = (tdo) => {
    if (!tdo || !tdo.tipoDocIdentidadID)
      return 'RUC'
    else if (tdo.tipoDocIdentidadID === configType.tipoDocIdeRUC)
      return 'RUC'
    else if (tdo.tipoDocIdentidadID === configType.tipoDocIdeDNI)
      return 'DNI'
    else
      return ''
  }

  const getBase = () => {
    let tbase = 0.0
    tiposTrib.forEach(trib => {
      tbase += (trib.montoBase)
    })
    tbase = formatRound(tbase, 2)
    return tbase
  }

  const getTrib = (tdoc, doc) => {
    const otrib = tdoc?.taxes?.map((p, index) => {
      const trib = doc?.tributos?.find(q => q.nombreTributo === p.nombreTributo)
      const tax = { ...p, key: index, montoTributo: (trib?.montoTributo ?? 0.0), montoBase: (trib?.montoBase ?? 0.0), isDoc: !!trib }
      return tax
    }) ?? []
    const atrib = doc?.uploadedXML ? otrib.filter(p => p.isDoc) : otrib
    const strib = atrib.find(() => true)
    return [atrib, strib]
  }

  const getAsig = (asg, index) => ({
    ...asg,
    key: index,
    label: `Asignación ${asg.line + 1}`,
  })

  const getCcosto = (ccos, index, asg) => {
    const rcc = asg?.ccosto?.find(p => p.dimID === ccos.dimID) || null
    const code = rcc?.codeID ? ccos.codes.find(p => p.codeID === rcc.codeID) : null
    const aops = code?.codeID ? searchResultCcos(code.codeID, ccos.codes) : []
    const ncc = { ...ccos, key: index, code, opciones: aops, dvSearch: code?.codeID }
    return ncc
  }

  const getCCosArea = (are, asg) => {
    const acos = are && are.ccosto ? are.ccosto.map((p, index) => getCcosto(p, index, asg)) : []
    return acos
  }

  const compareText = (field, value) => {
    if (value === undefined)
      return false
    else {
      const nvalue = value.toUpperCase()
      const nfield = field.toUpperCase()
      return nfield.indexOf(nvalue) !== -1
    }
  }

  const searchResultEgas = (value, agas) => {
    const ngas = agas.filter(p => value === configType.searchAllCode || compareText(p.code, value) || compareText(p.name, value))
    const aops = ngas.map(p => ({
      value: p.code,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{`${p.name}`}</span>
          <span>{`${p.code}`}</span>
        </div>
      ),
    }))
    return aops
  }

  const searchResultCcos = (value, acod) => {
    const ncod = acod.filter(p => value === configType.searchAllCode || compareText(p.codeID, value) || compareText(p.codeName, value))
    const aops = ncod.map(p => ({
      value: p.codeID,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{`${p.codeName}`}</span>
          <span>{`${p.codeID}`}</span>
        </div>
      ),
    }))
    return aops
  }

  const handleSearchEgas = (value, agas) => setOpcionesEgas(value ? searchResultEgas(value, agas) : [])
  const handleChangeEgas = (value) => { setFmEGasto(null); setDvSearchEgas(value) }
  const handleSelectEgas = (value, agas) => { setFmEGasto(egasto.find(p => p.code === value) || null); setOpcionesEgas(value ? searchResultEgas(value, agas) : []) }

  const handleSearchCcos = (value, ccos, prev, asg) => {
    const aops = value ? searchResultCcos(value, ccos.codes) : []
    const acos = prev.filter(p => p.dimID !== ccos.dimID)
    const ncos = [...acos, { ...ccos, opciones: aops, code: null, dvSearch: value }]
    ncos.sort(sortData)
    setCcosto(ncos)
    updAsignacion(asg, ncos)
  }

  const handleSelectCcos = (value, ccos, prev, asg) => {
    const code = ccos.codes.find(p => p.codeID === value) || null
    const aops = value ? searchResultCcos(value, ccos.codes) : []
    const acos = prev.filter(p => p.dimID !== ccos.dimID)
    const ncos = [...acos, { ...ccos, opciones: aops, code, dvSearch: value }]
    ncos.sort(sortData)
    setCcosto(ncos)
    updAsignacion(asg, ncos)
  }

  const handleChangeDocumento = (value, adoc) => {
    const sdoc = adoc.find(p => p.documentoID === value)
    setOpenModal(false)
    setSelDocumento(sdoc)
  }

  const handleChangeTipoAdj = (value, atad) => {
    const stad = atad.find(p => p.tipo === value)
    setSelTipoAdj(stad)
  }

  const handleChangeTipoDoc = (value) => {
    const stdo = tiposDoc.find(p => p.tipoDocumentoID === value)
    const [atrib, strib] = getTrib(stdo, dbDocumento)
    const mbase = strib?.montoBase ?? 0.0
    const mtrib = strib?.montoTributo ?? 0.0

    let tbase = 0.0
    let ttrib = 0.0
    atrib.forEach(trib => {
      tbase += (trib.montoBase)
      ttrib += formatRound(trib.montoTributo * (trib.negativo ? -1.0 : 1.0), 2)
    })
    const mtot = tbase + ttrib + fmCargo - fmDescuento
    const monRen = disMontoRen(selRendicion, fmMoneda) ? mtot : getMontoRen(fmMoneda, fmTipoCambio, mtot, selRendicion, fmTCambioRen)

    const isNumeric = str => /^[0-9]*$/gi.test(str)
    if (stdo && stdo.numerico)
      !isNumeric(fmNumero) && setFmNumero(null)

    setFmTipoDoc(stdo)
    setFmEmsrNumDoc(null)
    setFmEmisor(null)
    setFmValidatedCPE(false)
    setTiposTrib(atrib)
    setFmTipoTrib(strib)
    setFmMontoBase(mbase)
    setFmMontoTrib(mtrib)
    setFmTotal(mtot)
    setFmMontoRen(monRen)
    formDta.setFieldsValue({
      tipoTrib: strib?.nombreTributo,
      montoBase: mbase,
      montoTrib: mtrib,
      totalBase: tbase,
      totalTrib: ttrib,
      total: mtot,
      montoRen: monRen
    })

    setFmTipoSTrib(null)
    setFmMontoSTrib(dvMonto)
    setFmCatSTrib(null)
    setFmNumConst(null)
    setFmFechaPago(dvFecha)
    formCtb.resetFields()

    clearFileCPE()
  }

  const handleChangeAsignacion = (value) => {
    const sasg = asignaciones.find(p => p.line === value)
    setSelAsignacion(sasg)
    if (sasg)
      formAsg.setFieldsValue({
        monto: sasg.monto,
        porcentaje: sasg.porcentaje,
      })
    else
      formAsg.setFieldsValue({
        monto: dvMonto,
        porcentaje: dvMonto,
      })
    setCcosto(getCCosArea(area, sasg))
  }

  const handleAgregarAsig = () => {
    const tbase = getBase()
    if (!asignaciones || !tbase)
      return
    let tmon = 0
    let tporc = 0
    asignaciones.forEach(asg => {
      tmon += asg.monto;
      tporc += asg.porcentaje;
    })
    const omon = tbase - tmon
    const monto = formatRound(omon, 2)
    const oporc = 100.0 - tporc
    const porcentaje = formatRound(oporc, 2)
    const oasg = { line: asignaciones.length, monto, porcentaje, ccosto: [] }
    const sasg = getAsig(oasg, oasg.line)
    const aasg = [...asignaciones, sasg]

    setAsignaciones(aasg)
    setSelAsignacion(sasg)
    formAsg.setFieldsValue({
      monto: sasg.monto,
      porcentaje: sasg.porcentaje,
    })
    setCcosto(getCCosArea(area, sasg))
  }

  const handleRemoverAsig = () => {
    if (!selAsignacion || !asignaciones)
      return
    const oasg = asignaciones.filter(p => p.line !== selAsignacion.line)
    const aasg = oasg.map((p, index) => getAsig({ ...p, line: index }, index))
    const sasg = aasg.length > 0 ? aasg[aasg.length - 1] : null
    setAsignaciones(aasg)
    setSelAsignacion(sasg)
    formAsg.setFieldsValue({
      monto: sasg?.monto,
      porcentaje: sasg?.porcentaje,
    })
    setCcosto(getCCosArea(area, sasg))
  }

  const handleChangeTCambio = async (fechaDoc, monedaISO) => {
    const moneda = monedas.find(p => p.codigo === monedaISO)
    let tipoCambio = null
    let tCambioRen = null

    if (fechaDoc && moneda && moneda.codigo !== configType.monedaISOPEN) {
      setLoadTipoCambio(true)
      tipoCambio = await refreshTCambio(fechaDoc, moneda.codigo)
      setLoadTipoCambio(false)
    }

    if (fechaDoc && moneda && selRendicion && selRendicion.monedaISO !== moneda.codigo && selRendicion.monedaISO !== configType.monedaISOPEN) {
      setLoadTipoCambio(true)
      tCambioRen = await refreshTCambio(fechaDoc, selRendicion.monedaISO)
      setLoadTipoCambio(false)
    }

    const oCambioRen = tipoCambio && tCambioRen ? tipoCambio / tCambioRen : tCambioRen
    const nCambioRen = formatRound(oCambioRen, 3)
    const montoRen = disMontoRen(selRendicion, moneda) ? fmTotal : getMontoRen(moneda, tipoCambio, fmTotal, selRendicion, nCambioRen)

    setFmTipoCambio(tipoCambio)
    setFmTCambioRen(nCambioRen)
    setFmMontoRen(montoRen)
    formDta.setFieldsValue({
      tipoCambio: tipoCambio,
      montoRen: montoRen,
    })
  }

  const handleDataComprobante = async ({ target: { files } }) => {
    let err = null
    let data = null

    if (!files || files.length === 0) {
      clearFileCPE()
      return
    }

    const file = files[0]

    if (file.size > configType.attachMaxSize)
      err = {
        message: msgType.fileKoPeso,
        oops: false
      }
    else if (file.type !== configType.fileTypeXML)
      err = {
        message: msgType.fileKoXML,
        oops: false
      }
    else if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (!fmTipoDoc)
      err = {
        message: msgType.selectTipoDoc,
        oops: false
      }
    else if (!fmTipoDoc.isCPE)
      err = {
        message: msgType.fileNoCPE,
        oops: false
      }

    if (err) {
      showNotify(err, notifyType.warning)
      clearFileCPE()
      return
    }

    let checkData = true

    setLoadDataDocumento(true)

    const formData = new FormData()
    formData.append("", file)

    const url = billDataUrl(fmTipoDoc.tipoComprobanteID)
    const [data_api, err_api] = await axiosDnePut(url, formData)
    data = data_api
    err = err_api

    if (data) {
      if (data.tipoComprobanteID !== fmTipoDoc.tipoComprobanteID)
        err = {
          message: data.tipoComprobanteID ? `${msgType.fileKoTipoCpe}: ${data.tipoComprobanteID} - ${tiposDoc.find(p => p.tipoComprobanteID === data.tipoComprobanteID)?.nombre}` : `${msgType.fileKoTipoCpe}.`,
          oops: false
        }
      else if (data.adquirente?.documento !== selReceptor.ruc)
        err = {
          message: data.adquirente?.documento ? `${msgType.fileKoReceptor}: ${data.adquirente?.documento} - ${data.adquirente?.denominacion}` : `${msgType.fileKoReceptor}.`,
          oops: false
        }

      if (err)
        checkData = false
      else
        try {
          const base64 = await toBase64(file)
          const json64 = jsonBase64(data)

          const moneda = monedas.find(p => p.codigo === data.monedaID)
          const fechaDoc = data.fechaEmision ? moment(data.fechaEmision) : null
          const tipoCambio = fechaDoc && moneda && moneda.codigo !== configType.monedaISOPEN ? await refreshTCambio(moment(fechaDoc), moneda.codigo) : null
          const tCambioRen = fechaDoc && moneda && selRendicion.monedaISO !== moneda.codigo && selRendicion.monedaISO !== configType.monedaISOPEN ? await refreshTCambio(fechaDoc, selRendicion.monedaISO) : null
          const [atrib, strib] = getTrib(fmTipoDoc, { tributos: data.montoTributo?.aTributo, uploadedXML: true })
          const mbase = strib?.montoBase ?? 0.0
          const mtrib = strib?.montoTributo ?? 0.0
          const cargo = data.sumatoriaOtroCargo ?? 0.0
          const descuento = data.sumatoriaOtroDscto ?? 0.0
          const oCambioRen = tipoCambio && tCambioRen ? tipoCambio / tCambioRen : tCambioRen
          const nCambioRen = formatRound(oCambioRen, 3)
          const montoRen = disMontoRen(selRendicion, moneda) ? data.importeTotal : getMontoRen(moneda, tipoCambio, data.importeTotal, selRendicion, nCambioRen)
          const emisor = data.emisor ? { code: data.emisor.documento, name: data.emisor.denominacion, stdoContrb: null, condDomic: null, validated: false } : null
          const descrip = data.aItem?.find(() => true)?.descripcion ?? null

          let tbase = 0.0
          let ttrib = 0.0
          atrib.forEach(trib => {
            tbase += (trib.montoBase)
            ttrib += formatRound(trib.montoTributo * (trib.negativo ? -1.0 : 1.0), 2)
          })
          tbase = formatRound(tbase, 2)

          let tipoSTrib = null
          let montoSTrib = dvMonto
          let catSTrib = null
          const numConst = null
          const fechaPago = moment(dvFecha)
          if (data.detraccion) {
            tipoSTrib = tiposSTrib.find(p => p.codigo === configType.tributoDET.codigo)
            if (tipoSTrib) {
              montoSTrib = data.detraccion.montoTributo
              catSTrib = data.detraccion.bienServID
            }
          }
          else if (data.retencion) {
            tipoSTrib = tiposSTrib.find(p => p.codigo === configType.tributoRET.codigo)
            if (tipoSTrib) {
              montoSTrib = data.retencion.montoTributo
              catSTrib = data.retencion.regimenID
            }
          }

          setFmSerie(data.serie)
          setFmNumero(data.numero)
          setFmEmsrNumDoc(emisor?.code)
          setFmEmisor(emisor)
          setFmFechaDoc(fechaDoc)
          setFmMoneda(moneda)
          setFmTipoCambio(tipoCambio)
          setTiposTrib(atrib)
          setFmTipoTrib(strib)
          setFmMontoBase(mbase)
          setFmMontoTrib(mtrib)
          setFmCargo(cargo)
          setFmDescuento(descuento)
          setFmTotal(data.importeTotal)
          setFmMontoRen(montoRen)
          setFmDescrip(descrip)
          setFmUploadedXML(true)
          formDta.setFieldsValue({
            fechaDoc: fechaDoc,
            monedaISO: moneda?.codigo,
            tipoCambio: tipoCambio,
            tipoTrib: strib?.nombreTributo,
            montoBase: mbase,
            montoTrib: mtrib,
            totalBase: tbase,
            totalTrib: ttrib,
            cargo: cargo,
            descuento: descuento,
            total: data.importeTotal,
            montoRen: montoRen,
            descrip: descrip,
          })
          setFmTipoSTrib(tipoSTrib)
          setFmMontoSTrib(montoSTrib)
          setFmCatSTrib(catSTrib)
          setFmNumConst(numConst)
          setFmFechaPago(fechaPago)
          formCtb.setFieldsValue({
            tipoSTrib: tipoSTrib?.codigo,
            montoSTrib: montoSTrib,
            catSTrib: catSTrib,
            numConst: numConst,
            fechaPago: fechaPago,
          })

          if (asignaciones && asignaciones.length > 0) {
            const pasg = asignaciones[0]
            const sasg = { ...pasg, monto: tbase, porcentaje: 100.0 }
            const aasg = [sasg]
            setAsignaciones(aasg)
            setSelAsignacion(sasg)
            formAsg.setFieldsValue({
              monto: sasg.monto,
              porcentaje: sasg.porcentaje,
            })
            setCcosto(getCCosArea(area, sasg))
          }

          setDocumentXML(base64)
          setDocumentJSON(json64)
        }
        catch (error) {
          err = {
            message: msgType.apiError,
            detail: error.message,
            oops: true
          }
        }
    }
    if (err) {
      showNotify(err, checkData ? notifyType.error : notifyType.warning)
      clearFileCPE()
    }

    setLoadDataDocumento(false)
    setLastAccess()
  }

  const handleValComprobante = async () => {
    let err = null
    let data = null

    if (!fmEmisor)
      err = {
        message: `${msgType.registroNoField} emisor.`,
        oops: false
      }
    else if (!fmTipoDoc || !fmTipoDoc.tipoComprobanteID)
      err = {
        message: `${msgType.registroNoField} tipo de documento.`,
        oops: false
      }
    else if (!fmTipoDoc.numerico)
      err = {
        message: `${msgType.nnDataTipoDoc}`,
        oops: false
      }
    else if (!fmSerie)
      err = {
        message: `${msgType.registroNoField} serie.`,
        oops: false
      }
    else if (!fmNumero)
      err = {
        message: `${msgType.registroNoField} correlativo.`,
        oops: false
      }
    else if (!fmFechaDoc)
      err = {
        message: `${msgType.registroNoField} fecha de documento.`,
        oops: false
      }
    else if (!fmTotal)
      err = {
        message: `${msgType.registroNoField} importe total.`,
        oops: false
      }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    setLoadValComprobante(true)

    const tbase = getBase()
    const tipoCpe = fmTipoDoc.tipoComprobanteID !== configType.tipoCpeRH ? fmTipoDoc.tipoComprobanteID : configType.tipoCpeRH_Cto
    const monto = fmTipoDoc.tipoComprobanteID !== configType.tipoCpeRH ? fmTotal : tbase
    const fechaDoc = fmFechaDoc.format().split('T')[0] + 'T00:00:00'

    const url = cpeValidateUrl()
    const [data_api, err_api] = await axiosCtoPost(url, {
      suscriptorID: configType.ctoSuscriptor, emisorID: fmEmisor.code, tipoComprobanteID: tipoCpe, serie: fmSerie, numero: fmNumero, fechaEmision: fechaDoc, importeTotal: monto
    })
    data = data_api
    err = err_api

    if (data) {
      const { eCpeID } = data
      if (eCpeID === configType.cpeAceptado)
        setFmValidatedCPE(true)
      else if (eCpeID === configType.cpeNoExiste)
        showNotify({
          message: msgType.apiCtoCpeNoExiste,
          oops: false
        }, notifyType.warning)
      else if (eCpeID === configType.cpeAnulado)
        showNotify({
          message: msgType.apiCtoCpeAnulado,
          oops: false
        }, notifyType.warning)
      else if (eCpeID === null)
        showNotify({
          message: msgType.apiCtoSinRespuesta,
          oops: false
        }, notifyType.warning)
      else
        showNotify({
          message: msgType.apiCtoOtraRespuesta,
          oops: false
        }, notifyType.warning)
    }
    if (err)
      showNotify(err, notifyType.error)

    setLoadValComprobante(false)
    setLastAccess()
  }

  const handleValEmisor = async (emsr, xml) => {
    let err = null
    let data = null

    if (!fmTipoDoc)
      err = {
        message: msgType.selectTipoDoc,
        oops: false
      }
    else if (fmTipoDoc.tipoDocIdentidadID !== configType.tipoDocIdeRUC && fmTipoDoc.tipoDocIdentidadID !== configType.tipoDocIdeDNI)
      err = {
        message: msgType.koDataTipoDoc,
        oops: false
      }
    else if (!fmEmsrNumDoc)
      err = {
        message: msgType.inputEmsrDoc,
        oops: false
      }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    setLoadValEmisor(true)

    const webStorage = true ? storagePageDNIUrl(fmEmsrNumDoc) : webPageDNIUrl(fmEmsrNumDoc)
    const isRUC = fmTipoDoc.tipoDocIdentidadID === configType.tipoDocIdeRUC
    const url = isRUC ? storageRUCUrl(fmEmsrNumDoc) : webStorage
    const [data_api, err_api] = await axiosCtoGet(url)
    data = data_api
    err = err_api

    if (data) {
      const nemsr1 = { code: (isRUC ? data.ruc : data.dni), name: (isRUC ? data.nomRazSoc : data.apellNomb) }
      const nemsr2 = { stdoContrb: (isRUC ? data.stdoContrb : ''), condDomic: (isRUC ? data.condDomic : ''), validated: true }
      const nemsr = !emsr || (!emsr.validated && !xml) ? { ...nemsr1, ...nemsr2 } : { ...emsr, ...nemsr2 }
      setFmEmisor(nemsr)
    }
    if (err)
      showNotify(err, notifyType.error)

    setLoadValEmisor(false)
    setLastAccess()
  }

  const handleRegDoc = async () => {
    let err = null
    let data = null

    if (componentDisabled)
      return;

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (!fmEmisor)
      err = {
        message: `${msgType.registroNoField} emisor.`,
        oops: false
      }
    else if (!fmTipoDoc || !fmTipoDoc.tipoComprobanteID)
      err = {
        message: `${msgType.registroNoField} tipo de documento.`,
        oops: false
      }
    else if (!fmSerie)
      err = {
        message: `${msgType.registroNoField} serie.`,
        oops: false
      }
    else if (!fmNumero)
      err = {
        message: `${msgType.registroNoField} correlativo.`,
        oops: false
      }
    else if (!fmFechaDoc)
      err = {
        message: `${msgType.registroNoField} fecha de documento.`,
        oops: false
      }
    else if (!fmMoneda)
      err = {
        message: `${msgType.registroNoField} moneda.`,
        oops: false
      }
    else if (fmMoneda.codigo !== configType.monedaISOPEN && !fmTipoCambio)
      err = {
        message: `${msgType.registroNoField} tipo de cambio.`,
        oops: false
      }
    else if (selRendicion.monedaISO !== fmMoneda.codigo && selRendicion.monedaISO !== configType.monedaISOPEN && !fmTCambioRen)
      err = {
        message: `${msgType.registroNoField} tipo de cambio rendición.`,
        oops: false
      }
    else if (!tiposTrib || tiposTrib.length === 0)
      err = {
        message: `${msgType.registroNoField} tributos.`,
        oops: false
      }
    else if (!fmTipoTrib)
      err = {
        message: `${msgType.registroNoField} tipo de tributo.`,
        oops: false
      }
    else if (!fmMontoBase)
      err = {
        message: `${msgType.registroNoField} monto base.`,
        oops: false
      }
    else if (!fmTotal)
      err = {
        message: `${msgType.registroNoField} importe total.`,
        oops: false
      }
    else if (!fmMontoRen)
      err = {
        message: `${msgType.registroNoField} monto rendición.`,
        oops: false
      }
    else if (fmTipoSTrib && !fmMontoSTrib)
      err = {
        message: `${msgType.registroNoField} monto otro tributo.`,
        oops: false
      }
    else if (fmTipoSTrib && !fmCatSTrib)
      err = {
        message: `${msgType.registroNoField} categoría otro tributo.`,
        oops: false
      }
    else if (fmTipoSTrib && fmTipoSTrib.bool1 && !fmNumConst)
      err = {
        message: `${msgType.registroNoField} número de constancia.`,
        oops: false
      }
    else if (fmTipoSTrib && fmTipoSTrib.bool1 && !fmFechaPago)
      err = {
        message: `${msgType.registroNoField} fecha de pago.`,
        oops: false
      }
    else if (!fmEGasto)
      err = {
        message: `${msgType.registroNoField} elemto de gasto.`,
        oops: false
      }
    else if (!asignaciones)
      err = {
        message: `${msgType.registroNoField} asignación de centro de costo.`,
        oops: false
      }
    else if (moment().toDate().getTime() < fmFechaDoc.toDate().getTime())
      err = {
        message: `${msgType.koDataFechaDoc}`,
        oops: false
      }
    else if (fmTipoSTrib && fmTipoSTrib.bool1 && moment().toDate().getTime() < fmFechaPago.toDate().getTime())
      err = {
        message: `${msgType.koDataFechaPago}`,
        oops: false
      }
    else if (!selRendicion.allowPut)
      err = {
        message: msgType.rendicionNoRegDoc,
        oops: false
      }
    else if (area && area.ccosto) {
      for (let index = 0; index < asignaciones.length; index++) {
        const nasg = asignaciones[index]
        const ccos = area.ccosto.find(p => p.required && !nasg.ccosto.find(q => q.dimID === p.dimID && q.codeID))
        if (ccos) {
          err = {
            message: `Asignación ${nasg.line + 1}: ${ccos.dimName} requerido(a).`,
            oops: false
          }
          break
        }
      }
    }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    if (tolTCambio !== null && tolTCambio !== undefined && fmMoneda.codigo !== selRendicion.monedaISO) {
      if (selRendicion.monedaISO === configType.monedaISOPEN)
        err = valTCambio(tolTCambio, fmTipoCambio, fmMontoRen, fmTotal)
      else {
        if (fmMoneda.codigo === configType.monedaISOPEN)
          err = valTCambio(tolTCambio, fmTCambioRen, fmTotal, fmMontoRen)
        else
          err = valTCambio(tolTCambio, fmTCambioRen, fmMontoRen, fmTotal)
      }
    }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    setLoadRegDoc(true)

    const tbase = getBase()
    const numero = `${fmNumero}`
    const fechaDoc = fmFechaDoc.format().split('T')[0] + 'T00:00:00'
    const tributos = [...tiposTrib.filter(p => (p.montoBase !== 0.0 || p.montoTributo !== 0.0) && p.nombreTributo !== fmTipoTrib.nombreTributo), { ...fmTipoTrib, montoBase: fmMontoBase, montoTributo: fmMontoTrib }]
    const fechaPago = fmFechaPago ? (fmFechaPago.format().split('T')[0] + 'T00:00:00') : null
    const stributo = fmTipoSTrib != null ? { nombreTributo: fmTipoSTrib.codigo, montoTributo: fmMontoSTrib, categoriaID: fmCatSTrib, autodetraccion: (fmTipoSTrib.bool1 ? { numConstancia: fmNumConst.trim(), fechaPago } : null) } : null
    const nasign = asignaciones.map(p => ({ ...p, porcentaje: p.porcentaje / 100.0 }))
    const descrip = fmDescrip && fmDescrip.trim().length > 0 ? fmDescrip.trim() : null
    const isNew = !selDocumento

    const url = isNew ? docRegistrarUrl() : docModificarUrl(selDocumento.documentoID)
    const body = isNew ?
      {
        rcptNumDoc: selReceptor.ruc, numRD: selRendicion.numRD, tipoDocumentoID: fmTipoDoc.tipoDocumentoID,
        emsrNumDoc: fmEmisor.code, emsrNomRazSoc: fmEmisor.name, stdoContrb: fmEmisor.stdoContrb,
        condDomic: fmEmisor.condDomic, validatedEmsr: fmEmisor.validated, tipoComprobanteID: fmTipoDoc.tipoComprobanteID,
        serie: fmSerie, numero, fechaDoc, monedaISO: fmMoneda.codigo, tipoCambio: fmTipoCambio, tCambioRen: fmTCambioRen,
        base: tbase, cargo: fmCargo, descuento: fmDescuento, total: fmTotal, montoRen: fmMontoRen, egasto: fmEGasto.code, descrip,
        tributos, stributo, asignaciones: nasign, uploadedXML: fmUploadedXML, validatedCPE: fmValidatedCPE
      } :
      {
        tipoDocumentoID: fmTipoDoc.tipoDocumentoID,
        emsrNumDoc: fmEmisor.code, emsrNomRazSoc: fmEmisor.name, stdoContrb: fmEmisor.stdoContrb,
        condDomic: fmEmisor.condDomic, validatedEmsr: fmEmisor.validated, tipoComprobanteID: fmTipoDoc.tipoComprobanteID,
        serie: fmSerie, numero, fechaDoc, monedaISO: fmMoneda.codigo, tipoCambio: fmTipoCambio, tCambioRen: fmTCambioRen,
        base: tbase, cargo: fmCargo, descuento: fmDescuento, total: fmTotal, montoRen: fmMontoRen, egasto: fmEGasto.code, descrip,
        tributos, stributo, asignaciones: nasign, validatedCPE: fmValidatedCPE
      }
    const [data_api, err_api] = isNew ? await axiosTnaPost(url, body) : await axiosTnaPut(url, body)
    data = data_api
    err = err_api

    const getName = (fileExt) => (`${fmEmisor.code}-${fmTipoDoc.tipoComprobanteID}-${fmSerie}-${fmNumero}${fileExt}`)

    if (data) {
      const documentoID = isNew ? data.id : selDocumento.documentoID
      if (fmUploadedXML && isNew) {
        await attachFile(documentoID, configType.attachLnXML, documentXML, configType.fileTypeXML, getName(configType.fileExtXML))
        await attachFile(documentoID, configType.attachLnJSON, documentJSON, configType.contentTypeJSON, getName(configType.fileExtJSON))
      }
      showNotify({
        message: `${msgType.documentoRegistrar} ${data.numDO}`,
        oops: false
      }, notifyType.success)
      const ndoc = isNew ? { ...data, documentoID, key: documentos.length, monto: fmMontoRen } : { ...selDocumento, ...data, monto: fmMontoRen }
      const adoc = isNew ? documentos.map(p => p) : documentos.filter(p => p.documentoID !== documentoID)
      adoc.push(ndoc)
      adoc.sort(sortData)
      setDocumentos(adoc)
      setSelDocumento(ndoc)
      const nren = await refreshRen(selReceptor, selRendicion)
      if (nren && updateRen)
        updateRen(nren, false)
      if (updateDoc)
        updateDoc(adoc)
    }
    if (err)
      showNotify(err, notifyType.error)

    setLoadRegDoc(false)
    setLastAccess()
  }

  const handleModDoc = async () => {
    let err = null
    let data = null

    if (componentDisabled)
      return;

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (!selDocumento)
      err = {
        message: msgType.selectDocumento,
        oops: false
      }
    else if (!fmFechaDoc)
      err = {
        message: `${msgType.registroNoField} fecha de documento.`,
        oops: false
      }
    else if (!fmMoneda)
      err = {
        message: `${msgType.registroNoField} moneda.`,
        oops: false
      }
    else if (fmMoneda.codigo !== configType.monedaISOPEN && !fmTipoCambio)
      err = {
        message: `${msgType.registroNoField} tipo de cambio.`,
        oops: false
      }
    else if (selRendicion.monedaISO !== fmMoneda.codigo && selRendicion.monedaISO !== configType.monedaISOPEN && !fmTCambioRen)
      err = {
        message: `${msgType.registroNoField} tipo de cambio rendición.`,
        oops: false
      }
    else if (!fmMontoRen)
      err = {
        message: `${msgType.registroNoField} monto rendición.`,
        oops: false
      }
    else if (fmTipoSTrib && !fmMontoSTrib)
      err = {
        message: `${msgType.registroNoField} monto otro tributo.`,
        oops: false
      }
    else if (fmTipoSTrib && !fmCatSTrib)
      err = {
        message: `${msgType.registroNoField} categoría otro tributo.`,
        oops: false
      }
    else if (fmTipoSTrib && fmTipoSTrib.bool1 && !fmNumConst)
      err = {
        message: `${msgType.registroNoField} número de constancia.`,
        oops: false
      }
    else if (fmTipoSTrib && fmTipoSTrib.bool1 && !fmFechaPago)
      err = {
        message: `${msgType.registroNoField} fecha de pago.`,
        oops: false
      }
    else if (!fmEGasto)
      err = {
        message: `${msgType.registroNoField} elemto de gasto.`,
        oops: false
      }
    else if (!asignaciones)
      err = {
        message: `${msgType.registroNoField} asignación de centro de costo.`,
        oops: false
      }
    else if (moment().toDate().getTime() < fmFechaDoc.toDate().getTime())
      err = {
        message: `${msgType.koDataFechaDoc}`,
        oops: false
      }
    else if (fmTipoSTrib && fmTipoSTrib.bool1 && moment().toDate().getTime() < fmFechaPago.toDate().getTime())
      err = {
        message: `${msgType.koDataFechaPago}`,
        oops: false
      }
    else if (area && area.ccosto) {
      for (let index = 0; index < asignaciones.length; index++) {
        const nasg = asignaciones[index]
        const ccos = area.ccosto.find(p => p.required && !nasg.ccosto.find(q => q.dimID === p.dimID && q.codeID))
        if (ccos) {
          err = {
            message: `Asignación ${nasg.line + 1}: ${ccos.dimName} requerido(a).`,
            oops: false
          }
          break
        }
      }
    }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    setLoadRegDoc(true)

    const fechaDoc = fmFechaDoc.format().split('T')[0] + 'T00:00:00'
    const fechaPago = fmFechaPago ? (fmFechaPago.format().split('T')[0] + 'T00:00:00') : null
    const stributo = fmTipoSTrib != null ? { nombreTributo: fmTipoSTrib.codigo, montoTributo: fmMontoSTrib, categoriaID: fmCatSTrib, autodetraccion: (fmTipoSTrib.bool1 ? { numConstancia: fmNumConst.trim(), fechaPago } : null) } : null
    const nasign = asignaciones.map(p => ({ ...p, porcentaje: p.porcentaje / 100.0 }))

    const url = docModificarUrl(selDocumento.documentoID, true)
    const body = {
      fechaDoc, tipoCambio: fmTipoCambio, tCambioRen: fmTCambioRen, montoRen: fmMontoRen,
      egasto: fmEGasto.code, stributo, asignaciones: nasign, validatedCPE: fmValidatedCPE
    }
    const [data_api, err_api] = await axiosTnaPut(url, body)
    data = data_api
    err = err_api

    if (data) {
      showNotify({
        message: `${msgType.documentoModificar}`,
        oops: false
      }, notifyType.success)
      const ndoc = { ...selDocumento, ...data, monto: fmMontoRen }
      const adoc = documentos.filter(p => p.documentoID !== selDocumento.documentoID)
      adoc.push(ndoc)
      adoc.sort(sortData)
      setDocumentos(adoc)
      setSelDocumento(ndoc)
      const nren = await refreshRen(selReceptor, selRendicion)
      if (nren && updateRen)
        updateRen(nren, false)
    }
    if (err)
      showNotify(err, notifyType.error)

    setLoadRegDoc(false)
    setLastAccess()
  }

  const handleEliDoc = async () => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (!selDocumento)
      err = {
        message: msgType.selectDocumento,
        oops: false
      }
    else if (!selRendicion.allowPut && !validar)
      err = {
        message: msgType.rendicionNoEliDoc,
        oops: false
      }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    setLoadEliDoc(true)

    const url = validar ? docEliminarUrl(selDocumento.documentoID, true) : docEliminarUrl(selDocumento.documentoID)
    const [data_api, err_api] = await axiosTnaDelete(url)
    data = data_api
    err = err_api

    if (data) {
      showNotify({
        message: `${msgType.documentoEliminar}`,
        oops: false
      }, notifyType.success)
      const adoc = documentos.filter(p => p.documentoID !== selDocumento.documentoID)
      setDocumentos(adoc)
      setSelDocumento(null)
      const nren = await refreshRen(selReceptor, selRendicion)
      if (nren && updateRen)
        updateRen(nren, false)
      if (updateDoc)
        updateDoc(adoc)
    }
    if (err)
      showNotify(err, notifyType.error)

    setLoadEliDoc(false)
    setLastAccess()
  }

  const handleUpFile = async ({ target: { files } }) => {
    let err = null
    let data = null

    if (!files || files.length === 0) {
      clearFile()
      return
    }

    const file = files[0]
    const fileExt = file.name.split('.')

    if (file.size > configType.attachMaxSize)
      err = {
        message: msgType.fileKoPeso,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (!selDocumento)
      err = {
        message: msgType.selectDocumento,
        oops: false
      }
    else if (selTipoAdj && adjuntos.find(p => p.fileType === selTipoAdj.tipo))
      err = {
        message: msgType.rpDataTipoAdj,
        oops: false
      }
    else if (selTipoAdj && (!fileExt || fileExt.length < 2 || `.${fileExt[1].toLowerCase()}` !== selTipoAdj.fileExt.toLowerCase()))
      err = {
        message: `${msgType.fileKoTipo} ${selTipoAdj.fileExt}`,
        oops: false
      }
    else if (!selRendicion.allowPut && !validar)
      err = {
        message: msgType.rendicionNoAdj,
        oops: false
      }

    if (err) {
      showNotify(err, notifyType.warning)
      clearFile()
      return
    }

    setLoadFile(true)

    try {
      let data_put = null

      if (selTipoAdj) {
        const base64 = await toBase64(file)
        data_put = { fileName: file.name, document: base64, contentType: selTipoAdj.contentType }
      }
      else {
        const formData = new FormData()
        formData.append("", file)
        data_put = formData
      }

      const url = validar ? docAdjuntarUrl(selDocumento.documentoID, selTipoAdj?.tipo, null, true) : docAdjuntarUrl(selDocumento.documentoID, selTipoAdj?.tipo)
      const [data_api, err_api] = await axiosTnaPut(url, data_put)
      data = data_api
      err = err_api

      if (data) {
        const nadj = { ...data, dataOrder: data.line, key: data.line }
        const aadj = [...adjuntos, nadj]
        aadj.sort(sortData)
        setAdjuntos(aadj)
      }
    }
    catch (error) {
      err = {
        message: msgType.apiError,
        detail: error.message,
        oops: true
      }
    }
    if (err)
      showNotify(err, notifyType.error)

    clearFile()
    setLoadFile(false)
    setLastAccess()
  }

  const handleDelFile = async (record) => {
    let err = null
    let data = null

    if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (!selDocumento)
      err = {
        message: msgType.selectDocumento,
        oops: false
      }
    else if (!selRendicion.allowPut && !validar)
      err = {
        message: msgType.rendicionNoExc,
        oops: false
      }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    setCurrentLineFI(record.dataOrder)

    const url = validar ? docAdjuntarUrl(selDocumento.documentoID, null, record.dataOrder, true) : docAdjuntarUrl(selDocumento.documentoID, null, record.dataOrder)
    const [data_api, err_api] = await axiosTnaDelete(url)
    data = data_api
    err = err_api

    if (data && data.success) {
      const aadj = adjuntos.filter(p => p.dataOrder !== record.dataOrder)
      aadj.sort(sortData)
      setAdjuntos(aadj)
    }
    if (err)
      showNotify(err, notifyType.error)

    setCurrentLineFI(null)
    setLastAccess()
  }

  const handleDownFile = async (record, download) => {
    let err = null
    let data = null

    setCurrentLineFI(record.dataOrder)

    const url = docRecuperarUrl(selDocumento.documentoID, null, record.dataOrder)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (!download && record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      showNotify(err, notifyType.error)

    setCurrentLineFI(null)
    setLastAccess()
  }

  const fiColumns = [
    {
      title: 'Archivo',
      dataIndex: 'dataOrder',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex space-x-1 items-center'>
            <button
              className='text-blue-600 text-left'
              disabled={record.dataOrder === currentLineFI}
              onClick={() => handleDownFile(record, false)}
            >
              {`${record.fileName}`}
            </button>
            {
              record.fileType &&
              <span>{`- ${record.fileType}`}</span>
            }
          </div>
          <div className='flex justify-end items-center'>
            {
              record.dataOrder !== currentLineFI &&
              <>
                <button onClick={() => handleDownFile(record, true)}>
                  <CloudDownloadOutlined />
                </button>
                {
                  !record.required &&
                  <Popconfirm placement="left"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    title={`¿Confirma la operación?`}
                    onConfirm={() => handleDelFile(record)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <button className='ml-2'>
                      <DeleteFilled className='text-red-400' />
                    </button>
                  </Popconfirm>
                }
              </>
            }
            {
              record.dataOrder === currentLineFI &&
              <Spin size='small' className='ml-2' />
            }
          </div>
        </div>
    },
  ]

  return (
    <>
      {
        openModal &&
        <Modal
          open={openModal}
          title={"Buscar documento"}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          footer={[]}
          width={1050}>
          <ListaDoc selReceptor={selReceptor} selRendicion={selRendicion} showNotify={showNotify} setDocumento={(doc) => { doc && handleChangeDocumento(doc.id, documentos) }} />
        </Modal>
      }
      {
        loadPage &&
        <div className='my-5 flex justify-center'>
          <Spin tip='Loading....' size='large' />
        </div>
      }
      <div className='ant-common-titania'>
        {
          !loadPage &&
          <div className='flex justify-between mb-2.5'>
            <div className='flex space-x-2'>
              <Select placeholder='Nuevo documento' style={{ width: 280 }} value={selDocumento?.documentoID}
                onChange={(v) => handleChangeDocumento(v, documentos)} allowClear={!validar} disabled={componentDisabled}>
                {
                  documentos.map(({ key, documentoID, numDO }) => (
                    <Select.Option key={key} value={documentoID}>{numDO}</Select.Option>
                  ))
                }
              </Select>
              {
                (loadDocumento || loadTipoCambio) &&
                <div className='flex items-center text-indigo-600'>
                  <SyncOutlined spin />
                </div>
              }
              {
                !valAdjunto(dbDocumento, fmUploadedXML) &&
                <div className='flex items-center'>
                  <Tooltip placement="right" title="Elimine y vuelva a cargar">
                    <p className="text-xs text-red-900">
                      {`- ERROR`}
                    </p>
                  </Tooltip>
                </div>
              }
              {
                !selDocumento &&
                <Tooltip title="Limpiar documento" placement='right'>
                  <button onClick={() => setClearDoc(prev => !prev)} className='flex items-center'>
                    <ClearOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                  </button>
                </Tooltip>
              }
              {
                selRendicion && documentos.length > 0 &&
                <Tooltip title="Buscar documento" placement='right'>
                  <button onClick={() => setOpenModal(true)} className='flex items-center'>
                    <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                  </button>
                </Tooltip>
              }
              {
                enaMontoRen(selRendicion, fmMoneda) && !loadTipoCambio &&
                <Tooltip title="Actualizar tipo de cambio" placement='right'>
                  <button onClick={() => handleChangeTCambio(fmFechaDoc, fmMoneda?.codigo)} className='flex items-center'>
                    <ReloadOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                  </button>
                </Tooltip>
              }
              {
                totalDoc &&
                <div className='flex items-center'>
                  <span className='text-xs'>{totalDoc}</span>
                </div>
              }
            </div>
            <div className="flex flex-row justify-end space-x-3 mx-1">
              <div className="flex items-center text-sm text-indigo-700">
                {
                  (!validar || (activeTabKey === "2" && selDocumento)) &&
                  <Popconfirm
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    title={`¿Confirma la operación?`}
                    onConfirm={validar ? handleModDoc : handleRegDoc}
                    placement="left"
                    okText="Sí"
                    cancelText="No"
                    disabled={componentDisabled}
                  >
                    <div className='flex items-center cursor-pointer'>
                      <Tooltip title="Guardar documento" placement='left'>
                        <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                      </Tooltip>
                      Guardar
                    </div>
                  </Popconfirm>
                }
              </div>
              {
                selDocumento &&
                <div className="flex items-center text-sm text-indigo-700">
                  <Popconfirm
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    title={`¿Confirma la operación?`}
                    onConfirm={handleEliDoc}
                    placement="left"
                    okText="Sí"
                    cancelText="No"
                    disabled={componentDisabled}
                  >
                    <div className='flex items-center cursor-pointer'>
                      <Tooltip title="Eliminar documento" placement='left'>
                        <DeleteOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                      </Tooltip>
                      Eliminar
                    </div>
                  </Popconfirm>
                </div>
              }
            </div>
          </div>
        }

        <div className='ant-tabs-titania'>
          <Tabs
            defaultActiveKey="1"
            hidden={loadPage}
            activeKey={activeTabKey}
            onChange={setActiveTabKey}
            items={[
              {
                label: `Data`,
                key: '1',
                children: <>
                  <div className='flex space-x-4'>
                    <div>
                      <div className='mb-1'>
                        <span className='span-requerido-enr-newdoc'>*</span>
                        <span>Tipo de documento</span>
                      </div>
                      <div className='flex space-x-1.5'>
                        <Select placeholder='Seleccione tipo de documento' style={{ width: 280 }} value={fmTipoDoc?.tipoDocumentoID}
                          onChange={handleChangeTipoDoc} allowClear disabled={componentDisabled || fmUploadedXML || validar}>
                          {
                            tiposDoc.map(({ key, tipoDocumentoID, nombre }) => (
                              <Select.Option key={key} value={tipoDocumentoID}>{nombre}</Select.Option>
                            ))
                          }
                        </Select>
                        {
                          loadDataDocumento ?
                            <div className='flex items-center pl-0.5 text-indigo-600'>
                              <SyncOutlined spin />
                            </div> :
                            <>
                              {fmUploadedXML ?
                                <div className='flex items-center pl-0.5 text-indigo-600'>
                                  <Tooltip title="XML cargado" placement='top'>
                                    <CloudUploadOutlined onClick={() => { !selDocumento && clearFileCPE() }} />
                                  </Tooltip>
                                </div>
                                :
                                <>
                                  {
                                    fmTipoDoc?.isCPE &&
                                    <label
                                      htmlFor="file-upload-cpe"
                                      className={"flex items-center pl-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (!fmTipoDoc ? " hover:cursor-not-allowed" : "")}
                                    >
                                      <Tooltip title="Cargar comprobante electrónico" placement='top'>
                                        <CloudUploadOutlined className="mr-1" />
                                      </Tooltip>
                                      <span>CPE</span>
                                      <input id="file-upload-cpe" name="file-upload-cpe" type="file" className="sr-only"
                                        disabled={componentDisabled || validar}
                                        onChange={handleDataComprobante}
                                        accept=".xml"
                                      />
                                    </label>
                                  }
                                </>
                              }
                            </>
                        }
                        {
                          loadValComprobante ?
                            <div className='flex items-center pl-0.5 text-indigo-600'>
                              <SyncOutlined spin />
                            </div> :
                            <>
                              {
                                fmValidatedCPE ?
                                  <div className='flex items-center pl-0.5 text-indigo-600'>
                                    <Tooltip title="Comprobante validado" placement='top'>
                                      <ScheduleOutlined />
                                    </Tooltip>
                                  </div> :
                                  <>
                                    {
                                      fmTipoDoc?.isCPE &&
                                      <button
                                        className="flex items-center pl-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                        onClick={handleValComprobante}
                                        disabled={componentDisabled}>
                                        <Tooltip title="Validar comprobante electrónico" placement='top'>
                                          <ScheduleOutlined className="mr-1" />
                                        </Tooltip>
                                        <span>CPE</span>
                                      </button>
                                    }
                                  </>
                              }
                            </>
                        }
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full'>
                      <div className='w-1/2 '>
                        <div className='mb-1'>
                          <span className='span-requerido-enr-newdoc'>*</span>
                          <span>Serie</span>
                        </div>
                        <Input
                          placeholder="Ingrese serie"
                          className='w-full'
                          maxLength={10}
                          value={fmSerie}
                          onChange={(e) => { setFmSerie(e.target.value?.toUpperCase()); setFmValidatedCPE(false) }}
                          disabled={componentDisabled}
                          readOnly={fmUploadedXML || validar} />
                      </div>
                      <div className='w-1/2'>
                        <div className='mb-1'>
                          <span className='span-requerido-enr-newdoc'>*</span>
                          <span>Correlativo</span>
                        </div>
                        {
                          fmTipoDoc && fmTipoDoc.numerico ?
                            <InputNumber
                              placeholder='Ingrese número'
                              className='text-xs w-full'
                              size='middle'
                              maxLength={20} min={1}
                              bordered={true} precision={0}
                              value={fmNumero}
                              onChange={(v) => { setFmNumero(v); setFmValidatedCPE(false) }}
                              disabled={componentDisabled}
                              readOnly={fmUploadedXML || validar} /> :
                            <Input
                              placeholder="Ingrese número"
                              className='w-full'
                              maxLength={25}
                              value={fmNumero}
                              onChange={(e) => { setFmNumero(e.target.value?.toUpperCase()); setFmValidatedCPE(false) }}
                              disabled={componentDisabled}
                              readOnly={fmUploadedXML || validar} />
                        }
                      </div>
                    </div>
                  </div>

                  <div className='mt-2'>
                    <div className='mb-1'>
                      <span className='span-requerido-enr-newdoc'>*</span>
                      <span>Emisor</span>
                    </div>
                    <div className='flex space-x-0.5'>
                      <Input.Search value={fmEmsrNumDoc} onChange={(e) => { setFmEmsrNumDoc(e.target.value); setFmEmisor(null); setFmValidatedCPE(false) }}
                        size="middle" placeholder={`Ingrese ${getNameTipoDocIden(fmTipoDoc)}`} maxLength={15} style={{ width: '20.5rem' }}
                        onSearch={() => handleValEmisor(fmEmisor, fmUploadedXML)}
                        disabled={componentDisabled || loadValEmisor}
                        readOnly={fmUploadedXML || validar} />
                      <Input value={fmEmisor?.name} readOnly={(fmEmisor && fmEmisor.validated) || fmUploadedXML || !fmEmsrNumDoc || validar}
                        onChange={(e) => {
                          const emsr = { code: fmEmsrNumDoc, name: e.target.value, stdoContrb: null, condDomic: null, validated: false }
                          setFmEmisor(emsr);
                        }}
                        placeholder='Resultado de búsqueda' />
                      {
                        loadValEmisor &&
                        <div className='flex items-center text-indigo-600 pl-1'>
                          <SyncOutlined spin />
                        </div>
                      }
                      {
                        fmEmisor && (fmEmisor.stdoContrb || fmEmisor.condDomic) &&
                        <div className='flex flex-col justify-center pl-1' style={{ fontSize: '0.55rem' }}>
                          <label>{fmEmisor.stdoContrb}</label>
                          <label>{fmEmisor.condDomic}</label>
                        </div>
                      }
                      {
                        fmEmisor && fmEmisor.validated &&
                        <div className='flex items-center pl-1'>
                          <Tooltip title="Emisor validado" placement='left'>
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          </Tooltip>
                        </div>
                      }
                    </div>
                  </div>

                  <div className='mt-3'>
                    <Form
                      layout="vertical" requiredMark
                      onValuesChange={onValuesChangeDta}
                      disabled={componentDisabled}
                      autoComplete="off"
                      hidden={loadPage}
                      form={formDta}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            name="fechaDoc"
                            label="Fecha de documento"
                            initialValue={dvFecha}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <DatePicker placeholder='Ingrese fecha de documento'
                              onChange={(v) => handleChangeTCambio(v, fmMoneda?.codigo)}
                              getPopupContainer={(trigger) => trigger.parentElement}
                              disabled={fmUploadedXML}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="monedaISO"
                            label="Moneda"
                            initialValue={dvMoneda?.codigo}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <Select placeholder="Seleccione moneda"
                              onChange={(v) => handleChangeTCambio(fmFechaDoc, v)}
                              disabled={fmUploadedXML || validar}
                              allowClear>
                              {
                                monedas.map(({ codigo }) => (
                                  <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="tipoCambio"
                            label="Tipo de cambio"
                            rules={[
                              {
                                required: false,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Sin tipo de cambio'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15}
                              bordered={true} precision={3}
                              readOnly={true}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            name="tipoTrib"
                            label="Tipo de tributo"
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <Select placeholder="Seleccione tipo de tributo" allowClear={!fmUploadedXML && !validar}>
                              {
                                tiposTrib.map(({ nombreTributo }) => (
                                  <Select.Option key={nombreTributo} value={nombreTributo}>{nombreTributo}</Select.Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="montoBase"
                            label="Monto base"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Ingrese monto base'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15} min={0.0}
                              bordered={true} precision={2}
                              disabled={!fmTipoTrib}
                              readOnly={fmUploadedXML || validar}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="montoTrib"
                            label="Monto tributo"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Ingrese monto tributo'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15} min={0.0}
                              bordered={true} precision={2}
                              disabled={!fmTipoTrib}
                              readOnly={fmUploadedXML || validar}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            name="cargo"
                            label="Otros cargos"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: false,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Ingrese otros cargos'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15} min={0.0}
                              bordered={true} precision={2}
                              readOnly={fmUploadedXML || validar}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="totalBase"
                            label="Total base"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Sin total base'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15} min={0.0}
                              bordered={true} precision={2}
                              readOnly={true}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="totalTrib"
                            label="Total tributo"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: false,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Sin total tributo'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15}
                              bordered={true} precision={2}
                              readOnly={true}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            name="descuento"
                            label="Otros descuentos"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: false,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Ingrese otros descuentos'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15} min={0.0}
                              bordered={true} precision={2}
                              readOnly={fmUploadedXML || validar}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="total"
                            label="Importe total"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Sin importe total'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15} min={0.0}
                              bordered={true} precision={2}
                              readOnly={true}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="montoRen"
                            label={`Monto rendición` + (fmTCambioRen ? ` TC (${fmTCambioRen})` : '')}
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Ingrese monto rendición'
                              className='text-xs w-36'
                              size='middle'
                              maxLength={15} min={0.0}
                              bordered={true} precision={2}
                              readOnly={disMontoRen(selRendicion, fmMoneda)}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="descrip"
                            label="Descripción"
                            rules={[
                              {
                                required: false,
                                message: '',
                              },
                            ]}
                          >
                            <Input
                              placeholder='Ingrese descripción'
                              size='middle'
                              maxLength={150}
                              readOnly={validar}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              },
              {
                label: `Contable`,
                key: '2',
                forceRender: true,
                children:
                  <>
                    <div className='mb-3'>
                      <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                        <Form
                          layout="vertical" requiredMark
                          onValuesChange={onValuesChangeCtb}
                          disabled={componentDisabled || !fmTipoDoc || !fmTipoDoc.otroTributo}
                          autoComplete="off"
                          hidden={loadPage}
                          form={formCtb}>
                          <Row gutter={16}>
                            <Col span={8}>
                              <Form.Item
                                name="tipoSTrib"
                                label="Otro tributo"
                                rules={[
                                  {
                                    required: !!fmTipoSTrib,
                                    message: '',
                                  },
                                ]}
                              >
                                <Select placeholder="Seleccione tributo" allowClear>
                                  {
                                    tiposSTrib.map(({ codigo, nombre }) => (
                                      <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                    ))
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name="catSTrib"
                                label="Categoría"
                                rules={[
                                  {
                                    required: !!fmTipoSTrib,
                                    message: '',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder='Ingrese categoría'
                                  size='middle'
                                  maxLength={3}
                                  disabled={!fmTipoSTrib}
                                  style={{
                                    width: '100%',
                                  }} />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name="montoSTrib"
                                label="Monto otro tributo"
                                initialValue={dvMonto}
                                rules={[
                                  {
                                    required: !!fmTipoSTrib,
                                    message: '',
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder='Ingrese monto tributo'
                                  className='text-xs'
                                  size='middle'
                                  maxLength={15} min={0.0}
                                  bordered={true} precision={2}
                                  disabled={!fmTipoSTrib}
                                  style={{
                                    width: '100%',
                                  }} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16} hidden={!fmTipoSTrib || !fmTipoSTrib.bool1}>
                            <Col span={8}>
                              <Form.Item
                                name="numConst"
                                label="Número de constancia"
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder='Ingrese nº de constancia'
                                  size='middle'
                                  maxLength={20}
                                  style={{
                                    width: '100%',
                                  }} />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name="fechaPago"
                                label="Fecha de pago"
                                initialValue={dvFecha}
                                rules={[
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ]}
                              >
                                <DatePicker placeholder='Ingrese fecha de pago'
                                  getPopupContainer={(trigger) => trigger.parentElement}
                                  style={{
                                    width: '100%',
                                  }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </dl>
                    </div>
                    <div className='mb-2'>
                      <div className='mb-1'>
                        <span className='span-requerido-enr-newdoc'>*</span>
                        <span>Elemento de gasto</span>
                      </div>
                      <div className='flex space-x-0.5'>
                        <AutoComplete
                          dropdownMatchSelectWidth={500}
                          style={{ width: 230, }}
                          options={opcionesEgas}
                          onSearch={(v) => handleSearchEgas(v, egasto)}
                          onChange={handleChangeEgas}
                          onSelect={(v) => handleSelectEgas(v, egasto)}
                          value={dvSearchEgas}
                          disabled={componentDisabled}
                        >
                          <Input.Search size="middle" placeholder="Ingrese filtro" />
                        </AutoComplete>
                        <Input readOnly placeholder='Resultado de búsqueda' value={fmEGasto?.name} />
                      </div>
                    </div>
                    <div className='mb-2'>
                      <div className='mb-1'>
                        <span className='span-requerido-enr-newdoc'>*</span>
                        <span>Asignación de centro de costo</span>
                      </div>
                      <div className='flex items-center'>
                        <Select placeholder='Asignación' style={{ width: '13.5rem' }} allowClear value={selAsignacion?.line} onChange={handleChangeAsignacion} disabled={componentDisabled}>
                          {
                            asignaciones.map(({ key, line, label }) => (
                              <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                          }
                        </Select>
                        <div className='flex items-center ml-2 space-x-1'>
                          {
                            valAsignacion(asignaciones, getBase()) &&
                            <Tooltip title="Agregar asignación" placement='top'>
                              <button onClick={handleAgregarAsig} className='flex items-center'>
                                <FileAddOutlined className='text-indigo-600' style={{ fontSize: '0.9rem' }} />
                              </button>
                            </Tooltip>
                          }
                          {
                            selAsignacion &&
                            <Tooltip title="Remover asignación" placement='top'>
                              <button onClick={handleRemoverAsig} className='flex items-center'>
                                <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                              </button>
                            </Tooltip>
                          }
                        </div>
                      </div>
                    </div>
                    <Form
                      layout="vertical" requiredMark
                      onValuesChange={onValuesChangeAsg}
                      disabled={componentDisabled || !selAsignacion}
                      autoComplete="off"
                      hidden={loadPage}
                      form={formAsg}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            name="monto"
                            label="Monto"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Ingrese monto'
                              className='text-xs'
                              size='middle'
                              maxLength={15} min={0.0} max={getBase() ?? dvMonto}
                              bordered={true} precision={2}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="porcentaje"
                            label="Porcentaje (%)"
                            initialValue={dvMonto}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder='Ingrese porcentaje'
                              className='text-xs'
                              size='middle'
                              maxLength={15} min={0.0} max={100.0}
                              bordered={true} precision={2}
                              style={{
                                width: '100%',
                              }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    {
                      ccosto.length > 0 && selAsignacion &&
                      ccosto.map((ccos) => (
                        <div key={ccos.key} className='mb-2'>
                          <div className=' mb-1'>
                            {
                              ccos.required &&
                              <span className='span-requerido-enr-newdoc'>*</span>
                            }
                            <span>{ccos.dimName}</span>
                          </div>
                          <div className='flex space-x-0.5'>
                            <AutoComplete
                              dropdownMatchSelectWidth={500}
                              style={{ width: 230, }}
                              options={ccos.opciones}
                              onSearch={(v) => handleSearchCcos(v, ccos, ccosto, selAsignacion)}
                              onSelect={(v) => handleSelectCcos(v, ccos, ccosto, selAsignacion)}
                              value={ccos.dvSearch}
                              disabled={componentDisabled}
                            >
                              <Input.Search size="middle" placeholder="Ingrese filtro" />
                            </AutoComplete>
                            <Input readOnly placeholder='Resultado de búsqueda' value={ccos.code?.codeName} />
                          </div>
                        </div>
                      ))
                    }
                  </>
              },
              {
                label: <>Adjunto<span className='text-xs ml-1'>(<span className='text-blue-700'>doc</span>)</span></>,
                key: '3',
                disabled: !selDocumento,
                children:
                  <>
                    <p className='text-xs mb-3'>Suba un archivo para el <span className='font-semibold'>documento</span>:</p>
                    {
                      adjuntos.length > 0 &&
                      <div className='mb-3 ant-table-titania'>
                        <Table
                          columns={fiColumns}
                          dataSource={adjuntos}
                          pagination={false}
                          bordered
                          size='small'
                        />
                      </div>
                    }
                    <div className='flex items-center space-x-2'>
                      <Select placeholder='Seleccione tipo de archivo' style={{ width: 230 }} value={selTipoAdj?.tipo}
                        onChange={(v) => handleChangeTipoAdj(v, tiposAdj)} allowClear disabled={componentDisabled}>
                        {
                          tiposAdj.map(({ key, tipo, nombre }) => (
                            <Select.Option key={key} value={tipo}>{nombre}</Select.Option>
                          ))
                        }
                      </Select>
                      <label
                        htmlFor="file-upload-doc"
                        className={"flex items-center p-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (loadFile ? " hover:cursor-not-allowed" : "")}
                      >
                        <CloudUploadOutlined className="mr-1" />
                        <span>Subir archivo</span>
                        <input id="file-upload-doc" name="file-upload-doc" type="file" className="sr-only"
                          accept={selTipoAdj ? selTipoAdj.fileExt : "*.*"}
                          onChange={handleUpFile}
                          disabled={loadFile}
                        />
                      </label>
                      {
                        loadFile &&
                        <div className='flex items-center mr-2 text-indigo-600'>
                          <SyncOutlined spin />
                        </div>
                      }
                    </div>
                  </>
              }
            ]}
          />
        </div>
      </div >
    </>
  )
}
