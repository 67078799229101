import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useTokenDne } from "./useTokenDne";
import { axiosDne } from "../api/axiosDne";

const useInterceptDne = () => {
    const refresh = useTokenDne()
    const { auth } = useAuth()

    useEffect(() => {
        const requestIntercept = axiosDne.interceptors.request.use(
            config => {
                if (!config.headers['Authorization'])
                    config.headers['Authorization'] = `Bearer ${auth?.tokenDne ?? ''}`
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosDne.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true
                    const newTokenDne = await refresh()
                    prevRequest.headers['Authorization'] = `Bearer ${newTokenDne}`
                    return axiosDne(prevRequest)
                }
                return Promise.reject(error)
            }
        )

        return () => {
            axiosDne.interceptors.request.eject(requestIntercept)
            axiosDne.interceptors.response.eject(responseIntercept)
        }
        // eslint-disable-next-line
    }, [auth])

    return axiosDne
}

export default useInterceptDne