import './DetalleOpe.css'

import { CloudDownloadOutlined, } from '@ant-design/icons'
import { Spin, Table, Tabs } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { opeGetIdUrl, opeRecuperarUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate, formatDigits, formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../Msg/NotifyRed'
import { NotifyGreen } from '../../Msg/NotifyGreen'
import { NotifyYellow } from '../../Msg/NotifyYellow'

var jszip = require('jszip')

export const DetalleOpe = forwardRef((props, ref) => {
  const { selReceptor, selOperacion: vwOperacion, dbOperacion } = props

  const { axiosTnaGet } = useAxiosTna()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [loadPage, setLoadPage] = useState(true)
  const [loadGetOpe, setLoadGetOpe] = useState(false)
  const [camposOpe, setCamposOpe] = useState([])
  const [adjuntos, setAdjuntos] = useState([])
  const [selOperacion, setSelOperacion] = useState(null)
  const [currentLineFI, setCurrentLineFI] = useState(null)
  const [activeTabKey, setActiveTabKey] = useState("1")

  useEffect(() => {
    setLoadPage(loadGetOpe)
  }, [loadGetOpe])

  useEffect(() => {
    setSelOperacion(vwOperacion)
  }, [vwOperacion])

  useEffect(() => {
    const getCampo = (rec, ope) => {
      const campos = [
        { label: 'Receptor', value: `${rec.ruc} - ${rec.name}` },
        { label: 'Grupo', value: ope.grupo },
        { label: 'Tipo de operación', value: `${ope.tpoOP} ( ${ope.ingSal ? 'ingreso' : 'salida'} )` },
        { label: 'Nº de operación', value: (ope.isSubLin ? `${ope.numOP} ( ${ope.linOP + 1}.${ope.subLinOP + 1} )` : `${ope.numOP} ( ${ope.linOP + 1} )`) },
        { label: 'Fecha de operación', value: formatDate(ope.fechaOpe) },
        { label: 'Área', value: ope.area },
        { label: 'Nº de cuenta', value: `${ope.banco} ; ${ope.numeroCta}` },
        { label: 'Nº de transacción', value: ope.numTran },
        { label: 'Fecha de transacción', value: formatDate(ope.fechaTran) },
        { label: 'Tipo de pago', value: ope.tipoPago },
        { label: 'Monto', value: `${formatAmount(ope.monto)} ${ope.monedaISO}` + (ope.tipoCambio ? ` (TC: ${formatDigits(ope.tipoCambio)})` : '') },
        { label: 'Observación', value: ope.observ },
        { label: 'Rindente', value: getUsuario(ope.rindente) },
        { label: 'Nº de cuenta (Rindente)', value: ope.destino ? `${ope.destino.banco} ${ope.destino.numeroCta}` : null },
        { label: 'Fecha de registro', value: formatTime(ope.fechaReg) },
        { label: 'Tesorero', value: ope.usuarioReg },
      ]
      return campos.filter(p => p.value)
    }

    setCamposOpe(selReceptor && selOperacion ? getCampo(selReceptor, selOperacion) : [])
  }, [selReceptor, selOperacion])

  useEffect(() => {
    if (selOperacion) {
      if (selOperacion.attach)
        setAdjuntos(selOperacion.attach.map((att, index) => ({
          ...att,
          key: index
        })))
    }
  }, [selOperacion])

  useEffect(() => {
    let isMounted = true

    const getOpe = async () => {
      isMounted && setLoadGetOpe(true)

      const url = opeGetIdUrl(dbOperacion.id)
      const [data, err] = await axiosTnaGet(url)

      if (data) {
        const { success, data: ope } = data
        if (success)
          isMounted && setSelOperacion({ ...ope, key: ope.id })
      }
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadGetOpe(false)
    }

    if (dbOperacion)
      getOpe()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [dbOperacion])

  useImperativeHandle(
    ref,
    () => ({
      showNotify(err, type) {
        if (type === notifyType.warning)
          notifyYellowRef.current.handleOpen(err, type)
        else if (type === notifyType.error)
          notifyRedRef.current.handleOpen(err, type)
        else if (type === notifyType.success)
          notifyGreenRef.current.handleOpen(err, type)
      }
    })
  )

  const getUsuario = (usu) => {
    if (!usu)
      return ''
    else if (usu.docIdentidad)
      return `${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`
    else
      return `${usu.userID} - ${usu.userName}`
  }

  const getClassName = (index, lista) => {
    if (index === 0)
      return 'sm:flex'
    else if (index === lista.length - 1)
      return 'py-0.5 sm:flex'
    else
      return 'pt-0.5 sm:flex'
  }

  const handleDownFile = async (record, download) => {
    let err = null
    let data = null

    setCurrentLineFI(record.dataOrder)

    const url = opeRecuperarUrl(selOperacion.id, record.dataOrder)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (!download && record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setCurrentLineFI(null)
    setLastAccess()
  }

  const fiColumns = [
    {
      title: 'Archivo',
      dataIndex: 'dataOrder',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex space-x-1 items-center'>
            <button
              className='text-blue-600 text-left'
              disabled={record.dataOrder === currentLineFI}
              onClick={() => handleDownFile(record, false)}
            >
              {`${record.fileName}`}
            </button>
          </div>
          <div className='flex justify-end items-center'>
            {
              record.dataOrder !== currentLineFI &&
              <>
                <button onClick={() => handleDownFile(record, true)}>
                  <CloudDownloadOutlined />
                </button>
              </>
            }
            {
              record.dataOrder === currentLineFI &&
              <Spin size='small' className='ml-2' />
            }
          </div>
        </div>
    },
  ]

  return (
    <>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='detalleope-tabs'>
            <Tabs
              defaultActiveKey="1"
              activeKey={activeTabKey}
              onChange={setActiveTabKey}
              items={[
                {
                  label: `Data`,
                  key: '1',
                  children:
                    <>
                      <div>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposOpe.map(({ label, value }, index) => (
                              <div className={getClassName(index, camposOpe)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="text-gray-900">{value}</div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                    </>
                },
                {
                  label: `Adjunto`,
                  key: '2',
                  disabled: adjuntos.length === 0,
                  children:
                    <>
                      {
                        adjuntos.length > 0 &&
                        <div className='mb-3 detalleope-table'>
                          <Table
                            columns={fiColumns}
                            dataSource={adjuntos}
                            pagination={false}
                            bordered
                            size='small'
                          />
                        </div>
                      }
                    </>
                },
              ]}
            />
          </div>
      }
    </>
  )
})
