import './RegistrarREN.css'

import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { CloseCircleOutlined, QuestionCircleOutlined, FileAddOutlined, FileTextOutlined, SearchOutlined, ZoomOutOutlined, RetweetOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip, message } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../hooks/useAxiosCto'
import { catGetCodUrl, rctEntrRendUrl, renEliminarUrl, renEnviarUrl, renGetNregUrl, renGetNumUrl, renGetRegUrl, renRevisarUrl, segConnUrl, solGetRenUrl, stdGetTipoUrl, storageTCambioUrl, tsk8Url, tskRecuperarUrl, usuGetCurrUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { NewRen } from '../../../components/Panel/enr/RegRen/NewRen'
import { DetalleRen } from '../../../components/Panel/Detail/DetalleRen'
import { DetalleSol } from '../../../components/Panel/Detail/DetalleSol'

var jszip = require('jszip')

export const RegistrarREN = ({ page: { code: resource } }) => {
    const dvFechaIni = moment().startOf('month')
    const dvFechaFin = moment().endOf('month')

    const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
    const { axiosCtoGet } = useAxiosCto()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const newRenRef = useRef()
    const detRenRef = useRef()
    const detSolRef = useRef()
    const [disablePage, setDisablePage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
    const [loadTipoRen, setLoadTipoRen] = useState(true)
    const [loadEstado, setLoadEstado] = useState(true)
    const [loadMoneda, setLoadMoneda] = useState(true)
    const [loadUsuario, setLoadUsuario] = useState(true)
    const [loadConfigER, setLoadConfigER] = useState(false)
    const [loadBusqRen, setLoadBusqRen] = useState(false)
    const [loadEliRen, setLoadEliRen] = useState(false)
    const [loadEnvRen, setLoadEnvRen] = useState(false)
    const [loadTask8Add, setLoadTask8Add] = useState(false)
    const [loadTask8Down, setLoadTask8Down] = useState(false)
    const [allGroup, setAllGroup] = useState(false)
    const [receptores, setReceptores] = useState([])
    const [grupos, setGrupos] = useState([])
    const [tiposRen, setTiposRen] = useState([])
    const [tiposSol, setTiposSol] = useState([])
    const [areas, setAreas] = useState([])
    const [estados, setEstados] = useState([])
    const [monedas, setMonedas] = useState([])
    const [usuario, setUsuario] = useState(null)
    const [selReceptor, setSelReceptor] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [dbSolicitud, setDbSolicitud] = useState(null)
    const [pendiente, setPendiente] = useState(false)
    const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
    const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
    const [foTipoSol, setFoTipoSol] = useState(null)
    const [foTipoRen, setFoTipoRen] = useState(null)
    const [foArea, setFoArea] = useState(null)
    const [foEstado, setFoEstado] = useState(null)
    const [foNumSO, setFoNumSO] = useState(null)
    const [foNumRD, setFoNumRD] = useState(null)
    const [foSave, setFoSave] = useState(null)
    const [foRevertir, setFoRevertir] = useState(null)
    const [rendiciones, setRendiciones] = useState([])
    const [selRendicion, setSelRendicion] = useState(null)
    const [rdTotalDocuments, setRdTotalDocuments] = useState(0)
    const [rdPageSize, setRdPageSize] = useState(10)
    const [rdCurrentPage, setRdCurrentPage] = useState(1)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)
    const [activeTabRen, setActiveTabRen] = useState('1')
    const [currentLineRD, setCurrentLineRD] = useState(null)
    const [taskParam, setTaskParam] = useState({})
    const [taskInterval, setTaskInterval] = useState(false)
    const [task8, setTask8] = useState({ task: null, id: null, first: true })

    useEffect(() => {
        setLoadPage(loadTokenConn || loadRcptEmsr || loadTipoRen || loadEstado || loadMoneda || loadUsuario)
    }, [loadTokenConn, loadRcptEmsr, loadTipoRen, loadEstado, loadMoneda, loadUsuario])

    useEffect(() => {
        setDisablePage(loadConfigER || loadBusqRen || loadEliRen || loadEnvRen || loadTask8Add || loadTask8Down)
    }, [loadConfigER, loadBusqRen, loadEliRen, loadEnvRen, loadTask8Add, loadTask8Down])

    useEffect(() => {
        setDbSolicitud(selRendicion ? { numSO: selRendicion.numSO } : null)
    }, [selRendicion])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const rcptEmsr = async () => {
            const url = usuRcptEmsrUrl(resource)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setReceptores(data.rcpt)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRcptEmsr(false)
        }

        const stdTipo = async () => {
            const url = stdGetTipoUrl(configType.stdRendicion)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setEstados(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadEstado(false)
        }

        const catMoneda = async () => {
            const url = catGetCodUrl(configType.catMoneda)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setMonedas(data.detalle.filter(dt => dt.bool1))
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadMoneda(false)
        }

        const catTipoRen = async () => {
            const url = catGetCodUrl(configType.catTipoRen)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposRen(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoRen(false)
        }

        const currUsuario = async () => {
            const url = usuGetCurrUrl()
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setUsuario(data.data)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadUsuario(false)
        }

        if (!loadTokenConn) {
            rcptEmsr()
            stdTipo()
            catMoneda()
            catTipoRen()
            currUsuario()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const t8Get = async () => {
            const url = tsk8Url()
            const [data, err] = await axiosTnaGet(url)

            if (data) {
                isMounted && setTask8(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
                isMounted && setTaskParam(prev => ({
                    ...prev,
                    task: data.data,
                }))
            }
            if (err)
                console.log(`Task: ${err.message}`)
        }

        if (!loadTokenConn && (task8.first || (!task8.task && task8.id) || (task8.task && !task8.task.complete)))
            t8Get()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [taskInterval, loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const interval = setInterval(() => {
            isMounted && setTaskInterval(prev => !prev)
        }, 5000)

        return () => {
            clearInterval(interval)
            isMounted = false
        }
    }, [])

    const setConfigER = useCallback(async (person) => {
        setFoTipoSol(null)
        setFoArea(null)

        if (!person) {
            setTiposSol([])
            setAreas([])
            return
        }

        setLoadConfigER(true)

        const url = rctEntrRendUrl(person.rcptRUC, person.ruc, false, true)
        const [data, err] = await axiosTnaGet(url)

        if (data) {
            const { success, data: config } = data
            if (success) {
                setTiposSol(config.tiposSol || [])
                setAreas(config.areas?.filter(p => p.asignada) || [])
            }
        }
        if (err) {
            setTiposSol([])
            setAreas([])
            notifyRedRef.current.handleOpen(err, notifyType.error)
        }

        setLoadConfigER(false)
        // eslint-disable-next-line
    }, [setTiposSol, setAreas, setFoTipoSol, setFoArea])

    const refreshRen = async (rcpt, ren) => {
        const url = renGetNumUrl(rcpt.ruc, ren.numRD)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Rendición: ${err.message}`)
        const nren = data?.success ? data.data : null
        return nren
    }

    const sortRen = (a, b) => {
        if (a.index > b.index)
            return 1
        else
            return -1
    }

    const clearFile = () => {
        const input = document.getElementById("file-upload-docreg")
        if (input)
            input.value = null
    }

    const getUsu = (usu) => {
        if (!usu)
            return ''
        else if (usu.docIdentidad)
            return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
        else
            return `${usu.userID} - ${usu.userName}`
    }

    const getRen = (ren, index = null) => {
        var nren = { ...ren, key: ren.id, index, renSol: true, numSO: (ren.origen?.numSO ?? ren.numSO), solicitudID: (ren.origen?.solicitudID ?? ren.id) }
        if (index === null)
            delete nren.index
        return nren
    }

    const getTitleDrawer = (ren, grp) => {
        if (!ren || ren.isRen === null || ren.isRen === undefined || ren.isDet === null || ren.isDet === undefined)
            return ''
        else if (!ren.isRen)
            return `Solicitud ( ${ren.numSO} )`
        else if (ren.isDet === 1)
            return `Rendición ( ${ren.numRD} )`
        else if (ren.isDet === 0 && !ren.numRD)
            return `Nueva Rendición ( ${ren.grpCode ?? grp?.ruc} )`
        else if (ren.isDet === 0 && ren.numRD)
            return `Editar Rendición ( ${ren.numRD} )`
        else
            return ''
    }

    const updateRen = (ren, sel = false) => {
        setRendiciones(prev => {
            const aren = prev.filter(p => p.id !== ren.id)
            const nren = [...aren, ren]
            nren.sort(sortRen)
            return nren
        })
        if (sel)
            setSelRendicion(ren)
    }

    const clearFiltroRD = (all = true) => {
        if (all) {
            setFoSave(null)
            setRendiciones([])
            setRdTotalDocuments(0)
        }
        setSelRendicion(null)
        setSelectedRows([])
        setSelectedRowKeys([])
        setTaskParam({})
    }

    const handleActiveTabRen = useCallback((tab) => { setActiveTabRen(tab) }, [setActiveTabRen])

    const handleSetRendicion = useCallback((ren, sel = true) => {
        const uren = getRen(ren)
        setRendiciones(prev => {
            const oren = pendiente ? null : prev.find(p => p.id === uren.id)
            const aren = pendiente ? prev.filter(p => p.id !== uren.solicitudID) : prev.filter(p => p.id !== uren.id)
            const nren = pendiente ? [...aren] : (oren ? [...aren, { ...oren, ...uren }] : [...aren])
            nren.sort(sortRen)
            return nren
        })
        if (sel)
            setSelRendicion(prev => ({ ...prev, ...uren }))
    }, [setSelRendicion, pendiente])

    const handlePendiente = (value) => {
        setPendiente(value)
        setFoFechaIni(value ? null : dvFechaIni)
        setFoFechaFin(value ? null : dvFechaFin)
        setFoTipoSol(null)
        setFoTipoRen(null)
        setFoArea(null)
        setFoEstado(null)
        setFoNumSO(null)
        clearFiltroRD()
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        setSelRendicion(null)
        setActiveTabRen('1')
        setFoRevertir(false)
    }

    const handleSelReceptor = useCallback((person) => {
        setSelReceptor(person)
        let a_grupos = []
        if (person && person.grupo.length > 0)
            a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
        setGrupos(a_grupos)
        clearFiltroRD()
    }, [setSelReceptor])

    const handleSelGrupo = useCallback((person) => {
        setSelGrupo(person)
        setConfigER(person)
        clearFiltroRD()
    }, [setSelGrupo, setConfigER])

    const handleBuscarRDFecha = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!pendiente && (!foFechaIni || !foFechaFin))
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqRen(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
        const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
        const tipo = pendiente ? (foTipoSol || '') : (foTipoRen || '')
        const area = foArea || ''
        const estado = foEstado || ''
        const numSO = foNumSO || ''

        const url = pendiente ?
            solGetRenUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, tipo, area, resource, 1, rdPageSize) :
            renGetRegUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, tipo, area, estado, numSO, resource, 1, rdPageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: rens } = data
            setRendiciones(rens.map((ren, index) => getRen(ren, index)))
            setRdTotalDocuments(totalDocuments)
            setRdCurrentPage(1)
            clearFiltroRD(false)
            setFoSave({
                svSelReceptor: selReceptor,
                svGrupo: grupo,
                svFechaIni: fechaIni,
                svFechaFin: fechaFin,
                svTipo: tipo,
                svArea: area,
                svEstado: estado,
                svNumSO: numSO,
                svPendiente: pendiente
            })
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqRen(false)
        setLastAccess()
    }

    const handleChangePagRD = async (page, pageSize) => {
        if (!foSave)
            return

        const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, svEstado, svNumSO, svPendiente } = foSave

        let err = null
        let data = null

        setLoadBusqRen(true)

        const url = svPendiente ?
            solGetRenUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, resource, page, pageSize) :
            renGetRegUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svTipo, svArea, svEstado, svNumSO, resource, page, pageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: rens } = data
            setRendiciones(rens.map((ren, index) => getRen(ren, index)))
            setRdTotalDocuments(totalDocuments)
            setRdCurrentPage(page)
            clearFiltroRD(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqRen(false)
        setLastAccess()
    }

    const handleBuscarRDNum = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        if (!foNumRD || foNumRD.trim().length === 0)
            err = {
                message: msgType.inputNumRD,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqRen(true)

        const url = renGetNregUrl(selReceptor.ruc, foNumRD)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { success, data: ren } = data
            if (success && (allGroup || selGrupo.ruc === ren.grpCode)) {
                setRendiciones([getRen(ren, 0)])
                setRdTotalDocuments(1)
            }
            else {
                setRendiciones([])
                setRdTotalDocuments(0)
            }
            clearFiltroRD(false)
            setFoSave(null)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqRen(false)
        setLastAccess()
    }

    const handleNuevaRen = () => {
        const ren = (selectedRows && selectedRows.length > 0) ? (rendiciones.find(p => p.id === selectedRows[0].id) || {}) : {}
        let err = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if ((!selGrupo || allGroup) && !ren.id)
            err = {
                message: msgType.selectGrupo.replace('grupo', selReceptor.grpName.toLowerCase()),
                oops: false
            }
        else if (!pendiente && !ren.id)
            err = {
                message: msgType.registroNoSel,
                oops: false
            }
        else if (selectedRows && selectedRows.length > 1)
            err = {
                message: msgType.registroNoOne,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        if (!ren.isReview) {
            setSelRendicion({ ...ren, isRen: true, isDet: 0 })
            setOpenDrawer(true)
        }
        else
            notifyYellowRef.current.handleOpen({
                message: msgType.rendicionNoMod,
                oops: false
            }, notifyType.warning)
    }

    const handleDetalleRen = async (record, isRen, isDet, param) => {
        let ren = { ...record, isRen, isDet }
        if (isRen && param.task && param.task.complete && param.task.file && param && param.numRD === ren.numRD) {
            setTaskParam({})
            setCurrentLineRD(ren.numRD)
            const tren = getRen(await refreshRen({ ruc: param.rcptNumDoc }, { numRD: param.numRD }), ren.index)
            setCurrentLineRD(null)
            setRendiciones(prev => {
                const aren = prev.filter(p => p.id !== ren.id)
                const nren = [...aren, tren]
                nren.sort(sortRen)
                return nren
            })
            ren = { ...tren, isRen, isDet }
        }
        setSelRendicion(ren)
        setOpenDrawer(true)
    }

    const handleRegistrarRen = () => newRenRef.current.handleSave()

    const handleEliminarRen = async () => {
        let err = null
        let data = null

        if (!usuario)
            err = {
                message: msgType.noDataUser,
                oops: false
            }
        else if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selRendicion)
            err = {
                message: msgType.selectRendicion,
                oops: false
            }
        else if (!selRendicion.allowDelete)
            err = {
                message: msgType.rendicionNoEli,
                oops: false
            }

        if (err) {
            selRendicion.isDet === 1 ? detRenRef.current.showNotify(err, notifyType.warning) : newRenRef.current.showNotify(err, notifyType.warning)
            return
        }

        setLoadEliRen(true)

        const url = renEliminarUrl(selRendicion.id)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data) {
            setRendiciones(prev => prev.filter(ren => ren.id !== selRendicion.id))
            handleCloseDrawer()
            message.success(msgType.rendicionEliminar);
        }
        if (err)
            selRendicion.isDet === 1 ? detRenRef.current.showNotify(err, notifyType.error) : newRenRef.current.showNotify(err, notifyType.error)

        setLoadEliRen(false)
        setLastAccess()
    }

    const handleEnviarRen = async () => {
        let err = null
        let data = null

        if (!usuario)
            err = {
                message: msgType.noDataUser,
                oops: false
            }
        else if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selRendicion)
            err = {
                message: msgType.selectRendicion,
                oops: false
            }
        else if (!foRevertir && !selRendicion.allowPut)
            err = {
                message: msgType.rendicionNoEnv,
                oops: false
            }
        else if (foRevertir && (selRendicion.allowPut || (selRendicion.faprob && selRendicion.faprob.length > 0 && selRendicion.faprob[selRendicion.faprob.length - 1].revisado)))
            err = {
                message: msgType.rendicionNoEnvRev,
                oops: false
            }

        if (err) {
            detRenRef.current.showNotify(err, notifyType.warning)
            return
        }

        setLoadEnvRen(true)

        let tipoCambio = null

        if (!selRendicion.rendReview && !foRevertir && selRendicion.monedaISO !== configType.monedaISOPEN) {
            const fecha = moment().format().split('T')[0]
            const url = storageTCambioUrl(selRendicion.monedaISO, fecha)
            const [data_api, err_api] = await axiosCtoGet(url)
            if (data_api)
                tipoCambio = data_api.tcVenta
            if (err_api)
                console.log(`Tipo de cambio: ${err_api}`)
        }

        const body = !selRendicion.rendReview && !foRevertir ? { tipoCambio } : {}
        const url = selRendicion.rendReview && !foRevertir ? renRevisarUrl(selRendicion.id) : renEnviarUrl(selRendicion.id)
        const [data_api, err_api] = foRevertir ? await axiosTnaPut(url, body) : await axiosTnaPost(url, body)
        data = data_api
        err = err_api

        if (data) {
            const nren = getRen(await refreshRen(selReceptor, selRendicion), selRendicion.index)
            if (nren)
                updateRen({ ...selRendicion, ...nren }, true)
            if (selRendicion.rendReview && !foRevertir) {
                handleCloseDrawer()
                message.success(`${msgType.rendicionEnviar} al revisor.`);
            }
            else
                detRenRef.current.showNotify({
                    message: (foRevertir ? msgType.rendicionEnviarRev : (`${msgType.rendicionEnviar} al aprobador.`)),
                    oops: false
                }, notifyType.success)
        }
        if (err)
            detRenRef.current.showNotify(err, notifyType.error)

        setLoadEnvRen(false)
        setLastAccess()
    }

    const handleTask8_Add = async ({ target: { files } }) => {
        const ren = (selectedRows && selectedRows.length > 0) ? (rendiciones.find(p => p.id === selectedRows[0].id) || {}) : {}

        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.fileKoPeso,
                oops: false
            }
        else if (file.type !== configType.fileTypeTXT)
            err = {
                message: msgType.fileKoTXT,
                oops: false
            }
        else if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (pendiente || !ren.id)
            err = {
                message: msgType.registroNoSel,
                oops: false
            }
        else if (selectedRows && selectedRows.length > 1)
            err = {
                message: msgType.registroNoOne,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearFile()
            return
        }

        setLoadTask8Add(true)

        const formData = new FormData()
        formData.append("", file)

        const url = tsk8Url(selReceptor.ruc, ren.numRD)
        const [data_api, err_api] = await axiosTnaPut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            setTask8(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
            setTaskParam({ rcptNumDoc: selReceptor.ruc, numRD: ren.numRD, task: null })
        }
        if (err) {
            notifyRedRef.current.handleOpen(err, notifyType.error)
            clearFile()
        }

        setLoadTask8Add(false)
        setLastAccess()
    }

    const handleTask8_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask8Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask8(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask8Down(false)
        setLastAccess()
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys)
            setSelectedRows(newSelectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: false,
        }),
        type: 'radio',
    }

    const rdColumns = [
        {
            title: pendiente ? 'Nº Solicitud' : 'Nº Rendición',
            dataIndex: pendiente ? 'numSO' : 'numRD',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <button
                            className='text-blue-600 w-full text-left'
                            onClick={() => handleDetalleRen(record, !pendiente && record.renSol, 1, taskParam)}
                        >
                            <span>{`${pendiente || !record.renSol ? record.numSO : record.numRD}`}</span>
                        </button>
                    </div>
                    {
                        !pendiente &&
                        <div className='flex justify-end space-x-1'>
                            {
                                record.numSO &&
                                <Tooltip title={`${record.renSol ? 'Mostrar nº de solicitud' : 'Mostrar nº de rendición'}`} placement="right">
                                    <button onClick={() => updateRen({ ...record, renSol: !record.renSol })}>
                                        <RetweetOutlined />
                                    </button>
                                </Tooltip>
                            }
                            {
                                record.numRD === currentLineRD &&
                                <Spin size='small' className='ml-2' />
                            }
                        </div>
                    }
                </div>,
        },
        {
            title: 'Tipo Ren.',
            dataIndex: 'tipo',
            width: 120,
        },
        {
            title: pendiente ? 'Fecha Sol.' : 'Fecha Ren.',
            dataIndex: pendiente ? 'fechaSol' : 'fechaRen',
            width: 92,
            render: (_, record) => <span>{formatDate(pendiente ? record.fechaSol : record.fechaRen)}</span>
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 50,
            align: 'center',
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            })
        },
        {
            title: pendiente ? 'Saldo' : 'Monto',
            dataIndex: pendiente ? 'saldo' : 'totRen',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(pendiente ? record.saldo : record.totRen)}</span>
        },
        {
            title: 'Area',
            dataIndex: 'area',
            width: 250,
            responsive: ['lg'],
            render: (_, record) => allGroup ? <span>{`${record.grpCode} ${record.area}`}</span> : <span>{record.area}</span>
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            width: 110,
        },
        {
            title: 'Rindente',
            dataIndex: 'rindente',
            width: 320,
            responsive: ['xl'],
            render: (_, record) => <span>{getUsu(record.rindente ?? record.solicitante)}</span>
        },
    ]

    return (
        <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
            <Drawer
                title={getTitleDrawer(selRendicion, selGrupo)}
                placement="right"
                onClose={handleCloseDrawer}
                open={openDrawer}
                getContainer={false}
                style={{ position: 'absolute' }}
                bodyStyle={{ padding: 20, }}
                headerStyle={{ padding: 15, }}
                destroyOnClose={true}
                maskClosable={false}
                zIndex={5}
                width={720}
                closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
                extra={
                    <Space>
                        {
                            (selRendicion && selRendicion.isRen && !selRendicion.isReview) ?
                                <>
                                    {
                                        selRendicion.isDet === 0 && activeTabRen === '1' &&
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`¿Confirma la operación?`}
                                            onConfirm={handleRegistrarRen}
                                            placement="left"
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button shape="round" type="primary">
                                                Guardar
                                            </Button>
                                        </Popconfirm>
                                    }
                                    {
                                        (selRendicion.isDet === 1 || (selRendicion.isDet === 0 && activeTabRen === '1' && selRendicion.numRD)) &&
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`¿Confirma la operación?`}
                                            onConfirm={handleEliminarRen}
                                            placement="left"
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button shape="round" type="primary">
                                                Eliminar
                                            </Button>
                                        </Popconfirm>
                                    }
                                    {
                                        selRendicion.isDet === 1 &&
                                        <>
                                            <Popconfirm
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                title={`¿Confirma la operación?`}
                                                onConfirm={handleEnviarRen}
                                                placement="left"
                                                okText="Sí"
                                                cancelText="No"
                                            >
                                                <Button shape="round" type="primary">
                                                    {foRevertir ? `Revertir envio` : `Enviar`}
                                                </Button>
                                            </Popconfirm>
                                            {
                                                !disablePage &&
                                                <Switch checkedChildren="REV" unCheckedChildren="REV" checked={foRevertir} onChange={setFoRevertir} />
                                            }
                                        </>
                                    }
                                </> :
                                <></>
                        }
                    </Space>
                }
            >
                {
                    selRendicion ?
                        <>
                            {
                                !selRendicion.isRen ?
                                    <DetalleSol ref={detSolRef} selReceptor={selReceptor} dbSolicitud={dbSolicitud} /> :
                                    <>
                                        {
                                            selRendicion.isDet === 0 ?
                                                <NewRen ref={newRenRef} usuario={usuario} selReceptor={selReceptor} selGrupo={selGrupo} selRendicion={selRendicion}
                                                    tiposRen={tiposRen} monedas={monedas} updateRen={handleSetRendicion} tabKey={handleActiveTabRen} /> :
                                                <>
                                                    {
                                                        selRendicion.isDet === 1 ?
                                                            <DetalleRen ref={detRenRef} selReceptor={selReceptor} selRendicion={selRendicion} /> :
                                                            <></>
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </> :
                        <></>
                }
            </Drawer>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex-grow'>
                        <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
                            <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
                            {
                                !allGroup &&
                                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
                            }
                            <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
                        </div>

                        <div className='p-3 flex flex-col ant-common-titania'>
                            <div className='flex flex-row flex-wrap space-x-3 mb-3'>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        !disablePage ?
                                            <div className='flex items-center cursor-pointer' onClick={handleNuevaRen}>
                                                <Tooltip title={pendiente ? "Nueva rendición" : "Editar rendición"} placement='right'>
                                                    {
                                                        pendiente ?
                                                            <FileAddOutlined className="mr-1.5 text-gray-600" aria-hidden="true" /> :
                                                            <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                                    }
                                                </Tooltip>
                                                {pendiente ? 'Nueva' : 'Editar'}
                                            </div>
                                            :
                                            <div className='flex items-center cursor-not-allowed'>
                                                {
                                                    pendiente ?
                                                        <FileAddOutlined className="mr-1.5 text-gray-600" aria-hidden="true" /> :
                                                        <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                                }
                                                {pendiente ? 'Nueva' : 'Editar'}
                                            </div>
                                    }
                                </div>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task8.id || task8.task?.message) ?
                                            <>
                                                {
                                                    (!disablePage && !pendiente) ?
                                                        <label
                                                            htmlFor="file-upload-docreg"
                                                            className="flex items-center relative cursor-pointer"
                                                        >
                                                            <Tooltip title="Cargar archivo de documento" placement='right'>
                                                                <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            <span>Cargar</span>
                                                            <input id="file-upload-docreg" name="file-upload-docreg" type="file" className="sr-only"
                                                                onChange={handleTask8_Add}
                                                                accept=".txt"
                                                            />
                                                        </label> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask8Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Cargar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task8.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask8Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask8_Down(task8.task)}
                                                            >
                                                                {task8.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    task8.task?.message &&
                                    <div className='flex items-center'>
                                        <p className="text-xs text-red-700">
                                            {task8.task?.message}
                                        </p>
                                    </div>
                                }
                            </div>

                            <p className="text-xs text-gray-700 mb-2">
                                {
                                    pendiente ?
                                        <span>Busca una solicitud de fondo usando los filtros de fecha y tipo de solicitud.</span> :
                                        <span>Busca una rendición usando los filtros de fecha o número de rendición.</span>
                                }
                            </p>
                            <div className='flex space-x-10'>
                                <div className='flex items-center space-x-2'>
                                    <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                                    <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                                    <Switch checkedChildren="PEND" unCheckedChildren="PEND" checked={pendiente} onChange={handlePendiente} />
                                    <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                                        {
                                            !pendiente &&
                                            <Select placeholder='Tipo' value={foTipoRen} allowClear style={{ width: 150 }} onChange={(v) => setFoTipoRen(v || null)}>
                                                {
                                                    tiposRen.map(({ codigo, nombre }) => (
                                                        <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        {
                                            loadConfigER ?
                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg> :
                                                <>
                                                    {
                                                        pendiente &&
                                                        <Select placeholder='Tipo' value={foTipoSol} allowClear style={{ width: 150 }} onChange={(v) => setFoTipoSol(v || null)}>
                                                            {
                                                                tiposSol.map(({ reqCode, reqName }) => (
                                                                    <Select.Option key={reqCode} value={reqCode}>{reqName}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    }
                                                    <Select placeholder='Area' value={foArea} allowClear style={{ width: 200 }} onChange={(v) => setFoArea(v || null)}>
                                                        {
                                                            areas.map(({ areaCode, areaName }) => (
                                                                <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </>
                                        }
                                        {
                                            !pendiente &&
                                            <>
                                                <Select placeholder='Estado' value={foEstado} allowClear style={{ width: 135 }} onChange={(v) => setFoEstado(v || null)}>
                                                    {
                                                        estados.map(({ estadoID, nombre }) => (
                                                            <Select.Option key={estadoID} value={nombre}>{nombre}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                                <Input placeholder="Nº de solicitud" style={{ width: 135 }} onChange={(e) => setFoNumSO(e.target.value)} />
                                            </>
                                        }
                                    </div>
                                    {
                                        !disablePage ?
                                            <Tooltip title={pendiente ? "Buscar solicitud" : "Buscar rendición"}>
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarRDFecha} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                                {
                                    !pendiente &&
                                    <div className='flex items-center space-x-2'>
                                        <Input placeholder="Nº de rendición" style={{ width: 135 }} onChange={(e) => setFoNumRD(e.target.value)} />
                                        {
                                            !disablePage ?
                                                <Tooltip title="Buscar rendición">
                                                    <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarRDNum} />
                                                </Tooltip> :
                                                <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                        }
                                    </div>
                                }
                            </div>

                            <div className='lg:max-w-7xl'>
                                {
                                    loadBusqRen ?
                                        <div className='my-5 flex justify-center'>
                                            <Spin tip='Loading....' size='large' />
                                        </div> :
                                        <>
                                            <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-fnregren'>
                                                <Table
                                                    rowSelection={rowSelection}
                                                    columns={rdColumns}
                                                    dataSource={rendiciones}
                                                    pagination={false}
                                                    scroll={{ y: 400 }}
                                                    bordered
                                                    size='small'
                                                />
                                            </div>
                                            <div className='mt-2 flex justify-end ant-pagination-titania'>
                                                <Pagination
                                                    defaultCurrent={rdCurrentPage}
                                                    total={rdTotalDocuments}
                                                    showSizeChanger
                                                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} ${pendiente ? 'solicitudes' : 'rendiciones'}`}
                                                    defaultPageSize={rdPageSize}
                                                    onChange={handleChangePagRD}
                                                    onShowSizeChange={(_, size) => setRdPageSize(size)}
                                                    disabled={disablePage} />
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
