import moment from 'moment'
import React, { createContext, useState } from 'react'
import { getAuth } from '../api/apiUtil'
import { lstorageType } from '../types/lstorageType'
import { getLastAccess } from '../utils/lstorageUtil'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(init())

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

const init = () => {
    const authItem = JSON.parse(localStorage.getItem(lstorageType.hoUseAuth))
    if (authItem) {
        const lastAccess = getLastAccess()
        const { remember, idleTime, ropag } = authItem
        if (lastAccess && idleTime && ropag) {
            const lastDate = moment(lastAccess).add(idleTime, 'minutes').toDate()
            if (Date.now() < lastDate.getTime() || remember)
                return authItem
        }
    }
    return getAuth(false)
}