import { CheckCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'

export const LydOcp = () => {
    return (
        <>
            <p className='pl-1 mb-1 text-xs font-normal text-gray-800'>Sufijo de estado</p>
            <div className="flex items-center">
                <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                <p className="ml-2 text-xs font-medium text-gray-500">
                    {`(R)`}<span className='ml-1'>Documento con recepción</span>
                </p>
            </div>
            <div className="flex items-center">
                <CheckCircleIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />
                <p className="ml-2 text-xs font-medium text-gray-500">
                    {`(C)`}<span className='ml-1'>Documento con comprobante</span>
                </p>
            </div>
        </>
    )
}
