import './NewFon.css'

import { QuestionCircleOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import { Col, DatePicker, Form, Input, InputNumber, Popconfirm, Row, Select, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../../hooks/useAxiosCto'
import { solAtenderUrl, solGetNumUrl, storageTCambioUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { setLastAccess } from '../../../../utils/lstorageUtil'

export const NewFon = (props) => {
    const { selReceptor, selSolicitud, showNotify, updateSol } = props

    const dvFechaReq = moment()
    const dvMonto = 0.0
    const dvMotivo = null

    const { axiosTnaGet, axiosTnaPost, axiosTnaDelete } = useAxiosTna()
    const { axiosCtoGet } = useAxiosCto()
    const [form] = Form.useForm()
    const [componentDisabled, setComponentDisabled] = useState(false)
    const [loadRegFon, setLoadRegFon] = useState(false)
    const [loadEliFon, setLoadEliFon] = useState(false)
    const [fondos, setFondos] = useState([])
    const [selFondo, setSelFondo] = useState(null)
    const [fmFechaReq, setFmFechaReq] = useState(dvFechaReq)
    const [fmMonto, setFmMonto] = useState(dvMonto)
    const [fmMotivo, setFmMotivo] = useState(dvMotivo)

    useEffect(() => {
        setComponentDisabled(loadRegFon || loadEliFon)
    }, [loadRegFon, loadEliFon])

    useEffect(() => {
        if (selSolicitud) {
            if (selSolicitud.fondos) {
                const afon = selSolicitud.fondos.map((fon, index) => ({ ...fon, key: index, label: `Fondo ${fon.line + 1}` }))
                const sfon = afon.length > 0 ? afon[afon.length - 1] : null
                setFondos(afon)
                setSelFondo(sfon)
            }
        }
    }, [selSolicitud])

    useEffect(() => {
        if (selFondo) {
            const fechaReq = moment(selFondo.fechaReq)

            setFmFechaReq(fechaReq)
            setFmMonto(selFondo.monto)
            setFmMotivo(selFondo.motivo)
            form.setFieldsValue({
                fechaReq: fechaReq,
                monto: selFondo.monto,
                motivo: selFondo.motivo,
            })
        }
        else {
            setFmFechaReq(dvFechaReq)
            setFmMonto(dvMonto)
            setFmMotivo(dvMotivo)
            form.resetFields()
        }
        // eslint-disable-next-line
    }, [selFondo])

    const refreshSol = async (rcpt, sol) => {
        const url = solGetNumUrl(rcpt.ruc, sol.numSO)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Solicitud: ${err.message}`)
        const nsol = data?.success ? data.data : null
        return nsol
    }

    const onValuesChange = (changedValues, allValues) => {
        const fechaReq = allValues.fechaReq ?? null
        const monto = allValues.monto ?? null
        const motivo = allValues.motivo ?? null

        setFmFechaReq(fechaReq)
        setFmMonto(monto)
        setFmMotivo(motivo)
    }

    const handleChangeFondo = (value, afon) => {
        const sfon = afon.find(p => p.line === value)
        setSelFondo(sfon)
    }

    const handleRegFon = async () => {
        let err = null
        let data = null

        if (componentDisabled)
            return;

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selSolicitud)
            err = {
                message: msgType.selectSolicitud,
                oops: false
            }
        else if (!fmFechaReq)
            err = {
                message: `${msgType.inputFechaReq}`,
                oops: false
            }
        else if (!fmMonto)
            err = {
                message: `${msgType.inputMontoSol}`,
                oops: false
            }
        else if (!fmMotivo)
            err = {
                message: `${msgType.inputMotivoSol}`,
                oops: false
            }
        else if (fmFechaReq.toDate().getTime() < moment(selSolicitud.fechaSol).toDate().getTime())
            err = {
                message: `${msgType.koDataFechaReq}`,
                oops: false
            }
        else if (fmMonto <= 0.0)
            err = {
                message: `${msgType.koDataMontoSol}`,
                oops: false
            }
        else if (fmMotivo.trim() === '')
            err = {
                message: `${msgType.koDataMotivoSol}`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadRegFon(true)

        const motivo = fmMotivo.trim()
        const fechaReq = fmFechaReq.format().split('T')[0] + 'T00:00:00'
        let tipoCambio = null

        if (selSolicitud.monedaISO !== configType.monedaISOPEN) {
            const fecha = moment().format().split('T')[0]
            const url = storageTCambioUrl(selSolicitud.monedaISO, fecha)
            const [data_api, err_api] = await axiosCtoGet(url)
            if (data_api)
                tipoCambio = data_api.tcVenta
            if (err_api)
                console.log(`Tipo de cambio: ${err_api}`)
        }

        const url = solAtenderUrl(selSolicitud.id)
        const body = { tipoCambio, monto: fmMonto, fechaReq, motivo }
        const [data_api, err_api] = await axiosTnaPost(url, body)
        data = data_api
        err = err_api

        if (data && data.success) {
            showNotify({
                message: `${msgType.fondoRegistrar} ${selSolicitud.numSO}`,
                oops: false
            }, notifyType.success)
            const nsol = await refreshSol(selReceptor, selSolicitud)
            if (nsol && updateSol)
                updateSol(nsol)
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadRegFon(false)
        setLastAccess()
    }

    const handleEliFon = async () => {
        let err = null
        let data = null

        if (!selSolicitud)
            err = {
                message: msgType.selectSolicitud,
                oops: false
            }
        else if (!selFondo)
            err = {
                message: msgType.selectFondo,
                oops: false
            }
        else if (selFondo.line !== fondos[fondos.length - 1]?.line)
            err = {
                message: msgType.selectFondoU,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadEliFon(true)

        const url = solAtenderUrl(selSolicitud.id)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data && data.success) {
            showNotify({
                message: msgType.fondoEliminar,
                oops: false
            }, notifyType.success)
            const afon = fondos.filter(p => p.line !== selFondo.line)
            setFondos(afon)
            setSelFondo(null)
            const nsol = await refreshSol(selReceptor, selSolicitud)
            if (nsol && updateSol)
                updateSol(nsol, false)
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadEliFon(false)
        setLastAccess()
    }

    return (
        <div className='ant-common-titania'>
            <div className='flex justify-between mb-2.5'>
                <div className='flex space-x-2'>
                    <Select placeholder='Nuevo fondo' style={{ width: 150 }} value={selFondo?.line}
                        onChange={(v) => handleChangeFondo(v, fondos)} allowClear disabled={componentDisabled}>
                        {
                            fondos.map(({ key, line, label }) => (
                                <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                        }
                    </Select>
                </div>
                <div className="flex flex-row justify-end space-x-3 mx-1">
                    {
                        !selFondo &&
                        <div className="flex items-center text-sm text-indigo-700">
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={`¿Confirma la operación?`}
                                onConfirm={handleRegFon}
                                placement="left"
                                okText="Sí"
                                cancelText="No"
                                disabled={componentDisabled}
                            >
                                <div className='flex items-center cursor-pointer'>
                                    <Tooltip title="Guardar fondo" placement='left'>
                                        <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                    </Tooltip>
                                    Guardar
                                </div>
                            </Popconfirm>
                        </div>
                    }
                    {
                        selFondo &&
                        <div className="flex items-center text-sm text-indigo-700">
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={`¿Confirma la operación?`}
                                onConfirm={handleEliFon}
                                placement="left"
                                okText="Sí"
                                cancelText="No"
                                disabled={componentDisabled}
                            >
                                <div className='flex items-center cursor-pointer'>
                                    <Tooltip title="Eliminar fondo" placement='left'>
                                        <DeleteOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                    </Tooltip>
                                    Eliminar
                                </div>
                            </Popconfirm>
                        </div>
                    }
                </div>
            </div>

            <Form
                layout="vertical" requiredMark
                onValuesChange={onValuesChange}
                disabled={componentDisabled || selFondo}
                autoComplete="off"
                form={form}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="fechaReq"
                            label="Fecha requerida"
                            initialValue={dvFechaReq}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <DatePicker placeholder='Ingrese fecha requerida'
                                getPopupContainer={(trigger) => trigger.parentElement}
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="monto"
                            label={`Monto solicitado (${selSolicitud?.monedaISO})`}
                            initialValue={dvMonto}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder='Ingrese monto solicitado'
                                className='text-xs w-36'
                                size='middle'
                                maxLength={15}
                                bordered={true} precision={2}
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="motivo"
                            label="Motivo de solicitud"
                            initialValue={dvMotivo}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <Input.TextArea
                                rows={2}
                                maxLength={500}
                                placeholder="Ingrese motivo de solicitud" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        </div>
    )
}
