import { HomeIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setLastAccess } from '../../utils/lstorageUtil'

export const Breadcrumb = (props) => {
    const { page: { name: pageName, href, parent } } = props

    const navigate = useNavigate()
    const [pages, setPages] = useState([])

    useEffect(() => {
        let isMounted = true

        const data = [
            { name: 'Panel', href: '/panel', current: false }
        ]
        if (parent)
            data.push({ name: parent, href: null, current: false })
        data.push({ name: pageName, href, current: true })

        isMounted && setPages(data)

        return () => {
            isMounted = false
        }
    }, [pageName, href, parent])

    const handleNavigate = (nav) => {
        setLastAccess()
        navigate(nav.href)
    }

    return (
        <>
            <nav className="flex border-b border-gray-200 bg-white" aria-label="Breadcrumb">
                <ol className="mx-auto flex w-full space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <button
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => navigate('/')}
                            >
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                <span className="sr-only">Home</span>
                            </button>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                </svg>
                                {
                                    page.href ?
                                        <button
                                            className={"ml-4 text-sm font-medium " + (page.current ? "text-indigo-500 hover:text-indigo-700" : "text-gray-500 hover:text-gray-700")}
                                            aria-current={page.current ? 'page' : undefined}
                                            onClick={() => handleNavigate(page)}
                                        >
                                            {page.name}
                                        </button> :
                                        <button
                                            className="ml-4 text-sm font-medium text-gray-500 cursor-default"
                                            aria-current={page.current ? 'page' : undefined}
                                        >
                                            {page.name}
                                        </button>

                                }
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>
        </>
    )
}
