import moment from "moment"
import { lstorageType } from "../types/lstorageType"

export const setLastAccess = () => {
    try {
        const lastAccess = moment(new Date(Date.now())).unix()
        localStorage.setItem(lstorageType.daLastAccess, lastAccess)
    } catch (error) {
        console.log(error)
    }
}

export const getLastAccess = () => {
    const lastAccess = localStorage.getItem(lstorageType.daLastAccess) || null
    if (lastAccess)
        return moment.unix(lastAccess).toDate()
    return lastAccess
}