import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useTokenCto } from "./useTokenCto";
import { axiosCto } from "../api/axiosCto";

const useInterceptCto = () => {
    const refresh = useTokenCto()
    const { auth } = useAuth()

    useEffect(() => {
        const requestIntercept = axiosCto.interceptors.request.use(
            config => {
                if (!config.headers['Authorization'])
                    config.headers['Authorization'] = `Bearer ${auth?.tokenCto ?? ''}`
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosCto.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true
                    const newTokenCto = await refresh()
                    prevRequest.headers['Authorization'] = `Bearer ${newTokenCto}`
                    return axiosCto(prevRequest)
                }
                return Promise.reject(error)
            }
        )

        return () => {
            axiosCto.interceptors.request.eject(requestIntercept)
            axiosCto.interceptors.response.eject(responseIntercept)
        }
        // eslint-disable-next-line
    }, [auth])

    return axiosCto
}

export default useInterceptCto