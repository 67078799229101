import './NewSol.css'

import { SyncOutlined, CloudUploadOutlined, CloudDownloadOutlined, QuestionCircleOutlined, DeleteFilled } from '@ant-design/icons'
import { AutoComplete, Button, Col, DatePicker, Form, Input, InputNumber, Popconfirm, Row, Select, Spin, Table, Tabs, Tooltip } from 'antd'
import moment from 'moment'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../../hooks/useAxiosCto'
import { catGetCodUrl, rctEntrRendUrl, segConnUrl, solAdjuntarUrl, solGetNumUrl, solRecuperarUrl, solRegistrarUrl, storageTCambioUrl, usuGetAcerUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { downloadFile, viewerFile } from '../../../../utils/fileUtil'
import { NotifyYellow } from '../../../Msg/NotifyYellow'
import { NotifyRed } from '../../../Msg/NotifyRed'
import { NotifyGreen } from '../../../Msg/NotifyGreen'
import { NewFon } from './NewFon'
import { NewDev } from './NewDev'

var jszip = require('jszip')

export const NewSol = forwardRef((props, ref) => {
    const { usuario, selReceptor, selGrupo, selSolicitud, tiposSol, monedas, updateSol, tabKey } = props

    const dvRegistrado = selSolicitud?.numSO
    const dvFondo = selSolicitud?.numSO && selSolicitud.fondos && selSolicitud.fondos.length > 0 ? selSolicitud.fondos[0] : null
    const dvTipoSol = selSolicitud?.numSO ? tiposSol.find(p => p.reqCode === selSolicitud.reqCode) : tiposSol.find(() => true)
    const dvFechaSol = selSolicitud?.numSO ? moment(selSolicitud.fechaSol) : moment()
    const dvMoneda = selSolicitud?.numSO ? monedas.find(p => p.codigo === selSolicitud.monedaISO) : monedas.find(() => true)
    const dvFechaReq = dvFondo ? moment(dvFondo.fechaReq) : moment()
    const dvMonto = dvFondo ? dvFondo.monto : 0.0
    const dvMotivo = dvFondo ? dvFondo.motivo : null

    const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
    const { axiosCtoGet } = useAxiosCto()
    const [form] = Form.useForm()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [loadPage, setLoadPage] = useState(false)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadConfigER, setLoadConfigER] = useState(false)
    const [loadTipoPago, setLoadTipoPago] = useState(true)
    const [componentDisabled, setComponentDisabled] = useState(false)
    const [registrado, setRegistrado] = useState(dvRegistrado)
    const [loadUsuario, setLoadUsuario] = useState(false)
    const [loadFile, setLoadFile] = useState(false)
    const [loadRegSol, setLoadRegSol] = useState(false)
    const [usuarios, setUsuarios] = useState([])
    const [tiposPago, setTiposPago] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [areas, setAreas] = useState([])
    const [ccosto, setCcosto] = useState([])
    const [adjuntos, setAdjuntos] = useState([])
    const [fmTipoSol, setFmTipoSol] = useState(dvTipoSol)
    const [fmFechaSol, setFmFechaSol] = useState(dvFechaSol)
    const [fmArea, setFmArea] = useState(null)
    const [fmFechaReq, setFmFechaReq] = useState(dvFechaReq)
    const [fmMoneda, setFmMoneda] = useState(dvMoneda)
    const [fmMonto, setFmMonto] = useState(dvMonto)
    const [fmMotivo, setFmMotivo] = useState(dvMotivo)
    const [opcionesUsu, setOpcionesUsu] = useState([])
    const [selRindente, setSelRindente] = useState(null)
    const [dvSearchUsu, setDvSearchUsu] = useState(null)
    const [currentLineFI, setCurrentLineFI] = useState(null)
    const [activeTabKey, setActiveTabKey] = useState("1")

    useEffect(() => {
        setLoadPage(loadTokenConn || loadConfigER || loadTipoPago)
    }, [loadTokenConn, loadConfigER, loadTipoPago])

    useEffect(() => {
        setComponentDisabled(registrado || loadUsuario || loadFile || loadRegSol)
    }, [registrado, loadUsuario, loadFile, loadRegSol])

    useEffect(() => {
        setAdjuntos(selSolicitud?.attach ? selSolicitud.attach.filter(p => !selSolicitud.devoluciones || !selSolicitud.devoluciones.find(q => q.dataOrder === p.dataOrder)).map(p => ({ ...p, key: p.dataOrder })) : [])
    }, [selSolicitud])

    useEffect(() => {
        const area = selSolicitud?.areaCode ? areas.find(p => p.areaCode === selSolicitud.areaCode) : areas.find(() => true)
        setFmArea(area)
        form.setFieldsValue({
            area: area?.areaCode,
        })
    }, [selSolicitud, areas, form])

    useEffect(() => {
        const getCcosto = (ccos, index, sol) => {
            const scc = sol?.ccosto?.find(p => p.dimID === ccos.dimID) || null
            const code = scc?.codeID ? ccos.codes.find(p => p.codeID === scc.codeID) : null
            const aops = code?.codeID ? searchResultCcos(code.codeID, ccos.codes) : []
            const ncc = { ...ccos, index, code, opciones: aops, dvSearch: code?.codeID }
            return ncc
        }

        if (fmTipoSol && fmArea)
            setCcosto(fmArea.ccosto ? fmArea.ccosto.map((p, index) => getCcosto(p, index, selSolicitud)) : [])
        else
            setCcosto([])
        // eslint-disable-next-line
    }, [selSolicitud, fmTipoSol, fmArea])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const configER = async () => {
            isMounted && setLoadConfigER(true)

            const url = rctEntrRendUrl(selReceptor.ruc, selSolicitud?.grpCode ?? selGrupo?.ruc ?? '', true, true, (selSolicitud?.numSO ? (selSolicitud?.solicitante?.userID ?? '') : ''))
            const [data, err] = await axiosTnaGet(url)

            if (data) {
                const { success, data: config } = data
                if (success) {
                    const aare = config?.areas?.filter(p => p.asignada) || []
                    const acta = config?.cuentas || []
                    if (isMounted) {
                        setAreas(aare)
                        setCuentas(acta)
                    }
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadConfigER(false)
        }

        const catTipoPag = async () => {
            const url = catGetCodUrl(configType.catTipoPago)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposPago(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoPago(false)
        }

        if (!loadTokenConn) {
            configER()
            catTipoPag()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const acerUsuario = async () => {
            isMounted && setLoadUsuario(true)

            const url = usuGetAcerUrl(selReceptor.ruc, selSolicitud?.grpCode ?? selGrupo?.ruc ?? '', true, true, fmArea.areaCode)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.totalDocuments > 0) {
                const ausu = data.data.map(p => ({
                    ...p, value: `${p.docIdentidad?.documento}`, label: `${p.docIdentidad?.denominacion}`
                }))
                const usu = ausu.find(p => p.userID === (selSolicitud?.rindente?.userID ?? selSolicitud?.solicitante?.userID ?? usuario.userID))
                const aops = searchResultUsu(usu?.value, ausu)
                if (isMounted) {
                    setUsuarios(ausu)
                    setOpcionesUsu(aops)
                    setSelRindente(usu)
                    setDvSearchUsu(usu?.value)
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadUsuario(false)
        }

        if (fmArea)
            acerUsuario()
        else if (isMounted) {
            setUsuarios([])
            setOpcionesUsu([])
            setSelRindente(null)
            setDvSearchUsu(null)
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [fmArea])

    useImperativeHandle(
        ref,
        () => ({
            handleSave() {
                handleRegSol()
            },
            showNotify(err, type) {
                showNotifySol(err, type)
            }
        })
    )

    const refreshSol = async (rcpt, sol) => {
        const url = solGetNumUrl(rcpt.ruc, sol.numSO)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Solicitud: ${err.message}`)
        const nsol = data?.success ? data.data : null
        return nsol
    }

    const onValuesChange = (changedValues, allValues) => {
        const tipoSol = tiposSol.find(p => p.reqCode === allValues.tipoSol) || null
        const fechaSol = allValues.fechaSol ?? null
        const area = areas.find(p => p.areaCode === allValues.area) || null
        const fechaReq = allValues.fechaReq ?? null
        const moneda = monedas.find(p => p.codigo === allValues.monedaISO) || null
        const monto = allValues.monto ?? null
        const motivo = allValues.motivo ?? null

        setFmTipoSol(tipoSol)
        setFmFechaSol(fechaSol)
        setFmArea(area)
        setFmFechaReq(fechaReq)
        setFmMoneda(moneda)
        setFmMonto(monto)
        setFmMotivo(motivo)
    }

    const sortCcosto = (a, b) => {
        if (a.index > b.index)
            return 1
        else
            return -1
    }

    const clearFile = () => {
        const input = document.getElementById("file-upload")
        if (input)
            input.value = null
    }

    const showNotifySol = (err, type) => {
        if (type === notifyType.warning)
            notifyYellowRef.current.handleOpen(err, type)
        else if (type === notifyType.error)
            notifyRedRef.current.handleOpen(err, type)
        else if (type === notifyType.success)
            notifyGreenRef.current.handleOpen(err, type)
    }

    const compareText = (field, value) => {
        if (value === undefined)
            return false
        else {
            const nvalue = value.toUpperCase()
            const nfield = field.toUpperCase()
            return nfield.indexOf(nvalue) !== -1
        }
    }

    const searchResultUsu = (value, ausu) => {
        const nusu = ausu.filter(p => p.docIdentidad && (value === configType.searchAllCode || compareText(p.userID, value) || compareText(p.userName, value) || compareText(p.docIdentidad.documento, value) || compareText(p.docIdentidad.denominacion, value)))
        const aops = nusu.map(p => ({
            value: p.value,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>{`${p.docIdentidad.documento} - ${p.docIdentidad.denominacion}`}</span>
                    <span>{`${p.userID}`}</span>
                </div>
            ),
        }))
        return aops
    }

    const searchResultCcos = (value, acod) => {
        const ncod = acod.filter(p => value === configType.searchAllCode || compareText(p.codeID, value) || compareText(p.codeName, value))
        const aops = ncod.map(p => ({
            value: p.codeID,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>{`${p.codeName}`}</span>
                    <span>{`${p.codeID}`}</span>
                </div>
            ),
        }))
        return aops
    }

    const handleActiveTabKey = (activeKey) => { setActiveTabKey(activeKey); if (tabKey) tabKey(activeKey) }

    const handleSearchUsu = (value, ausu) => setOpcionesUsu(value ? searchResultUsu(value, ausu) : [])
    const handleChangeUsu = (value) => { setSelRindente(null); setDvSearchUsu(value) }
    const handleSelectUsu = (value, ausu) => { setSelRindente(usuarios.find(p => p.value === value) || null); setOpcionesUsu(value ? searchResultUsu(value, ausu) : []) }

    const handleSearchCcos = (value, ccos, prev) => {
        const aops = value ? searchResultCcos(value, ccos.codes) : []
        const acos = prev.filter(p => p.dimID !== ccos.dimID)
        const ncos = [...acos, { ...ccos, opciones: aops, code: null, dvSearch: value }]
        ncos.sort(sortCcosto)
        setCcosto(ncos)
    }

    const handleSelectCcos = (value, ccos, prev) => {
        const code = ccos.codes.find(p => p.codeID === value) || null
        const aops = value ? searchResultCcos(value, ccos.codes) : []
        const acos = prev.filter(p => p.dimID !== ccos.dimID)
        const ncos = [...acos, { ...ccos, opciones: aops, code, dvSearch: value }]
        ncos.sort(sortCcosto)
        setCcosto(ncos)
    }

    const handleRegSol = async () => {
        let err = null
        let data = null

        if (componentDisabled)
            return;

        if (!usuario)
            err = {
                message: msgType.noDataUser,
                oops: false
            }
        else if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!fmTipoSol)
            err = {
                message: `${msgType.selectTipoSol}`,
                oops: false
            }
        else if (!fmFechaSol)
            err = {
                message: `${msgType.inputFechaSol}`,
                oops: false
            }
        else if (!fmArea)
            err = {
                message: `${msgType.selectArea}`,
                oops: false
            }
        else if (!fmFechaReq)
            err = {
                message: `${msgType.inputFechaReq}`,
                oops: false
            }
        else if (!fmMoneda)
            err = {
                message: `${msgType.selectMoneda}`,
                oops: false
            }
        else if (!fmMonto)
            err = {
                message: `${msgType.inputMontoSol}`,
                oops: false
            }
        else if (!fmMotivo)
            err = {
                message: `${msgType.inputMotivoSol}`,
                oops: false
            }
        else if (fmArea.porOtro && !selRindente)
            err = {
                message: `${msgType.selectRindente}`,
                oops: false
            }
        else if (fmTipoSol.ccostoAct && !ccosto)
            err = {
                message: `${msgType.noDataCcosto}`,
                oops: false
            }
        else if (moment().toDate().getTime() < fmFechaSol.toDate().getTime())
            err = {
                message: `${msgType.koDataFechaSol}`,
                oops: false
            }
        else if (fmFechaReq.toDate().getTime() < fmFechaSol.toDate().getTime())
            err = {
                message: `${msgType.koDataFechaReq}`,
                oops: false
            }
        else if (fmMonto <= 0.0)
            err = {
                message: `${msgType.koDataMontoSol}`,
                oops: false
            }
        else if (fmMotivo.trim() === '')
            err = {
                message: `${msgType.koDataMotivoSol}`,
                oops: false
            }
        else {
            const cos = fmTipoSol.ccostoAct ? ccosto.find(p => p.required && !p.code) : null
            if (cos)
                err = {
                    message: `${cos.dimName} requerido(a).`,
                    oops: false
                }
        }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadRegSol(true)

        const motivo = fmMotivo.trim()
        const fechaSol = fmFechaSol.format().split('T')[0] + 'T00:00:00'
        const fechaReq = fmFechaReq.format().split('T')[0] + 'T00:00:00'
        const acos = fmTipoSol.ccostoAct ? ccosto.filter(p => p.code).map(p => ({ dimID: p.dimID, codeID: p.code.codeID })) : null
        let tipoCambio = null

        if (fmMoneda.codigo !== configType.monedaISOPEN) {
            const fecha = moment().format().split('T')[0]
            const url = storageTCambioUrl(fmMoneda.codigo, fecha)
            const [data_api, err_api] = await axiosCtoGet(url)
            if (data_api)
                tipoCambio = data_api.tcVenta
            if (err_api)
                console.log(`Tipo de cambio: ${err_api}`)
        }

        const url = solRegistrarUrl()
        const body = {
            rcptNumDoc: selReceptor.ruc, grupo: selGrupo.ruc, tipo: fmTipoSol.reqCode,
            area: fmArea.areaCode, rindente: selRindente?.userID ?? usuario.userID, fechaSol,
            monedaISO: fmMoneda.codigo, tipoCambio, monto: fmMonto, fechaReq, motivo,
            ccosto: acos
        }
        const [data_api, err_api] = await axiosTnaPost(url, body)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: `${msgType.solicitudRegistrar} ${data.numSO}`,
                oops: false
            }, notifyType.success)
            setRegistrado(true)
            const nsol = await refreshSol(selReceptor, data)
            if (nsol && updateSol)
                updateSol(nsol)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadRegSol(false)
        setLastAccess()
    }

    const handleUpFile = async ({ target: { files } }) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.fileKoPeso,
                oops: false
            }
        else if (!selSolicitud?.numSO)
            err = {
                message: msgType.noRegSolicitud,
                oops: false
            }
        else if (!selSolicitud.allowPut)
            err = {
                message: msgType.solicitudNoAdj,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearFile()
            return
        }

        setLoadFile(true)

        const formData = new FormData()
        formData.append("", file)

        const url = solAdjuntarUrl(selSolicitud.id)
        const [data_api, err_api] = await axiosTnaPut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            const nsol = await refreshSol(selReceptor, selSolicitud)
            if (nsol && updateSol)
                updateSol(nsol)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        clearFile()
        setLoadFile(false)
        setLastAccess()
    }

    const handleDelFile = async (record) => {
        let err = null
        let data = null

        if (!selSolicitud?.numSO)
            err = {
                message: msgType.noRegSolicitud,
                oops: false
            }
        else if (!selSolicitud.allowPut)
            err = {
                message: msgType.rendicionNoExc,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setCurrentLineFI(record.dataOrder)

        const url = solAdjuntarUrl(selSolicitud.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data && data.success) {
            const nsol = await refreshSol(selReceptor, selSolicitud)
            if (nsol && updateSol)
                updateSol(nsol)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = solRecuperarUrl(selSolicitud.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex space-x-1 items-center'>
                        <button
                            className='text-blue-600 text-left'
                            disabled={record.dataOrder === currentLineFI}
                            onClick={() => handleDownFile(record, false)}
                        >
                            {`${record.fileName}`}
                        </button>
                        {
                            record.fileType &&
                            <span>{`- ${record.fileType}`}</span>
                        }
                    </div>
                    <div className='flex justify-end items-center'>
                        {
                            record.dataOrder !== currentLineFI &&
                            <>
                                <button onClick={() => handleDownFile(record, true)}>
                                    <CloudDownloadOutlined />
                                </button>
                                {
                                    !record.required &&
                                    <Popconfirm placement="left"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        title={`¿Confirma la operación?`}
                                        onConfirm={() => handleDelFile(record)}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <button className='ml-2'>
                                            <DeleteFilled className='text-red-400' />
                                        </button>
                                    </Popconfirm>
                                }
                            </>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='ml-2' />
                        }
                    </div>
                </div>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' size='large' />
                </div>
            }
            <div className='ant-tabs-titania ant-common-titania'>
                <Tabs
                    defaultActiveKey="1"
                    hidden={loadPage}
                    activeKey={activeTabKey}
                    onChange={handleActiveTabKey}
                    items={[
                        {
                            label: `Data`,
                            key: '1',
                            children:
                                <>
                                    <Form
                                        layout="vertical" requiredMark
                                        onValuesChange={onValuesChange}
                                        disabled={componentDisabled}
                                        autoComplete="off"
                                        hidden={loadPage}
                                        form={form}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="tipoSol"
                                                    label="Tipo de solicitud"
                                                    initialValue={dvTipoSol?.reqCode}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione tipo de solicitud"
                                                        allowClear>
                                                        {
                                                            tiposSol.map(({ reqCode, reqName }) => (
                                                                <Select.Option key={reqCode} value={reqCode}>{reqName}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="fechaSol"
                                                    label="Fecha de solicitud"
                                                    initialValue={dvFechaSol}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker placeholder='Ingrese fecha de solicitud'
                                                        getPopupContainer={(trigger) => trigger.parentElement}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="area"
                                                    label="Area"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione área"
                                                        allowClear>
                                                        {
                                                            areas.map(({ areaCode, areaName }) => (
                                                                <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="monedaISO"
                                                    label="Moneda"
                                                    initialValue={dvMoneda?.codigo}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione moneda"
                                                        allowClear>
                                                        {
                                                            monedas.map(({ codigo }) => (
                                                                <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} hidden={!!selSolicitud?.numSO}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="fechaReq"
                                                    label="Fecha requerida"
                                                    initialValue={dvFechaReq}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker placeholder='Ingrese fecha requerida'
                                                        getPopupContainer={(trigger) => trigger.parentElement}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="monto"
                                                    label="Monto solicitado"
                                                    initialValue={dvMonto}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        placeholder='Ingrese monto solicitado'
                                                        className='text-xs w-36'
                                                        size='middle'
                                                        maxLength={15}
                                                        bordered={true} precision={2}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} hidden={!!selSolicitud?.numSO}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="motivo"
                                                    label="Motivo de solicitud"
                                                    initialValue={dvMotivo}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea
                                                        rows={2}
                                                        maxLength={500}
                                                        placeholder="Ingrese motivo de solicitud" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {
                                        !loadPage &&
                                        <>
                                            {
                                                fmArea && fmArea.porOtro &&
                                                <div className='mb-2'>
                                                    <div className=' mb-1'>
                                                        <span className='span-requerido-enr-newsol'>*</span>
                                                        <span>Rindente</span>
                                                    </div>
                                                    <div className='flex space-x-0.5'>
                                                        <AutoComplete
                                                            dropdownMatchSelectWidth={500}
                                                            style={{ width: 230, }}
                                                            options={opcionesUsu}
                                                            onSearch={(v) => handleSearchUsu(v, usuarios)}
                                                            onChange={handleChangeUsu}
                                                            onSelect={(v) => handleSelectUsu(v, usuarios)}
                                                            value={dvSearchUsu}
                                                            disabled={componentDisabled}
                                                        >
                                                            <Input.Search size="middle" placeholder="Ingrese filtro" />
                                                        </AutoComplete>
                                                        <Input readOnly placeholder='Resultado de búsqueda' value={selRindente?.label} />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                ccosto.length > 0 && fmTipoSol?.ccostoAct &&
                                                ccosto.map((ccos) => (
                                                    <div key={ccos.index} className='mb-2'>
                                                        <div className=' mb-1'>
                                                            {
                                                                ccos.required &&
                                                                <span className='span-requerido-enr-newsol'>*</span>
                                                            }
                                                            <span>{ccos.dimName}</span>
                                                        </div>
                                                        <div className='flex space-x-0.5'>
                                                            <AutoComplete
                                                                dropdownMatchSelectWidth={500}
                                                                style={{ width: 230, }}
                                                                options={ccos.opciones}
                                                                onSearch={(v) => handleSearchCcos(v, ccos, ccosto)}
                                                                onSelect={(v) => handleSelectCcos(v, ccos, ccosto)}
                                                                value={ccos.dvSearch}
                                                                disabled={componentDisabled}
                                                            >
                                                                <Input.Search size="middle" placeholder="Ingrese filtro" />
                                                            </AutoComplete>
                                                            <Input readOnly placeholder='Resultado de búsqueda' value={ccos.code?.codeName} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }
                                    <div className='flex justify-center mt-4'>
                                        {
                                            loadRegSol &&
                                            <Tooltip title="Procesando" placement="top">
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<SyncOutlined spin />}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                </>
                        },
                        {
                            label: `Fondo`,
                            key: '2',
                            disabled: !selSolicitud?.numSO,
                            children:
                                <NewFon selReceptor={selReceptor} selSolicitud={selSolicitud} showNotify={showNotifySol} updateSol={updateSol} />
                        },
                        {
                            label: `Devolución`,
                            key: '3',
                            disabled: !selSolicitud?.numSO,
                            children:
                                <NewDev selReceptor={selReceptor} selSolicitud={selSolicitud} cuentas={cuentas} tiposPago={tiposPago} showNotify={showNotifySol} updateSol={updateSol} />
                        },
                        {
                            label: `Adjunto`,
                            key: '4',
                            disabled: !selSolicitud?.numSO,
                            children:
                                <>
                                    {
                                        adjuntos.length > 0 &&
                                        <div className='mb-3 ant-table-titania'>
                                            <Table
                                                columns={fiColumns}
                                                dataSource={adjuntos}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                    }
                                    <div className='flex items-center space-x-2'>
                                        <label
                                            htmlFor="file-upload"
                                            className={"flex items-center p-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (loadFile ? " hover:cursor-not-allowed" : "")}
                                        >
                                            <CloudUploadOutlined className="mr-1" />
                                            <span>Subir archivo</span>
                                            <input id="file-upload" name="file-upload" type="file" className="sr-only"
                                                onChange={handleUpFile}
                                                disabled={loadFile}
                                            />
                                        </label>
                                        {
                                            loadFile &&
                                            <div className='flex items-center mr-2 text-indigo-600'>
                                                <SyncOutlined spin />
                                            </div>
                                        }
                                    </div>
                                </>
                        }
                    ]}
                />
            </div>
        </>
    )
})
