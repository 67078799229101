import { CheckCircleOutlined, DollarOutlined, QuestionCircleOutlined, LikeOutlined, DislikeOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Popconfirm, Table, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { cpeGetNumUrl, cpeProgramarUrl } from '../../../../api/apiUrl'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { formatAmount, formatDate, formatTime } from '../../../../utils/formatUtil'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { NotifyGreen } from '../../../Msg/NotifyGreen'
import { NotifyRed } from '../../../Msg/NotifyRed'
import { NotifyYellow } from '../../../Msg/NotifyYellow'

import './AprobProg.css'

export const AprobProg = (props) => {
    const { rcpt: rcptProp, cpe: cpeProp, updateCpe, getCpe, openDrawer } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [rcptDB, setRcptDB] = useState(null)
    const [cpeDB, setCpeDB] = useState(null)
    const [observProg, setObservProg] = useState(null)
    const [historial, setHistorial] = useState([])
    const [disablePage, setDisablePage] = useState(false)
    const [loadAprobarProg, setLoadAprobarProg] = useState(false)
    const [loadRechazarProg, setLoadRechazarProg] = useState(false)

    useEffect(() => {
        setDisablePage(loadAprobarProg || loadRechazarProg)
    }, [loadAprobarProg, loadRechazarProg])

    useEffect(() => {
        if (rcptProp)
            setRcptDB({ ...rcptProp })
        if (cpeProp) {
            setCpeDB({ ...cpeProp })
            if (cpeProp.progSel) {
                setObservProg(cpeProp.progSel.observacion)
                if (cpeProp.progSel.historial)
                    setHistorial(cpeProp.progSel.historial.map((prog, index) => ({
                        ...prog,
                        key: index
                    })))
            }
        }
    }, [rcptProp, cpeProp])

    const refreshCpe = async () => {
        let err = null
        let data = null

        const url = cpeGetNumUrl(rcptDB.ruc, cpeDB.emisor.ruc, cpeDB.tipoComprobanteID, cpeDB.serie, cpeDB.numero)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { success, data: cpe } = data
            if (success)
                return cpe
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)
        return null
    }

    const handleAprobarProg = async () => {
        let err = null
        let data = null

        if (!rcptDB)
            err = {
                message: msgType.stdCpeNoRcpt,
                oops: false
            }
        else if (!cpeDB.progSel)
            err = {
                message: `${msgType.stdCpeNoSel}`,
                oops: false
            }
        else if (cpeDB.progSel.aprobada)
            err = {
                message: `${msgType.stdCpeAprobada}`,
                oops: false
            }
        else if (cpeDB.progSel.pagada)
            err = {
                message: `${msgType.stdCpePagada}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadAprobarProg(true)

        const url = cpeProgramarUrl(cpeDB.id, cpeDB.progSel.line, 1)
        const [data_api, err_api] = await axiosTnaPut(url, null)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: msgType.stdCpeProgAprOK,
                oops: false
            }, notifyType.success)
            const cpeAPI = await refreshCpe()
            const cpeWEB = getCpe(cpeAPI)
            updateCpe(cpeAPI)
            setCpeDB(cpeWEB)
            setObservProg(cpeWEB.progSel.observacion)
            if (cpeWEB.progSel.historial)
                setHistorial(cpeWEB.progSel.historial.map((prog, index) => ({
                    ...prog,
                    key: index
                })))
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadAprobarProg(false)
        setLastAccess()
    }

    const handleRechazarProg = async () => {
        let err = null
        let data = null

        if (!rcptDB)
            err = {
                message: msgType.stdCpeNoRcpt,
                oops: false
            }
        else if (!cpeDB.progSel)
            err = {
                message: `${msgType.stdCpeNoSel}`,
                oops: false
            }
        else if (cpeDB.progSel.aprobada)
            err = {
                message: `${msgType.stdCpeAprobada}`,
                oops: false
            }
        else if (cpeDB.progSel.pagada)
            err = {
                message: `${msgType.stdCpePagada}`,
                oops: false
            }
        else if (!observProg || observProg.trim().length === 0)
            err = {
                message: `${msgType.stdCpeNoObserv}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadRechazarProg(true)

        const url = cpeProgramarUrl(cpeDB.id, cpeDB.progSel.line, 2)
        const body = { observacion: observProg.trim() }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: msgType.stdCpeProgRecOK,
                oops: false
            }, notifyType.success)
            const cpeAPI = await refreshCpe()
            const cpeWEB = getCpe(cpeAPI)
            updateCpe(cpeAPI)
            setCpeDB(cpeWEB)
            if (cpeWEB.progSel.historial)
                setHistorial(cpeWEB.progSel.historial.map((prog, index) => ({
                    ...prog,
                    key: index
                })))
            openDrawer(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadRechazarProg(false)
        setLastAccess()
    }

    const hiColumns = [
        {
            title: 'Fecha',
            dataIndex: 'fechaProg',
            width: 120,
            render: (_, record) => <span>{formatDate(record.fechaProg)}</span>
        },
        {
            title: 'Monto',
            dataIndex: 'montoProg',
            align: 'right',
            width: 120,
            render: (_, record) => <span>{formatAmount(record.montoProg)}</span>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                cpeDB &&
                <>
                    <div className='aprobprog-data-cpe text-xs font-normal text-gray-900'>
                        {
                            cpeDB.emisor &&
                            <>
                                <p><span>{`RUC: `}</span>{`${cpeDB.emisor.ruc}`}</p>
                                <p><span>{`Emisor: `}</span>{`${cpeDB.emisor.name}`}</p>
                            </>
                        }
                        {
                            cpeDB.factoring &&
                            <>
                                <p><span>{`RUC: `}</span>{`${cpeDB.factoring.ruc}`}</p>
                                <p><span>{`Beneficiario: `}</span>{`${cpeDB.factoring.nomRazSoc}`}</p>
                            </>
                        }
                        <p><span>{`Fecha de emisión: `}</span>{`${formatDate(cpeDB.fechaEmision)}`}</p>
                        <p><span>{`Fecha de registro: `}</span>{`${formatTime(cpeDB.fechaRegistro)}`}</p>
                        <p><span>{`Fecha contable: `}</span>{`${formatDate(cpeDB.fechaContable)}`}</p>
                        <p><span>{`Fecha de vencimiento: `}</span>{`${formatDate(cpeDB.fechaVcto)}`}</p>
                        <p><span>{`Moneda: `}</span>{`${cpeDB.monedaISO}`}</p>
                        <p><span>{`Saldo: `}</span>{`${formatAmount(cpeDB.saldo)}`}</p>
                    </div>
                    {
                        cpeDB.progSel &&
                        <>
                            <p className="text-xs text-red-800 mt-3">
                                Información de programación:
                            </p>
                            <div className='mt-2 flex flex-col space-y-2'>
                                <div className='aprobprog-data-cpe text-xs font-normal text-gray-900'>
                                    <div className='flex flex-row items-center space-x-1'>
                                        <p><span>{`Fecha programada: `}</span>{`${formatDate(cpeDB.progSel.fechaProg)}`}</p>
                                        {
                                            cpeDB.progSel.aprobada &&
                                            <Tooltip placement="top" title="Programación aprobada">
                                                <CheckCircleOutlined className='text-blue-800' />
                                            </Tooltip>
                                        }
                                        {
                                            cpeDB.progSel.observacion &&
                                            <Tooltip placement="top" title="Programación rechazada">
                                                <CloseCircleOutlined className='text-red-800' />
                                            </Tooltip>
                                        }
                                        {
                                            cpeDB.progSel.pagada &&
                                            <Tooltip placement="top" title="Programación pagada">
                                                <DollarOutlined className='text-green-800' />
                                            </Tooltip>
                                        }
                                    </div>
                                    <p><span>{`Monto programado: `}</span>{`${formatAmount(cpeDB.progSel.montoProg)}`}</p>
                                    <p><span>{`Cuenta: `}</span>{`${cpeDB.progSel.cuenta || ''}`}</p>
                                    {
                                        (cpeDB.progSel.observacion || cpeDB.progSel.aprobada) && observProg &&
                                        <p><span>{`Motivo: `}</span>{`${observProg || ''}`}</p>
                                    }
                                </div>
                                {
                                    !(cpeDB.progSel.observacion || cpeDB.progSel.aprobada) &&
                                    <Input placeholder="Motivo de rechazo"
                                        className='text-xs w-80 h-8'
                                        value={observProg}
                                        onChange={(e) => setObservProg(e.target.value)} />
                                }
                            </div>
                            {
                                !(cpeDB.progSel.observacion || cpeDB.progSel.aprobada) &&
                                <div className='aprobprog-save flex justify-center mt-4 space-x-1'>
                                    {
                                        disablePage ?
                                            <Tooltip title="Procesando" placement="bottom">
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<SyncOutlined spin />}
                                                />
                                            </Tooltip> :
                                            (
                                                <>
                                                    <Tooltip title={"Aprobar programación"} placement="bottom">
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            title={`¿Confirma la operación?`}
                                                            onConfirm={handleAprobarProg}
                                                            okText="Sí"
                                                            cancelText="No"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<LikeOutlined />}
                                                            />
                                                        </Popconfirm>
                                                    </Tooltip>
                                                    {
                                                        cpeDB.progSel.line !== 0 &&
                                                        <Tooltip title="Rechazar programación" placement="bottom">
                                                            <Popconfirm
                                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                                title={`¿Confirma la operación?`}
                                                                onConfirm={handleRechazarProg}
                                                                okText="Sí"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    shape="circle"
                                                                    icon={<DislikeOutlined />}
                                                                />
                                                            </Popconfirm>
                                                        </Tooltip>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            }
                            {
                                historial.length > 0 &&
                                <div className='mt-4'>
                                    <p className="text-xs text-gray-800">
                                        Historial de re-programación:
                                    </p>
                                    <div className='max-w-xs flex mt-2'>
                                        <div className='aprobprog-tabla'>
                                            <Table
                                                columns={hiColumns}
                                                dataSource={historial}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}
