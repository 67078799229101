import { CloudDownloadOutlined } from '@ant-design/icons'
import { Spin, Table, Tabs, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { cpeGetNpgUrl, pagGetNumUrl, pagRecuperarUrl, segConnUrl } from '../../../api/apiUrl'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { notifyType } from '../../../types/notifyType'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { formatAmount, formatCamelCase, formatDate, formatTime } from '../../../utils/formatUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { NotifyRed } from '../../Msg/NotifyRed'

import './DetallePag.css'

var jszip = require('jszip')

export const DetallePag = (props) => {
    const { rcpt: rcptDB, numPago } = props

    const { axiosTnaGet } = useAxiosTna()
    const notifyRedRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadCargarPag, setLoadCargarPag] = useState(true)
    const [loadCargarCpe, setLoadCargarCpe] = useState(true)
    const [attachedFiles, setAttachedFiles] = useState([])
    const [currentLineFI, setCurrentLineFI] = useState(null)
    const [pagDB, setPagDB] = useState(null)
    const [cpesDB, setCpesDB] = useState([])

    useEffect(() => {
        setLoadPage(loadTokenConn || loadCargarPag || loadCargarCpe)
    }, [loadTokenConn, loadCargarPag, loadCargarCpe])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const getPag = async () => {
            const url = pagGetNumUrl(rcptDB.ruc, numPago)
            const [data, err] = await axiosTnaGet(url)

            if (data && isMounted) {
                const { success, data: pag } = data
                if (success) {
                    setPagDB(pag)
                    if (pag.attach)
                        setAttachedFiles(() => {
                            const files = pag.attach.map(file => ({ ...file, key: file.dataOrder }))
                            files.sort(sortAttach)
                            return files
                        })
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadCargarPag(false)
        }

        const getCpe = async () => {
            const url = cpeGetNpgUrl(rcptDB.ruc, numPago)
            const [data, err] = await axiosTnaGet(url)

            if (data && isMounted) {
                const { success, data: cps } = data
                if (success) {
                    cps.sort(sortCpe)
                    setCpesDB(cps.map(cp => ({ ...cp, key: cp.id })))
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadCargarCpe(false)
        }

        if (!loadTokenConn && numPago) {
            getPag()
            getCpe()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [rcptDB, numPago, loadTokenConn])

    useEffect(() => {
        let isMounted = true

        if (isMounted && pagDB?.comprobante && cpesDB)
            setCpesDB(prev => prev.map(cp => {
                const pc = pagDB.comprobante.find(p => p.comprobanteID === cp.id)
                return { ...cp, pag: { monto: pc?.monto, montoCpe: pc?.montoCpe } }
            }))

        return () => {
            isMounted = false
        }
    }, [pagDB, cpesDB])

    const sortAttach = (a, b) => {
        if (a.dataOrder > b.dataOrder)
            return 1
        else if (a.dataOrder < b.dataOrder)
            return -1
        else
            return -1
    }

    const sortCpe = (a, b) => {
        if (a.fechaEmision > b.fechaEmision)
            return 1
        else if (a.fechaEmision < b.fechaEmision)
            return -1
        else if (a.serie > b.serie)
            return 1
        else if (a.serie < b.serie)
            return -1
        if (a.numero > b.numero)
            return 1
        else
            return -1
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = pagRecuperarUrl(pagDB.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex items-center'>
                    <button
                        className='text-blue-600 w-full text-left'
                        disabled={record.dataOrder === currentLineFI}
                        onClick={() => handleDownFile(record, false)}
                    >
                        {`${record.fileName}`}
                    </button>
                    <div className='flex justify-end'>
                        {
                            record.dataOrder !== currentLineFI &&
                            <button className='mr-1' onClick={() => handleDownFile(record, true)}>
                                <CloudDownloadOutlined />
                            </button>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='mr-1' />
                        }
                    </div>
                </div>
        },
    ]

    const cpColumns = [
        {
            title: 'Documento',
            dataIndex: 'documento',
            render: (_, record) =>
                <span>{`${record.emisor?.ruc}-${record.tipoComprobanteID}-${record.serie}-${record.numero}`}</span>
        },
        {
            title: 'Monto',
            dataIndex: 'total',
            width: 120,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.pag?.monto ?? 0)}</span>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            {
                loadPage &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' />
                </div>
            }
            {
                pagDB &&
                <>
                    <div className='detallepag-data text-xs font-normal text-gray-900'>
                        <div className="flex items-center">
                            <p><span>{`Nº de pago: `}</span>{`${pagDB.numPago}`}</p>
                            <p className="p-0.5 text-xs ml-1 text-indigo-600">
                                {`${pagDB.estado}`}
                            </p>
                        </div>
                        <p><span>{`Fecha de pago: `}</span>{`${formatDate(pagDB.fechaPago)}`}</p>
                        {
                            rcptDB &&
                            <>
                                <p><span>{`RUC: `}</span>{`${rcptDB.ruc}`}</p>
                                <p><span>{`Receptor: `}</span>{`${rcptDB.name}`}</p>
                            </>
                        }
                        <p><span>{`Moneda: `}</span>{`${pagDB.monedaISO}`}</p>
                        <p><span>{`Total: `}</span>{`${formatAmount(pagDB.total)}`}</p>
                        <p><span>{`Tipo de pago: `}</span>{`${pagDB.tipoPago}`}</p>
                        <p><span>{`Banco: `}</span>{`${pagDB.banco ?? ''}`}</p>
                        <p><span>{`Nº de cuenta: `}</span>{`${pagDB.numCuenta ?? ''}`}</p>
                        <p><span>{`Nº de operación: `}</span>{`${pagDB.numOperacion ?? ''}`}</p>
                    </div>
                    <div className='detallepag-data-adi mt-1.5 text-xs font-normal text-gray-900'>
                        <p><span>{`${formatCamelCase(rcptDB?.grpName ?? '')}: `}</span>{`${pagDB.grupo}`}</p>
                        <p><span>{`Fecha de registro: `}</span>{`${formatTime(pagDB.fechaRegistro)}`}</p>
                        <p><span>{`Pago programado: `}</span>{pagDB.programado ? 'SI' : 'NO'}</p>
                    </div>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Adjunto`,
                                key: '1',
                                children:
                                    attachedFiles.length > 0 ?
                                        <div className='detallepag-tabla'>
                                            <Table
                                                columns={fiColumns}
                                                dataSource={attachedFiles}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                        : <span></span>
                            },
                            {
                                label: `Comprobante`,
                                key: '2',
                                children:
                                    cpesDB.length > 0 ?
                                        <div className='detallepag-tabla'>
                                            <Table
                                                columns={cpColumns}
                                                dataSource={cpesDB}
                                                pagination={false}
                                                bordered
                                                size='small'
                                                summary={(pageData) => {
                                                    let montoTot = 0.0
                                                    pageData.forEach(({ pag }) => {
                                                        montoTot += (pag?.monto ?? 0)
                                                    })
                                                    return (
                                                        <>
                                                            <Table.Summary.Row>
                                                                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                                <Table.Summary.Cell index={1} align='right'>
                                                                    <Typography.Text type='danger'>{formatAmount(montoTot)}</Typography.Text>
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                        </>
                                                    )
                                                }}
                                            />
                                        </div>
                                        : <span></span>
                            },
                        ]}
                    />
                </>
            }
        </>
    )
}
