import { CheckCircleOutlined, DollarOutlined, QuestionCircleOutlined, SaveOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, InputNumber, Popconfirm, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { cpeGetNumUrl, cpeProgramarUrl } from '../../../../api/apiUrl'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { formatAmount, formatDate, formatTime } from '../../../../utils/formatUtil'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { NotifyGreen } from '../../../Msg/NotifyGreen'
import { NotifyRed } from '../../../Msg/NotifyRed'
import { NotifyYellow } from '../../../Msg/NotifyYellow'

import './EntryProg.css'

export const EntryProg = (props) => {
    const { rcpt: rcptProp, cpe: cpeProp, updateCpe, getCpe, openDrawer } = props

    const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [rcptDB, setRcptDB] = useState(null)
    const [cpeDB, setCpeDB] = useState(null)
    const [fechaProg, setFechaProg] = useState(null)
    const [montoProg, setMontoProg] = useState(null)
    const [cuentaProg, setCuentaProg] = useState(null)
    const [historial, setHistorial] = useState([])
    const [disablePage, setDisablePage] = useState(false)
    const [loadGuardarProg, setLoadGuardarProg] = useState(false)
    const [loadEliminarProg, setLoadEliminarProg] = useState(false)

    useEffect(() => {
        setDisablePage(loadGuardarProg || loadEliminarProg)
    }, [loadGuardarProg, loadEliminarProg])

    useEffect(() => {
        if (rcptProp)
            setRcptDB({ ...rcptProp })
        if (cpeProp) {
            setCpeDB({ ...cpeProp })
            if (cpeProp.progSel && cpeProp.progSel.line !== 0) {
                const { fechaProg: fecha, montoProg: monto, cuenta } = cpeProp.progSel
                setFechaProg(moment(fecha))
                setMontoProg(monto)
                setCuentaProg(cuenta)
                if (cpeProp.progSel.historial)
                    setHistorial(cpeProp.progSel.historial.map((prog, index) => ({
                        ...prog,
                        key: index
                    })))
            }
        }
    }, [rcptProp, cpeProp])

    const refreshCpe = async () => {
        const url = cpeGetNumUrl(rcptDB.ruc, cpeDB.emisor.ruc, cpeDB.tipoComprobanteID, cpeDB.serie, cpeDB.numero)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Comprobante: ${err.message}`)
        const ncpe = data?.success ? data.data : null
        return ncpe
    }

    const handleGuardarProg = async () => {
        let err = null
        let data = null

        if (!rcptDB)
            err = {
                message: msgType.proCpeNoRcpt,
                oops: false
            }
        else if (!fechaProg)
            err = {
                message: `${msgType.proCpeNoFechaP}`,
                oops: false
            }
        else if (!montoProg)
            err = {
                message: `${msgType.proCpeNoMontoP}`,
                oops: false
            }
        else if (fechaProg.toDate().getTime() < moment(cpeDB.fechaEmision).toDate().getTime())
            err = {
                message: `${msgType.proCpeKoFecha}`,
                oops: false
            }
        else if (montoProg <= 0.0 || montoProg > cpeDB.formaPago.montoProg - cpeDB.formaPago.pagoProg)
            err = {
                message: `${msgType.proCpeKoMonto}`,
                oops: false
            }
        else if (!cpeDB.progSel)
            err = {
                message: `${msgType.proCpeNoSel}`,
                oops: false
            }
        else if (cpeDB.progSel.pagada)
            err = {
                message: `${msgType.proCpePagada}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadGuardarProg(true)

        const line = cpeDB.progSel.line === 0 ? null : cpeDB.progSel.line
        const fecha = fechaProg.format().split('T')[0] + 'T00:00:00'
        let cuenta = !cuentaProg || cuentaProg.trim().length === 0 ? null : cuentaProg.trim()

        const url = cpeProgramarUrl(cpeDB.id, line)
        const body = { fechaProg: fecha, montoProg, cuenta }
        const [data_api, err_api] = line ? await axiosTnaPut(url, body) : await axiosTnaPost(url, body)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: line ? msgType.proCpeUpdateOK : msgType.proCpeSaveOK,
                oops: false
            }, notifyType.success)
            const cpeAPI = await refreshCpe()
            const cpeWEB = getCpe(cpeAPI)
            updateCpe(cpeAPI)
            setCpeDB(cpeWEB)
            if (cpeWEB.progSel.historial)
                setHistorial(cpeWEB.progSel.historial.map((prog, index) => ({
                    ...prog,
                    key: index
                })))
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadGuardarProg(false)
        setLastAccess()
    }

    const handleEliminarProg = async () => {
        let err = null
        let data = null

        if (!rcptDB)
            err = {
                message: msgType.proCpeNoRcpt,
                oops: false
            }
        else if (!cpeDB.progSel)
            err = {
                message: `${msgType.proCpeNoSel}`,
                oops: false
            }
        else if (cpeDB.progSel.pagada)
            err = {
                message: `${msgType.proCpePagada}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadEliminarProg(true)

        const url = cpeProgramarUrl(cpeDB.id, cpeDB.progSel.line)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: msgType.proCpeDeleteOK,
                oops: false
            }, notifyType.success)
            const cpeAPI = await refreshCpe()
            updateCpe(cpeAPI)
            setCpeDB(null)
            setHistorial([])
            openDrawer(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadEliminarProg(false)
        setLastAccess()
    }

    const hiColumns = [
        {
            title: 'Fecha',
            dataIndex: 'fechaProg',
            width: 120,
            render: (_, record) => <span>{formatDate(record.fechaProg)}</span>
        },
        {
            title: 'Monto',
            dataIndex: 'montoProg',
            align: 'right',
            width: 120,
            render: (_, record) => <span>{formatAmount(record.montoProg)}</span>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                cpeDB &&
                <>
                    <div className='data-cpe-entryprog text-xs font-normal text-gray-900'>
                        {
                            cpeDB.emisor &&
                            <>
                                <p><span>{`RUC: `}</span>{`${cpeDB.emisor.ruc}`}</p>
                                <p><span>{`Emisor: `}</span>{`${cpeDB.emisor.name}`}</p>
                            </>
                        }
                        {
                            cpeDB.factoring &&
                            <>
                                <p><span>{`RUC: `}</span>{`${cpeDB.factoring.ruc}`}</p>
                                <p><span>{`Beneficiario: `}</span>{`${cpeDB.factoring.nomRazSoc}`}</p>
                            </>
                        }
                        <p><span>{`Fecha de emisión: `}</span>{`${formatDate(cpeDB.fechaEmision)}`}</p>
                        <p><span>{`Fecha de registro: `}</span>{`${formatTime(cpeDB.fechaRegistro)}`}</p>
                        <p><span>{`Fecha contable: `}</span>{`${formatDate(cpeDB.fechaContable)}`}</p>
                        <p><span>{`Fecha de vencimiento: `}</span>{`${formatDate(cpeDB.fechaVcto)}`}</p>
                        <p><span>{`Moneda: `}</span>{`${cpeDB.monedaISO}`}</p>
                        <p className='cursor-pointer' onClick={() => setMontoProg(cpeDB.formaPago.montoProg - cpeDB.formaPago.pagoProg)}><span>{`Saldo programable: `}</span>{`${formatAmount(cpeDB.formaPago.montoProg - cpeDB.formaPago.pagoProg)}`}</p>
                        {
                            cpeDB.progSel && !cpeDB.progSel.pagada &&
                            (cpeDB.formaPago.montoProg - cpeDB.formaPago.pagoProg !== cpeDB.formaPago.montoProg - cpeDB.formaPago.totalProg + cpeDB.progSel.montoProg) &&
                            <p className='cursor-pointer' onClick={() => setMontoProg(cpeDB.formaPago.montoProg - cpeDB.formaPago.totalProg + cpeDB.progSel.montoProg)}><span>{`Pendiente por programar: `}</span>{`${formatAmount(cpeDB.formaPago.montoProg - cpeDB.formaPago.totalProg + cpeDB.progSel.montoProg)}`}</p>
                        }
                    </div>
                    {
                        cpeDB.progSel &&
                        <div className='ant-common-titania'>
                            <p className="text-xs text-red-800 mt-3">
                                Información de programación:
                            </p>
                            <div className='mt-2 flex flex-col space-y-1'>
                                <div className='flex flex-row items-center space-x-1'>
                                    <DatePicker
                                        placeholder='Fecha'
                                        className='w-36'
                                        size='middle'
                                        value={fechaProg}
                                        onChange={(d,) => setFechaProg(d ?? null)} />
                                    {
                                        cpeDB.progSel.aprobada &&
                                        <Tooltip placement="top" title="Programación aprobada">
                                            <CheckCircleOutlined className='text-blue-800' />
                                        </Tooltip>
                                    }
                                    {
                                        cpeDB.progSel.pagada &&
                                        <Tooltip placement="top" title="Programación pagada">
                                            <DollarOutlined className='text-green-800' />
                                        </Tooltip>
                                    }
                                </div>
                                <InputNumber
                                    placeholder='Monto'
                                    className='text-xs w-36'
                                    size='middle'
                                    bordered={true} precision={2}
                                    value={montoProg}
                                    onChange={setMontoProg} />
                                <Input placeholder="Cuenta"
                                    className='text-xs w-36 h-8'
                                    value={cuentaProg}
                                    onChange={(e) => setCuentaProg(e.target.value)} />
                                {
                                    cpeDB.progSel.observacion &&
                                    <div className='data-cpe-entryprog text-xs font-normal pt-1 text-gray-900'>
                                        <p><span className='text-red-800'>{`Rechazado: `}</span>{`${cpeDB.progSel.observacion}`}</p>
                                    </div>
                                }
                            </div>
                            <div className='flex justify-center mt-4 space-x-1'>
                                {
                                    disablePage ?
                                        <Tooltip title="Procesando" placement="bottom">
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<SyncOutlined spin />}
                                            />
                                        </Tooltip> :
                                        (
                                            <>
                                                <Tooltip title={cpeDB.progSel.line === 0 ? "Guardar programación" : "Actualizar programación"} placement="bottom">
                                                    <Popconfirm
                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                        title={`¿Confirma la operación?`}
                                                        onConfirm={handleGuardarProg}
                                                        okText="Sí"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<SaveOutlined />}
                                                        />
                                                    </Popconfirm>
                                                </Tooltip>
                                                {
                                                    cpeDB.progSel.line !== 0 &&
                                                    <Tooltip title="Eliminar programación" placement="bottom">
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            title={`¿Confirma la operación?`}
                                                            onConfirm={handleEliminarProg}
                                                            okText="Sí"
                                                            cancelText="No"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<DeleteOutlined />}
                                                            />
                                                        </Popconfirm>
                                                    </Tooltip>
                                                }
                                            </>
                                        )
                                }
                            </div>
                            {
                                historial.length > 0 &&
                                <div className='mt-4'>
                                    <p className="text-xs text-gray-800">
                                        Historial de re-programación:
                                    </p>
                                    <div className='max-w-xs flex mt-2'>
                                        <div className='ant-table-titania'>
                                            <Table
                                                columns={hiColumns}
                                                dataSource={historial}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                        <span></span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </>
            }
        </>
    )
}
