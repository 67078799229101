import { CloudDownloadOutlined, FileAddOutlined, FileSearchOutlined } from '@ant-design/icons'
import { Select, Spin, Table } from 'antd'
import React, { memo, useEffect, useRef, useState } from 'react'
import { rcpGetNocUrl, rcpRecuperarUrl } from '../../../../api/apiUrl'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { configType } from '../../../../types/configType'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { downloadFile, viewerFile } from '../../../../utils/fileUtil'
import { formatAmount, formatDate } from '../../../../utils/formatUtil'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { NotifyRed } from '../../../Msg/NotifyRed'

import './ListRcpReg.css'

var jszip = require('jszip')

export const ListRcpReg = memo((props) => {
    const { record: ocp, selectedRcpt, openDrawer } = props

    const { axiosTnaGet } = useAxiosTna()
    const notifyRedRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [receipts, setReceipts] = useState([])
    const [currentNumRE, setCurrentNumRE] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    useEffect(() => {
        let isMounted = true

        const busqNumCpe = async () => {
            const url = rcpGetNocUrl(selectedRcpt.ruc, ocp.numOC)
            const [data, err] = await axiosTnaGet(url)

            if (data && isMounted) {
                const { success, data: rcs } = data
                if (success) {
                    rcs.sort(sortRcp)
                    setReceipts(rcs.map(rc => {
                        for (const cpe of rc.comprobante) {
                            cpe.value = `${cpe.tipoComprobanteID}-${cpe.serie}-${cpe.numero}`
                            cpe.label = `${cpe.serie}-${cpe.numero}`
                        }
                        if (rc.comprobante.length > 0)
                            rc.cpe = rc.comprobante[0]
                        if (rc.attach)
                            rc.file = rc.attach[0] || null
                        return { ...rc, key: rc.numRE, }
                    }))
                }
                else
                    setReceipts([])
                setSelectedRowKeys([])
                setSelectedRows([])
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)
            setLoadPage(false)
        }

        busqNumCpe()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [ocp, selectedRcpt])

    const sortRcp = (a, b) => {
        if (a.fechaRecibo > b.fechaRecibo)
            return 1
        else if (a.fechaRecibo < b.fechaRecibo)
            return -1
        else if (a.numRE > b.numRE)
            return 1
        else
            return -1
    }

    const handleClickNumRE = async (record) => {
        const attach = record.file

        let err = null
        let data = null

        setCurrentNumRE(record.numRE)

        const url = rcpRecuperarUrl(record.id, attach.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(attach.fileName).async('base64')
                if (attach.contentType === configType.contentTypePDF)
                    viewerFile(unzip, attach.contentType, attach.fileName)
                else
                    downloadFile(unzip, attach.contentType, attach.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentNumRE('')
        setLastAccess()
    }

    const handleChangeSelCpe = (value, record) => setReceipts(prev => {
        const rcps = prev.filter(rcp => rcp.numRE !== record.numRE)
        const cpe = record.comprobante.find(cpe => cpe.value === value)
        const data = [...rcps, { ...record, cpe }]
        data.sort(sortRcp)
        return data
    })

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.total === record.totCpe,
            name: record.id,
        }),
    }

    const rcColumns = [
        {
            title: 'Nº Ingreso',
            dataIndex: 'numRE',
            render: (text, record) =>
                <div className='flex items-center'>
                    <button
                        className='text-blue-600 w-full text-left'
                        onClick={() => openDrawer(ocp, [], null, record, 3)}
                        disabled={record.numRE === currentNumRE}
                    >
                        {text}
                    </button>
                    {
                        record.file &&
                        <div className='flex justify-end'>
                            {
                                record.numRE !== currentNumRE &&
                                <button onClick={() => handleClickNumRE(record)}>
                                    <CloudDownloadOutlined />
                                </button>
                            }
                            {
                                record.numRE === currentNumRE &&
                                <Spin size='small' />
                            }
                        </div>
                    }
                </div>
        },
        {
            title: 'Fecha',
            dataIndex: 'fechaRecibo',
            width: 100,
            render: (_, record) => <span>{formatDate(record.fechaRecibo)}</span>
        },
        {
            title: 'Doc. Proveedor',
            dataIndex: 'numDocProv',
            width: 150,
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            width: 120,
            render: (text, record) => <span>{text}{record.sufijo || ''}</span>
        },
        {
            title: 'CPE',
            dataIndex: 'cpe',
            width: 140,
            render: (_, record) => record.comprobante.length > 0 && !selectedRowKeys.find(p => p === record.numRE) ?
                <div className='flex items-center'>
                    <button className='mr-2 p-0'
                        onClick={() => openDrawer(ocp, [], record.cpe, null, 1)}
                    >
                        <FileSearchOutlined />
                    </button>
                    <Select value={record.cpe.value} className='w-full' size='small' style={{ fontSize: '0.65rem' }}
                        onChange={(v) => handleChangeSelCpe(v, record)}>
                        {
                            record.comprobante.map((cpe, index) => (
                                <Select.Option key={index} value={cpe.value}>{cpe.label}</Select.Option>
                            ))
                        }
                    </Select>
                </div> :
                <div className='flex items-center'>
                    <button className={'mr-2 p-0' + (!(selectedRowKeys.find(p => p === record.numRE) || false) ? ' cursor-not-allowed' : '')}
                        onClick={() => openDrawer(ocp, selectedRows, null, null, 1)}
                        disabled={!(selectedRowKeys.find(p => p === record.numRE) || false)}
                    >
                        <FileAddOutlined />
                    </button>
                    <Select placeholder='NUEVO' className='w-full' size='small' style={{ fontSize: '0.65rem' }} />
                </div>
        },
        {
            title: 'Total',
            dataIndex: 'total',
            width: 120,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.total)}</span>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            {
                loadPage &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' />
                </div>
            }
            {
                !loadPage &&
                <div className='listrcpreg-tabla-re m-1'>
                    <p className='mb-2 text-xs text-gray-700'>
                        Seleccione una recepción y registre un comprobante de pago.
                    </p>
                    <Table rowSelection={rowSelection} columns={rcColumns} dataSource={receipts} pagination={false} bordered />
                </div>
            }
        </>
    )
})
