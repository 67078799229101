import React, { useLayoutEffect } from 'react'
import { lstorageType } from '../../types/lstorageType'

export const FileViewer = () => {
  let file = JSON.parse(localStorage.getItem(lstorageType.vwFile)) || null
  if (!file)
    window.close()

  useLayoutEffect(() => {
    localStorage.removeItem(lstorageType.vwFile)
    if (typeof window.orientation !== 'undefined') {
      document.getElementById('downFile').click()
      window.close()
    }
  }, [])

  return (
    <div className='p-0 m-0 w-full h-full absolute'>
      {
        file &&
        <object className='w-full h-full'
          data={`data:${file.contentType};base64,${file.base64}`}
          type={file.contentType}
        >
          <br />
          <a href={`data:${file.contentType};base64,${file.base64}`} download={file.fileName} id='downFile'>
            Descargar archivo
          </a>
        </object>
      }
    </div>
  )
}
