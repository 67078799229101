import { Buffer } from 'buffer'
import { lstorageType } from "../types/lstorageType"

export const viewerFile = (contentBase64, contentType, fileName) => {
    const file = { base64: contentBase64, contentType, fileName }
    localStorage.setItem(lstorageType.vwFile, JSON.stringify(file))

    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink)
    downloadLink.href = '/panel/file'
    downloadLink.target = '_blank'
    downloadLink.click();
}

export const downloadFile = (contentBase64, contentType, fileName) => {
    const file = { base64: contentBase64, contentType, fileName }

    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink)
    downloadLink.href = `data:${file.contentType};base64,${file.base64}`
    downloadLink.download = file.fileName
    downloadLink.click();
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if ((encoded.length % 4) > 0)
            encoded += '='.repeat(4 - (encoded.length % 4))
        resolve(encoded)
    }
    reader.onerror = error => reject(error)
})

export const jsonBase64 = obj => {
    const objJsonStr = JSON.stringify(obj)
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return objJsonB64
}