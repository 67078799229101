import { configType } from "../types/configType"

const app_P = process.env.REACT_APP_API_App_Titania_P
const app_D = process.env.REACT_APP_API_App_Titania_D

const getEnvironment = () => {
    return [
        {
            app: app_P,
            isDemo: false
        },
        {
            app: app_D,
            isDemo: true
        }
    ]
}

export const getAuth = (isDemo) => {
    const env = getEnvironment().find(e => e.isDemo === isDemo)
    return { ...env, logged: false }
}

export const getError = (err) => {
    const msg = ((err.response?.data?.message || err.response?.data?.Message) || err.message) || null
    return {
        message: msg,
        detail: (err.response?.data?.detail || err.response?.data?.MessageDetail) || null,
        status: err.response?.status || 0,
        oops: !(err.response?.data?.detail === null) && msg === configType.errorInterno
    }
}