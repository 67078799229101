import { getError } from "../api/apiUtil";
import useInterceptCto from "./useInterceptCto";

export const useAxiosCto = () => {
    const axiosCto = useInterceptCto();

    const axiosCtoGet = async (url) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosCto.get(url, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosCtoPost = async (url, data) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosCto.post(url, JSON.stringify(data), {
                signal: controller.signal,
                headers: { 'Content-Type': 'application/json' },
            })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosCtoDelete = async (url) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosCto.delete(url, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosCtoPut = async (url, data) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosCto.put(url, data, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    return { axiosCtoGet, axiosCtoPost, axiosCtoDelete, axiosCtoPut }
}
