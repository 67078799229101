import './NewRen.css'

import { SyncOutlined, CloudDownloadOutlined, QuestionCircleOutlined, CloudUploadOutlined, DeleteFilled } from '@ant-design/icons'
import { AutoComplete, Button, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Spin, Switch, Table, Tabs, Tooltip } from 'antd'
import moment from 'moment'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { catGetCodUrl, rctEntrRendUrl, renAdjuntarUrl, renGetNumUrl, renModificarUrl, renRecuperarUrl, renRegistrarUrl, segConnUrl, usuGetAcerUrl } from '../../../../api/apiUrl'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { msgType } from '../../../../types/msgType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { downloadFile, viewerFile } from '../../../../utils/fileUtil'
import { NotifyRed } from '../../../Msg/NotifyRed'
import { NotifyGreen } from '../../../Msg/NotifyGreen'
import { NotifyYellow } from '../../../Msg/NotifyYellow'
import { NewDoc } from './NewDoc'
import { NewDev } from './NewDev'
import { EditRenCtb } from '../ModData/EditRenCtb'

var jszip = require('jszip')

export const NewRen = forwardRef((props, ref) => {
  const { usuario, selReceptor, selGrupo, selRendicion, tiposRen: tiposRen_all, monedas, updateRen, tabKey, validar } = props

  const dvFechaRen = selRendicion?.numRD ? moment(selRendicion.fechaRen) : moment()
  const dvMoneda = selRendicion?.monedaISO ? monedas.find(p => p.codigo === selRendicion.monedaISO) : monedas.find(() => true)
  const dvMotivo = selRendicion?.numRD ? selRendicion.motivo : null
  const dvShowCCosto = selRendicion && selRendicion.ccosto && selRendicion.ccosto.length === 0 ? false : true

  const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
  const [form] = Form.useForm()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [loadPage, setLoadPage] = useState(false)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadConfigER, setLoadConfigER] = useState(false)
  const [loadTipoPago, setLoadTipoPago] = useState(true)
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [loadUsuario, setLoadUsuario] = useState(false)
  const [loadFile, setLoadFile] = useState(false)
  const [loadRegRen, setLoadRegRen] = useState(false)
  const [usuarios, setUsuarios] = useState([])
  const [tiposRem, setTiposRem] = useState([])
  const [tiposRen, setTiposRen] = useState([])
  const [tiposPago, setTiposPago] = useState([])
  const [cuentas, setCuentas] = useState([])
  const [areas, setAreas] = useState([])
  const [egasto, setEGasto] = useState([])
  const [ccosto, setCcosto] = useState([])
  const [documentos, setDocumentos] = useState([])
  const [adjuntos, setAdjuntos] = useState([])
  const [tolTCambio, setTolTCambio] = useState(null)
  const [fmTipoRen, setFmTipoRen] = useState(null)
  const [fmFechaRen, setFmFechaRen] = useState(dvFechaRen)
  const [fmArea, setFmArea] = useState(null)
  const [fmMoneda, setFmMoneda] = useState(dvMoneda)
  const [fmMotivo, setFmMotivo] = useState(dvMotivo)
  const [opcionesUsu, setOpcionesUsu] = useState([])
  const [opcionesEgas, setOpcionesEgas] = useState([])
  const [selRindente, setSelRindente] = useState(null)
  const [selEGasto, setSelEGasto] = useState(null)
  const [dvSearchUsu, setDvSearchUsu] = useState(null)
  const [dvSearchEgas, setDvSearchEgas] = useState(null)
  const [showCCosto, setShowCCosto] = useState(dvShowCCosto)
  const [currentLineFI, setCurrentLineFI] = useState(null)
  const [activeTabKey, setActiveTabKey] = useState("1")

  useEffect(() => {
    setLoadPage(loadTokenConn || loadConfigER || loadTipoPago)
  }, [loadTokenConn, loadConfigER, loadTipoPago])

  useEffect(() => {
    setComponentDisabled(loadUsuario || loadFile || loadRegRen || validar)
  }, [loadUsuario, loadFile, loadRegRen, validar])

  useEffect(() => {
    setDocumentos(selRendicion?.documentos ? [...selRendicion.documentos] : [])
    setAdjuntos(selRendicion?.attach ? selRendicion.attach.filter(p => !selRendicion.devoluciones || !selRendicion.devoluciones.find(q => q.dataOrder === p.dataOrder)).map(p => ({ ...p, key: p.dataOrder })) : [])
  }, [selRendicion])

  useEffect(() => {
    const area = selRendicion?.areaCode ? areas.find(p => p.areaCode === selRendicion.areaCode) : areas.find(() => true)
    setFmArea(area)
    form.setFieldsValue({
      area: area?.areaCode,
    })
  }, [selRendicion, areas, form])

  useEffect(() => {
    const otren = tiposRen_all.filter(p => p.bool1 === false || selRendicion?.numSO).map(p => p.bool1 === true && selRendicion?.numSO ? { ...p, nombre: `${p.nombre} - ${selRendicion.numSO}` } : p)
    const tren = otren.find(p => p.bool1 === false)
    const atren = otren.filter(p => p.bool1 === true)
    if (tren)
      tiposRem.forEach(p => {
        atren.push({ ...tren, codigo: p.rendCode, nombre: p.rendName, tipoCode: tren.codigo })
      })
    let tipoRen
    if (selRendicion?.numRD)
      tipoRen = selRendicion.origen ? atren.find(p => p.codigo === selRendicion.tipoCode) : atren.find(p => p.codigo === selRendicion.rendCode)
    else
      tipoRen = atren.find(() => true)
    setTiposRen(atren)
    setFmTipoRen(tipoRen)
    form.setFieldsValue({
      tipoRen: tipoRen?.codigo,
    })
  }, [selRendicion, tiposRen_all, tiposRem, form])

  useEffect(() => {
    const getCcosto = (ccos, index, ren) => {
      const rcc = ren?.ccosto?.find(p => p.dimID === ccos.dimID) || null
      const code = rcc?.codeID ? ccos.codes.find(p => p.codeID === rcc.codeID) : null
      const aops = code?.codeID ? searchResultCcos(code.codeID, ccos.codes) : []
      const ncc = { ...ccos, index, code, opciones: aops, dvSearch: code?.codeID }
      return ncc
    }

    if (fmTipoRen && fmArea)
      setCcosto(fmArea.ccosto ? fmArea.ccosto.map((p, index) => getCcosto(p, index, selRendicion)) : [])
    else
      setCcosto([])
    // eslint-disable-next-line
  }, [selRendicion, fmTipoRen, fmArea])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const configER = async () => {
      isMounted && setLoadConfigER(true)

      const url = rctEntrRendUrl(selReceptor.ruc, selRendicion?.grpCode ?? selGrupo?.ruc ?? '', false, true, (selRendicion?.numRD ? (selRendicion?.solicitante?.userID ?? '') : ''))
      const [data, err] = await axiosTnaGet(url)

      if (data) {
        const { success, data: config } = data
        if (success) {
          const ttcab = config?.tolTCambio || null
          const atren = config?.tiposRen || []
          const acta = config?.cuentas || []
          const aare = config?.areas?.filter(p => p.asignada) || []
          const agas = config?.egasto || []
          const gas = agas.find(p => p.code === selRendicion?.egastoCode)
          const aops = searchResultEgas(gas?.code, agas)
          if (isMounted) {
            setTolTCambio(ttcab)
            setTiposRem(atren)
            setCuentas(acta)
            setAreas(aare)
            setEGasto(agas)
            setOpcionesEgas(aops)
            setSelEGasto(gas)
            setDvSearchEgas(gas?.code)
          }
        }
      }
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadConfigER(false)
    }

    const catTipoPag = async () => {
      const url = catGetCodUrl(configType.catTipoPago)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposPago(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadTipoPago(false)
    }

    if (!loadTokenConn) {
      configER()
      catTipoPag()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const acerUsuario = async () => {
      isMounted && setLoadUsuario(true)

      const url = usuGetAcerUrl(selReceptor.ruc, selRendicion?.grpCode ?? selGrupo?.ruc ?? '', false, true, fmArea.areaCode)
      const [data, err] = await axiosTnaGet(url)

      if (data && data.totalDocuments > 0) {
        const ausu = data.data.map(p => ({
          ...p, value: `${p.docIdentidad?.documento}`, label: `${p.docIdentidad?.denominacion}`
        }))
        const usu = ausu.find(p => p.userID === (selRendicion?.rindente?.userID ?? selRendicion?.solicitante?.userID ?? usuario.userID))
        const aops = searchResultUsu(usu?.value, ausu)
        if (isMounted) {
          setUsuarios(ausu)
          setOpcionesUsu(aops)
          setSelRindente(usu)
          setDvSearchUsu(usu?.value)
        }
      }
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadUsuario(false)
    }

    if (fmArea)
      acerUsuario()
    else if (isMounted) {
      setUsuarios([])
      setOpcionesUsu([])
      setSelRindente(null)
      setDvSearchUsu(null)
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [fmArea])

  useImperativeHandle(
    ref,
    () => ({
      handleSave() {
        handleRegRen()
      },
      showNotify(err, type) {
        showNotifyRen(err, type)
      }
    })
  )

  const refreshRen = async (rcpt, ren) => {
    const url = renGetNumUrl(rcpt.ruc, ren.numRD)
    const [data, err] = await axiosTnaGet(url)

    if (err)
      console.log(`Rendición: ${err.message}`)
    const nren = data?.success ? data.data : null
    return nren
  }

  const onValuesChange = (changedValues, allValues) => {
    const tipoRen = tiposRen.find(p => p.codigo === allValues.tipoRen) || null
    const fechaRen = allValues.fechaRen ?? null
    const area = areas.find(p => p.areaCode === allValues.area) || null
    const moneda = monedas.find(p => p.codigo === allValues.monedaISO) || null
    const motivo = allValues.motivo ?? null

    setFmTipoRen(tipoRen)
    setFmFechaRen(fechaRen)
    setFmArea(area)
    setFmMoneda(moneda)
    setFmMotivo(motivo)
  }

  const sortCcosto = (a, b) => {
    if (a.index > b.index)
      return 1
    else
      return -1
  }

  const clearFile = () => {
    const input = document.getElementById("file-upload")
    if (input)
      input.value = null
  }

  const showNotifyRen = (err, type) => {
    if (type === notifyType.warning)
      notifyYellowRef.current.handleOpen(err, type)
    else if (type === notifyType.error)
      notifyRedRef.current.handleOpen(err, type)
    else if (type === notifyType.success)
      notifyGreenRef.current.handleOpen(err, type)
  }

  const compareText = (field, value) => {
    if (value === undefined)
      return false
    else {
      const nvalue = value.toUpperCase()
      const nfield = field.toUpperCase()
      return nfield.indexOf(nvalue) !== -1
    }
  }

  const searchResultUsu = (value, ausu) => {
    const nusu = ausu.filter(p => p.docIdentidad && (value === configType.searchAllCode || compareText(p.userID, value) || compareText(p.userName, value) || compareText(p.docIdentidad.documento, value) || compareText(p.docIdentidad.denominacion, value)))
    const aops = nusu.map(p => ({
      value: p.value,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{`${p.docIdentidad.documento} - ${p.docIdentidad.denominacion}`}</span>
          <span>{`${p.userID}`}</span>
        </div>
      ),
    }))
    return aops
  }

  const searchResultEgas = (value, agas) => {
    const ngas = agas.filter(p => value === configType.searchAllCode || compareText(p.code, value) || compareText(p.name, value))
    const aops = ngas.map(p => ({
      value: p.code,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{`${p.name}`}</span>
          <span>{`${p.code}`}</span>
        </div>
      ),
    }))
    return aops
  }

  const searchResultCcos = (value, acod) => {
    const ncod = acod.filter(p => value === configType.searchAllCode || compareText(p.codeID, value) || compareText(p.codeName, value))
    const aops = ncod.map(p => ({
      value: p.codeID,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{`${p.codeName}`}</span>
          <span>{`${p.codeID}`}</span>
        </div>
      ),
    }))
    return aops
  }

  const handleDocumentos = useCallback((docs) => { setDocumentos(docs) }, [setDocumentos])
  const handleActiveTabKey = (activeKey) => { setActiveTabKey(activeKey); if (tabKey) tabKey(activeKey) }

  const handleSearchUsu = (value, ausu) => setOpcionesUsu(value ? searchResultUsu(value, ausu) : [])
  const handleChangeUsu = (value) => { setSelRindente(null); setDvSearchUsu(value) }
  const handleSelectUsu = (value, ausu) => { setSelRindente(usuarios.find(p => p.value === value) || null); setOpcionesUsu(value ? searchResultUsu(value, ausu) : []) }

  const handleSearchEgas = (value, agas) => setOpcionesEgas(value ? searchResultEgas(value, agas) : [])
  const handleChangeEgas = (value) => { setSelEGasto(null); setDvSearchEgas(value) }
  const handleSelectEgas = (value, agas) => { setSelEGasto(egasto.find(p => p.code === value) || null); setOpcionesEgas(value ? searchResultEgas(value, agas) : []) }

  const handleSearchCcos = (value, ccos, prev) => {
    const aops = value ? searchResultCcos(value, ccos.codes) : []
    const acos = prev.filter(p => p.dimID !== ccos.dimID)
    const ncos = [...acos, { ...ccos, opciones: aops, code: null, dvSearch: value }]
    ncos.sort(sortCcosto)
    setCcosto(ncos)
  }

  const handleSelectCcos = (value, ccos, prev) => {
    const code = ccos.codes.find(p => p.codeID === value) || null
    const aops = value ? searchResultCcos(value, ccos.codes) : []
    const acos = prev.filter(p => p.dimID !== ccos.dimID)
    const ncos = [...acos, { ...ccos, opciones: aops, code, dvSearch: value }]
    ncos.sort(sortCcosto)
    setCcosto(ncos)
  }

  const handleRegRen = async () => {
    let err = null
    let data = null

    if (componentDisabled)
      return;

    if (!usuario)
      err = {
        message: msgType.noDataUser,
        oops: false
      }
    else if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !selRendicion)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (!fmTipoRen)
      err = {
        message: `${msgType.selectTipoRen}`,
        oops: false
      }
    else if (!fmFechaRen)
      err = {
        message: `${msgType.inputFechaRen}`,
        oops: false
      }
    else if (!fmArea)
      err = {
        message: `${msgType.selectArea}`,
        oops: false
      }
    else if (!fmMoneda)
      err = {
        message: `${msgType.selectMoneda}`,
        oops: false
      }
    else if (fmArea.porOtro && !selRindente)
      err = {
        message: `${msgType.selectRindente}`,
        oops: false
      }
    else if (moment().toDate().getTime() < fmFechaRen.toDate().getTime())
      err = {
        message: `${msgType.koDataFechaRen}`,
        oops: false
      }
    else if (selRendicion?.numRD && !selRendicion.allowPut)
      err = {
        message: msgType.rendicionNoMod,
        oops: false
      }
    else if (showCCosto) {
      const cos = ccosto.find(p => p.required && !p.code)
      if (cos)
        err = {
          message: `${cos.dimName} requerido(a).`,
          oops: false
        }
    }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadRegRen(true)

    const motivo = fmMotivo?.trim()
    const fechaRen = fmFechaRen.format().split('T')[0] + 'T00:00:00'
    const acos = showCCosto ? ccosto.filter(p => p.code).map(p => ({ dimID: p.dimID, codeID: p.code.codeID })) : null
    const numSO = fmTipoRen.bool1 ? selRendicion?.numSO : null
    const isNew = !selRendicion?.numRD || !selRendicion?.id

    const url = isNew ? renRegistrarUrl() : renModificarUrl(selRendicion?.id)
    const body = isNew ?
      {
        rcptNumDoc: selReceptor.ruc, grupo: selRendicion?.grpCode ?? selGrupo?.ruc,
        rendCode: (numSO ? null : fmTipoRen.codigo), tipo: (numSO ? fmTipoRen.codigo : fmTipoRen.tipoCode),
        area: fmArea.areaCode, rindente: selRindente?.userID ?? usuario.userID, fechaRen,
        monedaISO: fmMoneda.codigo, motivo, egasto: selEGasto?.code, ccosto: acos, numSO
      } :
      {
        rendCode: (numSO ? null : fmTipoRen.codigo), tipo: (numSO ? fmTipoRen.codigo : fmTipoRen.tipoCode),
        area: fmArea.areaCode, rindente: selRindente?.userID ?? selRendicion?.solicitante?.userID,
        fechaRen, monedaISO: fmMoneda.codigo, motivo, egasto: selEGasto?.code, ccosto: acos, numSO
      }
    const [data_api, err_api] = isNew ? await axiosTnaPost(url, body) : await axiosTnaPut(url, body)
    data = data_api
    err = err_api

    if (data) {
      notifyGreenRef.current.handleOpen({
        message: `${msgType.rendicionRegistrar} ${data.numRD}`,
        oops: false
      }, notifyType.success)
      const nren = await refreshRen(selReceptor, data)
      if (nren && updateRen)
        updateRen(nren)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadRegRen(false)
    setLastAccess()
  }

  const handleUpFile = async ({ target: { files } }) => {
    let err = null
    let data = null

    if (!files || files.length === 0) {
      clearFile()
      return
    }

    const file = files[0]

    if (file.size > configType.attachMaxSize)
      err = {
        message: msgType.fileKoPeso,
        oops: false
      }
    else if (!selRendicion?.numRD)
      err = {
        message: msgType.noRegRendicion,
        oops: false
      }
    else if (!selRendicion.allowPut)
      err = {
        message: msgType.rendicionNoAdj,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      clearFile()
      return
    }

    setLoadFile(true)

    const formData = new FormData()
    formData.append("", file)

    const url = renAdjuntarUrl(selRendicion.id)
    const [data_api, err_api] = await axiosTnaPut(url, formData)
    data = data_api
    err = err_api

    if (data) {
      const nren = await refreshRen(selReceptor, selRendicion)
      if (nren && updateRen)
        updateRen(nren)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    clearFile()
    setLoadFile(false)
    setLastAccess()
  }

  const handleDelFile = async (record) => {
    let err = null
    let data = null

    if (!selRendicion?.numRD)
      err = {
        message: msgType.noRegRendicion,
        oops: false
      }
    else if (!selRendicion.allowPut)
      err = {
        message: msgType.rendicionNoExc,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setCurrentLineFI(record.dataOrder)

    const url = renAdjuntarUrl(selRendicion.id, record.dataOrder)
    const [data_api, err_api] = await axiosTnaDelete(url)
    data = data_api
    err = err_api

    if (data && data.success) {
      const nren = await refreshRen(selReceptor, selRendicion)
      if (nren && updateRen)
        updateRen(nren)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setCurrentLineFI(null)
    setLastAccess()
  }

  const handleDownFile = async (record, download) => {
    let err = null
    let data = null

    setCurrentLineFI(record.dataOrder)

    const url = renRecuperarUrl(selRendicion.id, record.dataOrder)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (!download && record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setCurrentLineFI(null)
    setLastAccess()
  }

  const fiColumns = [
    {
      title: 'Archivo',
      dataIndex: 'dataOrder',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex space-x-1 items-center'>
            <button
              className='text-blue-600 text-left'
              disabled={record.dataOrder === currentLineFI}
              onClick={() => handleDownFile(record, false)}
            >
              {`${record.fileName}`}
            </button>
            {
              record.fileType &&
              <span>{`- ${record.fileType}`}</span>
            }
          </div>
          <div className='flex justify-end items-center'>
            {
              record.dataOrder !== currentLineFI &&
              <>
                <button onClick={() => handleDownFile(record, true)}>
                  <CloudDownloadOutlined />
                </button>
                {
                  !record.required &&
                  <Popconfirm placement="left"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    title={`¿Confirma la operación?`}
                    onConfirm={() => handleDelFile(record)}
                    okText="Sí"
                    cancelText="No"
                  >
                    <button className='ml-2'>
                      <DeleteFilled className='text-red-400' />
                    </button>
                  </Popconfirm>
                }
              </>
            }
            {
              record.dataOrder === currentLineFI &&
              <Spin size='small' className='ml-2' />
            }
          </div>
        </div>
    },
  ]

  return (
    <>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage &&
        <div className='my-5 flex justify-center'>
          <Spin tip='Loading....' size='large' />
        </div>
      }
      <div className='ant-tabs-titania ant-common-titania'>
        <Tabs
          defaultActiveKey="1"
          hidden={loadPage}
          activeKey={activeTabKey}
          onChange={handleActiveTabKey}
          items={[
            {
              label: `Data`,
              key: '1',
              children:
                <>
                  <Form
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChange}
                    disabled={componentDisabled}
                    autoComplete="off"
                    hidden={loadPage}
                    form={form}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="tipoRen"
                          label="Tipo de rendición"
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Select placeholder="Seleccione tipo de rendición" allowClear>
                            {
                              tiposRen.map(({ codigo, nombre }) => (
                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="fechaRen"
                          label="Fecha de rendición"
                          initialValue={dvFechaRen}
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <DatePicker placeholder='Ingrese fecha de rendición'
                            getPopupContainer={(trigger) => trigger.parentElement}
                            style={{
                              width: '100%',
                            }} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="area"
                          label="Area"
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Select placeholder="Seleccione área" allowClear disabled={selRendicion?.numSO || documentos.length > 0}>
                            {
                              areas.map(({ areaCode, areaName }) => (
                                <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="monedaISO"
                          label="Moneda"
                          initialValue={dvMoneda?.codigo}
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Select placeholder="Seleccione moneda" allowClear disabled={selRendicion?.numSO || documentos.length > 0}>
                            {
                              monedas.map(({ codigo }) => (
                                <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name="motivo"
                          label="Motivo de rendición"
                          initialValue={dvMotivo}
                          rules={[
                            {
                              required: false,
                              message: '',
                            },
                          ]}
                        >
                          <Input.TextArea
                            rows={2}
                            maxLength={500}
                            placeholder="Ingrese motivo de rendición" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  {
                    !loadPage &&
                    <>
                      {
                        fmArea && fmArea.porOtro &&
                        <div className='mb-2'>
                          <div className=' mb-1'>
                            <span className='span-requerido-enr-newren'>*</span>
                            <span>Rindente</span>
                          </div>
                          <div className='flex space-x-0.5'>
                            <AutoComplete
                              dropdownMatchSelectWidth={500}
                              style={{ width: 230, }}
                              options={opcionesUsu}
                              onSearch={(v) => handleSearchUsu(v, usuarios)}
                              onChange={handleChangeUsu}
                              onSelect={(v) => handleSelectUsu(v, usuarios)}
                              value={dvSearchUsu}
                              disabled={componentDisabled || selRendicion?.numSO}
                            >
                              <Input.Search size="middle" placeholder="Ingrese filtro" />
                            </AutoComplete>
                            <Input readOnly placeholder='Resultado de búsqueda' value={selRindente?.label} />
                          </div>
                        </div>
                      }
                      <div className='mb-2'>
                        <div className=' mb-1'>
                          <span>Elemento de gasto</span>
                        </div>
                        <div className='flex space-x-0.5'>
                          <AutoComplete
                            dropdownMatchSelectWidth={500}
                            style={{ width: 230, }}
                            options={opcionesEgas}
                            onSearch={(v) => handleSearchEgas(v, egasto)}
                            onChange={handleChangeEgas}
                            onSelect={(v) => handleSelectEgas(v, egasto)}
                            value={dvSearchEgas}
                            disabled={componentDisabled}
                          >
                            <Input.Search size="middle" placeholder="Ingrese filtro" />
                          </AutoComplete>
                          <Input readOnly placeholder='Resultado de búsqueda' value={selEGasto?.name} />
                        </div>
                      </div>
                      {
                        ccosto.length > 0 && showCCosto &&
                        ccosto.map((ccos) => (
                          <div key={ccos.index} className='mb-2'>
                            <div className=' mb-1'>
                              {
                                ccos.required &&
                                <span className='span-requerido-enr-newren'>*</span>
                              }
                              <span>{ccos.dimName}</span>
                            </div>
                            <div className='flex space-x-0.5'>
                              <AutoComplete
                                dropdownMatchSelectWidth={500}
                                style={{ width: 230, }}
                                options={ccos.opciones}
                                onSearch={(v) => handleSearchCcos(v, ccos, ccosto)}
                                onSelect={(v) => handleSelectCcos(v, ccos, ccosto)}
                                value={ccos.dvSearch}
                                disabled={componentDisabled}
                              >
                                <Input.Search size="middle" placeholder="Ingrese filtro" />
                              </AutoComplete>
                              <Input readOnly placeholder='Resultado de búsqueda' value={ccos.code?.codeName} />
                            </div>
                          </div>
                        ))
                      }
                      <div className='mt-3'>
                        <Switch checkedChildren="centro de costo" unCheckedChildren="centro de costo" checked={showCCosto} onChange={setShowCCosto} />
                      </div>
                    </>
                  }
                  <div className='flex justify-center mt-4'>
                    {
                      loadRegRen &&
                      <Tooltip title="Procesando" placement="top">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<SyncOutlined spin />}
                        />
                      </Tooltip>
                    }
                  </div>
                </>
            },
            {
              label: `Documento`,
              key: '2',
              disabled: !selRendicion?.numRD,
              children:
                <NewDoc selReceptor={selReceptor} selRendicion={selRendicion} monedas={monedas} egasto={egasto} area={fmArea} tolTCambio={tolTCambio}
                  showNotify={showNotifyRen} updateRen={updateRen} updateDoc={handleDocumentos} validar={validar} />
            },
            {
              label: `Devolución`,
              key: '3',
              disabled: !selRendicion?.numRD || fmTipoRen?.bool1 === true || validar,
              children:
                <NewDev selReceptor={selReceptor} selRendicion={selRendicion} cuentas={cuentas} tiposPago={tiposPago}
                  showNotify={showNotifyRen} updateRen={updateRen} />
            },
            (
              validar ?
                {
                  label: `Contable`,
                  key: '4',
                  disabled: !validar,
                  children:
                    <EditRenCtb selReceptor={selReceptor} dbRendicion={selRendicion} showNotify={showNotifyRen}
                      updateRen={updateRen}
                    />
                } : {}
            ),
            {
              label: <>Adjunto<span className='text-xs ml-1'>(<span className='text-blue-700'>ren</span>)</span></>,
              key: (validar ? '5' : '4'),
              disabled: !selRendicion?.numRD || validar,
              children:
                <>
                  <p className='text-xs mb-3'>Suba un archivo para la <span className='font-semibold'>rendición</span>:</p>
                  {
                    adjuntos.length > 0 &&
                    <div className='mb-3 ant-table-titania'>
                      <Table
                        columns={fiColumns}
                        dataSource={adjuntos}
                        pagination={false}
                        bordered
                        size='small'
                      />
                    </div>
                  }
                  <div className='flex items-center space-x-2'>
                    <label
                      htmlFor="file-upload"
                      className={"flex items-center p-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (loadFile ? " hover:cursor-not-allowed" : "")}
                    >
                      <CloudUploadOutlined className="mr-1" />
                      <span>Subir archivo</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only"
                        onChange={handleUpFile}
                        disabled={loadFile}
                      />
                    </label>
                    {
                      loadFile &&
                      <div className='flex items-center mr-2 text-indigo-600'>
                        <SyncOutlined spin />
                      </div>
                    }
                  </div>
                </>
            }
          ]}
        />
      </div >
    </>
  )
})
