import { Chart as ChartJS, ArcElement, Tooltip as TooltipJS, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { SyncOutlined, AuditOutlined, PieChartOutlined } from '@ant-design/icons'
import { DatePicker, Empty, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { accAsignarUrl, chtGetTotDocUrl, rolGetUserPagUrl, usuRcptEmsrUrl } from '../../api/apiUrl'
import { AppFooter } from '../../components/AppFooter'
import { configType } from '../../types/configType';
import { NotifyGreen } from '../../components/Msg/NotifyGreen'
import { NotifyRed } from '../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../components/Msg/NotifyYellow'
import { useAuth } from '../../hooks/useAuth'
import { useAxiosTna } from '../../hooks/useAxiosTna'
import { useForm } from '../../hooks/useForm'
import { msgType } from '../../types/msgType'
import { notifyType } from '../../types/notifyType'
import { setLastAccess } from '../../utils/lstorageUtil'

import './Dashboard.css'

ChartJS.register(ArcElement, TooltipJS, Legend)

const chartJS = [
  { tipoDocumentoID: 'OC', label: 'Orden de compra', backgroundColor: 'rgba(255, 99, 132, 0.2)', borderColor: 'rgba(255, 99, 132, 1)' },
  { tipoDocumentoID: 'RC', label: 'Recepción', backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgba(54, 162, 235, 1)' },
  { tipoDocumentoID: 'CP', label: 'Comprobante', backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)' },
  { tipoDocumentoID: 'PG', label: 'Pago', backgroundColor: 'rgba(75, 182, 150, 0.2)', borderColor: 'rgba(75, 182, 150, 1)' },
  { tipoDocumentoID: 'SO', label: 'Solicitud', backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)' },
  { tipoDocumentoID: 'RD', label: 'Rendición', backgroundColor: 'rgba(255, 159, 64, 0.2)', borderColor: 'rgba(255, 159, 64, 1)' },
  { tipoDocumentoID: 'DO', label: 'Documento', backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)' },
  { tipoDocumentoID: 'OP', label: 'Operación', backgroundColor: 'rgba(104, 162, 215, 0.2)', borderColor: 'rgba(104, 162, 215, 1)' },
]

export const Dashboard = () => {
  const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
  const { setAuth } = useAuth()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [disablePage, setDisablePage] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
  const [loadAsignar, setLoadAsignar] = useState(false)
  const [loadGraficar, setLoadGraficar] = useState(false)
  const [runBuildChart, setRunBuildChart] = useState(false)
  const [emisores, setEmisores] = useState([])
  const [receptores, setReceptores] = useState([])
  const [grupos, setGrupos] = useState([])
  const [selectedEmsr, setSelectedEmsr] = useState(null)
  const [selectedRcpt, setSelectedRcpt] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [fechaAnual, setFechaAnual] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [{ accessCode }, handleInputChange, resetInputData] = useForm({ accessCode: '' })

  useEffect(() => {
    setLoadPage(loadRcptEmsr)
  }, [loadRcptEmsr])

  useEffect(() => {
    setDisablePage(loadAsignar)
  }, [loadAsignar])

  useEffect(() => {
    let isMounted = true

    const rcptEmsr = async () => {
      const url = usuRcptEmsrUrl(configType.recursoAll)
      const [data, err] = await axiosTnaGet(url)

      if (data && isMounted) {
        if (data.rcpt && data.rcpt.length > 0) {
          setReceptores(data.rcpt)
          changeRcpt(data.rcpt.length > 0 ? data.rcpt[0] : null)
        }
        else if (data.emsr && data.emsr.length > 0) {
          setEmisores(data.emsr)
          changeEmsr(data.emsr.length > 0 ? data.emsr[0] : null)
        }
        setFechaAnual(moment())
      }
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadRcptEmsr(false)
    }

    rcptEmsr()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    let isMounted = true

    if (isMounted && selectedRcpt && (selectedGroup || selectedRcpt.grpAll))
      handleBuildChart()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [runBuildChart])


  const changeEmsr = (person) => {
    setSelectedEmsr(person)
    let rcpts = []
    if (person && person.rcpt.length > 0)
      rcpts = person.rcpt
    setReceptores(rcpts)
    changeRcpt(rcpts.length > 0 ? rcpts[0] : null)
  }

  const changeRcpt = (person) => {
    setSelectedRcpt(person)
    let grupos = []
    if (person && person.grupo.length > 0)
      grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem }))
    setGrupos(grupos)
    changeGroup(grupos.length > 0 ? grupos[0] : null)
  }

  const changeGroup = (person) => {
    setSelectedGroup(person)
    setRunBuildChart(true)
  }

  const handleChangeEmsr = (v) => {
    const person = emisores.find(p => p.ruc === v) || null
    changeEmsr(person)
  }

  const handleChangeRcpt = (v) => {
    const person = receptores.find(p => p.ruc === v) || null
    changeRcpt(person)
  }

  const handleChangeGroup = (v) => {
    const person = grupos.find(p => p.ruc === v) || null
    changeGroup(person)
  }

  const handleAssignCode = async () => {
    let err = null
    let data = null

    if (!accessCode)
      err = {
        message: msgType.dabrdNoCodigo,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadAsignar(true)

    let url = accAsignarUrl(accessCode)
    const [data_api, err_api] = await axiosTnaPut(url, null)
    data = data_api
    err = err_api

    if (data) {
      resetInputData()
      notifyGreenRef.current.handleOpen({
        message: msgType.dabrdAssignCodeOK,
        oops: false
      }, notifyType.success)

      url = rolGetUserPagUrl()
      const [data_rol, err_rol] = await axiosTnaGet(url)
      err = err_rol

      if (data_rol && data_rol.success) {
        const { resource } = data_rol
        setAuth(prev => ({ ...prev, ropag: resource }))
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadAsignar(false)
    setLastAccess()
  }

  const handleBuildChart = async () => {
    let err = null
    let data = null

    if (!selectedRcpt)
      err = {
        message: msgType.dabrdNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.dabrdNoGroup,
        oops: false
      }
    else if (!selectedEmsr && emisores && emisores.length > 0)
      err = {
        message: msgType.dabrdNoEmsr,
        oops: false
      }
    else if (!fechaAnual)
      err = {
        message: msgType.dabrdNoAnho,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadGraficar(true)

    const grpCode = selectedRcpt.grpAll ? '' : selectedGroup.ruc
    const emsrRUC = selectedEmsr ? selectedEmsr.ruc : ''
    const fechaIni = `${fechaAnual.format('YYYY')}-01-01T00:00:00`
    const fechaFin = `${fechaAnual.format('YYYY')}-12-31T00:00:00`

    const url = chtGetTotDocUrl(selectedRcpt.ruc, grpCode, emsrRUC, fechaIni, fechaFin)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { success, data: aTipDoc } = data
      if (success) {
        const aData = chartJS.map(p => {
          const tipDoc = aTipDoc.find(q => q.tipoDocumentoID === p.tipoDocumentoID) || null
          return { ...p, tipDoc }
        }).filter(p => p.tipDoc).map(p => ({
          tipoDocumentoID: p.tipoDocumentoID,
          label: p.label,
          data: selectedEmsr ? p.tipDoc.emsrTotal : p.tipDoc.rcptTotal,
          backgroundColor: p.backgroundColor,
          borderColor: p.borderColor,
        }))
        if (aData && aData.length > 0) {
          const dataJS = {
            labels: aData.map(p => p.label),
            datasets: [
              {
                label: '# de Documentos',
                data: aData.map(p => p.data),
                backgroundColor: aData.map(p => p.backgroundColor),
                borderColor: aData.map(p => p.borderColor),
                borderWidth: 1,
              },
            ],
          }
          setChartData(dataJS)
        }
        else
          setChartData(null)
      }
      else
        setChartData(null)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadGraficar(false)
    setLastAccess()
  }

  return (
    <div className="bg-white flex flex-col flex-grow">
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='flex-grow p-3'>
            <p className="text-xs text-gray-700 mb-2">
              Ingresa un código de acceso para poder utilizar el sistema.
            </p>
            <div className="flex">
              <span className="inline-flex items-center p-1 text-xs rounded-l rounded-r-none border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-500">
                CODIGO
              </span>
              <input
                type="text"
                name="accessCode"
                id="accessCode"
                autoComplete='accessCode'
                className="block text-xs pl-2 mr-1.5 w-28 rounded-l-none rounded-r border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                value={accessCode}
                onChange={handleInputChange}
                readOnly={disablePage}
                maxLength='8'
              />
              {
                !loadAsignar &&
                <button
                  className="flex items-center relative rounded-md bg-white font-medium text-indigo-600"
                  disabled={disablePage}
                  onClick={handleAssignCode}
                >
                  <Tooltip title="Asignar código" placement='right'>
                    <AuditOutlined aria-hidden="true" />
                  </Tooltip>
                </button>
              }
              {
                loadAsignar &&
                <div className='flex items-center text-indigo-600'>
                  <SyncOutlined spin />
                </div>
              }
            </div>

            <div className='mt-5'>
              <p className="text-xs text-gray-700 mb-2">
                El siguiente gráfico indica el total de documentos ingresados al sistema.
              </p>
              <div className='flex items-center space-x-2'>
                {
                  emisores.length > 0 &&
                  <>
                    {
                      selectedEmsr ?
                        <Select value={selectedEmsr.ruc} placeholder='Emisor' allowClear style={{ width: 300 }} onChange={(v) => handleChangeEmsr(v || null)}>
                          {
                            emisores.map(({ ruc, name }) => (
                              <Select.Option key={ruc} value={ruc}>{name}</Select.Option>
                            ))
                          }
                        </Select> :
                        <Select placeholder='Emisor' allowClear style={{ width: 300 }} onChange={(v) => handleChangeEmsr(v || null)}>
                          {
                            emisores.map(({ ruc, name }) => (
                              <Select.Option key={ruc} value={ruc}>{name}</Select.Option>
                            ))
                          }
                        </Select>
                    }
                  </>
                }
                {
                  selectedRcpt ?
                    <Select value={selectedRcpt.ruc} placeholder='Receptor' allowClear style={{ width: 300 }} onChange={(v) => handleChangeRcpt(v || null)}>
                      {
                        receptores.map(({ ruc, name }) => (
                          <Select.Option key={ruc} value={ruc}>{name}</Select.Option>
                        ))
                      }
                    </Select> :
                    <Select placeholder='Receptor' allowClear style={{ width: 300 }} onChange={(v) => handleChangeRcpt(v || null)}>
                      {
                        receptores.map(({ ruc, name }) => (
                          <Select.Option key={ruc} value={ruc}>{name}</Select.Option>
                        ))
                      }
                    </Select>
                }
                {
                  !(selectedRcpt?.grpAll ?? true) &&
                  <>
                    {
                      selectedGroup ?
                        <Select value={selectedGroup.ruc} placeholder={`${selectedRcpt.grpName ?? ''}`} allowClear style={{ width: 175 }} onChange={(v) => handleChangeGroup(v || null)}>
                          {
                            grupos.map(({ ruc, name }) => (
                              <Select.Option key={ruc} value={ruc}>{name}</Select.Option>
                            ))
                          }
                        </Select> :
                        <Select placeholder={`${selectedRcpt.grpName ?? ''}`} allowClear style={{ width: 175 }} onChange={(v) => handleChangeGroup(v || null)}>
                          {
                            grupos.map(({ ruc, name }) => (
                              <Select.Option key={ruc} value={ruc}>{name}</Select.Option>
                            ))
                          }
                        </Select>
                    }
                  </>
                }
                <DatePicker placeholder='Año' picker="year"
                  value={fechaAnual}
                  onChange={(d,) => setFechaAnual(d ?? null)} />
                {
                  !loadGraficar &&
                  <button
                    className="flex items-center relative rounded-md bg-white font-medium text-indigo-600"
                    disabled={disablePage}
                    onClick={handleBuildChart}
                  >
                    <Tooltip title="Generar gráfico" placement='right'>
                      <PieChartOutlined aria-hidden="true" />
                    </Tooltip>
                  </button>
                }
                {
                  loadGraficar &&
                  <div className='flex items-center text-indigo-600'>
                    <SyncOutlined spin />
                  </div>
                }
              </div>
              <div className='h-96 mt-2 w-full flex justify-start'>
                {
                  chartData ?
                    <Pie data={chartData} /> :
                    <Empty className='ml-10' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </div>
            </div>
          </div>
      }
      <div className='w-full'>
        <AppFooter />
      </div>
    </div>
  )
}
