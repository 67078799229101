import './ValidarCPE.css'

import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { SyncOutlined } from '@ant-design/icons'
import { Spin, Tooltip } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { segConnUrl, tsk11Url, tskRecuperarUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'

var jszip = require('jszip')

const campos = [
    { label: 'RUC', value: 'RUC del emisor' },
    { label: 'TCP', value: 'Código del tipo de comprobante' },
    { label: 'SER', value: 'Número de serie' },
    { label: 'NUM', value: 'Número correlativo' },
    { label: 'FEC', value: 'Fecha de emisión en formato yyyyMMdd' },
    { label: 'TOT', value: 'Importe total' },
]

export const ValidarCPE = ({ page: { code: resource } }) => {
    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [disablePage, setDisablePage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
    const [loadTask11Add, setLoadTask11Add] = useState(false)
    const [loadTask11Down, setLoadTask11Down] = useState(false)
    const [receptores, setReceptores] = useState([])
    const [selReceptor, setSelReceptor] = useState(null)
    const [taskInterval, setTaskInterval] = useState(false)
    const [task11, setTask11] = useState({ task: null, id: null, first: true })

    useEffect(() => {
        setLoadPage(loadTokenConn || loadRcptEmsr)
    }, [loadTokenConn, loadRcptEmsr])

    useEffect(() => {
        setDisablePage(loadTask11Add || loadTask11Down)
    }, [loadTask11Add, loadTask11Down])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const rcptEmsr = async () => {
            const url = usuRcptEmsrUrl(resource)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setReceptores(data.rcpt)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRcptEmsr(false)
        }

        if (!loadTokenConn) {
            rcptEmsr()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const t9Get = async () => {
            const url = tsk11Url()
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTask11(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
            if (err)
                console.log(`Task: ${err.message}`)
        }

        if (!loadTokenConn && (task11.first || (!task11.task && task11.id) || (task11.task && !task11.task.complete)))
            t9Get()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [taskInterval, loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const interval = setInterval(() => {
            isMounted && setTaskInterval(prev => !prev)
        }, 5000)

        return () => {
            clearInterval(interval)
            isMounted = false
        }
    }, [])

    const clearFile = () => {
        const input = document.getElementById("file-upload-ctocpeval")
        if (input)
            input.value = null
    }

    const getClassName = (index, lista) => {
        if (index === 0)
            return 'sm:flex'
        else if (index === lista.length - 1)
            return 'py-0.5 sm:flex'
        else
            return 'pt-0.5 sm:flex'
    }

    const handleSelReceptor = useCallback((person) => {
        setSelReceptor(person)
    }, [setSelReceptor])

    const handleTask11_Add = async ({ target: { files } }) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.fileKoPeso,
                oops: false
            }
        else if (file.type !== configType.fileTypeTXT)
            err = {
                message: msgType.fileKoTXT,
                oops: false
            }
        else if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearFile()
            return
        }

        setLoadTask11Add(true)

        const formData = new FormData()
        formData.append("", file)

        const url = tsk11Url(selReceptor.ruc)
        const [data_api, err_api] = await axiosTnaPut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            setTask11(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
        }
        if (err) {
            notifyRedRef.current.handleOpen(err, notifyType.error)
            clearFile()
        }

        setLoadTask11Add(false)
        setLastAccess()
    }

    const handleTask11_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask11Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask11(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask11Down(false)
        setLastAccess()
    }

    return (
        <div className="bg-white flex flex-col flex-grow">
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex-grow'>
                        <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
                            <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
                        </div>

                        <div className='p-3 flex flex-col ant-common-titania'>
                            <div className="flex flex-row flex-wrap space-x-3 mb-3">
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task11.id || task11.task?.message) ?
                                            <>
                                                {
                                                    (!disablePage) ?
                                                        <label
                                                            htmlFor="file-upload-ctocpeval"
                                                            className="flex items-center relative cursor-pointer"
                                                        >
                                                            <Tooltip title="Cargar archivo de documento" placement='right'>
                                                                <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            <span>Cargar</span>
                                                            <input id="file-upload-ctocpeval" name="file-upload-ctocpeval" type="file" className="sr-only"
                                                                onChange={handleTask11_Add}
                                                                accept=".txt"
                                                            />
                                                        </label> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask11Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Cargar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task11.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask11Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask11_Down(task11.task)}
                                                            >
                                                                {task11.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    task11.task?.message &&
                                    <div className='flex items-center'>
                                        <p className="text-xs text-red-700">
                                            {task11.task?.message}
                                        </p>
                                    </div>
                                }
                            </div>

                            <p className="text-xs text-gray-700 mb-2">
                                Carga un archivo de texto con el listado de comprobantes por validar.
                            </p>

                            <div className='text-xs mb-2'>
                                <span>Estructura del archivo</span>
                                <p className='text-blue-900'>RUC|TCP|SER|NUM|FEC|TOT</p>
                            </div>

                            <div className='text-xs'>
                                <span>Ejemplo del arhivo</span>
                                <p className='text-blue-900'>20562986651|01|F001|12494|20230705|5905.9</p>
                            </div>

                            <div className='mt-3 ml-1 w-1/3'>
                                <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                                    {
                                        campos.map(({ label, value }, index) => (
                                            <div className={getClassName(index, campos)} key={index}>
                                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-20 sm:flex-none sm:pr-6">{label}</dt>
                                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                    <div className="text-gray-900 text-xs">{value}</div>
                                                </dd>
                                            </div>
                                        ))
                                    }
                                </dl>
                            </div>
                        </div>
                    </div>
            }
            <div className='w-full'>
                <AppFooter />
            </div>
        </div >
    )
}