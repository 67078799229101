import './EditRenCtb'

import { SyncOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Checkbox, Col, DatePicker, Form, Popconfirm, Row, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { renGetNumUrl, renModificarUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { setLastAccess } from '../../../../utils/lstorageUtil'

export const EditRenCtb = (props) => {
    const { selReceptor, dbRendicion, showNotify, updateRen } = props

    const dvFecha = moment()
    const dvReponer = false

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const [formDta] = Form.useForm()
    const [componentDisabled, setComponentDisabled] = useState(false)
    const [loadModRen, setLoadModRen] = useState(false)
    const [fmFechaCtb, setFmFechaCtb] = useState(dvFecha)
    const [fmReponer, setFmReponer] = useState(dvReponer)

    useEffect(() => {
        setComponentDisabled(loadModRen)
    }, [loadModRen])

    useEffect(() => {
        if (dbRendicion) {
            const fechaCtb = moment(dbRendicion.fechaCtb)
            setFmFechaCtb(fechaCtb)
            setFmReponer(dbRendicion.reponer)
            formDta.setFieldsValue({
                fechaCtb: fechaCtb,
                reponer: dbRendicion.reponer,
            })
        }
        else {
            setFmFechaCtb(dvFecha)
            setFmReponer(dvReponer)
            formDta.resetFields()
        }
        // eslint-disable-next-line
    }, [dbRendicion])

    const refreshRen = async (rcpt, ren) => {
        const url = renGetNumUrl(rcpt.ruc, ren.numRD)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Rendición: ${err.message}`)
        const nren = data?.success ? data.data : null
        return nren
    }

    const onValuesChangeDta = (changedValues, allValues) => {
        const fechaCtb = allValues.fechaCtb ?? null
        const reponer = allValues.reponer ?? null
        setFmFechaCtb(fechaCtb)
        setFmReponer(reponer)
    }

    const handleModRen = async () => {
        let err = null
        let data = null

        if (componentDisabled)
            return;

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!dbRendicion)
            err = {
                message: msgType.selectRendicion,
                oops: false
            }
        else if (!fmFechaCtb)
            err = {
                message: `${msgType.registroNoField} fecha contable.`,
                oops: false
            }
        else if (fmReponer === null)
            err = {
                message: `${msgType.registroNoField} reponer.`,
                oops: false
            }
        else if (fmFechaCtb.toDate().getTime() < moment(dbRendicion.fechaRen).toDate().getTime())
            err = {
                message: `${msgType.koDataFechaCtb}`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModRen(true)

        const fechaCtb = fmFechaCtb.format().split('T')[0] + 'T00:00:00'

        const url = renModificarUrl(dbRendicion.id, true)
        const body = { fechaCtb, reponer: fmReponer }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.rendicionModificar}`,
                oops: false
            }, notifyType.success)
            const nren = await refreshRen(selReceptor, dbRendicion)
            if (nren && updateRen)
                updateRen(nren, true)
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModRen(false)
        setLastAccess()
    }

    return (
        <div className='flex justify-between'>

            <div className='w-full'>
                <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                    <Form
                        layout="vertical" requiredMark
                        onValuesChange={onValuesChangeDta}
                        disabled={componentDisabled}
                        autoComplete="off"
                        form={formDta}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name="fechaCtb"
                                    label="Fecha contable"
                                    initialValue={dvFecha}
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <DatePicker placeholder='Ingrese fecha contable'
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        style={{
                                            width: '100%',
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="reponer"
                                    label="Reponer rendición"
                                    valuePropName="checked"
                                    initialValue={dvReponer}
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Checkbox disabled={!dbRendicion?.origen}>
                                        {fmReponer ? 'Con reposición' : 'Sin reposición'}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={8}></Col>
                        </Row>
                    </Form>
                </dl>
            </div>

            <div className="flex flex-row justify-end space-x-3 mx-1">
                {
                    loadModRen &&
                    <div className='flex items-center text-indigo-600'>
                        <SyncOutlined spin />
                    </div>
                }
                <div className="flex items-center text-sm text-indigo-700">
                    <Popconfirm
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        title={`¿Confirma la operación?`}
                        onConfirm={handleModRen}
                        placement="left"
                        okText="Sí"
                        cancelText="No"
                        disabled={loadModRen}
                    >
                        <div className='flex items-center cursor-pointer'>
                            <Tooltip title="Guardar rendición" placement='left'>
                                <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                            </Tooltip>
                            Guardar
                        </div>
                    </Popconfirm>
                </div>
            </div>

        </div>
    )
}