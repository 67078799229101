import './AccesoER.css'

import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { CloseCircleOutlined, QuestionCircleOutlined, FileAddOutlined, FileTextOutlined, SearchOutlined, ZoomOutOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip, message } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { aerEliminarUrl, aerGetCfgUrl, catGetCodUrl, rctGrpRecurUrl, segConnUrl, tsk15Url, tsk16Url, tskRecuperarUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { NewAer } from '../../../components/Panel/cfg/NewAer'

var jszip = require('jszip')

export const AccesoER = ({ page: { code: resource } }) => {
    const dvFechaIni = moment().startOf('month')
    const dvFechaFin = moment().endOf('month')

    const { axiosTnaGet, axiosTnaPut, axiosTnaDelete, axiosTnaPost } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const newAerRef = useRef()
    const [disablePage, setDisablePage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
    const [loadRol, setLoadRol] = useState(true)
    const [loadConfigRT, setLoadConfigRT] = useState(false)
    const [loadBusqAer, setLoadBusqAer] = useState(false)
    const [loadEliAer, setLoadEliAer] = useState(false)
    const [loadTask15Add, setLoadTask15Add] = useState(false)
    const [loadTask15Down, setLoadTask15Down] = useState(false)
    const [loadTask16Add, setLoadTask16Add] = useState(false)
    const [loadTask16Down, setLoadTask16Down] = useState(false)
    const [allGroup, setAllGroup] = useState(false)
    const [newEdit, setNewEdit] = useState(true)
    const [receptores, setReceptores] = useState([])
    const [grupos, setGrupos] = useState([])
    const [roles, setRoles] = useState([])
    const [areas, setAreas] = useState([])
    const [selReceptor, setSelReceptor] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
    const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
    const [foRol, setFoRol] = useState(null)
    const [foArea, setFoArea] = useState(null)
    const [foAccess, setFoAccess] = useState(null)
    const [foSave, setFoSave] = useState(null)
    const [accesos, setAccesos] = useState([])
    const [selAcceso, setSelAcceso] = useState(null)
    const [arTotalDocuments, setArTotalDocuments] = useState(0)
    const [arPageSize, setArPageSize] = useState(10)
    const [arCurrentPage, setArCurrentPage] = useState(1)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)
    const [activeTabAer, setActiveTabAer] = useState('1')
    const [taskInterval, setTaskInterval] = useState(false)
    const [task15, setTask15] = useState({ task: null, id: null, first: true })
    const [task16, setTask16] = useState({ task: null, id: null, first: true })

    useEffect(() => {
        setLoadPage(loadTokenConn || loadRcptEmsr || loadRol)
    }, [loadTokenConn, loadRcptEmsr, loadRol])

    useEffect(() => {
        setDisablePage(loadConfigRT || loadBusqAer || loadEliAer || loadTask15Add || loadTask15Down || loadTask16Add || loadTask16Down)
    }, [loadConfigRT, loadBusqAer, loadEliAer, loadTask15Add, loadTask15Down, loadTask16Add, loadTask16Down])

    useEffect(() => {
        setNewEdit(!(selectedRows && selectedRows.length > 0))
    }, [selectedRows])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const rcptEmsr = async () => {
            const url = usuRcptEmsrUrl(resource)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setReceptores(data.rcpt)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRcptEmsr(false)
        }

        const catRol = async () => {
            const url = catGetCodUrl(configType.catRol)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setRoles(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRol(false)
        }

        if (!loadTokenConn) {
            rcptEmsr()
            catRol()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const t15Get = async () => {
            const url = tsk15Url()
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTask15(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
            if (err)
                console.log(`Task: ${err.message}`)
        }

        const t16Get = async () => {
            const url = tsk16Url()
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTask16(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
            if (err)
                console.log(`Task: ${err.message}`)
        }

        if (!loadTokenConn) {
            if (task15.first || (!task15.task && task15.id) || (task15.task && !task15.task.complete))
                t15Get()
            if (task16.first || (!task16.task && task16.id) || (task16.task && !task16.task.complete))
                t16Get()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [taskInterval, loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const interval = setInterval(() => {
            isMounted && setTaskInterval(prev => !prev)
        }, 5000)

        return () => {
            clearInterval(interval)
            isMounted = false
        }
    }, [])

    const setConfigRT = useCallback(async (person) => {
        setFoArea(null)

        if (!person) {
            setAreas([])
            return
        }

        setLoadConfigRT(true)

        const url = rctGrpRecurUrl(person.rcptRUC, allGroup ? '' : person.ruc, resource)
        const [data, err] = await axiosTnaGet(url)

        if (data) {
            const { success, data: config } = data
            if (success) {
                setAreas(config.areas || [])
            }
        }
        if (err) {
            setAreas([])
            notifyRedRef.current.handleOpen(err, notifyType.error)
        }

        setLoadConfigRT(false)

        // eslint-disable-next-line
    }, [setAreas, setFoArea])

    const sortAer = (a, b) => {
        if (a.index > b.index)
            return 1
        else
            return -1
    }

    const clearFile = () => {
        const input = document.getElementById("file-upload-aercfg")
        if (input)
            input.value = null
    }

    const getAer = (aer, index = null) => {
        var naer = { ...aer, key: aer.id, index }
        if (index === null)
            delete naer.index
        return naer
    }

    const getTitleDrawer = (aer, grp) => {
        if (!aer || aer.isDet === null || aer.isDet === undefined)
            return ''
        else if (aer.isDet === 0 && !aer.id)
            return `Nuevo Acceso ( ${aer.ruc ?? grp?.rcptRUC} - ${aer.grpCode ?? grp?.ruc} )`
        else if (aer.isDet === 0 && aer.id)
            return `Editar Acceso ( ${aer.ruc ?? grp?.rcptRUC} - ${aer.grpCode ?? grp?.ruc} )`
        else
            return ''
    }

    const clearFiltroAR = (all = true) => {
        if (all) {
            setFoSave(null)
            setAccesos([])
            setArTotalDocuments(0)
        }
        setSelAcceso(null)
        setSelectedRows([])
        setSelectedRowKeys([])
    }

    const handleActiveTabAer = useCallback((tab) => { setActiveTabAer(tab) }, [setActiveTabAer])

    const handleSetAcceso = useCallback((aer, sel = true) => {
        const uaer = getAer(aer)
        setAccesos(prev => {
            const oaer = prev.find(p => p.id === uaer.id)
            const aaer = prev.filter(p => p.id !== uaer.id)
            const naer = oaer ? [...aaer, { ...oaer, ...uaer }] : [...aaer]
            naer.sort(sortAer)
            return naer
        })
        if (sel)
            setSelAcceso(prev => ({ ...prev, ...uaer }))
    }, [setSelAcceso])

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        setSelAcceso(null)
        setActiveTabAer('1')
    }

    const handleSelReceptor = useCallback((person) => {
        setSelReceptor(person)
        let a_grupos = []
        if (person && person.grupo.length > 0)
            a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
        setGrupos(a_grupos)
        clearFiltroAR()
    }, [setSelReceptor])

    const handleSelGrupo = useCallback((person) => {
        setSelGrupo(person)
        setConfigRT(person)
        clearFiltroAR()
    }, [setSelGrupo, setConfigRT])

    const handleBuscarARFecha = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foAccess && (!foFechaIni || !foFechaFin))
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqAer(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
        const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
        const access = foAccess || ''
        const rol = foRol || ''
        const area = foArea || ''

        const url = aerGetCfgUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, access, rol, area, resource, 1, arPageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: aers } = data
            setAccesos(aers.map((aer, index) => getAer(aer, index)))
            setArTotalDocuments(totalDocuments)
            setArCurrentPage(1)
            clearFiltroAR(false)
            setFoSave({
                svSelReceptor: selReceptor,
                svGrupo: grupo,
                svFechaIni: fechaIni,
                svFechaFin: fechaFin,
                svAccess: access,
                svRol: rol,
                svArea: area
            })
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqAer(false)
        setLastAccess()
    }

    const handleChangePagAR = async (page, pageSize) => {
        if (!foSave)
            return

        const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svAccess, svRol, svArea } = foSave

        let err = null
        let data = null

        setLoadBusqAer(true)

        const url = aerGetCfgUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svAccess, svRol, svArea, resource, page, pageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: aers } = data
            setAccesos(aers.map((aer, index) => getAer(aer, index)))
            setArTotalDocuments(totalDocuments)
            setArCurrentPage(page)
            clearFiltroAR(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqAer(false)
        setLastAccess()
    }

    const handleNuevaAer = () => {
        const aer = (selectedRows && selectedRows.length > 0) ? (accesos.find(p => p.id === selectedRows[0].id) || {}) : {}
        let err = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if ((!selGrupo || allGroup) && !aer.id)
            err = {
                message: msgType.selectGrupo.replace('grupo', selReceptor.grpName.toLowerCase()),
                oops: false
            }
        else if (selectedRows && selectedRows.length > 1)
            err = {
                message: msgType.registroNoOne,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setSelAcceso({ ...aer, isDet: 0 })
        setOpenDrawer(true)
    }

    const handleRegistrarAer = () => newAerRef.current.handleSave()

    const handleEliminarAer = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selAcceso)
            err = {
                message: msgType.selectAcceso,
                oops: false
            }

        if (err) {
            newAerRef.current.showNotify(err, notifyType.warning)
            return
        }

        setLoadEliAer(true)

        const url = aerEliminarUrl(selAcceso.id)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data) {
            setAccesos(prev => prev.filter(aer => aer.id !== selAcceso.id))
            handleCloseDrawer()
            message.success(msgType.accesoEliminar);
        }
        if (err)
            newAerRef.current.showNotify(err, notifyType.error)

        setLoadEliAer(false)
        setLastAccess()
    }

    const handleTask15_Add = async ({ target: { files } }) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.fileKoPeso,
                oops: false
            }
        else if (file.type !== configType.fileTypeTXT)
            err = {
                message: msgType.fileKoTXT,
                oops: false
            }
        else if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearFile()
            return
        }

        setLoadTask15Add(true)

        const formData = new FormData()
        formData.append("", file)

        const url = tsk15Url(selReceptor.ruc)
        const [data_api, err_api] = await axiosTnaPut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            setTask15(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
        }
        if (err) {
            notifyRedRef.current.handleOpen(err, notifyType.error)
            clearFile()
        }

        setLoadTask15Add(false)
        setLastAccess()
    }

    const handleTask15_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask15Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask15(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask15Down(false)
        setLastAccess()
    }

    const handleTask16_Add = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foAccess && (!foFechaIni || !foFechaFin))
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadTask16Add(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
        const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
        const access = foAccess || ''
        const rol = foRol || ''
        const area = foArea || ''

        const url = tsk16Url()
        const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, grupo, fechaIni, fechaFin, access, rol, area, resource })
        data = data_api
        err = err_api

        if (data) {
            setTask16(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask16Add(false)
        setLastAccess()
    }

    const handleTask16_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask16Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask16(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask16Down(false)
        setLastAccess()
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys)
            setSelectedRows(newSelectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: false,
        }),
        type: 'radio',
    }

    const rdColumns = [
        {
            title: 'Usuario',
            dataIndex: 'usuario',
            render: (_, record) => <span>{record.usuario?.userID}</span>
        },
        {
            title: 'Rol',
            dataIndex: 'rol',
            width: 120,
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            })
        },
        {
            title: 'Area',
            dataIndex: 'area',
            width: 250,
            responsive: ['lg'],
            render: (_, record) => allGroup ? <span>{`${record.grpCode} ${record.area}`}</span> : <span>{record.area}</span>
        },
        {
            title: 'Fecha',
            dataIndex: 'regDate',
            width: 150,
            render: (_, record) => <span>{formatTime(record.regDate)}</span>
        },
    ]

    return (
        <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
            <Drawer
                title={getTitleDrawer(selAcceso, selGrupo)}
                placement="right"
                onClose={handleCloseDrawer}
                open={openDrawer}
                getContainer={false}
                style={{ position: 'absolute' }}
                bodyStyle={{ padding: 20, }}
                headerStyle={{ padding: 15, }}
                destroyOnClose={true}
                maskClosable={false}
                zIndex={5}
                width={720}
                closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
                extra={
                    <Space>
                        {
                            (selAcceso) ?
                                <>
                                    {
                                        selAcceso.isDet === 0 &&
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`¿Confirma la operación?`}
                                            onConfirm={handleRegistrarAer}
                                            placement="left"
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button shape="round" type="primary">
                                                Guardar
                                            </Button>
                                        </Popconfirm>
                                    }
                                    {
                                        (selAcceso.isDet === 1 || (selAcceso.isDet === 0 && activeTabAer === '1' && selAcceso.id)) &&
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`¿Confirma la operación?`}
                                            onConfirm={handleEliminarAer}
                                            placement="left"
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button shape="round" type="primary">
                                                Eliminar
                                            </Button>
                                        </Popconfirm>
                                    }
                                </> :
                                <></>
                        }
                    </Space>
                }
            >
                {
                    selAcceso ?
                        <>
                            {
                                selAcceso.isDet === 0 ?
                                    <NewAer ref={newAerRef} selReceptor={selReceptor} selGrupo={selGrupo} selAcceso={selAcceso}
                                        roles={roles} updateAer={handleSetAcceso} tabKey={handleActiveTabAer} /> :
                                    <></>
                            }
                        </> :
                        <></>
                }
            </Drawer>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex-grow'>
                        <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
                            <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
                            {
                                !allGroup &&
                                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
                            }
                            <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
                        </div>

                        <div className='p-3 flex flex-col ant-common-titania'>
                            <div className='flex flex-row flex-wrap space-x-3 mb-3'>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        !disablePage ?
                                            <div className='flex items-center cursor-pointer' onClick={handleNuevaAer}>
                                                <Tooltip title={newEdit ? "Nuevo acceso" : "Editar acceso"} placement='right'>
                                                    {
                                                        newEdit ?
                                                            <FileAddOutlined className="mr-1.5 text-gray-600" aria-hidden="true" /> :
                                                            <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                                    }
                                                </Tooltip>
                                                {newEdit ? 'Nuevo' : 'Editar'}
                                            </div>
                                            :
                                            <div className='flex items-center cursor-not-allowed'>
                                                {
                                                    newEdit ?
                                                        <FileAddOutlined className="mr-1.5 text-gray-600" aria-hidden="true" /> :
                                                        <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                                }
                                                {newEdit ? 'Nuevo' : 'Editar'}
                                            </div>
                                    }
                                </div>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task15.id || task15.task?.message) ?
                                            <>
                                                {
                                                    (!disablePage) ?
                                                        <label
                                                            htmlFor="file-upload-docreg"
                                                            className="flex items-center relative cursor-pointer"
                                                        >
                                                            <Tooltip title="Cargar archivo de documento" placement='right'>
                                                                <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            <span>Cargar</span>
                                                            <input id="file-upload-docreg" name="file-upload-docreg" type="file" className="sr-only"
                                                                onChange={handleTask15_Add}
                                                                accept=".txt"
                                                            />
                                                        </label> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask15Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Cargar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task15.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask15Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask15_Down(task15.task)}
                                                            >
                                                                {task15.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task16.id || task16.task?.message) ?
                                            <>
                                                {
                                                    (!disablePage) ?
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            title={`¿Confirma la operación?`}
                                                            onConfirm={handleTask16_Add}
                                                            placement="right"
                                                            okText="Sí"
                                                            cancelText="No"
                                                        >
                                                            <div className='flex items-center cursor-pointer'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                                {`Descargar`}
                                                            </div>
                                                        </Popconfirm> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask16Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Descargar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task16.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask16Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask16_Down(task16.task)}
                                                            >
                                                                {task16.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    (task15.task?.message || task16.task?.message) &&
                                    <div className='flex items-center'>
                                        <p className="text-xs text-red-700">
                                            {task15.task?.message ?? task16.task?.message}
                                        </p>
                                    </div>
                                }
                            </div>

                            <p className="text-xs text-gray-700 mb-2">
                                <span>Busca un acceso de entrega a rendir usando los filtros de rango de registro o cuenta de usuario.</span>
                            </p>
                            <div className='flex space-x-10'>
                                <div className='flex items-center space-x-2'>
                                    <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                                    <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                                    <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                                        <Select placeholder='Rol' allowClear style={{ width: 150 }} onChange={(v) => setFoRol(v || null)}>
                                            {
                                                roles.map(({ codigo, nombre }) => (
                                                    <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                        {
                                            loadConfigRT ?
                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg> :
                                                <>
                                                    <Select placeholder='Area' value={foArea} allowClear style={{ width: 210 }} onChange={(v) => setFoArea(v || null)}>
                                                        {
                                                            areas.map(({ areaCode, areaName }) => (
                                                                <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </>
                                        }
                                        <Input placeholder="Usuario" value={foAccess} style={{ width: 200 }} onChange={(e) => setFoAccess(e.target.value)} />
                                    </div>
                                    {
                                        !disablePage ?
                                            <Tooltip title={"Buscar acceso"}>
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarARFecha} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                            </div>

                            <div className='lg:max-w-[54.3rem]'>
                                {
                                    loadBusqAer ?
                                        <div className='my-5 flex justify-center'>
                                            <Spin tip='Loading....' size='large' />
                                        </div> :
                                        <>
                                            <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-cferdacc'>
                                                <Table
                                                    rowSelection={rowSelection}
                                                    columns={rdColumns}
                                                    dataSource={accesos}
                                                    pagination={false}
                                                    scroll={{ y: 400 }}
                                                    bordered
                                                    size='small'
                                                />
                                            </div>
                                            <div className='mt-2 flex justify-end ant-pagination-titania'>
                                                <Pagination
                                                    defaultCurrent={arCurrentPage}
                                                    total={arTotalDocuments}
                                                    showSizeChanger
                                                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} accesos`}
                                                    defaultPageSize={arPageSize}
                                                    onChange={handleChangePagAR}
                                                    onShowSizeChange={(_, size) => setArPageSize(size)}
                                                    disabled={disablePage} />
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
