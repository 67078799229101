import moment from 'moment'
import React from 'react'
import { configType } from '../types/configType'

export const AppFooter = () => {
    return (
        <div>
            <p className="mt-3 mb-2 text-center text-xs leading-5 text-gray-500">
                &copy; {` ${moment().year()} Titania (${configType.version}) All rights reserved.`}
            </p>
        </div>
    )
}
