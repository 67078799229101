import './ConsultarCTB.css'

import { ArrowDownTrayIcon, Cog8ToothIcon } from '@heroicons/react/20/solid'
import { CloseCircleOutlined, SearchOutlined, ZoomOutOutlined, EyeOutlined, QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { catGetCodUrl, ctbGetConUrl, ctbGetNumUrl, ctbGetSdocUrl, ctbGetSrinUrl, ctbGetStpoUrl, segConnUrl, tsk13Url, tsk14Url, tskRecuperarUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate, formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { DetalleRen } from '../../../components/Panel/Detail/DetalleRen'
import { DetalleSol } from '../../../components/Panel/Detail/DetalleSol'
import { DetalleOpe } from '../../../components/Panel/Detail/DetalleOpe'

var jszip = require('jszip')

export const ConsultarCTB = ({ page: { code: resource } }) => {
    const dvFechaIni = moment().startOf('month')
    const dvFechaFin = moment().endOf('month')
    const dvFecha = moment()

    const { axiosTnaGet, axiosTnaPost } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const detRenRef = useRef()
    const detSolRef = useRef()
    const detOpeRef = useRef()
    const searchInput = useRef(null)
    const [disablePage, setDisablePage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
    const [loadTipoCta, setLoadTipoCta] = useState(true)
    const [loadTipoMov, setLoadTipoMov] = useState(true)
    const [loadBusqCtb, setLoadBusqCtb] = useState(false)
    const [loadTask13Add, setLoadTask13Add] = useState(false)
    const [loadTask13Down, setLoadTask13Down] = useState(false)
    const [loadTask14Add, setLoadTask14Add] = useState(false)
    const [loadTask14Down, setLoadTask14Down] = useState(false)
    const [allGroup, setAllGroup] = useState(false)
    const [receptores, setReceptores] = useState([])
    const [grupos, setGrupos] = useState([])
    const [tiposCta, setTiposCta] = useState([])
    const [tiposMov, setTiposMov] = useState([])
    const [tiposSal, setTiposSal] = useState([])
    const [selReceptor, setSelReceptor] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [dbSolicitud, setDbSolicitud] = useState(null)
    const [dbRendicion, setDbRendicion] = useState(null)
    const [dbOperacion, setDbOperacion] = useState(null)
    const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
    const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
    const [foFecha, setFoFecha] = useState(dvFecha)
    const [foTipoCta, setFoTipoCta] = useState(null)
    const [foTipoMov, setFoTipoMov] = useState(null)
    const [foTipoSal, setFoTipoSal] = useState(null)
    const [foRindente, setFoRindente] = useState(null)
    const [foNumMV, setFoNumMV] = useState(null)
    const [foSave, setFoSave] = useState(null)
    const [contabilidad, setContabilidad] = useState([])
    const [saldosDoc, setSaldosDoc] = useState([])
    const [saldosRin, setSaldosRin] = useState([])
    const [saldosTpo, setSaldosTpo] = useState([])
    const [selContabilidad, setSelContabilidad] = useState(null)
    const [ordenCtb, setOrdenCtb] = useState(false)
    const [cbTotalDocuments, setCbTotalDocuments] = useState(0)
    const [cbPageSize, setCbPageSize] = useState(10)
    const [cbCurrentPage, setCbCurrentPage] = useState(1)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [, setSelectedRows] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)
    const [taskInterval, setTaskInterval] = useState(false)
    const [task13, setTask13] = useState({ task: null, id: null, first: true })
    const [task14, setTask14] = useState({ task: null, id: null, first: true })

    useEffect(() => {
        setLoadPage(loadTokenConn || loadRcptEmsr || loadTipoCta || loadTipoMov)
    }, [loadTokenConn, loadRcptEmsr, loadTipoCta, loadTipoMov])

    useEffect(() => {
        setDisablePage(loadBusqCtb || loadTask13Add || loadTask13Down || loadTask14Add || loadTask14Down)
    }, [loadBusqCtb, loadTask13Add, loadTask13Down, loadTask14Add, loadTask14Down])

    useEffect(() => {
        setDbSolicitud(selContabilidad && selContabilidad.solRen ? { numSO: selContabilidad.numMV } : null)
        setDbRendicion(selContabilidad && (!selContabilidad.solRen || (selContabilidad.origen && !selContabilidad.origen.opeRen)) ? { numRD: (!selContabilidad.solRen ? selContabilidad.numMV : selContabilidad.origen.numRD) } : null)
        setDbOperacion(selContabilidad && selContabilidad.origen && selContabilidad.origen.opeRen ? { id: selContabilidad.origen.origenID } : null)
    }, [selContabilidad])

    useEffect(() => {
        const tipos = [
            { codigo: '1', nombre: 'Contabilidad' },
            { codigo: '2', nombre: 'S. Documento' },
            { codigo: '3', nombre: 'S. Rindente' },
            { codigo: '4', nombre: 'S. Tipo Cta' }]
        const tipo = tipos.find(() => true)?.codigo
        setTiposSal(tipos)
        setFoTipoSal(tipo)
    }, [])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const rcptEmsr = async () => {
            const url = usuRcptEmsrUrl(resource)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setReceptores(data.rcpt)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRcptEmsr(false)
        }

        const catTipoCta = async () => {
            const url = catGetCodUrl(configType.catTipoCta)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposCta(data.detalle || [])
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoCta(false)
        }

        const catTipoMov = async () => {
            const url = catGetCodUrl(configType.catTipoMov)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposMov(data.detalle || [])
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoMov(false)
        }

        if (!loadTokenConn) {
            rcptEmsr()
            catTipoCta()
            catTipoMov()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const t13Get = async () => {
            const url = tsk13Url()
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTask13(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
            if (err)
                console.log(`Task: ${err.message}`)
        }

        const t14Get = async () => {
            const url = tsk14Url()
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTask14(prev => ({
                    ...prev,
                    task: data.data,
                    id: data.data?.id ?? prev.id,
                    first: false
                }))
            if (err)
                console.log(`Task: ${err.message}`)
        }

        if (!loadTokenConn) {
            if (task13.first || (!task13.task && task13.id) || (task13.task && !task13.task.complete))
                t13Get()
            if (task14.first || (!task14.task && task14.id) || (task14.task && !task14.task.complete))
                t14Get()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [taskInterval, loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const interval = setInterval(() => {
            isMounted && setTaskInterval(prev => !prev)
        }, 5000)

        return () => {
            clearInterval(interval)
            isMounted = false
        }
    }, [])

    const getUsu = (usu) => {
        if (!usu)
            return ''
        else if (usu.docIdentidad)
            return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
        else
            return `${usu.userID} - ${usu.userName}`
    }

    const getCtb = (ctb, index = null) => {
        var nctb = { ...ctb, key: `${ctb.id}-${ctb.linea?.linea}`, index, monto: ctb.linea?.monto, montoFn: ctb.linea?.montoFn, debe: Math.abs(Math.max(ctb.linea?.monto, 0.0)), haber: Math.abs(Math.min(ctb.linea?.monto, 0.0)), tipoCtaLabel: ctb.linea?.tipoCta ?? ctb.linea.tipoCtaCode }
        if (index === null)
            delete nctb.index
        return nctb
    }

    const getTitleDrawer = (ctb) => {
        if (!ctb || ctb.isDet === null || ctb.isDet === undefined || (ctb.isDet !== 0 && !ctb.origen))
            return ''
        else if (ctb.isDet === 0 && ctb.solRen)
            return `Solicitud ( ${ctb.numMV} )`
        else if (ctb.isDet === 0 && !ctb.solRen)
            return `Rendición ( ${ctb.numMV} )`
        else if (ctb.isDet === 1 && ctb.origen.opeRen)
            return `Operación ( ${ctb.numMV} )`
        else if (ctb.isDet === 1 && !ctb.origen.opeRen)
            return `Rendición ( ${ctb.origen.numRD ?? ctb.numMV} )`
        else
            return ''
    }

    const clearFiltroCB = (all = true) => {
        if (all) {
            setFoSave(null)
            setContabilidad([])
            setCbTotalDocuments(0)
        }
        setSelContabilidad(null)
        setSelectedRows([])
        setSelectedRowKeys([])
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        setSelContabilidad(null)
    }

    const handleSelReceptor = useCallback((person) => {
        setSelReceptor(person)
        let a_grupos = []
        if (person && person.grupo.length > 0)
            a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
        setGrupos(a_grupos)
        clearFiltroCB()
    }, [setSelReceptor])

    const handleSelGrupo = useCallback((person) => {
        setSelGrupo(person)
        clearFiltroCB()
    }, [setSelGrupo])

    const handleBuscarCB = async (isCon) => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (isCon && (!foFechaIni || !foFechaFin))
            err = {
                message: msgType.selectFecha,
                oops: false
            }
        else if (!isCon && !foNumMV)
            err = {
                message: msgType.inputNumMV,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqCtb(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
        const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
        const tipoCta = foTipoCta || ''
        const tipoMov = foTipoMov || ''
        const rindente = foRindente || ''
        const numMV = foNumMV || ''

        const url = isCon ?
            ctbGetConUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, tipoCta, tipoMov, rindente, resource, ordenCtb, 1, cbPageSize) :
            ctbGetNumUrl(selReceptor.ruc, numMV, 1, cbPageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: ctbs } = data
            if (!isCon && !allGroup && ctbs.find(p => p.grpCode !== selGrupo.ruc)) {
                setContabilidad([])
                setCbTotalDocuments(0)
            }
            else {
                setContabilidad(ctbs.map((ctb, index) => getCtb(ctb, index)))
                setCbTotalDocuments(totalDocuments)
            }
            setCbCurrentPage(1)
            clearFiltroCB(false)
            setFoSave({
                svSelReceptor: selReceptor,
                svGrupo: grupo,
                svFechaIni: fechaIni,
                svFechaFin: fechaFin,
                svTipoCta: tipoCta,
                svTipoMov: tipoMov,
                svRindente: rindente,
                svOrdenCtb: ordenCtb,
                svNumMV: numMV,
                svIsCon: isCon,
            })
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqCtb(false)
        setLastAccess()
    }

    const handleChangePagCB = async (page, pageSize) => {
        if (!foSave)
            return

        const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svTipoCta, svTipoMov, svRindente, svOrdenCtb, svNumMV, svIsCon } = foSave

        let err = null
        let data = null

        setLoadBusqCtb(true)

        const url = svIsCon ?
            ctbGetConUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svTipoCta, svTipoMov, svRindente, resource, svOrdenCtb, page, pageSize) :
            ctbGetNumUrl(svSelReceptor.ruc, svNumMV, page, pageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: ctbs } = data
            setContabilidad(ctbs.map((ctb, index) => getCtb(ctb, index)))
            setCbTotalDocuments(totalDocuments)
            setCbCurrentPage(page)
            clearFiltroCB(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqCtb(false)
        setLastAccess()
    }

    const handleBuscarSD = async (tpoSal) => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foFecha)
            err = {
                message: msgType.selectFecha1,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqCtb(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fecha = foFecha.format().split('T')[0] + 'T00:00:00'
        const tipoCta = foTipoCta || ''
        const rindente = foRindente || ''

        const url = tpoSal === '2' ? ctbGetSdocUrl(selReceptor.ruc, grupo, fecha, tipoCta, rindente, resource) :
            (tpoSal === '3' ? ctbGetSrinUrl(selReceptor.ruc, grupo, fecha, rindente, resource) :
                ctbGetStpoUrl(selReceptor.ruc, grupo, fecha, resource))
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { data: ctbs } = data
            const sdos = ctbs.map((ctb, index) => getCtb({ ...ctb, id: index }, index))
            tpoSal === '2' && setSaldosDoc(sdos)
            tpoSal === '3' && setSaldosRin(sdos)
            tpoSal === '4' && setSaldosTpo(sdos)
            clearFiltroCB(false)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqCtb(false)
        setLastAccess()
    }

    const handleDetalleCtb = (record, isDet) => {
        const ctb = { ...record, isDet }
        setSelContabilidad(ctb)
        setOpenDrawer(true)
    }

    const handleTask13_Add = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foFechaIni || !foFechaFin)
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadTask13Add(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fecha = foFechaFin.format().split('T')[0] + 'T00:00:00'

        const url = tsk13Url()
        const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, grupo, fecha, resource })
        data = data_api
        err = err_api

        if (data) {
            setTask13(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask13Add(false)
        setLastAccess()
    }

    const handleTask13_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask13Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask13(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask13Down(false)
        setLastAccess()
    }

    const handleTask14_Add = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!foFechaIni || !foFechaFin)
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadTask14Add(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni.format().split('T')[0] + 'T00:00:00'
        const fechaFin = foFechaFin.format().split('T')[0] + 'T00:00:00'
        const tipoCta = foTipoCta || ''
        const tipoMov = foTipoMov || ''
        const rindente = foRindente || ''

        const url = tsk14Url()
        const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, grupo, fechaIni, fechaFin, tipoCta, tipoMov, rindente, resource, sort: ordenCtb })
        data = data_api
        err = err_api

        if (data) {
            setTask14(prev => ({ ...prev, task: null, id: data.id }))
            notifyGreenRef.current.handleOpen({
                message: msgType.fileRequest,
                oops: false
            }, notifyType.success)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask14Add(false)
        setLastAccess()
    }

    const handleTask14_Down = async ({ id, file: record }) => {
        let err = null

        setLoadTask14Down(true)

        const url = tskRecuperarUrl(id)
        const [data, err_api] = await axiosTnaGet(url)
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
                setTask14(prev => ({ ...prev, task: null, id: null }))
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadTask14Down(false)
        setLastAccess()
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => confirm()
    const handleReset = (clearFilters, confirm) => { clearFilters(); confirm() }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8, }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Ingrese palabra`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    className='text-xs'
                    style={{ marginBottom: 8, display: 'block', }}
                />
                <Space>
                    <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, }}
                    >
                        Buscar
                    </Button>
                    <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90, }}
                    >
                        Reiniciar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />
        ),
        onFilter: (value, record) => {
            if (dataIndex === 'numMV') {
                const { numMV } = record
                return numMV.toLowerCase().includes(value.toLowerCase())
            }
            else if (dataIndex === 'rindente') {
                const rindente = getUsu(record.rindente ?? record.solicitante)
                return rindente && rindente.toLowerCase().includes(value.toLowerCase())
            }
            else if (dataIndex === 'fechaMov') {
                const { fechaMov } = record
                const fecha = moment(fechaMov).format('DD/MM/yyyy')
                return fecha.includes(value.toLowerCase())
            }
            else
                return false
        },
    })

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys)
            setSelectedRows(newSelectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: false,
        }),
        type: 'radio',
    }

    const cbColumns = [
        {
            title: 'Nº Movimiento',
            dataIndex: 'numMV',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <button
                            className='text-blue-600 w-full text-left'
                            onClick={() => handleDetalleCtb(record, 0)}
                        >
                            <span>{record.numMV}</span>
                        </button>
                    </div>
                    {
                        record.origen && (record.solRen || record.origen.opeRen) &&
                        <div className='flex justify-end space-x-1'>
                            <Tooltip title={record.origen.opeRen ? 'Detalle de operación' : 'Detalle de rendición'} placement="right">
                                <button onClick={() => handleDetalleCtb(record, 1)}>
                                    <EyeOutlined />
                                </button>
                            </Tooltip>
                        </div>
                    }
                </div>,
        },
        {
            title: 'Tipo Mov.',
            dataIndex: 'tipoMov',
            width: 90,
        },
        {
            title: 'Fecha Mov.',
            dataIndex: 'fechaMov',
            width: 92,
            render: (_, record) => <span>{formatDate(record.fechaMov)}</span>
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 50,
            align: 'center',
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            })
        },
        {
            title: 'Tipo Cta.',
            dataIndex: 'tipoCtaLabel',
            width: 120,
        },
        {
            title: 'Monto',
            dataIndex: 'monto',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.monto)}</span>
        },
        {
            title: 'Monto S/.',
            dataIndex: 'montoFn',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.montoFn)}</span>
        },
        {
            title: 'Fecha Reg.',
            dataIndex: 'fechaReg',
            width: 150,
            responsive: ['lg'],
            render: (_, record) => <span>{formatTime(record.fechaReg)}</span>
        },
        {
            title: 'Rindente',
            dataIndex: 'rindente',
            width: 340,
            responsive: ['xl'],
            render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${getUsu(record.rindente ?? record.solicitante)}`}</span>
        },
    ]

    const sdColumns = [
        {
            title: 'Nº Movimiento',
            dataIndex: 'numMV',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <button
                            className='text-blue-600 w-full text-left'
                            onClick={() => handleDetalleCtb(record, 0)}
                        >
                            <span>{record.numMV}</span>
                        </button>
                    </div>
                </div>,
            ...getColumnSearchProps('numMV'),
        },
        {
            title: 'Rindente',
            dataIndex: 'rindente',
            width: 340,
            responsive: ['xl'],
            render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${getUsu(record.rindente ?? record.solicitante)}`}</span>,
            ...getColumnSearchProps('rindente'),
        },
        {
            title: 'Fecha Mov.',
            dataIndex: 'fechaMov',
            width: 110,
            render: (_, record) => <span>{formatDate(record.fechaMov)}</span>,
            ...getColumnSearchProps('fechaMov'),
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 60,
            align: 'center',
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            }),
            sorter: (a, b) => (a.monedaISO > b.monedaISO ? 1 : -1),
        },
        {
            title: 'Tipo Cta.',
            dataIndex: 'tipoCtaLabel',
            width: 120,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => (a.tipoCtaLabel.length > b.tipoCtaLabel.length ? 1 : -1),
        },
        {
            title: 'Debe',
            dataIndex: 'debe',
            width: 100,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.debe)}</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.debe - b.debe,
        },
        {
            title: 'Haber',
            dataIndex: 'haber',
            width: 100,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.haber)}</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.haber - b.haber,
        },
    ]

    const srColumns = [
        {
            title: 'Rindente',
            dataIndex: 'rindente',
            render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${getUsu(record.rindente ?? record.solicitante)}`}</span>,
            ...getColumnSearchProps('rindente'),
        },
        {
            title: 'Tipo Doc.',
            dataIndex: 'tipoDoc',
            width: 120,
            responsive: ['xl'],
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.tipoDoc.length - b.tipoDoc.length,
        },
        {
            title: 'Fecha Mov.',
            dataIndex: 'fechaMov',
            width: 110,
            responsive: ['xl'],
            render: (_, record) => <span>{formatDate(record.fechaMov)}</span>,
            ...getColumnSearchProps('fechaMov'),
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 60,
            align: 'center',
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            }),
            sorter: (a, b) => (a.monedaISO > b.monedaISO ? 1 : -1),
        },
        {
            title: 'Tipo Cta.',
            dataIndex: 'tipoCtaLabel',
            width: 120,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => (a.tipoCtaLabel.length > b.tipoCtaLabel.length ? 1 : -1),
        },
        {
            title: 'Debe',
            dataIndex: 'debe',
            width: 100,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.debe)}</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.debe - b.debe,
        },
        {
            title: 'Haber',
            dataIndex: 'haber',
            width: 100,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.haber)}</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.haber - b.haber,
        },
    ]

    const stColumns = [
        {
            title: 'Tipo Doc.',
            dataIndex: 'tipoDoc',
            render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${record.tipoDoc}`}</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.tipoDoc.length - b.tipoDoc.length,
        },
        {
            title: 'Fecha Mov.',
            dataIndex: 'fechaMov',
            width: 110,
            render: (_, record) => <span>{formatDate(record.fechaMov)}</span>,
            ...getColumnSearchProps('fechaMov'),
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 60,
            align: 'center',
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            }),
            sorter: (a, b) => (a.monedaISO > b.monedaISO ? 1 : -1),
        },
        {
            title: 'Tipo Cta.',
            dataIndex: 'tipoCtaLabel',
            render: (_, record) => <span>{`${record.tipoCtaLabel}`}</span>,
            width: 120,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => (a.tipoCtaLabel.length > b.tipoCtaLabel.length ? 1 : -1),
        },
        {
            title: 'Debe',
            dataIndex: 'debe',
            width: 120,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.debe)}</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.debe - b.debe,
        },
        {
            title: 'Haber',
            dataIndex: 'haber',
            width: 120,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.haber)}</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.haber - b.haber,
        },
    ]

    return (
        <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
            <Drawer
                title={getTitleDrawer(selContabilidad)}
                placement="right"
                onClose={handleCloseDrawer}
                open={openDrawer}
                getContainer={false}
                style={{ position: 'absolute' }}
                bodyStyle={{ padding: 20, }}
                headerStyle={{ padding: 15, }}
                destroyOnClose={true}
                maskClosable={false}
                zIndex={5}
                width={720}
                closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
                extra={
                    <Space>
                        <></>
                    </Space>
                }
            >
                {
                    selContabilidad ?
                        <>
                            {
                                selContabilidad.isDet === 0 ?
                                    <>
                                        {
                                            selContabilidad.solRen ?
                                                <DetalleSol ref={detSolRef} selReceptor={selReceptor} dbSolicitud={dbSolicitud} /> :
                                                <DetalleRen ref={detRenRef} selReceptor={selReceptor} dbRendicion={dbRendicion} />
                                        }
                                    </> :
                                    <>
                                        {
                                            selContabilidad.isDet === 1 && selContabilidad.origen ?
                                                <>
                                                    {
                                                        selContabilidad.origen.opeRen ?
                                                            <DetalleOpe ref={detOpeRef} selReceptor={selReceptor} dbOperacion={dbOperacion} /> :
                                                            <DetalleRen ref={detRenRef} selReceptor={selReceptor} dbRendicion={dbRendicion} />
                                                    }
                                                </> :
                                                <></>
                                        }
                                    </>
                            }
                        </> :
                        <></>
                }
            </Drawer>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex-grow'>
                        <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
                            <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
                            {
                                !allGroup &&
                                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
                            }
                            <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
                        </div>

                        <div className='p-3 flex flex-col ant-common-titania'>
                            <div className="flex flex-row flex-wrap space-x-3 mb-3">
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task13.id || task13.task?.message) ?
                                            <>
                                                {
                                                    (!disablePage && foTipoSal === '1') ?
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            title={`¿Confirma la operación?`}
                                                            onConfirm={handleTask13_Add}
                                                            placement="right"
                                                            okText="Sí"
                                                            cancelText="No"
                                                        >
                                                            <div className='flex items-center cursor-pointer'>
                                                                <Tooltip title="Generar contabilidad" placement='right'>
                                                                    <Cog8ToothIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                                </Tooltip>
                                                                {`Generar`}
                                                            </div>
                                                        </Popconfirm> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <Cog8ToothIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask13Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Generar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task13.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <Cog8ToothIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask13Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask13_Down(task13.task)}
                                                            >
                                                                {task13.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <Cog8ToothIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                <div className="flex items-center text-sm text-indigo-700">
                                    {
                                        (!task14.id || task14.task?.message) ?
                                            <>
                                                {
                                                    (!disablePage && foTipoSal === '1') ?
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            title={`¿Confirma la operación?`}
                                                            onConfirm={handleTask14_Add}
                                                            placement="right"
                                                            okText="Sí"
                                                            cancelText="No"
                                                        >
                                                            <div className='flex items-center cursor-pointer'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                                {`Descargar`}
                                                            </div>
                                                        </Popconfirm> :
                                                        <div className='flex items-center cursor-not-allowed'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            {
                                                                loadTask14Add &&
                                                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                </svg>
                                                            }
                                                            {`Descargar`}
                                                        </div>
                                                }
                                            </> :
                                            <>
                                                {
                                                    task14.task?.file ?
                                                        <div className='flex items-center text-xs'>
                                                            <Tooltip title="Descargar archivo" placement='right'>
                                                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            </Tooltip>
                                                            {
                                                                loadTask14Down &&
                                                                <SyncOutlined spin className='mr-2' />
                                                            }
                                                            <button
                                                                className='text-blue-600'
                                                                disabled={disablePage}
                                                                onClick={() => handleTask14_Down(task14.task)}
                                                            >
                                                                {task14.task.file.fileName}
                                                            </button>
                                                        </div> :
                                                        <div className='flex items-center text-xs'>
                                                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                                            <SyncOutlined spin className='mr-2' />
                                                            {`Preparando archivo`}
                                                        </div>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    (task13.task?.message || task14.task?.message) &&
                                    <div className='flex items-center'>
                                        <p className="text-xs text-red-700">
                                            {task13.task?.message ?? task14.task?.message}
                                        </p>
                                    </div>
                                }
                            </div>

                            <p className="text-xs text-gray-700 mb-2">
                                <span>{`Busca ${foTipoSal === '1' ? 'la contabilidad' : 'el saldo'} de solicitud de fondo o reembolso usando los filtros de fecha o número de movimiento.`}</span>
                            </p>
                            <div className='flex space-x-10'>
                                <div className='flex items-center space-x-2'>
                                    <Select placeholder='Saldo' value={foTipoSal} style={{ width: 140 }} onChange={(v) => setFoTipoSal(v || null)}>
                                        {
                                            tiposSal.map(({ codigo, nombre }) => (
                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                    {
                                        foTipoSal === '1' &&
                                        <Tooltip title={ordenCtb ? "Ordenar por número" : "Ordenar por fecha"}>
                                            <Switch checkedChildren="N" unCheckedChildren="F" checked={ordenCtb} onChange={setOrdenCtb} />
                                        </Tooltip>
                                    }
                                    {foTipoSal === '1' && <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d || null)} />}
                                    {foTipoSal === '1' && <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d || null)} />}
                                    {foTipoSal !== '1' && <DatePicker placeholder='Fecha' value={foFecha} onChange={(d,) => setFoFecha(d || null)} />}
                                    {
                                        foTipoSal !== '4' &&
                                        <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                                            {
                                                (foTipoSal === '1' || foTipoSal === '2') &&
                                                <Select placeholder='Tipo Cta.' value={foTipoCta} allowClear style={{ width: 170 }} onChange={(v) => setFoTipoCta(v || null)}>
                                                    {
                                                        tiposCta.map(({ codigo, nombre }) => (
                                                            <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            }
                                            {
                                                foTipoSal === '1' &&
                                                <Select placeholder='Tipo Mov.' value={foTipoMov} allowClear style={{ width: 130 }} onChange={(v) => setFoTipoMov(v || null)}>
                                                    {
                                                        tiposMov.map(({ codigo, nombre }) => (
                                                            <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            }
                                            <Input placeholder="Rindente" value={foRindente} style={{ width: 200 }} onChange={(e) => setFoRindente(e.target.value)} />
                                        </div>
                                    }
                                    {
                                        !disablePage ?
                                            <Tooltip title={foTipoSal === '1' ? "Buscar asiento" : "Buscar saldo"}>
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => { if (foTipoSal === '1') handleBuscarCB(true); else handleBuscarSD(foTipoSal); }} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                                {
                                    foTipoSal === '1' &&
                                    <div className='flex items-center space-x-2'>
                                        <Input placeholder="Nº de movimiento" style={{ width: 150 }} onChange={(e) => setFoNumMV(e.target.value)} />
                                        {
                                            !disablePage ?
                                                <Tooltip title="Buscar asiento">
                                                    <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => handleBuscarCB(false)} />
                                                </Tooltip> :
                                                <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                        }
                                    </div>
                                }
                            </div>

                            <div className='lg:max-w-7xl'>
                                {
                                    loadBusqCtb ?
                                        <div className='my-5 flex justify-center'>
                                            <Spin tip='Loading....' size='large' />
                                        </div> :
                                        foTipoSal === '1' ?
                                            <>
                                                <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-fnconctb'>
                                                    <Table
                                                        rowSelection={rowSelection}
                                                        columns={cbColumns}
                                                        dataSource={contabilidad}
                                                        pagination={false}
                                                        scroll={{ y: 400 }}
                                                        bordered
                                                        size='small'
                                                    />
                                                </div>
                                                <div className='mt-2 flex justify-end ant-pagination-titania'>
                                                    <Pagination
                                                        defaultCurrent={cbCurrentPage}
                                                        total={cbTotalDocuments}
                                                        showSizeChanger
                                                        showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} movimiento`}
                                                        defaultPageSize={cbPageSize}
                                                        onChange={handleChangePagCB}
                                                        onShowSizeChange={(_, size) => setCbPageSize(size)}
                                                        disabled={disablePage} />
                                                </div>
                                            </> :
                                            <div className={'ant-table-titania ant-table-rowsel-titania ant-pagination-titania mt-3' + (foTipoSal === '2' ? ' w-[65rem]' : (foTipoSal === '3' ? ' w-[65rem]' : (allGroup ? ' w-[48rem]' : ' w-[45rem]')))}>
                                                <Table
                                                    rowSelection={rowSelection}
                                                    columns={foTipoSal === '2' ? sdColumns : (foTipoSal === '3' ? srColumns : stColumns)}
                                                    dataSource={foTipoSal === '2' ? saldosDoc : (foTipoSal === '3' ? saldosRin : saldosTpo)}
                                                    pagination={{
                                                        showSizeChanger: true,
                                                        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`
                                                    }}
                                                    showSizeChanger
                                                    scroll={{ y: 400 }}
                                                    bordered
                                                    size='small'
                                                />
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
