export const configType = {
    expToken: 5,
    contentTypePDF: 'application/pdf',
    contentTypeJSON: 'application/json',
    fileTypeXML: 'text/xml',
    fileTypeTXT: 'text/plain',
    recursoAll: 'dashboard',
    errorInterno: 'Error interno.',
    stdComprobante: 'Comprobante',
    stdSolicitud: 'Solicitud',
    stdRendicion: 'Rendicion',
    catTipoOrden: 'OC',
    catTipoCpe: '01',
    catMoneda: '02',
    catTipoDocId: '06',
    catCondProg: 'PR',
    catCondCnta: 'EC',
    catTipoSTrib: 'TB',
    catTipoPago: 'TP',
    catBanco: 'BAN',
    catTipoCtaBan: 'TPCTB',
    catTipoOpeER: 'OPER',
    catTipoRen: 'TPREN',
    catTipoMov: 'MVER',
    catTipoCta: 'CTAER',
    catRol: 'RLER',
    cpeNoExiste: '0',
    cpeAceptado: '1',
    cpeAnulado: '2',
    tipoDocIdeDNI: '1',
    tipoDocIdeRUC: '6',
    tipoCpeRH_Cto: 'R1',
    tipoCpeRH: '02',
    tipoCpeBV: '03',
    condPagoContado: 'Contado',
    monedaISOPEN: 'PEN',
    searchAllCode: 'all code',
    fileExtTXT: '.txt',
    fileExtXML: '.xml',
    fileExtJSON: '.json',
    fileExtAny: '.*',
    attachLnXML: 'CPE',
    attachLnJSON: 'DATA',
    version: 'v1.7.5',
    attachMaxSize: 1024 * 1024 * 7,
    responsiveLg: 1590,
    ctoSuscriptor: process.env.REACT_APP_API_Suscriptor_Calisto,
    tributoPER: { codigo: 'PER', nombre: 'Percepción' },
    tributoDET: { codigo: 'DET', nombre: 'Detracción' },
    tributoRET: { codigo: 'RET', nombre: 'Retención' },
}