import { UserOutlined, CheckCircleTwoTone, SyncOutlined, ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Col, Form, Input, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { catGetCodUrl, segConnUrl, storagePageDNIUrl, storageRUCUrl, usuGetCurrUrl, usuModificarUrl, webPageDNIUrl } from '../../../api/apiUrl'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../hooks/useAxiosCto'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { AppFooter } from '../../../components/AppFooter'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'

export const Usuario = () => {
    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const { axiosCtoGet } = useAxiosCto()
    const [formDta] = Form.useForm()
    const [formCta] = Form.useForm()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadUsuario, setLoadUsuario] = useState(true)
    const [loadTipoDoc, setLoadTipoDoc] = useState(true)
    const [loadBanco, setLoadBanco] = useState(true)
    const [loadMoneda, setLoadMoneda] = useState(true)
    const [loadTipoCta, setLoadTipoCta] = useState(true)
    const [loadValEmisor, setLoadValEmisor] = useState(false)
    const [loadModUsuario, setLoadModUsuario] = useState(false)
    const [componentDisabled, setComponentDisabled] = useState(false)
    const [usuario, setUsuario] = useState(null)
    const [tiposDoc, setTiposDoc] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [bancos, setBancos] = useState([])
    const [monedas, setMonedas] = useState([])
    const [tiposCta, setTiposCta] = useState([])
    const [fmUserName, setFmUserName] = useState(null)
    const [fmTipoDoc, setFmTipoDoc] = useState(null)
    const [fmEmsrNumDoc, setFmEmsrNumDoc] = useState(null)
    const [fmEmisor, setFmEmisor] = useState(null)
    const [fmBanco, setFmBanco] = useState(null)
    const [fmMoneda, setFmMoneda] = useState(null)
    const [fmTipoCta, setFmTipoCta] = useState(null)
    const [fmNumeroCta, setFmNumeroCta] = useState(null)
    const [fmCCI, setFmCCI] = useState(null)
    const [selCuenta, setSelCuenta] = useState(null)
    const [clearCta, setClearCta] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado, setConfirmado] = useState(true)

    useEffect(() => {
        setLoadPage(loadTokenConn || loadUsuario || loadTipoDoc || loadBanco || loadMoneda || loadTipoCta)
    }, [loadTokenConn, loadUsuario, loadTipoDoc, loadBanco, loadMoneda, loadTipoCta])

    useEffect(() => {
        setComponentDisabled(loadValEmisor || loadModUsuario)
    }, [loadValEmisor, loadModUsuario])

    useEffect(() => {
        const tdoc = tiposDoc.find(p => p.codigo === usuario?.docIdentidad?.tipoDocumentoID) || null
        const acta = usuario?.cuentas?.map((p, i) => getCta(p, i)) || []
        const scta = acta.find(p => true) || null

        setFmUserName(usuario?.userName)
        setFmTipoDoc(tdoc)
        setFmEmsrNumDoc(usuario?.docIdentidad?.documento)
        setFmEmisor(usuario?.docIdentidad)
        setCuentas(acta)
        setSelCuenta(scta)

        formDta.setFieldsValue({
            userName: usuario?.userName,
        })
    }, [usuario, tiposDoc, formDta])

    useEffect(() => {
        const banco = bancos.find(p => p.codigo === selCuenta?.bancoID) || null
        const moneda = monedas.find(p => p.codigo === selCuenta?.monedaISO) || null
        const tipoCta = tiposCta.find(p => p.codigo === selCuenta?.tipoCtaID) || null
        const numeroCta = selCuenta?.numeroCta || null
        const cci = selCuenta?.cci || null

        setFmBanco(banco)
        setFmMoneda(moneda)
        setFmTipoCta(tipoCta)
        setFmNumeroCta(numeroCta)
        setFmCCI(cci)
        setConfirmado(true)

        formCta.setFieldsValue({
            bancoID: banco?.codigo,
            monedaISO: moneda?.codigo,
            tipoCtaID: tipoCta?.codigo,
            numeroCta: numeroCta,
            cci: cci,
        })
    }, [selCuenta, clearCta, bancos, monedas, tiposCta, formCta])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const currUsuario = async () => {
            const url = usuGetCurrUrl()
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setUsuario(data.data)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadUsuario(false)
        }

        const catTipoDoc = async () => {
            const url = catGetCodUrl(configType.catTipoDocId)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposDoc(data.detalle.filter(dt => dt.bool1))
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoDoc(false)
        }

        const catBanco = async () => {
            const url = catGetCodUrl(configType.catBanco)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setBancos(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadBanco(false)
        }

        const catMoneda = async () => {
            const url = catGetCodUrl(configType.catMoneda)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setMonedas(data.detalle.filter(dt => dt.bool1))
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadMoneda(false)
        }


        const catTipoCta = async () => {
            const url = catGetCodUrl(configType.catTipoCtaBan)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposCta(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoCta(false)
        }

        if (!loadTokenConn) {
            currUsuario()
            catTipoDoc()
            catBanco()
            catMoneda()
            catTipoCta()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    const refreshUsu = async () => {
        const url = usuGetCurrUrl()
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Usuario: ${err.message}`)
        const nusu = data?.success ? data.data : null
        return nusu
    }

    const getCta = (cta, index) => {
        var ncta = { ...cta, key: index, label: `${index + 1}: ${cta.monedaISO} - ${cta.numeroCta}` }
        return ncta
    }

    const sortCta = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const onValuesChangeDta = (changedValues, allValues) => {
        const userName = allValues.userName ?? null
        setFmUserName(userName)
        setGuardado(false)
    }

    const onValuesChangeCta = (changedValues, allValues) => {
        const banco = bancos.find(p => p.codigo === allValues.bancoID) || null
        const moneda = monedas.find(p => p.codigo === allValues.monedaISO) || null
        const tipoCta = tiposCta.find(p => p.codigo === allValues.tipoCtaID) || null
        const numeroCta = allValues.numeroCta ?? null
        const cci = allValues.cci ?? null

        setFmBanco(banco)
        setFmMoneda(moneda)
        setFmTipoCta(tipoCta)
        setFmNumeroCta(numeroCta)
        setFmCCI(cci)
        setConfirmado(false)
        setGuardado(false)
    }

    const handleChangeTipoDoc = (value) => {
        const stdo = tiposDoc.find(p => p.codigo === value) || null
        setFmTipoDoc(stdo)
        setFmEmsrNumDoc(null)
        setFmEmisor(null)
        setGuardado(false)
    }

    const handleChangeCuenta = (value, acta) => {
        const scta = acta.find(p => p.key === value) || null
        setSelCuenta(scta)
    }

    const handleAgregarCuenta = () => {
        let err = null

        if (!fmBanco)
            err = {
                message: `${msgType.registroNoField} banco.`,
                oops: false
            }
        else if (!fmMoneda)
            err = {
                message: `${msgType.registroNoField} moneda.`,
                oops: false
            }
        else if (!fmTipoCta)
            err = {
                message: `${msgType.registroNoField} tipo de cuenta.`,
                oops: false
            }
        else if (!fmNumeroCta || fmNumeroCta.trim() === '')
            err = {
                message: `${msgType.registroNoField} número de cuenta.`,
                oops: false
            }
        else if (!fmCCI || fmCCI.trim() === '')
            err = {
                message: `${msgType.registroNoField} CCI.`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        let maxKey = selCuenta ? selCuenta.key : (cuentas.length > 0 ? Math.max.apply(null, cuentas.map(p => p.key)) + 1 : 0)
        const scta = getCta({
            bancoID: fmBanco.codigo,
            monedaISO: fmMoneda.codigo,
            tipoCtaID: fmTipoCta.codigo,
            numeroCta: fmNumeroCta.trim(),
            cci: fmCCI.trim()
        }, maxKey)
        setCuentas(prev => {
            const acta = selCuenta ? prev.filter(p => p.key !== selCuenta.key) : prev.filter(p => true)
            const ncta = [...acta, scta]
            ncta.sort(sortCta)
            return ncta
        })
        setSelCuenta(scta)
        setConfirmado(true)
    }

    const handleQuitarCuenta = () => {
        if (selCuenta) {
            const acta = cuentas.filter(p => p.key !== selCuenta.key)
            const scta = acta.find(p => true) || null
            setCuentas(acta)
            setSelCuenta(scta)
        }
    }

    const handleValEmisor = async (emsr) => {
        let err = null
        let data = null

        if (!fmTipoDoc)
            err = {
                message: msgType.selectTipoDoc,
                oops: false
            }
        else if (fmTipoDoc.codigo !== configType.tipoDocIdeRUC && fmTipoDoc.codigo !== configType.tipoDocIdeDNI)
            err = {
                message: msgType.koDataTipoDoc,
                oops: false
            }
        else if (!fmEmsrNumDoc)
            err = {
                message: msgType.inputDocIdent,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadValEmisor(true)

        const webStorage = true ? storagePageDNIUrl(fmEmsrNumDoc) : webPageDNIUrl(fmEmsrNumDoc)
        const isRUC = fmTipoDoc.tipoDocIdentidadID === configType.tipoDocIdeRUC
        const url = isRUC ? storageRUCUrl(fmEmsrNumDoc) : webStorage
        const [data_api, err_api] = await axiosCtoGet(url)
        data = data_api
        err = err_api

        if (data) {
            const nemsr1 = { documento: (isRUC ? data.ruc : data.dni), denominacion: (isRUC ? data.nomRazSoc : data.apellNomb) }
            const nemsr2 = { validado: true }
            const nemsr = !emsr || !emsr.validado ? { ...nemsr1, ...nemsr2 } : { ...emsr, ...nemsr2 }
            setFmEmisor(nemsr)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadValEmisor(false)
        setLastAccess()
    }

    const handleModUsuario = async () => {
        let err = null
        let data = null

        if (!usuario)
            err = {
                message: msgType.selectUsuario,
                oops: false
            }
        else if (!fmUserName)
            err = {
                message: `${msgType.registroNoField} nombre de usuario.`,
                oops: false
            }
        else if (fmTipoDoc && !fmEmisor)
            err = {
                message: `${msgType.registroNoField} número de documento.`,
                oops: false
            }
        else if (selCuenta) {
            if (!fmBanco)
                err = {
                    message: `${msgType.registroNoField} banco.`,
                    oops: false
                }
            else if (!fmMoneda)
                err = {
                    message: `${msgType.registroNoField} moneda.`,
                    oops: false
                }
            else if (!fmTipoCta)
                err = {
                    message: `${msgType.registroNoField} tipo de cuenta.`,
                    oops: false
                }
            else if (!fmNumeroCta || fmNumeroCta.trim() === '')
                err = {
                    message: `${msgType.registroNoField} número de cuenta.`,
                    oops: false
                }
            else if (!fmCCI || fmCCI.trim() === '')
                err = {
                    message: `${msgType.registroNoField} CCI.`,
                    oops: false
                }
        }
        else {
            if (fmBanco)
                err = {
                    message: `${msgType.registroSiField} banco.`,
                    oops: false
                }
            else if (fmMoneda)
                err = {
                    message: `${msgType.registroSiField} moneda.`,
                    oops: false
                }
            else if (fmTipoCta)
                err = {
                    message: `${msgType.registroSiField} tipo de cuenta.`,
                    oops: false
                }
            else if (fmNumeroCta && fmNumeroCta.trim() !== '')
                err = {
                    message: `${msgType.registroSiField} número de cuenta.`,
                    oops: false
                }
            else if (fmCCI && fmCCI.trim() !== '')
                err = {
                    message: `${msgType.registroSiField} CCI.`,
                    oops: false
                }
        }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadModUsuario(true)

        const userName = fmUserName.trim()
        const docIdentidad = fmTipoDoc && fmEmisor ? {
            tipoDocumentoID: fmTipoDoc.codigo, documento: fmEmisor.documento, denominacion: fmEmisor.denominacion, validado: fmEmisor.validado
        } : null
        const ctaBanco = cuentas.length > 0 ? cuentas : null

        const url = usuModificarUrl(usuario.id)
        const body = { userName, docIdentidad, ctaBanco }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: `${msgType.usuarioModificar} ${usuario.userID}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            const nusu = await refreshUsu()
            setUsuario(nusu)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadModUsuario(false)
        setLastAccess()
    }

    return (
        <div className="flex flex-col flex-grow items-center">
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            <div className='bg-white flex flex-col m-5 p-3 max-w-2xl min-w-[23rem] ant-common-titania'>
                {
                    loadPage &&
                    <div className='flex mt-2 w-full justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div>
                }
                {
                    !loadPage &&
                    <div className='flex items-center justify-center space-x-2'>
                        <UserOutlined />
                        <span className='text-blue-600'>{usuario.userID}</span>
                    </div>
                }
                {
                    !guardado &&
                    <div className='flex items-center justify-center'>
                        <span className='text-yellow-700 text-[0.6rem]'>[ Sin guardar ]</span>
                    </div>
                }
                <Form
                    className='mt-4'
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeDta}
                    disabled={componentDisabled}
                    hidden={loadPage}
                    autoComplete="off"
                    form={formDta}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="userName"
                                label="Nombre de usuario"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese nombre de usuario'
                                    size='middle'
                                    maxLength={50}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {
                    !loadPage &&
                    <div className='flex space-x-4'>
                        <div>
                            <div className='mb-1'>
                                <span>Tipo de documento</span>
                            </div>
                            <div className='flex space-x-1.5 bg-red-200'>
                                <Select placeholder='Seleccione tipo de documento' style={{ width: 280 }} value={fmTipoDoc?.codigo}
                                    onChange={handleChangeTipoDoc} allowClear disabled={componentDisabled}>
                                    {
                                        tiposDoc.map(({ codigo, nombre, string1 }) => (
                                            <Select.Option key={codigo} value={codigo}>{string1 ?? nombre}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                }
                {
                    !loadPage &&
                    <div className='mt-2'>
                        <div className='mb-1'>
                            <span className='titania-span-requerido'>*</span>
                            <span>Nº de documento</span>
                        </div>
                        <div className='flex-row md:flex md:space-x-0.5'>
                            <Input.Search value={fmEmsrNumDoc} onChange={(e) => { setFmEmsrNumDoc(e.target.value); setFmEmisor(null); setGuardado(false); }}
                                size="middle" placeholder={`Ingrese documento`} maxLength={15} style={{ width: '20.5rem' }}
                                onSearch={() => handleValEmisor(fmEmisor)}
                                disabled={componentDisabled || loadValEmisor} />
                            <Input value={fmEmisor?.denominacion} readOnly={(fmEmisor && fmEmisor.validado) || !fmEmsrNumDoc}
                                onChange={(e) => {
                                    const emsr = { documento: fmEmsrNumDoc, denominacion: e.target.value, validado: false }
                                    setFmEmisor(emsr);
                                    setGuardado(false);
                                }}
                                placeholder='Resultado de búsqueda' />
                            {
                                loadValEmisor &&
                                <div className='flex items-center text-indigo-600 pl-1'>
                                    <SyncOutlined spin />
                                </div>
                            }
                            {
                                fmEmisor && fmEmisor.validado &&
                                <div className='flex items-center pl-1'>
                                    <Tooltip title="Emisor validado" placement='left'>
                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    !loadPage &&
                    <div className='mt-6'>
                        <div className='mb-1'>
                            <span>Cuenta bancaria</span>
                        </div>
                        <div className='flex space-x-2'>
                            <Select placeholder='Nueva cuenta' style={{ width: 280 }} value={selCuenta?.key}
                                onChange={(v) => handleChangeCuenta(v, cuentas)} disabled={componentDisabled} allowClear>
                                {
                                    cuentas.map(({ key, label }) => (
                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {
                                !selCuenta &&
                                <Tooltip title="Limpiar cuenta" placement='top'>
                                    <button onClick={() => setClearCta(prev => !prev)} className='flex items-center'>
                                        <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                !selCuenta &&
                                <Tooltip title="Nueva cuenta" placement='top'>
                                    <button onClick={handleAgregarCuenta} className='flex items-center'>
                                        <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                selCuenta &&
                                <Tooltip title="Modificar cuenta" placement='top'>
                                    <button onClick={handleAgregarCuenta} className='flex items-center'>
                                        <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                selCuenta &&
                                <Tooltip title="Quitar cuenta" placement='top'>
                                    <button onClick={handleQuitarCuenta} className='flex items-center'>
                                        <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                !confirmado &&
                                <div className='flex items-center justify-center'>
                                    <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className='ant-form-titania'>
                    <Form
                        className='mt-4'
                        layout="vertical" requiredMark
                        onValuesChange={onValuesChangeCta}
                        disabled={componentDisabled}
                        hidden={loadPage}
                        autoComplete="off"
                        form={formCta}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="bancoID"
                                    label="Banco"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione banco"
                                        allowClear>
                                        {
                                            bancos.map(({ codigo, nombre }) => (
                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="monedaISO"
                                    label="Moneda"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione moneda"
                                        allowClear>
                                        {
                                            monedas.map(({ codigo }) => (
                                                <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="tipoCtaID"
                                    label="Tipo de cuenta"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione tipo de cuenta"
                                        allowClear>
                                        {
                                            tiposCta.map(({ codigo, nombre }) => (
                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="numeroCta"
                                    label="Número de cuenta"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Ingrese número de cuenta'
                                        size='middle'
                                        maxLength={25}
                                        style={{
                                            width: '100%',
                                        }} />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="cci"
                                    label="CCI"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Ingrese código interbancario'
                                        size='middle'
                                        maxLength={25}
                                        style={{
                                            width: '100%',
                                        }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {
                    !loadPage &&
                    <div className="flex flex-row justify-end space-x-3 mx-1 mt-4">
                        <div className="flex items-center text-sm text-indigo-700">
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={`¿Confirma la operación?`}
                                onConfirm={handleModUsuario}
                                placement="left"
                                okText="Sí"
                                cancelText="No"
                                disabled={componentDisabled}
                            >
                                <div className='flex items-center cursor-pointer'>
                                    <Tooltip title="Guardar usuario" placement='left'>
                                        <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                    </Tooltip>
                                    Guardar
                                </div>
                            </Popconfirm>
                        </div>
                    </div>
                }
            </div>
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
