import './NewDev.css'

import { QuestionCircleOutlined, SaveOutlined, DeleteOutlined, CloudUploadOutlined, CloudDownloadOutlined, DeleteFilled, SyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Spin, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { renAdjuntarUrl, renDevolverUrl, renGetNumUrl, renRecuperarUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { downloadFile, viewerFile } from '../../../../utils/fileUtil'

var jszip = require('jszip')

export const NewDev = (props) => {
    const { selReceptor, selRendicion, cuentas: cuentas_all, tiposPago: tiposPago_all, showNotify, updateRen } = props

    const dvCuentasBan = cuentas_all ? [...cuentas_all] : []
    const dvTiposPago = tiposPago_all ? tiposPago_all.filter(p => p.bool1) : []
    const dvTipoPago = dvTiposPago.find(() => true)
    const dvFechaTran = moment()
    const dvMonto = selRendicion?.saldo ?? 0.0

    const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
    const { confirm } = Modal
    const [form] = Form.useForm()
    const [componentDisabled, setComponentDisabled] = useState(false)
    const [loadFile, setLoadFile] = useState(false)
    const [loadRegDev, setLoadRegDev] = useState(false)
    const [loadEliDev, setLoadEliDev] = useState(false)
    const [devoluciones, setDevoluciones] = useState([])
    const [cuentasBan,] = useState(dvCuentasBan)
    const [bancos, setBancos] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [tiposPago,] = useState(dvTiposPago)
    const [adjuntos, setAdjuntos] = useState([])
    const [selDevolucion, setSelDevolucion] = useState(null)
    const [fmBanco, setFmBanco] = useState(null)
    const [fmCuenta, setFmCuenta] = useState(null)
    const [fmTipoPago, setFmTipoPago] = useState(dvTipoPago)
    const [fmNumTran, setFmNumTran] = useState()
    const [fmFechaTran, setFmFechaTran] = useState(dvFechaTran)
    const [fmMonto, setFmMonto] = useState(dvMonto)
    const [currentLineFI, setCurrentLineFI] = useState(null)

    useEffect(() => {
        setComponentDisabled(loadFile || loadRegDev || loadEliDev)
    }, [loadFile, loadRegDev, loadEliDev])

    useEffect(() => {
        const aban = groupByBanco(selRendicion, cuentasBan)
        const ban = aban.find(() => true)
        setBancos(aban)
        setFmBanco(ban)
        form.setFieldsValue({
            banco: ban?.bancoID,
        })
    }, [selRendicion, cuentasBan, form])

    useEffect(() => {
        if (selRendicion) {
            if (selRendicion.devoluciones) {
                const adev = selRendicion.devoluciones.map((dev, index) => ({
                    ...dev,
                    key: index,
                    label: `Devolución ${dev.line + 1}`,
                    adjuntos: selRendicion.attach && dev.dataOrder != null ? selRendicion.attach.filter(p => p.dataOrder === dev.dataOrder).map(p => ({ ...p, key: p.dataOrder })) : []
                }))
                const sdev = adev.length > 0 ? adev[adev.length - 1] : null
                setDevoluciones(adev)
                setSelDevolucion(sdev)
            }
        }
    }, [selRendicion])

    useEffect(() => {
        if (selDevolucion) {
            const ban = bancos.find(p => p.bancoID === selDevolucion.bancoID) || null
            const acta = getCuenta(selRendicion, ban, cuentasBan)
            const cta = acta.find(p => p.numeroCta === selDevolucion.numeroCta) || null
            const tipoPago = tiposPago.find(p => p.codigo === selDevolucion.tipoPagoID) || null
            const fechaTran = moment(selDevolucion.fechaTran)

            setFmBanco(ban)
            setCuentas(acta)
            setFmCuenta(cta)
            setFmTipoPago(tipoPago)
            setFmMonto(selDevolucion.monto)
            setFmNumTran(selDevolucion.numTran)
            setFmFechaTran(fechaTran)
            form.setFieldsValue({
                banco: ban?.bancoID,
                cuenta: cta?.numeroCta,
                tipoPago: tipoPago?.codigo,
                numTran: selDevolucion.numTran,
                fechaTran: fechaTran,
                monto: selDevolucion.monto,
            })

            setAdjuntos(selDevolucion.adjuntos || [])
        }
        else {
            const ban = bancos.find(() => true) || null
            const acta = getCuenta(selRendicion, ban, cuentasBan)
            const cta = acta.find(() => true) || null
            const tipoPago = tiposPago.find(() => true) || null

            setFmBanco(ban)
            setCuentas(acta)
            setFmCuenta(cta)
            setFmTipoPago(tipoPago)
            setFmMonto(dvMonto)
            setFmNumTran(null)
            setFmFechaTran(dvFechaTran)
            form.setFieldsValue({
                banco: ban?.bancoID,
                cuenta: cta?.numeroCta,
                tipoPago: tipoPago?.codigo,
                numTran: null,
                fechaTran: dvFechaTran,
                monto: dvMonto,
            })

            setAdjuntos([])
        }
        // eslint-disable-next-line
    }, [selDevolucion, selRendicion, cuentasBan, bancos, tiposPago])

    const refreshRen = async (rcpt, ren) => {
        const url = renGetNumUrl(rcpt.ruc, ren.numRD)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Rendición: ${err.message}`)
        const nren = data?.success ? data.data : null
        return nren
    }

    const onValuesChange = (changedValues, allValues) => {
        const banco = bancos.find(p => p.bancoID === allValues.banco) || null
        const cuenta = cuentas.find(p => p.numeroCta === allValues.cuenta) || null
        const tipoPago = tiposPago.find(p => p.codigo === allValues.tipoPago) || null
        const numTran = allValues.numTran ?? null
        const fechaTran = allValues.fechaTran ?? null
        const monto = allValues.monto ?? null

        setFmBanco(banco)
        setFmCuenta(cuenta)
        setFmTipoPago(tipoPago)
        setFmNumTran(numTran)
        setFmFechaTran(fechaTran)
        setFmMonto(monto)

        if (changedValues.hasOwnProperty('banco')) {
            const acta = getCuenta(selRendicion, banco, cuentasBan)
            const cta = acta.find(() => true)
            setCuentas(acta)
            setFmCuenta(cta)
            form.setFieldsValue({
                cuenta: cta?.numeroCta,
            })
        }
    }

    const groupByBanco = (ren, ctasBan) => {
        const aban = []
        if (ren && ctasBan)
            ctasBan.filter(p => p.monedaISO === ren.monedaISO).map(ctab => {
                const ban = aban.find(p => p.bancoID === ctab.bancoID)
                if (!ban)
                    aban.push({ bancoID: ctab.bancoID, bancoName: ctab.bancoName })
                return ctab
            })
        return aban
    }

    const getCuenta = (ren, ban, ctasBan) => {
        if (!ren || !ban || !ctasBan)
            return []
        else
            return ctasBan.filter(p => p.monedaISO === ren.monedaISO && p.bancoID === ban.bancoID).map(p => ({ ...p, label: (ban.cci ? `( CCI ) ${p.cci}` : p.numeroCta), id: (ban.cci ? p.cci : p.numeroCta) }))
    }

    const clearFile = () => {
        const input = document.getElementById("file-upload-dev")
        if (input)
            input.value = null
    }

    const handleChangeDevolucion = (value, adev) => {
        const sdev = adev.find(p => p.line === value)
        setSelDevolucion(sdev)
    }

    const handleRegDev_Confirm = () => {
        if (fmMonto === selRendicion?.saldo)
            handleRegDev()
        else
            confirm({
                title: msgType.neDataMontoDevS,
                icon: <ExclamationCircleOutlined />,
                content: '¿Confirma la operación?',
                onOk() {
                    handleRegDev()
                },
                onCancel() { },
            })
    }

    const handleRegDev = async () => {
        let err = null
        let data = null

        if (componentDisabled)
            return;

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selRendicion)
            err = {
                message: msgType.selectRendicion,
                oops: false
            }
        else if (!fmBanco)
            err = {
                message: `${msgType.selectBanco}`,
                oops: false
            }
        else if (!fmCuenta)
            err = {
                message: `${msgType.selectCuenta}`,
                oops: false
            }
        else if (!fmTipoPago)
            err = {
                message: `${msgType.selectTipoPago}`,
                oops: false
            }
        else if (!fmNumTran)
            err = {
                message: `${msgType.inputNumTran}`,
                oops: false
            }
        else if (!fmFechaTran)
            err = {
                message: `${msgType.inputFechaTran}`,
                oops: false
            }
        else if (!fmMonto)
            err = {
                message: `${msgType.inputMontoDev}`,
                oops: false
            }
        else if (fmNumTran.trim() === '')
            err = {
                message: `${msgType.koDataNumTran}`,
                oops: false
            }
        else if (fmFechaTran.toDate().getTime() < moment(selRendicion.fechaRen).toDate().getTime() || moment().toDate().getTime() < fmFechaTran.toDate().getTime())
            err = {
                message: `${msgType.koDataFechaTran}`,
                oops: false
            }
        else if (fmMonto <= 0.0)
            err = {
                message: `${msgType.koDataMontoTran}`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadRegDev(true)

        const numTran = fmNumTran.trim()
        const fechaTran = fmFechaTran.format().split('T')[0] + 'T00:00:00'

        const url = renDevolverUrl(selRendicion.id)
        const body = {
            banco: fmBanco.bancoID, numeroCta: fmCuenta.numeroCta, tipoPago: fmTipoPago.codigo,
            numTran, fechaTran, monto: fmMonto
        }
        const [data_api, err_api] = await axiosTnaPost(url, body)
        data = data_api
        err = err_api

        if (data && data.success) {
            showNotify({
                message: `${msgType.devolucionRegistrar} ${selRendicion.numRD}`,
                oops: false
            }, notifyType.success)
            const nren = await refreshRen(selReceptor, selRendicion)
            if (nren && updateRen)
                updateRen(nren)
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadRegDev(false)
        setLastAccess()
    }

    const handleEliDev = async () => {
        let err = null
        let data = null

        if (!selRendicion)
            err = {
                message: msgType.selectRendicion,
                oops: false
            }
        else if (!selDevolucion)
            err = {
                message: msgType.selectDevolucion,
                oops: false
            }
        else if (selDevolucion.line !== devoluciones[devoluciones.length - 1]?.line)
            err = {
                message: msgType.selectDevolucionU,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadEliDev(true)

        const url = renDevolverUrl(selRendicion.id)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data && data.success) {
            showNotify({
                message: msgType.devolucionEliminar,
                oops: false
            }, notifyType.success)
            const adev = devoluciones.filter(p => p.line !== selDevolucion.line)
            setDevoluciones(adev)
            setSelDevolucion(null)
            const nren = await refreshRen(selReceptor, selRendicion)
            if (nren && updateRen)
                updateRen(nren, false)
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadEliDev(false)
        setLastAccess()
    }

    const handleUpFile = async ({ target: { files } }) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.fileKoPeso,
                oops: false
            }
        else if (!selRendicion?.numRD)
            err = {
                message: msgType.noRegRendicion,
                oops: false
            }
        else if (!selDevolucion)
            err = {
                message: msgType.noRegDevolucion,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            clearFile()
            return
        }

        setLoadFile(true)

        const formData = new FormData()
        formData.append("", file)

        const url = renAdjuntarUrl(selRendicion.id, selDevolucion.line)
        const [data_api, err_api] = await axiosTnaPut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            const nren = await refreshRen(selReceptor, selRendicion)
            if (nren && updateRen)
                updateRen(nren)
        }
        if (err)
            showNotify(err, notifyType.error)

        clearFile()
        setLoadFile(false)
        setLastAccess()
    }

    const handleDelFile = async (record) => {
        let err = null
        let data = null

        if (!selRendicion?.numRD)
            err = {
                message: msgType.noRegRendicion,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setCurrentLineFI(record.dataOrder)

        const url = renAdjuntarUrl(selRendicion.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data && data.success) {
            const nren = await refreshRen(selReceptor, selRendicion)
            if (nren && updateRen)
                updateRen(nren)
        }
        if (err)
            showNotify(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = renRecuperarUrl(selRendicion.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            showNotify(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex space-x-1 items-center'>
                        <button
                            className='text-blue-600 text-left'
                            disabled={record.dataOrder === currentLineFI}
                            onClick={() => handleDownFile(record, false)}
                        >
                            {`${record.fileName}`}
                        </button>
                        {
                            record.fileType &&
                            <span>{`- ${record.fileType}`}</span>
                        }
                    </div>
                    <div className='flex justify-end items-center'>
                        {
                            record.dataOrder !== currentLineFI &&
                            <>
                                <button onClick={() => handleDownFile(record, true)}>
                                    <CloudDownloadOutlined />
                                </button>
                                {
                                    !record.required &&
                                    <Popconfirm placement="left"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        title={`¿Confirma la operación?`}
                                        onConfirm={() => handleDelFile(record)}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <button className='ml-2'>
                                            <DeleteFilled className='text-red-400' />
                                        </button>
                                    </Popconfirm>
                                }
                            </>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='ml-2' />
                        }
                    </div>
                </div>
        },
    ]

    return (
        <div className='ant-common-titania'>
            <div className='flex justify-between mb-2.5'>
                <div className='flex space-x-2'>
                    <Select placeholder='Nueva devolución' style={{ width: 180 }} value={selDevolucion?.line}
                        onChange={(v) => handleChangeDevolucion(v, devoluciones)} allowClear disabled={componentDisabled}>
                        {
                            devoluciones.map(({ key, line, label }) => (
                                <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                        }
                    </Select>
                </div>
                <div className="flex flex-row justify-end space-x-3 mx-1">
                    {
                        loadRegDev &&
                        <div className='flex items-center text-indigo-600'>
                            <SyncOutlined spin />
                        </div>
                    }
                    {
                        !selDevolucion &&
                        <div className="flex items-center text-sm text-indigo-700">
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={`¿Confirma la operación?`}
                                onConfirm={handleRegDev_Confirm}
                                placement="left"
                                okText="Sí"
                                cancelText="No"
                                disabled={componentDisabled}
                            >
                                <div className='flex items-center cursor-pointer'>
                                    <Tooltip title="Guardar devolución" placement='left'>
                                        <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                    </Tooltip>
                                    Guardar
                                </div>
                            </Popconfirm>
                        </div>
                    }
                    {
                        selDevolucion &&
                        <div className="flex items-center text-sm text-indigo-700">
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={`¿Confirma la operación?`}
                                onConfirm={handleEliDev}
                                placement="left"
                                okText="Sí"
                                cancelText="No"
                                disabled={componentDisabled}
                            >
                                <div className='flex items-center cursor-pointer'>
                                    <Tooltip title="Eliminar devolución" placement='left'>
                                        <DeleteOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                    </Tooltip>
                                    Eliminar
                                </div>
                            </Popconfirm>
                        </div>
                    }
                </div>
            </div>

            <Form
                layout="vertical" requiredMark
                onValuesChange={onValuesChange}
                disabled={componentDisabled || selDevolucion}
                autoComplete="off"
                form={form}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="banco"
                            label="Banco"
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <Select placeholder="Seleccione banco" allowClear>
                                {
                                    bancos.map(({ bancoID, bancoName }) => (
                                        <Select.Option key={bancoID} value={bancoID}>{bancoName}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="cuenta"
                            label="Cuenta bancaria"
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <Select placeholder="Seleccione cta bancaria" allowClear>
                                {
                                    cuentas.map(({ numeroCta, label }) => (
                                        <Select.Option key={numeroCta} value={numeroCta}>{label}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="tipoPago"
                            label="Tipo de pago"
                            initialValue={dvTipoPago?.codigo}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <Select placeholder="Seleccione tipo de pago" allowClear>
                                {
                                    tiposPago.map(({ codigo, nombre }) => (
                                        <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="numTran"
                            label="Número de transacción"
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <Input
                                placeholder='Ingrese nº de transacción'
                                size='middle'
                                maxLength={25}
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="fechaTran"
                            label="Fecha de transacción"
                            initialValue={dvFechaTran}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <DatePicker placeholder='Ingrese fecha de transacción'
                                getPopupContainer={(trigger) => trigger.parentElement}
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="monto"
                            label={`Monto devuelto (${selRendicion?.monedaISO})`}
                            initialValue={dvMonto}
                            rules={[
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder='Ingrese monto devuelto'
                                className='text-xs w-36'
                                size='middle'
                                maxLength={15}
                                bordered={true} precision={2}
                                style={{
                                    width: '100%',
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form >

            {
                adjuntos.length > 0 &&
                <div className='mb-3 ant-table-titania'>
                    <Table
                        columns={fiColumns}
                        dataSource={adjuntos}
                        pagination={false}
                        bordered
                        size='small'
                    />
                </div>
            }
            {
                selDevolucion && adjuntos.length === 0 &&
                <div className='flex items-center space-x-2'>
                    <label
                        htmlFor="file-upload-dev"
                        className={"flex items-center p-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (loadFile ? " hover:cursor-not-allowed" : "")}
                    >
                        <CloudUploadOutlined className="mr-1" />
                        <span>Subir archivo</span>
                        <input id="file-upload-dev" name="file-upload-dev" type="file" className="sr-only"
                            onChange={handleUpFile}
                            disabled={loadFile}
                        />
                    </label>
                    {
                        loadFile &&
                        <div className='flex items-center mr-2 text-indigo-600'>
                            <SyncOutlined spin />
                        </div>
                    }
                </div>
            }
        </div>
    )
}
