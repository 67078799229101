import moment from 'moment'
import React, { useRef, useState } from 'react'
import { ChangePwdForm } from '../../components/Login/ChangePwdForm'
import { SignInForm } from '../../components/Login/SignInForm'
import { SignUpForm } from '../../components/Login/SignUpForm'
import { NotifyGreen } from '../../components/Msg/NotifyGreen'
import { NotifyRed } from '../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../components/Msg/NotifyYellow'
import { notifyType } from '../../types/notifyType'
import { assetsImg } from '../../utils/imgUtil'
import { configType } from '../../types/configType'

const imgPath = assetsImg('./pagelogin.jpg')

export const Login = () => {
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [showLogin, setShowLogin] = useState(1)
  const [data, setData] = useState(null)

  const handleOpen = (err, type) => {
    if (type === notifyType.error)
      notifyRedRef.current.handleOpen(err, type)
    else if (type === notifyType.success)
      notifyGreenRef.current.handleOpen(err, type)
    else if (type === notifyType.warning)
      notifyYellowRef.current.handleOpen(err, type)
  }

  const handleShow = (comp, data) => {
    setData(data)
    setShowLogin(comp)
  }

  return (
    <>
      <div className="flex min-h-full">
        <NotifyRed ref={notifyRedRef} />
        <NotifyGreen ref={notifyGreenRef} />
        <NotifyYellow ref={notifyYellowRef} />
        {showLogin === 1 && <SignInForm openNotify={handleOpen} showLogin={handleShow} init={data} />}
        {showLogin === 2 && <SignUpForm openNotify={handleOpen} showLogin={handleShow} />}
        {showLogin === 3 && <ChangePwdForm openNotify={handleOpen} showLogin={handleShow} />}
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={imgPath}
            alt=""
          />
          <div className='absolute bottom-2 text-white w-full'>
            <div>
              <p className="mt-3 mb-2 text-center text-xs leading-5 text-white">
                &copy; {` ${moment().year()} Titania (${configType.version})`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

