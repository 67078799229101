import { getError } from "../api/apiUtil";
import useInterceptDne from "./useInterceptDne";

export const useAxiosDne = () => {
    const axiosDne = useInterceptDne();

    const axiosDneGet = async (url) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosDne.get(url, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosDnePost = async (url, data) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosDne.post(url, JSON.stringify(data), {
                signal: controller.signal,
                headers: { 'Content-Type': 'application/json' },
            })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosDneDelete = async (url) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosDne.delete(url, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    const axiosDnePut = async (url, data) => {
        let err = null
        let dat = null
        const controller = new AbortController()
        try {
            const resp = await axiosDne.put(url, data, { signal: controller.signal })
            dat = resp?.data
        } catch (error) {
            err = getError(error)
            console.log(error)
        }
        return [dat, err]
    }

    return { axiosDneGet, axiosDnePost, axiosDneDelete, axiosDnePut }
}
