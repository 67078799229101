import {
  SearchOutlined, ZoomOutOutlined, CloseCircleOutlined, BankFilled, FileSearchOutlined, FileAddOutlined,
  AppstoreAddOutlined, AppstoreOutlined, QuestionCircleOutlined, SyncOutlined, CheckCircleOutlined
} from '@ant-design/icons'
import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Spin, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { catGetCodUrl, cpeGetProgUrl, segConnUrl, tsk1Url, tsk2Url, tskRecuperarUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { AppFooter } from '../../../components/AppFooter'
import { LydCpe } from '../../../components/Leyend/LydCpe'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { EntryProg } from '../../../components/Panel/cpe/ProCPE/EntryProg'
import { DetalleCpe } from '../../../components/Panel/Detail/DetalleCpe'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { notifyType } from '../../../types/notifyType'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'

import './ProgramarCPE.css'

var jszip = require('jszip')

export const ProgramarCPE = ({ page: { code: resource } }) => {
  const { axiosTnaGet, axiosTnaPost, axiosTnaPut } = useAxiosTna()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [disablePage, setDisablePage] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
  const [loadCondProg, setLoadCondProg] = useState(true)
  const [loadTipoCpe, setLoadTipoCpe] = useState(true)
  const [loadMoneda, setLoadMoneda] = useState(true)
  const [loadBusqCP, setLoadBusqCP] = useState(false)
  const [loadTask1Add, setLoadTask1Add] = useState(false)
  const [loadTask1Down, setLoadTask1Down] = useState(false)
  const [loadTask2Add, setLoadTask2Add] = useState(false)
  const [loadTask2Down, setLoadTask2Down] = useState(false)
  const [rcpt, setRcpt] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedRcpt, setSelectedRcpt] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [condProg, setCondProg] = useState([])
  const [tiposCpe, setTiposCpe] = useState([])
  const [monedas, setMonedas] = useState([])
  const [foFechaIni, setFoFechaIni] = useState(null)
  const [foFechaFin, setFoFechaFin] = useState(null)
  const [foCondProg, setFoCondProg] = useState(null)
  const [foTipoCpe, setFoTipoCpe] = useState(null)
  const [foMoneda, setFoMoneda] = useState(null)
  const [foEmisorID, setFoEmisorID] = useState(null)
  const [foSave, setFoSave] = useState(null)
  const [bills, setBills] = useState([])
  const [cpTotalDocuments, setCpTotalDocuments] = useState(0)
  const [cpPageSize, setCpPageSize] = useState(10)
  const [cpCurrentPage, setCpCurrentPage] = useState(1)
  const [selectedCpe, setSelectedCpe] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [taskInterval, setTaskInterval] = useState(false)
  const [task1, setTask1] = useState({ task: null, id: null, first: true })
  const [task2, setTask2] = useState({ task: null, id: null, first: true })

  useEffect(() => {
    setLoadPage(loadTokenConn || loadRcptEmsr || loadCondProg || loadTipoCpe || loadMoneda)
  }, [loadTokenConn, loadRcptEmsr, loadCondProg, loadTipoCpe, loadMoneda])

  useEffect(() => {
    setDisablePage(loadBusqCP || loadTask1Add || loadTask1Down || loadTask2Add || loadTask2Down)
  }, [loadBusqCP, loadTask1Add, loadTask1Down, loadTask2Add, loadTask2Down])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const rcptEmsr = async () => {
      const url = usuRcptEmsrUrl(resource)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setRcpt(data.rcpt)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadRcptEmsr(false)
    }

    const catCondProg = async () => {
      const url = catGetCodUrl(configType.catCondProg)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setCondProg(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadCondProg(false)
    }

    const catTipoCpe = async () => {
      const url = catGetCodUrl(configType.catTipoCpe)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposCpe(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadTipoCpe(false)
    }

    const catMoneda = async () => {
      const url = catGetCodUrl(configType.catMoneda)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setMonedas(data.detalle.filter(dt => dt.bool1))
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadMoneda(false)
    }

    if (!loadTokenConn) {
      rcptEmsr()
      catCondProg()
      catTipoCpe()
      catMoneda()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const t1Get = async () => {
      const url = tsk1Url()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTask1(prev => ({
          ...prev,
          task: data.data,
          id: data.data?.id ?? prev.id,
          first: false
        }))
      if (err)
        console.log(`Task: ${err.message}`)
    }

    const t2Get = async () => {
      const url = tsk2Url()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTask2(prev => ({
          ...prev,
          task: data.data,
          id: data.data?.id ?? prev.id,
          first: false
        }))
      if (err)
        console.log(`Task: ${err.message}`)
    }

    if (!loadTokenConn) {
      if (task1.first || (!task1.task && task1.id) || (task1.task && !task1.task.complete))
        t1Get()
      if (task2.first || (!task2.task && task2.id) || (task2.task && !task2.task.complete))
        t2Get()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [taskInterval, loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const interval = setInterval(() => {
      isMounted && setTaskInterval(prev => !prev)
    }, 5000)

    return () => {
      clearInterval(interval)
      isMounted = false
    }
  }, [])

  const sortCpe = (a, b) => {
    if (a.fechaContable > b.fechaContable)
      return 1
    if (a.fechaContable < b.fechaContable)
      return -1
    if (a.serie > b.serie)
      return 1
    if (a.serie < b.serie)
      return -1
    if (a.numero > b.numero)
      return 1
    else
      return -1
  }

  const getCpe = (cp) => {
    const newProg = cp.formaPago.montoProg - cp.formaPago.totalProg > 0.0 ? { line: 0, montoProg: 0.0, pagada: false, label: 'NUEVA' } : null
    const intProg = cp.formaPago.lineProg ? cp.formaPago.programacion.find(p => p.line === cp.formaPago.lineProg) : null

    const programacion = cp.formaPago.programacion.map(p => ({ ...p, label: formatDate(p.fechaProg) }))

    const progSel = intProg ?? newProg
    const progData = newProg ? [newProg, ...programacion] : [...programacion]

    return { ...cp, key: cp.id, progSel, progData, progInt: intProg }
  }

  const updateCpe = (cp) => {
    if (cp) {
      setBills(prev => {
        const cpe = getCpe(cp)
        const cpes = prev.filter(p => p.id !== cp.id)
        const data = [...cpes, cpe]
        data.sort(sortCpe)
        return data
      })
    }
  }

  const clearAttach = () => {
    const input = document.getElementById(`file-upload-cpeprog`)
    if (input)
      input.value = null
  }

  const clearFiltroCP = () => {
    setFoSave(null)
    setBills([])
    setCpTotalDocuments(0)
  }

  const handleOpenDrawer = useCallback((open) => setOpenDrawer(open), [setOpenDrawer])

  const handleCloseDrawer = () => {
    setSelectedCpe(null)
    setOpenDrawer(false)
  }

  const handleSelectRcpt = useCallback((person) => {
    setSelectedRcpt(person)
    let grupos = []
    if (person && person.grupo.length > 0)
      grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem }))
    setGroups(grupos)
    clearFiltroCP()
  }, [setSelectedRcpt])

  const handleSelectGroup = useCallback((person) => {
    setSelectedGroup(person)
    clearFiltroCP()
  }, [setSelectedGroup])

  const handleChangeSelProg = (value, record) => setBills(prev => {
    const cps = prev.filter(cp => cp.id !== record.id)
    const progSel = record.progData.find(prog => prog.line === value)
    const data = [...cps, { ...record, progSel }]
    data.sort(sortCpe)
    return data
  })

  const handleClickNumCP = (record, accion) => {
    const cpe = { ...record }
    cpe.value = `${cpe.tipoComprobanteID}-${cpe.serie}-${cpe.numero}`
    cpe.accion = accion
    setSelectedCpe(cpe)
    setOpenDrawer(true)
  }

  const handleBuscarCPFecha = async () => {
    let err = null
    let data = null

    if (!selectedRcpt)
      err = {
        message: msgType.proCpeNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.proCpeNoGroup,
        oops: false
      }
    else if (!foFechaIni || !foFechaFin)
      err = {
        message: msgType.proCpeNoFecha,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqCP(true)

    const grpCode = selectedRcpt.grpAll ? '' : selectedGroup.ruc
    const fechaIni = moment(foFechaIni).format().split('T')[0]
    const fechaFin = moment(foFechaFin).format().split('T')[0]
    const condProg = foCondProg || ''
    const tipoCpe = foTipoCpe || ''
    const moneda = foMoneda || ''
    const emisorID = foEmisorID || ''

    const url = cpeGetProgUrl(selectedRcpt.ruc, grpCode, fechaIni, fechaFin, condProg, tipoCpe, moneda, emisorID, 1, cpPageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: cps } = data
      cps.sort(sortCpe)
      setBills(cps.map(cp => getCpe(cp)))
      setCpTotalDocuments(totalDocuments)
      setCpCurrentPage(1)
      setFoSave({
        svSelectedRcpt: selectedRcpt,
        svGrpCode: grpCode,
        svFechaIni: fechaIni,
        svFechaFin: fechaFin,
        svCondProg: condProg,
        svTipoCpe: tipoCpe,
        svMoneda: moneda,
        svEmisorID: emisorID,
      })
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqCP(false)
    setLastAccess()
  }

  const handleChangePagCP = async (page, pageSize) => {
    if (!foSave)
      return

    const { svSelectedRcpt, svGrpCode, svFechaIni, svFechaFin, svCondProg, svTipoCpe, svMoneda, svEmisorID } = foSave

    let err = null
    let data = null

    setLoadBusqCP(true)

    const url = cpeGetProgUrl(svSelectedRcpt.ruc, svGrpCode, svFechaIni, svFechaFin, svCondProg, svTipoCpe, svMoneda, svEmisorID, page, pageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: cps } = data
      cps.sort(sortCpe)
      setBills(cps.map(cp => getCpe(cp)))
      setCpTotalDocuments(totalDocuments)
      setCpCurrentPage(page)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqCP(false)
    setLastAccess()
  }

  const handleTask1_Add = async () => {
    let err = null
    let data = null

    if (!selectedRcpt)
      err = {
        message: msgType.proCpeNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.proCpeNoGroup,
        oops: false
      }
    else if (!foFechaIni || !foFechaFin)
      err = {
        message: msgType.proCpeNoFecha,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadTask1Add(true)

    const grpCode = selectedRcpt.grpAll ? '' : selectedGroup.ruc
    const fechaIni = moment(foFechaIni).format().split('T')[0]
    const fechaFin = moment(foFechaFin).format().split('T')[0]
    const condProg = foCondProg || ''
    const tipoCpe = foTipoCpe || ''
    const moneda = foMoneda || ''
    const emisorID = foEmisorID || ''

    const url = tsk1Url()
    const [data_api, err_api] = await axiosTnaPost(url, {
      rcptNumDoc: selectedRcpt.ruc, grupo: grpCode, fechaIni, fechaFin,
      condicionID: condProg, tipoComprobanteID: tipoCpe, monedaISO: moneda, emsrNumDoc: emisorID
    })
    data = data_api
    err = err_api

    if (data) {
      setTask1(prev => ({ ...prev, task: null, id: data.id }))
      notifyGreenRef.current.handleOpen({
        message: msgType.proCpeTask1OK,
        oops: false
      }, notifyType.success)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask1Add(false)
    setLastAccess()
  }

  const handleTask1_Down = async ({ id, file: record }) => {
    let err = null
    let data = null

    setLoadTask1Down(true)

    const url = tskRecuperarUrl(id)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
        setTask1(prev => ({ ...prev, task: null, id: null }))
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask1Down(false)
    setLastAccess()
  }

  const handleTask2_Add = async ({ target: { files } }) => {
    let err = null
    let data = null

    if (!files || files.length === 0) {
      clearAttach()
      return;
    }

    const file = files[0]
    const fileExt = file.name.split('.')

    if (!selectedRcpt)
      err = {
        message: msgType.proCpeNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.proCpeNoGroup,
        oops: false
      }
    else if (file.size > configType.attachMaxSize)
      err = {
        message: msgType.proCpeNoFileS,
        oops: false
      }
    else if (!fileExt || fileExt.length < 2 || `.${fileExt[1]}` !== configType.fileExtTXT)
      err = {
        message: msgType.proCpeNoFileE,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      clearAttach()
      return
    }

    setLoadTask2Add(true)

    const formData = new FormData()
    formData.append("", file)

    const grpCode = selectedRcpt.grpAll ? '' : selectedGroup.ruc

    const url = tsk2Url(selectedRcpt.ruc, grpCode)
    const [data_api, err_api] = await axiosTnaPut(url, formData)
    data = data_api
    err = err_api

    if (data) {
      setTask2(prev => ({ ...prev, task: null, id: data.id }))
      notifyGreenRef.current.handleOpen({
        message: msgType.proCpeTask2OK,
        oops: false
      }, notifyType.success)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask2Add(false)
    setLastAccess()
  }

  const handleTask2_Down = async ({ id, file: record }) => {
    let err = null
    let data = null

    setLoadTask2Down(true)

    const url = tskRecuperarUrl(id)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
        setTask2(prev => ({ ...prev, task: null, id: null }))
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask2Down(false)
    setLastAccess()
  }

  const cpColumns = [
    {
      title: 'RUC',
      dataIndex: 'emisor.documento',
      width: 110,
      render: (_, record) => <span>{record.emisor.ruc}</span>
    },
    {
      title: 'Emisor',
      dataIndex: 'emisor.denominacion',
      render: (_, record) => <span>{record.emisor.name}</span>
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      width: 65,
      render: (_, record) => <span>{tiposCpe.find(p => p.codigo === record.tipoComprobanteID)?.nombre}</span>
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      width: 118,
      render: (_, record) =>
        <div className='flex items-center'>
          <button
            className='text-blue-600 w-full text-left'
            onClick={() => handleClickNumCP(record, true)}
          >
            <span>{`${record.serie}-${record.numero}`}</span>
          </button>
          <div className='flex justify-end'>
            {
              record.factoring &&
              <Tooltip placement="left" title="Factoring">
                <BankFilled className='text-green-800' />
              </Tooltip>
            }
          </div>
        </div>
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaContable',
      width: 92,
      render: (_, record) => <span>{formatDate(record.fechaContable)}</span>
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 50,
      align: 'center',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 100,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.total)}</span>
    },
    {
      title: 'Saldo Prog.',
      dataIndex: 'saldoProg',
      width: 100,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.formaPago.montoProg - record.formaPago.pagoProg)}</span>
    },
    {
      title: 'Fecha Vcto.',
      dataIndex: 'fechaVcto',
      width: 92,
      render: (_, record) => <span>{formatDate(record.fechaVcto)}</span>
    },
    {
      title: 'Fecha Prog.',
      dataIndex: 'fechaProg',
      width: 140,
      render: (_, record) => record.progSel && record.progData ?
        <div className='flex items-center'>
          {
            <button className='mr-2 p-0' onClick={() => handleClickNumCP(record, false)}>
              {
                record.progSel.line === 0 ?
                  <FileAddOutlined /> :
                  <FileSearchOutlined />
              }
            </button>
          }
          < Select value={record.progSel.line} className='w-full' size='small' style={{ fontSize: '0.65rem' }}
            onChange={(v) => handleChangeSelProg(v, record)}>
            {
              record.progData.map((prog, index) => (
                <Select.Option key={index} value={prog.line}>{prog.label}</Select.Option>
              ))
            }
          </Select >
        </div > :
        <div className='flex items-center'>
          <Select className='w-full' size='small' style={{ fontSize: '0.65rem' }} />
        </div>
    },
    {
      title: 'Monto Prog.',
      dataIndex: 'montoProg',
      width: 100,
      align: 'right',
      render: (_, record) => record.progSel ?
        <span>{formatAmount(record.progSel.montoProg)}</span> :
        <span></span>
    },
    {
      title: '-@-',
      dataIndex: 'interno',
      width: 55,
      render: (_, record) =>
        <div className='flex items-center space-x-1.5'>
          {
            record.progSel && record.progSel.line !== 0 &&
            <Tooltip placement="left" title={record.progSel.cntProg === 1 ? "Programado" : "Re-Programado"}>
              {
                record.progSel.cntProg === 1 ?
                  <AppstoreOutlined className='text-blue-800' /> :
                  <AppstoreAddOutlined className='text-blue-800' />
              }
            </Tooltip>
          }
          {
            (record.progSel && record.progSel.line !== 0 && record.progSel.aprobada) &&
            <Tooltip placement="left" title="Programación aprobada">
              <CheckCircleOutlined className='text-blue-800' />
            </Tooltip>
          }
          {
            (record.progSel && record.progSel.line !== 0 && !record.progSel.aprobada && !record.progSel.observacion) &&
            <Tooltip placement="left" title="Programación por aprobar">
              <CheckCircleOutlined className='text-yellow-600' />
            </Tooltip>
          }
          {
            (record.progSel && record.progSel.line !== 0 && !record.progSel.aprobada && record.progSel.observacion) &&
            <Tooltip placement="left" title="Programación rechazada">
              <CheckCircleOutlined className='text-red-800' />
            </Tooltip>
          }
        </div>
    },
  ]

  return (
    <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
      <Drawer
        title={`Comprobante: ${selectedCpe?.value ?? ''}`}
        placement="right"
        onClose={handleCloseDrawer}
        open={openDrawer}
        getContainer={false}
        style={{ position: 'absolute' }}
        bodyStyle={{ padding: 20, }}
        headerStyle={{ padding: 15, }}
        destroyOnClose={true}
        maskClosable={false}
        zIndex={5}
        closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
      >
        {
          (selectedCpe?.accion ?? true) ?
            <DetalleCpe rcpt={selectedRcpt} cpe={selectedCpe} /> :
            <EntryProg rcpt={selectedRcpt} cpe={selectedCpe} updateCpe={updateCpe} getCpe={getCpe} openDrawer={handleOpenDrawer} />
        }
      </Drawer>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='flex-grow'>
            <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
              <SelRcptEmsr comboLabel="RECEPTOR" people={rcpt} setPerson={handleSelectRcpt} flex_basis='basis-96' />
              {
                !(selectedRcpt?.grpAll ?? true) &&
                <SelRcptEmsr comboLabel={selectedRcpt?.grpName ?? 'Grupo'} people={groups} setPerson={handleSelectGroup} flex_basis='basis-72' />
              }
            </div>
            <div className='p-3 flex flex-col ant-common-titania'>
              <div className="flex flex-row flex-wrap space-x-3 mb-3">
                <div className="flex items-center text-sm text-indigo-700">
                  {
                    (!task1.id || task1.task?.message) ?
                      <>
                        {
                          !disablePage ?
                            <Popconfirm
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              title={`¿Confirma la operación?`}
                              onConfirm={handleTask1_Add}
                              placement="right"
                              okText="Sí"
                              cancelText="No"
                            >
                              <div className='flex items-center cursor-pointer'>
                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                {`Descargar`}
                              </div>
                            </Popconfirm> :
                            <div className='flex items-center cursor-not-allowed'>
                              <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              {
                                loadTask1Add &&
                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                              }
                              {`Descargar`}
                            </div>
                        }
                      </> :
                      <>
                        {
                          task1.task?.file ?
                            <div className='flex items-center text-xs'>
                              <Tooltip title="Descargar archivo" placement='right'>
                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              </Tooltip>
                              {
                                loadTask1Down &&
                                <SyncOutlined spin className='mr-2' />
                              }
                              <button
                                className='text-blue-600'
                                disabled={disablePage}
                                onClick={() => handleTask1_Down(task1.task)}
                              >
                                {task1.task.file.fileName}
                              </button>
                            </div> :
                            <div className='flex items-center text-xs'>
                              <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              <SyncOutlined spin className='mr-2' />
                              {`Preparando archivo`}
                            </div>
                        }
                      </>
                  }
                </div>
                <div className="flex items-center text-sm text-indigo-700">
                  {
                    (!task2.id || task2.task?.message) ?
                      <>
                        {
                          !disablePage ?
                            <label
                              htmlFor="file-upload-cpeprog"
                              className="flex items-center relative cursor-pointer"
                            >
                              <Tooltip title="Cargar archivo de programación" placement='right'>
                                <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              </Tooltip>
                              <span>Cargar</span>
                              <input id="file-upload-cpeprog" name="file-upload-cpeprog" type="file" className="sr-only"
                                onChange={handleTask2_Add}
                              />
                            </label> :
                            <div className='flex items-center cursor-not-allowed'>
                              <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              {
                                loadTask2Add &&
                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                              }
                              {`Cargar`}
                            </div>
                        }
                      </> :
                      <>
                        {
                          task2.task?.file ?
                            <div className='flex items-center text-xs'>
                              <Tooltip title="Descargar archivo" placement='right'>
                                <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              </Tooltip>
                              {
                                loadTask2Down &&
                                <SyncOutlined spin className='mr-2' />
                              }
                              <button
                                className='text-blue-600'
                                disabled={disablePage}
                                onClick={() => handleTask2_Down(task2.task)}
                              >
                                {task2.task.file.fileName}
                              </button>
                            </div> :
                            <div className='flex items-center text-xs'>
                              <ArrowUpTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              <SyncOutlined spin className='mr-2' />
                              {`Procesando archivo`}
                            </div>
                        }
                      </>
                  }
                </div>
                {
                  (task1.task?.message || task2.task?.message) &&
                  <div className='flex items-center'>
                    <p className="text-xs text-red-700">
                      {task1.task?.message ?? task2.task?.message}
                    </p>
                  </div>
                }
              </div>

              <p className="text-xs text-gray-700 mb-2">
                Busca un comprabante usando los filtros de fecha contable para registrar su programación.
              </p>
              <div className='flex items-center space-x-2'>
                <DatePicker placeholder='Fecha inicio' onChange={(d,) => setFoFechaIni(d?.toDate() ?? null)} />
                <DatePicker placeholder='Fecha fin' onChange={(d,) => setFoFechaFin(d?.toDate() ?? null)} />
                <Select placeholder='Condición' allowClear style={{ width: 155 }} onChange={(v) => setFoCondProg(v || null)}>
                  {
                    condProg.map(({ codigo, nombre }) => (
                      <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                    ))
                  }
                </Select>
                <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                  <Select placeholder='Tipo' allowClear style={{ width: 100 }} onChange={(v) => setFoTipoCpe(v || null)}>
                    {
                      tiposCpe.map(({ codigo, nombre }) => (
                        <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                      ))
                    }
                  </Select>
                  <Select placeholder='-$-' allowClear style={{ width: 75 }} onChange={(v) => setFoMoneda(v || null)}>
                    {
                      monedas.map(({ codigo }) => (
                        <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                      ))
                    }
                  </Select>
                  <Input placeholder="RUC Emisor" style={{ width: 130 }} onChange={(e) => setFoEmisorID(e.target.value)} />
                </div>
                {
                  !disablePage ?
                    <Tooltip title="Buscar comprobante">
                      <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarCPFecha} />
                    </Tooltip> :
                    <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                }
              </div>

              <div className='lg:max-w-7xl'>
                {
                  loadBusqCP ?
                    <div className='my-5 flex justify-center'>
                      <Spin tip='Loading....' size='large' />
                    </div> :
                    <>
                      <div className='mt-3 ant-table-titania ant-table-fnprocpe'>
                        <Table
                          columns={cpColumns}
                          dataSource={bills}
                          pagination={false}
                          scroll={{ y: 400 }}
                          bordered
                          size='small'
                        />
                      </div>
                      <div className='fnprocpe-pag-cp mt-2 flex justify-end'>
                        <Pagination
                          defaultCurrent={cpCurrentPage}
                          total={cpTotalDocuments}
                          showSizeChanger
                          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} comprobantes`}
                          defaultPageSize={cpPageSize}
                          onChange={handleChangePagCP}
                          onShowSizeChange={(_, size) => setCpPageSize(size)}
                          disabled={disablePage} />
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
      }
      <div className='w-full'>
        <AppFooter />
      </div>
      <div className='absolute bottom-3 left-3'>
        <LydCpe />
      </div>
    </div>
  )
}
