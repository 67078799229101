import { axiosTnaGet } from "../api/apiAxios"
import { segCtoTokenUrl } from "../api/apiUrl"
import { getAuth } from "../api/apiUtil"
import { useAuth } from "./useAuth"

export const useTokenCto = () => {
    const { setAuth } = useAuth()

    const refresh = async () => {
        const url = segCtoTokenUrl()
        const [dat_api, err_api] = await axiosTnaGet(url)
        const tokenCto = dat_api?.token
        if (!err_api)
            setAuth(prev => { return { ...prev, tokenCto } })
        else
            setAuth(getAuth(false))
        return tokenCto
    }

    return refresh
}